import * as React from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import { Avatar } from "@mui/material"
import { InputLabel } from "@mui/material"

import classes from "./style.module.css"
import { Grid, Typography } from "@mui/material"
import { Link, useNavigate } from "react-router-dom"
import { boxStyle } from "./notificationStyle"
import { flexboxStyle } from "./notificationStyle"
import logoIcon from "../../../images/momy_logo.svg"
import { BorderBottom } from "@mui/icons-material"
import { api, useGetNotificationQuery } from "../../../features/Auth/authApi"
import { CalculateTimeDifference } from "../../Notification/timeNotification"
import { useAppSelector } from "../../../app/hooks"
import { useStore } from "react-redux"
import bellIconNoti1 from "../../../images/momy_logo.svg"

import { boolean } from "yup"
import CenteredSpinner from "../../common/CenteredSpinner"
import { useAppDispatch } from "../../../app/store"
import { overViewApi } from "../../../features/OverViewAuth/overViewApi"

type Props = {
  open: boolean
  handleClose: (value: string | any) => void
  setOpen: (value: boolean) => void
}

interface Array {}

export default function NotificationModal({
  open,
  handleClose,
  setOpen,
}: Props) {
  const [showAll, setShowAll] = React.useState<Array>([])
  const navigate = useNavigate()
  const {
    data: notificationData,
    isLoading,
    isFetching,
  } = useGetNotificationQuery({ pageLimit: 3 })

  const handleToggleShow = (index: any) => {
    setShowAll((prevShowMore: any) => {
      const newShowMore = [...prevShowMore] // Create a copy of the previous state
      newShowMore[index] = !newShowMore[index] // Toggle the visibility at the specified index
      return newShowMore // Return the updated state
    })
  }

  const dispatch = useAppDispatch()

  React.useEffect(() => {
    dispatch(overViewApi.util.invalidateTags(["countNotification"]))
  }, [notificationData])

  const viewServiceDeatils = (key: any, id: any) => {
    if (
      key === "Accepted" ||
      key === "Expired" ||
      key === "Completed" ||
      key === "Declined"
    ) {
      navigate(`/dashboard/bookings/details/${id}`)
      handleClose("" as any)
    } else if (key === "Active") {
      navigate(`/dashboard/active-view/${id}`)
      handleClose("" as any)
    }
  }
  return (
    <>
      {isLoading ? (
        ""
      ) : (
        <Dialog
          className={classes.notification_modal_popup_p}
          PaperProps={{
            sx: {
              position: "absolute",
              right: "50px",
              borderRadius: "16px",
              top: "45px",
              backgroundColor: "white",
              boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.03)", //
              "@media (max-width:480px)": {
                right: "0px",
              },
              "@media (max-width:360px)": {
                right: "60px",
              },
            },
          }}
          disableEscapeKeyDown
          open={open}
          onClose={handleClose}
          onBackdropClick={handleClose as any}
        >
          <Box sx={boxStyle} className="custom-scrollbar">
            <Box sx={flexboxStyle}>
              <InputLabel
                style={{
                  fontSize: "14px",
                  color: "#f475a4",
                  paddingLeft: "10px",
                }}
              >
                Notifications
              </InputLabel>

              <InputLabel style={{ fontSize: "14px" }}>
                <Link
                  style={{ textDecoration: "none", color: "#25324B" }}
                  onClick={handleClose as any}
                  to="/dashboard/notification"
                >
                  See all
                </Link>
              </InputLabel>
            </Box>

            <Grid
              className={classes.notification_grid}
              container
              justifyContent="center"
              sm={12}
            >
              <Grid item md={11} sm={12}>
                {notificationData?.slice(0, 3)?.map((noti: any) => (
                  <Box>
                    <Box className={classes.notification_grid_card}>
                      <Box
                        className="feature-card d-flex align-items-start gap-1 mt-3"
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          viewServiceDeatils(
                            noti?.postId?.status,
                            noti?.postId?._id,
                          )
                        }
                      >
                        <img
                          src={logoIcon}
                          className={classes.notification_card_icon}
                        />
                        <Box>
                          <Typography
                            className={classes.notification_card_heading}
                          >
                            {noti?.type}
                          </Typography>
                          <Typography
                            variant="body1"
                            className={classes.notification_card_text}
                          >
                            {noti?.content}
                          </Typography>
                          <Typography
                            variant="body1"
                            className={classes.notification_card_time}
                          >
                            <CalculateTimeDifference
                              createdAt={noti?.createdAt}
                            />
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Grid>
            </Grid>
          </Box>
        </Dialog>
      )}
    </>
  )
}
