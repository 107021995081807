import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { RootState } from "../../app/store"

const BASE_URL = import.meta.env.VITE_REACT_APP_API_URL

export const apiPayment = createApi({
  reducerPath: "paymentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/api/`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token

      if (token) {
        headers.set("authorization", `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: [`changePasswordTags`],
  endpoints: (builder) => ({
    paymentBooking: builder.mutation<any, any>({
      query: (data) => ({
        url: `payment/create-checkout`,
        method: "POST",
        body: data,
      }),
    }),

    createpayment: builder.mutation<any, any>({
      query: (data) => ({
        url: `seeker/create-payment`,
        method: "POST",
        body: data,
      }),
    }),

    protected: builder.mutation<{ message: string }, void>({
      query: () => "protected",
    }),
  }),
})

export const {
  usePaymentBookingMutation,
  useCreatepaymentMutation,

  // useGetActivePostApiMutation,
} = apiPayment
