import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { RootState } from "../../app/store"
const BASE_URL = import.meta.env.VITE_REACT_APP_API_URL

// const BASE_URL = "https://mommyshelp-dev.ddns.net"

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/api/seeker/`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token

      if (token) {
        headers.set("authorization", `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: [
    "changePasswordTags",
    "loginUser",
    "notifications",
    "updateAccount",
  ],
  endpoints: (builder) => ({
    login: builder.mutation<any, any>({
      query: (credentials) => ({
        url: "login",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["loginUser", "updateAccount"],
    }),

    logoutCustomer: builder.mutation<any, any>({
      query: (credentials) => ({
        url: "logout",
        method: "POST",
        body: credentials,
      }),
    }),

    emailVerify: builder.query<any, any>({
      query: () => ({
        url: "verify-email",
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
    }),

    userRegister: builder.mutation<any, any>({
      query: (userRegisterData) => ({
        url: "register",
        method: "POST",
        body: userRegisterData,
      }),
    }),

    forgetPassword: builder.mutation<any, any>({
      query: (forgetRequestData) => ({
        url: "forget-password",
        // mode: "cors",
        method: "POST",
        body: forgetRequestData,
      }),
    }),

    resetPassword: builder.mutation<any, any>({
      query: (resetRequestData) => ({
        url: "reset-password",
        // mode: "cors",
        method: "POST",
        body: resetRequestData,
      }),
    }),

    changePassword: builder.mutation<any, any>({
      query: (resetData) => ({
        url: "reset-password",
        // mode: "cors",
        method: "POST",
        body: resetData,
      }),
    }),

    providersReview: builder.mutation<any, any>({
      query: (resetData) => ({
        url: "get-providers-review",
        // mode: "cors",
        method: "GET",
        // body: resetData,
      }),
    }),

    editReview: builder.mutation<any, any>({
      query: (id) => ({
        url: `get-providers-review?reviewId=${id}`,
        // mode: "cors",
        method: "GET",
        // body: resetData,
      }),
    }),

    updateReview: builder.mutation<any, any>({
      query: (payload) => ({
        url: `create-update-providers-review`,
        // mode: "cors",
        method: "POST",
        body: payload,
      }),
    }),

    postTimelineApi: builder.mutation<any, any>({
      query: (payload) => ({
        url: `get-post-timeline?postId=${payload}`,
        // mode: "cors",
        method: "GET",
        // body: payload,
      }),
    }),

    accountUpdate: builder.mutation<any, any>({
      query: (data) => ({
        url: `update-account`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["changePasswordTags", "updateAccount"],
    }),

    getProfile: builder.query<any, any>({
      query: () => ({
        url: "get-profile",
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
      providesTags: [
        "changePasswordTags",
        "loginUser",
        "updateAccount",
        "notifications",
      ],
    }),

    // getNotification: builder.query<any, any>({
    //   query: (data) => ({
    //     url: `/get-notification?pageNo=${data?.pageNo || ""}&pageLimit=${
    //       data?.pageLimit || ""
    //     }&isSeenKey=${data?.isSeenKey || ""}`,
    //     method: "GET",
    //     onSuccess: (data: any) => {
    //       return console.log(data, "dataaa")
    //     },
    //   }),
    //   providesTags : ["loginUser"]
    // }),

    getNotification: builder.query<any, any>({
      query: (data) => ({
        url: `/get-notification?pageNo=${data?.pageNo || ""}&pageLimit=${
          data?.pageLimit || ""
        }`,
        method: "GET",
        onSuccess: (data: any) => {
          return console.log(data, "dataaa")
        },
      }),
      transformResponse: (response: any) => {
        if (response.data) return response.data
      },
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result?.map(({ _id }: any) => ({
                type: "notification" as const,
                _id,
              })),
              "notifications",
              "updateAccount",
            ]
          : [{ type: "notifications", result }],
    }),

    // change password api

    chanegPasswords: builder.mutation<any, any>({
      query: (data) => ({
        url: `change-password`,
        method: "POST",
        body: data,
      }),
    }),

    protected: builder.mutation<{ message: string }, void>({
      query: () => "protected",
    }),
  }),
})

export const {
  useLoginMutation,
  useLogoutCustomerMutation,
  useProtectedMutation,
  useEmailVerifyQuery,
  useForgetPasswordMutation,
  useUserRegisterMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
  useProvidersReviewMutation,
  useEditReviewMutation,
  useUpdateReviewMutation,
  usePostTimelineApiMutation,
  useAccountUpdateMutation,
  useGetProfileQuery,
  useChanegPasswordsMutation,
  useGetNotificationQuery,

  // useGetActivePostApiMutation,
} = api
