import React from "react";
import dayjs from 'dayjs';
import {
    Avatar,
    Box,
    Button,
    Divider,
    Grid,
    Paper,
    Rating,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Typography,
    FormControlLabel,
    Input,
} from "@mui/material"
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { LiaAngleRightSolid } from "react-icons/lia"
import { FaChevronLeft } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import { RiEdit2Line } from "react-icons/ri";
import * as Style from "../style"
import moment from "moment"
import { Form } from "react-bootstrap";
const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 60,
    height: 30,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: "4px 2px",
        transitionDuration: '300ms',
        transform: 'translateX(3px)',
        color: '#C9C4C4',
        "& + .MuiSwitch-track": {
            border: "1px solid",
            borderColor: "#C9C4C4",
        },
        '&.Mui-checked': {
            transform: 'translateX(31px)',
            color: theme.palette.pink.main,
            boxShadow: "none",
            '& + .MuiSwitch-track': {
                backgroundColor: "transparent",
                border: "1px solid",
                borderColor: theme.palette.pink.main,
                opacity: 1,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
        boxShadow: "none"
    },
    '& .MuiSwitch-track': {
        borderRadius: 70 / 2,
        backgroundColor: "transparent",
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));
const AddOffers = () => {
    const [value, setValue] = React.useState("")
    const [age, setAge] = React.useState("")
    const handleSelectChange = (event: any) => {
        setAge(event.target.value)
    }

    return (
        <>
            {" "}
            <Box className="pt-5 px-5">
                <Style.Overview>
                    <Button className="back-btn"><FaChevronLeft /></Button>
                    <Grid container>
                        <Grid md={6} className="mb-4 h-auto pe-md-2">
                            <Box className="feature-card shadow-none p-3 mb-4">
                                <Typography className="field-label">Offer/ Discount <RiEdit2Line /></Typography>
                                <Input placeholder="Give title to service" className="feature-field title_field" fullWidth />
                            </Box>
                            <Box className="feature-card shadow-none p-3">
                                <Typography className="field-label">Description <RiEdit2Line /></Typography>
                                <Input placeholder="Give title to service" multiline rows={6} className="feature-field" fullWidth />
                            </Box>
                        </Grid>
                        <Grid md={6} className="mb-4 ps-md-2">
                            <Box className="feature-card shadow-none p-3 mb-4">
                                <Typography className="field-label mb-1">Active/ Deactive</Typography>
                                <Typography variant="body1" className="lh-base mb-3">Lorem ipsum dolor sit amet consectetur. Gravida id nibh eleifend amet viverra eget quis. Quam non eu nibh gravida in neque nisl in eget. </Typography>
                                <FormControlLabel
                                    className="active-toggle"
                                    control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                                    label="Active"
                                />
                            </Box>
                            <Box className="feature-card shadow-none p-3">
                                <Typography className="field-label mb-1">Valid detail <RiEdit2Line /></Typography>
                                <Typography variant="body1" className="lh-base mb-4">Lorem ipsum dolor sit amet consectetur. Gravida id nibh eleifend amet viverra eget quis. Quam non eu nibh gravida in neque nisl in eget. </Typography>
                                <Box className="d-flex justify-content-center align-items-start mt-3 gap-2 mb-5">
                                    <Typography variant="body1" className="lh-lg" color={"black"}>Date</Typography>
                                    <Box className="date-picker-field">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer
                                                components={[
                                                    'MobileDatePicker',
                                                ]}
                                            >
                                                <DemoItem label="from">
                                                    <MobileDatePicker defaultValue={dayjs('2022-04-17')} />
                                                </DemoItem>
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Box>
                                    <Box className="date-picker-field">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer
                                                components={[
                                                    'MobileDatePicker',
                                                ]}
                                            >
                                                <DemoItem label="to">
                                                    <MobileDatePicker defaultValue={dayjs('2022-04-17')} />
                                                </DemoItem>
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Box>
                                </Box>
                                <Box className="d-flex justify-content-center align-items-start mt-3 gap-2">
                                    <Typography variant="body1" className="lh-lg" color={"black"}>Users</Typography>
                                    <FormControl
                                        sx={{ m: 1, minWidth: 120 }}
                                        className="book-selectbx"
                                        size="small"
                                    >
                                        <Select
                                        name=""
                                            labelId="book-select-label"
                                            id="demo-select-small"
                                            fullWidth
                                            value={age}
                                            onChange={handleSelectChange}
                                        >
                                            <MenuItem value={10}>For all</MenuItem>
                                            <MenuItem value={20}>For Me</MenuItem>
                                            <MenuItem value={30}>For subscribe</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid md={12}>
                            <Button variant="contained" className="delete-feature-btn me-2">Delete Offer</Button>
                            <Button variant="outlined" className="btn-save">Save</Button>
                        </Grid>
                    </Grid>
                </Style.Overview>
            </Box>
        </>
    )
}
export default AddOffers