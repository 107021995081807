import * as React from "react"
import {
  Box,
  Button,
  Grid,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material"

import * as Style from "./style"
import { MdOutlineModeEditOutline } from "react-icons/md"
import { useProvidersReviewMutation } from "../../features/Auth/authApi"
import { useEffect } from "react"
import EditReviews from "./EditReviews"
import SimpleBackdrop from "../Loder/Loder"
const Feedback = () => {
  const [loading, setLoading] = React.useState(true)
  const [open, setOpen] = React.useState("")
  const [providerId, setProviderId] = React.useState()
  const handleOpen = (id : any, providerId : any) => {
    setOpen(id)
    setProviderId(providerId)
  }
  const handleClose = () => setOpen("")

  const [reviewsProvider, { isError, error, data }] =
    useProvidersReviewMutation()

  React.useEffect(() => {
    if (data?.message) {
      setLoading(false)
    }
  }, [data])

  useEffect(() => {
    const payload ={}
    reviewsProvider(payload)
  }, [])

  return (
    <>
      <Style.TableAppointment>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid xs={8}>
            <Typography>Your Recent Review</Typography>
            <TableContainer>
              {data?.data?.map((item : any) => (
                <Table
                  aria-label="simple table"
                  style={{ marginBottom: "20px" }}
                >
                  <TableBody>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                          marginBottom: "8px",
                        },
                      }}
                    >
                      <TableCell>
                        <img
                          src="https://picsum.photos/seed/picsum/200/300"
                          alt="img"
                          style={{
                            borderRadius: " 16px 0px 0px 16px",
                            objectFit: "cover",
                            height: "75px",
                          }}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <Typography
                          color="pink.main"
                          sx={{ fontFamily: " Nunito Sans", fontSize: "13px" }}
                        >
                          {item?.providerData?.name}
                        </Typography>
                      </TableCell>
                      <TableCell align="right" width={400}>
                        {item?.message}
                      </TableCell>
                      <TableCell align="right">
                        <Rating
                          name="half-rating"
                          defaultValue={item?.rating}
                          max={5}
                          // mb={1}
                          sx={{ verticalAlign: "middle" }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  <Button
                    variant="outlined"
                    sx={{
                      color: "pink.main",
                      borderColor: "pink.main",
                      fontSize: "16px",
                      float: "right",
                    }}
                    onClick={() =>
                      handleOpen(item?._id, item?.providerData?._id)
                    }
                  >
                    Edit <MdOutlineModeEditOutline fontSize={20} />
                  </Button>
                </Table>
              ))}
            </TableContainer>
          </Grid>
          <Grid xs={3}>
            {/* <Typography sx={{ fontFamily: "poppins" }}>
              Your Recent Service
            </Typography> */}
            <Box
              className="recent"
              sx={{
                padding: "10px",
                background: "white.main",
                borderRadius: "8px",

                display: "flex",
                flexDirection: " column",
                alignItems: "center",
                width: " 100%",
                justifyContent: "end",
              }}
            >
              <img
                src="https://picsum.photos/id/237/200/300"
                style={{
                  height: "60px",
                  width: "60px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  marginBottom: "20px",
                }}
                alt="img"
              />
              <Typography
                sx={{ color: "grey.main300", fontFamily: "Montserrat" }}
                mb={1}
              >
                Sarah Geller
              </Typography>
              <Typography
                sx={{ color: "grey.main300", fontFamily: "Montserrat" }}
                mb={1}
              >
                Service: Babysitting
              </Typography>
              <Typography
                sx={{ color: "grey.main300", fontFamily: "Montserrat" }}
                mb={1}
              >
                Date: June 10th, 2023
              </Typography>
              <Typography
                sx={{ color: "grey.main300", fontFamily: "Montserrat" }}
                mb={1}
              >
                Time: 6:00pm-10:00pm
              </Typography>
              <Button
                variant="outlined"
                sx={{
                  color: "pink.main",
                  borderColor: "pink.main",
                  fontSize: "16px",
                  float: "right",
                }}
              >
                Write Your Review <MdOutlineModeEditOutline fontSize={20} />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Style.TableAppointment>
      {loading && <SimpleBackdrop />}
      <EditReviews
        open={open as any}
        handleClose={handleClose}
        providerId={providerId as any}
      />
    </>
  )
}
export default Feedback
