import * as React from "react"
import {
  Avatar,
  Box,
  Button,
  Divider,
  Table,
  Grid,
  Paper,
  Rating,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import * as Style from "../style"
import comingsoon from "../../../images/comingsoon.png";


const OfferPage = () => {


  return (
    <>
       <TableContainer
            component={Paper}
            className="dashboard-table-wrapper custom-scrollbar overflow-y-hidden"
          >
            <Table
              style={{
                minWidth: 650,
                border: "none",
                backgroundColor: "#fff",
                borderRadius: "16px",
                overflow: "hidden",
              }}
              className="dashboard-table"
              aria-label="simple table"
            >
                <TableBody>

                 
                    
                    
              <TableRow>
                      <TableCell 
                        align="center"
                        colSpan={7}
                      
                        sx={{
                          width: "100%",
                          pointerEvents: "none",
                          border: "none",
                          height: "498px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection:"column",

                        }}
                      >
                        <img
                          src={comingsoon}
                          style={{
                            width: "100%",
                            maxWidth: "208px",
                            marginBottom: "15px",
                          }}
                        />
                        <span className="no-data-text"
                        style={{fontSize:"20px",
                        color:"#000",
                        fontWeight:"600",}}>Offer Coming Soon</span>
                      </TableCell>
                    </TableRow>
                </TableBody>
       
            </Table>
          </TableContainer>
    </>
  )
}
export default OfferPage
