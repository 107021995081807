import "./App.css"
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom"
import AppWrapper from "./components/AppWrapper"
import AdminDashboardTemplate from "./components/AdminDashboardTemplate/AdminDashboardTemplate"
import { ThemeProvider } from "@mui/material"
import theme from "./Themes/Default"
import { authRoute, customerDashboard } from "./components/routesfile"
import EmailVeriFy from "./components/Auth/EmailVerify"
import ResetPasswordAuth from "./components/Auth/ResetPassword"
import AuthWrapper from "./components/Auth/AuthWrapper"
import { useAppSelector } from "./app/hooks"
import { useEffect, useState } from "react"
import { useSocketNotificationsQuery } from "./features/Socket/Socketio"
import { useAppDispatch } from "./app/store"
import { toast } from "react-toastify"
import { api } from "./features/Auth/authApi"
import { overViewApi } from "./features/OverViewAuth/overViewApi"
import { useSocketChatMessageQuery } from "./features/ChatSocket/chat"
import { _setMessage, _setTicket } from "./features/ChatAuth/chatSlice"
import { Ticket } from "./components/ChatenterFace/chatinterface"
import { chatViewApi } from "./features/ChatAuth/chatApi"
import StorylaneEmbed from "./components/StorylaneEmbed"
// import { useSocketChatMessageQuery } from "./features/Chat/chat"

interface NotificationProps {
  content: string
}

function App() {
  const token = useAppSelector((state) => state.auth.token)

  const [getnotification, setGetNotification] = useState<NotificationProps[]>(
    [],
  )

  const { data: notificationData } = useSocketNotificationsQuery(token, {
    refetchOnMountOrArgChange: true,
    skip: token == null,
  })
  useEffect(() => {
    if (notificationData) {
      setGetNotification(notificationData)
    }
  }, [notificationData])

  const { data: chatMessage } = useSocketChatMessageQuery(token, {
    refetchOnMountOrArgChange: true,
    skip: token == null,
  })

  const dispatch = useAppDispatch()
  useEffect(() => {
    if (getnotification.length) {
      const id = notificationData[0]?._id
      dispatch(api.util.invalidateTags(["notifications"]))
      dispatch(
        overViewApi.util.invalidateTags([
          "activejob",
          "notifications",
          "loginUser",
        ]),
      )
      dispatch(chatViewApi.util.invalidateTags(["notifications"]))
    }
  }, [getnotification])

  // useEffect(() => {
  //   if (chatMessage?.length) {
  //     dispatch(_setMessage(chatMessage))

  //     // dispatch(_setTicket(newArrayList))
  //   }
  // }, [chatMessage, dispatch])

  // const baseRoutes = [
  //   {
  //     index: true,
  //     element: <AuthWrapper />,

  //     // loader: <>load</>,
  //   },
  // ]

  const router = createBrowserRouter([
    {
      path: "/",
      element: <AppWrapper />,
      // loader: <>loader</>,
      // children: baseRoutes,
    },
    {
      element: <EmailVeriFy />,
      path: "verify-email",
    },
    {
      element: <ResetPasswordAuth />,
      path: "reset-password",
    },
    {
      path: "/dashboard",
      element:
        token && token ? (
          <AdminDashboardTemplate />
        ) : (
          <Navigate to="/" replace />
        ),

      children: customerDashboard,
    },
    {
      path: "/",
      element: !token ? <AuthWrapper /> : <Navigate to="/dashboard" replace />,
      children: authRoute,
    },
  ])
  return (
    <>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </>
  )
}

export default App
