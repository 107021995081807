import React, { useCallback, useEffect, useRef, useState } from "react"
import SelectedChatInfo from "./SelectedChatInfo"
// import ChatDialogues from "./ChatDialogues"
import { IoArrowBackCircle } from "react-icons/io5"
import styles from "./ChatUi.module.css"
import { Col, Container, Form, Row } from "react-bootstrap"
import { FiSend } from "react-icons/fi"
import addfile from "../../images/addfile.svg"
import { useCreateChatMessageMutation } from "../../features/ChatAuth/chatApi"
import { useSearchParams } from "react-router-dom"
import EmojiPicker from "emoji-picker-react"
import { Box, CircularProgress, debounce, Typography } from "@mui/material"
import FileUpload from "../common/multipleImage"
import { RiArrowDropDownLine } from "react-icons/ri"
import { useAppSelector } from "../../app/hooks"
import { Ticket } from "../ChatenterFace/chatinterface"
import Dialogue from "./Dialogue"

interface PropsUser {
  setChatResponsive: (value: boolean) => void
  updateChatList: string
  // userName: any[] // Adjust type according to your actual data structure
}

const ChatArea = ({ setChatResponsive, updateChatList }: PropsUser) => {
  const [sendMessage, setSendMessage] = useState<string>("")
  const [arrayImages, setArrayImages] = useState<any[]>([]) // Adjust type according to your actual data structure
  const [uploadFileShow, setUploadFileShow] = useState(false)
  const [imageIndexCheck, setImageIndexCheck] = useState<any[]>([]) // Adjust type according to your actual data structure
  const [mujiShow, setMujiShow] = useState(false)
  const [chosenEmoji, setChosenEmoji] = useState<any>(null) // Adjust type according to your actual data structure
  const [isSendingMessage, setIsSendingMessage] = useState(false)
  const [ticketIdPass] = useSearchParams()
  const _chatId: string | null = ticketIdPass.get("chatId")
  const _adminId: string | null = ticketIdPass.get("adminId")
  const [createMessage, { isLoading }] = useCreateChatMessageMutation()

  const handleFile = () => {
    setUploadFileShow(!uploadFileShow)
    setMujiShow(false)
  }

  function base64ToFile(
    base64Data: string,
    filename: string,
    mimeType: string,
  ): File {
    const byteCharacters = atob(base64Data?.split(",")[1])
    const byteArrays: Uint8Array[] = []

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    const file = new File(byteArrays, filename, { type: mimeType })
    return file
  }

  const imagesArray = (images: any[]) => {
    setArrayImages(images)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSendMessage(event.target.value)
  }

  const submitChat = (
    event:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault()

    // Check if sendMessage (text message) is not empty or imageIndexCheck (images) is not empty
    if (sendMessage.trim() !== "" || imageIndexCheck.length > 0) {
      if (isSendingMessage) {
        return
      }
      setIsSendingMessage(true)

      const formData = new FormData()
      formData.append("ticketId", _chatId || "")
      formData.append("content", sendMessage || "")
      formData.append("userType", "SEEKER")
      formData.append("adminId", _adminId || "")
      formData.append("recieverType", "ADMIN")

      arrayImages.forEach((image, index) => {
        const key = index === 0 ? "mediaArr" : `mediaArr${index}`
        formData.append(key, image as any)
      })

      createMessage(formData)
        .then((response) => {
          if ((response as any)?.data?.status == 200) {
            setUploadFileShow(false)
            setImageIndexCheck([])
            setSendMessage("")
            setMujiShow(false)
            setChosenEmoji(null)
            setArrayImages([])
          }
        })
        .catch((error) => {
          console.error("Error creating message:", error)
        })
        .finally(() => {
          setIsSendingMessage(false) // Reset flag after message sent or failed
        })
    }
  }

  const onEmojiClick = (event: any) => {
    setChosenEmoji(event?.unified)

    const unicode = event?.unified
    const emoji = String.fromCodePoint(parseInt(unicode, 16))
    setSendMessage(sendMessage + emoji)
  }

  const handleMoujiShow = () => {
    setMujiShow(!mujiShow)
    setUploadFileShow(false)
  }

  const state = useAppSelector((state: any) => state.authChatView)
  const TicketPath: Ticket[] =
    state._search?.split("").length > 0
      ? state._searchedTickets
      : state._Tickets

  const userNameNew: any = TicketPath?.find((item: any) => item?._id == _chatId)

  const textareaRef = useRef<HTMLTextAreaElement | null>(null)

  useEffect(() => {
    if (textareaRef.current) {
      // Reset the height to auto to get the natural height
      textareaRef.current.style.height = "auto"
      // Set the height to the scrollHeight to expand as needed
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    }
  }, [sendMessage])

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault() // Prevent default behavior of Enter key
      submitChat(event as any)
    }
  }

  // scroll top chat

  const [loadingMore, setLoadingMore] = useState<boolean>(false)
  const [scrollLimit, setScrollLimit] = useState<number>(30)

  const chatListRef = useRef<HTMLDivElement>(null)
  const topOfChatRef = useRef<HTMLDivElement>(null)

  const handleScroll = useCallback(
    debounce(() => {
      if (chatListRef.current) {
        const { scrollTop, clientHeight, scrollHeight } = chatListRef.current
        if (scrollTop === 0 && !loadingMore) {
          setLoadingMore(true)
          setScrollLimit((prevLimit) => prevLimit + 20)
        }
      }
    }, 300),
    [loadingMore],
  )

  useEffect(() => {
    const ref = chatListRef.current
    ref?.addEventListener("scroll", handleScroll)

    return () => {
      ref?.removeEventListener("scroll", handleScroll)
    }
  }, [loadingMore])

  useEffect(() => {
    if (chatListRef.current && topOfChatRef.current) {
      chatListRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    }
  }, [scrollLimit])

  useEffect(() => {
    if (_chatId) {
      setScrollLimit(20)
    } else if (updateChatList) {
      setScrollLimit(updateChatList as any)
    }
  }, [_chatId, updateChatList])

  useEffect(() => {
    if (updateChatList) {
      setScrollLimit(updateChatList as any)
    }
  }, [updateChatList])

  return (
    <>
      <SelectedChatInfo setChatResponsive={setChatResponsive} />

      <div
        className={`${styles["chat-area"]} ${styles["custom-shadow"]} ${styles["chat-area-bg"]} p-2 p-lg-3 pt-4 mb-2 position-relative ${styles["rounded-xl"]}`}
      >
        <div
          ref={chatListRef}
          className={`overflow-y-auto custom-scrollbar ${styles["chat-dialogue-h"]}`}
        >
          <Dialogue
            setMessageLoading={setLoadingMore}
            scrollLimit={scrollLimit}
            loadingMore={loadingMore}
            topOfChatRef={topOfChatRef}
          />
        </div>

        {_chatId && _adminId && (
          <Container>
            <form onSubmit={submitChat}>
              <Row
                className={`position-absolute bottom-0 mb-1 w-100 ${styles["rounded-xl"]}`}
              >
                <Col className="px-0">
                  <div
                    className={`position-relative m-auto my-1 ${styles["message-field-group"]}`}
                  >
                    <Form.Control
                      as="textarea"
                      ref={textareaRef}
                      value={sendMessage}
                      className={`text-truncate ${styles["message-field"]}`}
                      placeholder="Type a message"
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      disabled={userNameNew?.status === "CLOSED"}
                      style={{
                        overflow: "hidden",
                        resize: "none",
                        maxWidth: "100%",
                        maxHeight: "150px",
                        whiteSpace: "pre-wrap",
                        boxSizing: "border-box",
                      }}
                      rows={1}
                    />

                    <button
                      type="button"
                      className={`btn border-0 ${styles["emoji-btn"]}`}
                      onClick={handleMoujiShow}
                      disabled={userNameNew?.status === "CLOSED"}
                    >
                      {!mujiShow ? "😊" : <RiArrowDropDownLine />}
                    </button>
                    {mujiShow && (
                      <EmojiPicker
                        style={{ width: "310px", height: "310px" }}
                        onEmojiClick={onEmojiClick}
                        className={`p-absolute ${styles["chat-emoji"]}`}
                      />
                    )}

                    <button
                      type="button"
                      className={`btn ${styles["files-btn"]}`}
                      onClick={handleFile}
                      disabled={userNameNew?.status === "CLOSED"}
                    >
                      <img src={addfile} alt="Add File" />
                    </button>
                  </div>
                </Col>

                <Col xs="auto" className="d-flex align-items-center ps-0">
                  {/* {(sendMessage.trim() !== "" ||
            imageIndexCheck.length > 0 ||
            chosenEmoji !== null) && ( */}
                  <button
                    type="submit"
                    className={`${styles["send-btn--type-2"]}`}
                    disabled={
                      sendMessage.trim() !== "" ||
                      imageIndexCheck.length > 0 ||
                      chosenEmoji !== null ||
                      isSendingMessage
                        ? false
                        : true
                    }
                  >
                    {isLoading ? (
                      <Typography fontSize={1}>
                        <CircularProgress
                          style={{ color: "pink", fontSize: 24 }}
                          size={24}
                        />
                      </Typography>
                    ) : (
                      <FiSend />
                    )}
                  </button>
                  {/* )} */}
                </Col>
              </Row>
            </form>

            {uploadFileShow && (
              <Row>
                <Box className={` ${styles["chat-background"]}`}>
                  <FileUpload
                    base64ToFile={base64ToFile as any}
                    uploadImageArray={imagesArray}
                    handleFile={handleFile}
                    setImageIndexCheck={setImageIndexCheck as any}
                    reapeatImage={"" as any} // Adjust as needed
                    bookingDetailsShow={false}
                  />
                </Box>
              </Row>
            )}
          </Container>
        )}
      </div>
    </>
  )
}

export default ChatArea
