import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { RootState } from "../../app/store"

const BASE_URL = "http://192.168.29.239:5000"

export const manageViewApi = createApi({
  reducerPath: 'manageViewAuth',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/api/`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token
      if (token) {
        headers.set("authorization", `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: ['managereport', `bookingRequest`],
  endpoints: (builder) => ({
    getGenratePost: builder.query<any, void>({
      query: (args: void) => "team/get-generated-posts",
      providesTags: ["bookingRequest"],
    }),






    protected: builder.mutation<{ message: string }, void>({
      query: () => "protected",
    }),
  }),

})

export const {
  useGetGenratePostQuery,

} = manageViewApi
