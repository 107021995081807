import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import io, { Socket } from "socket.io-client"
import { RootState, store, useAppDispatch } from "../../app/store"
import { toast } from "react-toastify"
import { _setMessage, _setTicket } from "../ChatAuth/chatSlice"
import { Ticket } from "../../components/ChatenterFace/chatinterface"
import { setnewMessage } from "../OverViewAuth/overViewSlice"
import { setSocketId } from "../Auth/authSlice"
// import { useGetBookingHistoryQuery } from "../OverViewAuth/overViewApi"
let socket: Socket
export const chatMessageApi = createApi({
  reducerPath: "chatApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/" }),
  tagTypes: ["chat"],
  endpoints: (builder) => ({
    // socketChatMessage: builder.query<any, string | null>({
    //   query: () => "",
    socketChatMessage: builder.query<any, any>({
      queryFn: (arg: string | null) => ({ data: [] }), // Dummy query to satisfy RTK Query
      async onCacheEntryAdded(
        token,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        if (!token) {
          return
        }

        const BASE_URL = import.meta.env.VITE_REACT_APP_API_URL
        socket = io(`${BASE_URL}/chat?token=${token}`, {
          reconnection: true,
          reconnectionAttempts: Infinity,
          reconnectionDelay: 1000,
          reconnectionDelayMax: 1000,
          timeout: 20000,
          autoConnect: true,
        })

        socket.on("connect", () => {
          const id: any = socket.id
          store.dispatch(setSocketId(id))
        })
        socket.on("ping", () => {
          socket.emit("pong") // Respond with pong to server
        })
        socket.on("message", (message: any) => {
          store.dispatch(_setMessage(message))
          store.dispatch(setnewMessage(message))
          // toast.success(message?.content, {
          //   autoClose: 5000,
          // })
          // updateCachedData((draft) => {
          //   draft.push(message)
          // })
        })

        socket.on("chat-tickets", (chattickets: any) => {
          const ticketNewArray = new Array(chattickets)
          store.dispatch(_setTicket(ticketNewArray as any))
        })

        // await cacheDataLoaded
        // await new Promise((resolve) => socket.on("disconnect", resolve))

        // await cacheDataLoaded
        // await cacheEntryRemoved
        // socket.disconnect()

        try {
          await cacheDataLoaded
          await cacheEntryRemoved
        } finally {
          socket.disconnect()
        }
      },
      providesTags: ["chat"],
    }),
  }),
})

export const { useSocketChatMessageQuery } = chatMessageApi
