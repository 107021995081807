import React from "react"
import styles from "./EditBookingPage.module.css"
import { FiPhone } from "react-icons/fi"
import { MdOutlineMail } from "react-icons/md"
import { FaEdit } from "react-icons/fa"
import { Button } from "react-bootstrap"
import { useAppSelector } from "../../app/hooks"
import { useNavigate } from "react-router-dom"
import dummyImage from "../../images/dummy-image.jpg"
import moment from "moment"

export default function EditBookingPage() {
  const bookingDeatils = useAppSelector(
    (state) => state.postCreate.bookingDetails,
  )

  const userDetails: any = useAppSelector((state) => state.auth)

  const navigate = useNavigate()

  function formatAgeRange(ageRange: any) {
    const [minAge, maxAge] = ageRange.split(",")

    return `${minAge} - ${maxAge}`
  }

  const ageRange = bookingDeatils?.ageRange

  const agePro = formatAgeRange(ageRange)

  return (
    <>
      <div className={styles["edt-booking-maincard"]}>
        <div className={styles["edt-booking-card"]}>
          <div className={styles["edt-booking-header"]}>
            <img
              src={userDetails?.userProfile?.data?.profilePic || dummyImage}
              alt="Supriya Jain"
              className={styles["edt-booking-profile-image"]}
            />
            <div className={styles["edt-booking-info"]}>
              <h2 className={styles["edt-booking-name"]}>
                {userDetails?.userInfo?.user?.name}
              </h2>
              <p className={styles["edt-booking-contact"]}>
                <MdOutlineMail
                  size={20}
                  className={styles["edt-booking-icon"]}
                />
                {userDetails?.userInfo?.user?.email}
              </p>
              <p className={styles["edt-booking-contact"]}>
                <FiPhone size={18} className={styles["edt-booking-icon"]} />
                {userDetails?.userProfile?.data?.contactNo}
              </p>
            </div>
            <button
              className={styles["edt-booking-edit"]}
              onClick={() => navigate("/dashboard/create-booking")}
            >
              <FaEdit color="#F475A4" size={22} />
            </button>
          </div>
          <div className={styles["edt-booking-divider"]} />
          <div className={styles["edt-booking-details"]}>
            <p>
              <span className={styles["edt-booking-label"]}>
                Service Required
              </span>{" "}
              - <span>{bookingDeatils?.serviceName}</span>
            </p>
            <p>
              <span className={styles["edt-booking-label"]}>Date & Time</span> -
              {moment(bookingDeatils?.from).format(" dd DD  MMMM ")} |{" "}
              {bookingDeatils?.timeUpdate +
                " -" +
                bookingDeatils?.updateEndTime}
              {/* Wed, 10 Nov | 6:00am-9:10pm */}
            </p>
            <p>
              <span className={styles["edt-booking-label"]}>
                Number of provider required
              </span>{" "}
              - {bookingDeatils?.personRequired}
            </p>
            <p>
              <span className={styles["edt-booking-label"]}>
                Age of provider
              </span>{" "}
              - {agePro}
            </p>
            <p>
              <span className={styles["edt-booking-label"]}>
                Language of provider
              </span>{" "}
              - {bookingDeatils?.language?.join(",")}
            </p>
            <p>
              <span className={styles["edt-booking-label"]}>Location</span> -
              {bookingDeatils?.location}
            </p>
            {bookingDeatils?.description && (
              <p>
                <span className={styles["edt-booking-label"]}>
                  Additional Instructions
                </span>{" "}
                - {bookingDeatils?.description}
              </p>
            )}
          </div>
          {bookingDeatils?.attachment?.length > 0 && (
            <div className={styles["edt-booking-photos"]}>
              <h4 className={styles["edt-booking-photos-title"]}>
                Uploaded Photos
              </h4>
              {bookingDeatils?.attachment.map((item: any) => (
                <div className={styles["edt-booking-photo-gallery"]}>
                  <img
                    src={URL.createObjectURL(item)}
                    alt="Photo 1"
                    className={styles["edt-booking-photo"]}
                  />
                </div>
              ))}
            </div>
          )}
          <div className="text-end w-100">
            <Button
              className={styles["edt-booking-nxt-btn"]}
              onClick={() => navigate(`/dashboard/booking-overview`)}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
