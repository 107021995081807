import React, { useState } from "react"
import {
  Box,
  Button,
  Grid,
  Typography,
  Divider,
  Avatar,
  AvatarGroup,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Stack,
  Pagination,
} from "@mui/material"
import * as Style from "./style"
import { IoChevronForwardOutline } from "react-icons/io5"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import approvedImg from "../../images/babysitting.png"
import {
  useAcceptGenrateBookingQuery,
  useGetGenratedPostSeekerQuery,
  useGetUpcomingBookingsQuery,
} from "../../features/OverViewAuth/overViewApi"
import moment from "moment"
import CircularIndeterminate from "../Loading/Loading"
import {
  FormatChangeTime,
  FormatDate,
  FormatTime,
} from "../common/utctolocalDate"
import { MdOutlineLocationOn } from "react-icons/md"
import { Spinner } from "react-bootstrap"
import dummyImage from "../../images/dummy.png"
import noDataUpcoming from "../../images/no-results.svg"
import CenteredSpinner from "../common/CenteredSpinner"
const steps = ["Booking Summary", "Payment", "Request sent"]

const MyBookings = () => {
  const [value, setValue] = React.useState("")
  const [searchParam, setSearchParam] = useSearchParams()
  const [selectedPage, setSelectedPage] = useState(1)
  const navigate = useNavigate()

  const {
    data: upcomingBooking,
    isFetching,
    isLoading,
  } = useAcceptGenrateBookingQuery({ pageNo: selectedPage })
  const handlePageChange = (event: any, value: any) => {
    setSelectedPage(value)
  }

  return (
    <>
      {isFetching || isLoading ? (
        <CenteredSpinner />
      ) : (
        <Box>
          <Style.Overview>
            <Box className="">
              <Grid item xs={12} className="mb-5">
                <Box className="">
                  <Typography align="left" variant="h2" pb={1} mt={2}>
                    Upcoming Bookings
                  </Typography>

                  <TableContainer
                    component={Paper}
                    className="dashboard-table-wrapper custom-scrollbar"
                  >
                    <Table
                      style={{
                        minWidth: 650,
                        border: "none",
                        backgroundColor: "#fff",
                        borderRadius: "16px",
                        overflow: "hidden",
                      }}
                      className="dashboard-table"
                      aria-label="simple table"
                    >
                      {upcomingBooking?.data?.length > 0 && (
                        <TableHead>
                          <TableRow>
                            <TableCell align="center"></TableCell>
                            <TableCell
                              align="center"
                              className="d-flex align-items-center justify-content-center"
                            >
                              Service provider assigned
                            </TableCell>
                            <TableCell align="center" className="service-head">
                              Service
                            </TableCell>

                            <TableCell align="center">Start time</TableCell>
                            <TableCell align="center">End Time</TableCell>
                            <TableCell align="center">Action</TableCell>
                          </TableRow>
                        </TableHead>
                      )}

                      <TableBody>
                        {isLoading ? (
                          <CenteredSpinner />
                        ) : (
                          <>
                            {upcomingBooking?.data?.length > 0 ? (
                              upcomingBooking?.data?.map((item: any) => (
                                <>
                                  <TableRow
                                    key={item._id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      navigate(
                                        `/dashboard/bookings/details/${item?._id}`,
                                      )
                                      // setSearchParam({ chatId: "help-center" })
                                    }}
                                  >
                                    {item?.assignedTo?.length > 0 ? (
                                      item?.assignedTo
                                        ?.slice(0, 1)
                                        ?.map((providerAssign: any) => (
                                          <>
                                            <TableCell>
                                              <span className="img-wrapper ms-1">
                                                <img
                                                  src={
                                                    providerAssign?.profilePic ||
                                                    dummyImage
                                                  }
                                                  style={{
                                                    width: "74px",
                                                    height: "74px",
                                                    objectFit: "cover",
                                                  }}
                                                />
                                              </span>
                                            </TableCell>

                                            <TableCell>
                                              <span className="img-wrapper">
                                                {providerAssign?.name &&
                                                  providerAssign.name
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    providerAssign.name.slice(
                                                      1,
                                                    )}{" "}
                                                {/* - */}
                                                <span
                                                  style={{
                                                    color: "grey",
                                                  }}
                                                >
                                                  {/* Assigned */}
                                                  <br />
                                                </span>
                                                {item?.assignedTo?.length >
                                                  1 && (
                                                  <>
                                                    +{" "}
                                                    {item?.assignedTo?.slice(1)
                                                      ?.length +
                                                      " " +
                                                      "Others"}
                                                  </>
                                                )}
                                              </span>
                                            </TableCell>
                                          </>
                                        ))
                                    ) : (
                                      <>
                                        <TableCell>
                                          <span className=" ms-1">
                                            <img
                                              src={dummyImage}
                                              style={{
                                                width: "74px",
                                                height: "74px",
                                                objectFit: "cover",
                                              }}
                                            />
                                          </span>
                                        </TableCell>
                                        <TableCell>
                                          <span
                                            className="language mx-auto text-center"
                                            style={{ color: "red" }}
                                          >
                                            No service providers assigned yet
                                          </span>
                                        </TableCell>
                                      </>
                                    )}

                                    <TableCell
                                      component="td"
                                      align="center"
                                      scope="row"
                                    >
                                      <span>{item?.service?.name}</span>
                                    </TableCell>

                                    <TableCell align="center">
                                      <span className="datetime">
                                        <span className="d-flex flex-column">
                                          <span className="weekday">
                                            <FormatDate
                                              dateString={item?.from}
                                            />
                                          </span>

                                          <span>
                                            <FormatTime
                                              dateString={item?.from}
                                            />
                                          </span>

                                          <span>
                                            <FormatChangeTime
                                              dateString={item?.from}
                                            />
                                          </span>
                                        </span>
                                      </span>
                                    </TableCell>
                                    <TableCell align="center">
                                      <span className="datetime">
                                        <span className="d-flex flex-column">
                                          <span className="weekday">
                                            <FormatDate
                                              dateString={item?.till}
                                            />
                                          </span>

                                          <span>
                                            <FormatTime
                                              dateString={item?.till}
                                            />
                                          </span>

                                          <span>
                                            <FormatChangeTime
                                              dateString={item?.till}
                                            />
                                          </span>
                                        </span>
                                      </span>
                                    </TableCell>

                                    <TableCell align="center">
                                      <span className="minh-1">
                                        <Link
                                          to={`${
                                            item?.status === "Accepted"
                                              ? `/dashboard/bookings/details/${item?._id}`
                                              : ""
                                          }`}
                                          className={`view-profile w-unset ${
                                            item?.status !== "Accepted"
                                              ? "no-track-color"
                                              : ""
                                          }`}
                                        >
                                          {item?.status === "Accepted"
                                            ? "Track Service"
                                            : "Waiting"}
                                        </Link>
                                      </span>
                                    </TableCell>
                                  </TableRow>
                                </>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell
                                  align="center"
                                  colSpan={7}
                                  sx={{
                                    width: "100%",
                                    pointerEvents: "none",
                                    border: "none",
                                    height: "400px",
                                  }}
                                >
                                  <img
                                    src={noDataUpcoming}
                                    style={{
                                      width: "100%",
                                      maxWidth: "208px",
                                    }}
                                  />
                                  <span className="no-data-text">
                                    No Upcoming bookings for you
                                  </span>
                                </TableCell>
                              </TableRow>
                            )}
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>

              {/* <Typography align="left" variant="h2" mb={2}>
                Upcoming Bookings
              </Typography> */}
              {/* <Grid container className="gap-3">
                {upcomingBooking?.data?.map((item: any) => (
                  <Grid item>
                    <Box className="Status approved-card text-center">
                      <Typography align="left" className="mb-2" variant="body1">
                        Date & Time:
                      </Typography>
                      <Box className="d-flex gap-2 align-items-center mb-4">
                        <Typography variant="body1">
                          {" "}
                          <FormatTime dateString={item?.from} />
                          <FormatChangeTime dateString={item?.from} />
                         
                        </Typography>
                        <Typography variant="body1">|</Typography>
                        <Typography variant="body1">
                          <FormatTime dateString={item?.till} />
                          <FormatChangeTime dateString={item?.till} />
                         
                        </Typography>
                      </Box>
                      <Typography align="left" variant="body1" className="mb-3">
                        Service: {item?.service?.name}
                      </Typography>
                      <Divider className="dark-divider" />
                      {item?.status === "Accepted" ? (
                        <>
                          <Typography
                            variant="body1"
                            className="text-success-provider my-3"
                            align="left"
                          >
                            Assigned providers
                          </Typography>

                          

                          {item?.assignedTo
                            ?.slice(0, 1)
                            ?.map((assigne: any) => (
                              <Box className="d-flex align-items-center gap-3 mb-4">
                                <Box>
                                  <AvatarGroup max={3}>
                                    <Avatar
                                      alt="Remy Sharp"
                                      src={assigne?.profilePic}
                                      onClick={() =>
                                        navigate(
                                          `/dashboard/bookings/details/${item?._id}`,
                                        )
                                      }
                                      style={{ cursor: "pointer" }}
                                    />{" "}
                                  </AvatarGroup>

                                  {item?.assignedTo?.length > 1 && (
                                    <span>
                                      + {item?.assignedTo?.slice(1)?.length}{" "}
                                      other
                                    </span>
                                  )}
                                </Box>
                                <Box className="text-start">
                                  <Typography
                                    align="left"
                                    className="mb-3"
                                    variant="body1"
                                  >
                                    {assigne?.name}
                                  </Typography>
                                 
                                </Box>
                              </Box>
                            ))}
                          <Link
                            to={`/dashboard/booking-timeline/${item?._id}`}
                            className="w-100 d-block text-decoration-none track-btn"
                          >
                            Track Services
                            <IoChevronForwardOutline />
                          </Link>
                        </>
                      ) : (
                        <>
                          <Typography variant="body1" className="mt-3">
                            Waiting for service provider to assign
                          </Typography>
                          <Link
                            
                            to=""
                            className="w-100 d-block text-decoration-none track-btn"
                            style={{
                              backgroundColor: "white",
                              color: "gray",
                              border: "1px solid gray",
                              pointerEvents: "none",
                            }}
                            onClick={(e) => e.preventDefault()}
                          >
                            Track Service <IoChevronForwardOutline />
                          </Link>
                        </>
                      )}
                    </Box>
                  </Grid>
                ))}
              </Grid> */}
            </Box>
            {upcomingBooking?.data?.length > 0 && (
              <Box className="d-md-flex justify-content-between align-items-center mt-3">
                <Typography variant="body1" className="white-space">
                  {" "}
                  Showing {upcomingBooking?.totalDocument?.showingData} /{" "}
                  {upcomingBooking?.totalDocument?.totalCount} Total
                </Typography>
                <Grid container justifyContent="flex-end">
                  <Grid item md={3}>
                    <Box display="flex" className="flex-column">
                      <Stack spacing={2} className="mb-3">
                        <Pagination
                          count={upcomingBooking?.totalDocument?.totalPages}
                          color="secondary"
                          className="custom-pagination"
                          page={selectedPage}
                          onChange={handlePageChange}
                          sx={{
                            "& .MuiPaginationItem-page.Mui-selected": {
                              backgroundColor: "#F475A4",
                              boxSizing: "border-box",
                            },
                            "& .css-wjh20t-MuiPagination-ul": {
                              flexWrap: "nowrap",
                              justifyContent: "end",
                            },
                          }}
                        />
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Style.Overview>
        </Box>
      )}
    </>
  )
}

export default MyBookings
