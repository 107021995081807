import React, { useEffect, useState } from "react"
import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material"
import { LiaAngleRightSolid } from "react-icons/lia"
import * as Style from "./style"
import moment from "moment"
import { IoCameraOutline } from "react-icons/io5"
import { Link, useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
// import { addYears } from "date-fns"
import * as yup from "yup"
import {
  useAccountUpdateMutation,
  useGetProfileQuery,
} from "../../features/Auth/authApi"
import { toast } from "react-toastify"
import dummyImage from "../../images/dummy.png"
import { useGetLanguageQuery } from "../../features/OverViewAuth/overViewApi"
import CenteredSpinner from "../common/CenteredSpinner"
import { languages } from "../Languagejson/language"
import LocationSelector from "../Booking/SearchLocation"
import { setuserProfile } from "../../features/Auth/authSlice"
import { useAppDispatch } from "../../app/hooks"

interface EditAccountUser {
  // profilePic: string
  name: string
  email: string
  contactNo: string
  // gender: string
  // location: string
  // language: string
  // dob: string
}

interface editprops {
  setEditSave: (value: boolean) => void
  editSave: boolean
}

const EditAccount = ({ setEditSave, editSave }: editprops) => {
  const [
    accountUpdate,
    { data: updateData, error, isLoading: updateIsloading },
  ] = useAccountUpdateMutation()
  const [previewImage, setPreviewImage] = useState(null)
  const [fileUpload, setFileUpload] = useState(null)
  const [isInputFocused, setIsInputFocused] = useState(false)
  const [loactionSearchData, setLocationSearchData] = useState<string>("")
  const [errorMsg, setErrorMsg] = useState<string>("")
  const [genderOption, setGenderOption] = useState("")
  const dispatch = useAppDispatch()
  const { data: getProfile, isFetching, isLoading } = useGetProfileQuery({})

  useEffect(() => {
    if (getProfile) {
      dispatch(setuserProfile(getProfile))
    }
  }, [getProfile])

  const naviage = useNavigate()

  const handleGender = (e: any) => {
    setGenderOption(e.target.value)
  }

  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([])

  const handleLanguageChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const selected = event.target.value as string[]
    if (selected.length > 3) {
      selected.splice(0, selected.length - 3)
    }

    setSelectedLanguages(selected)
  }

  // const handleLanguageChange = (
  //   event: React.ChangeEvent<{ value: unknown }>,
  // ) => {
  //   const selected = event.target.value as string[]
  //   if (selected.length > 2) {
  //     selected.splice(0, selected.length - 2)
  //   }

  //   setSelectedLanguages(selected)
  // }

  const onChnage = (e: any) => {
    const file = e.target.files[0]
    setFileUpload(file)
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreviewImage((reader as any)?.result)
      }
      reader.readAsDataURL(file)
    }
  }

  const dobSchema = yup
    .string()
    .nullable()
    .test(
      "The age of Customer should be between 16- 98 years",

      function (value) {
        if (!value) return true // Allow empty value
        const selectedDate = new Date(value)
        const currentDate = new Date()
        return selectedDate.toDateString() !== currentDate.toDateString()
      },
    )
    .test(
      "age",
      "The age of Customer should be between 16- 98 years",
      function (value) {
        if (!value) return true // Allow empty value
        const selectedDate = new Date(value)
        const currentDate = new Date()
        const age = currentDate.getFullYear() - selectedDate.getFullYear()
        const monthDiff = currentDate.getMonth() - selectedDate.getMonth()
        return (
          age >= 16 &&
          age <= 98 &&
          (age > 16 ||
            (age === 16 &&
              (monthDiff > 0 ||
                (monthDiff === 0 &&
                  currentDate.getDate() >= selectedDate.getDate()))))
        )
      },
    )

  const schemaResolver = yup.object().shape({
    name: yup
      .string()
      .required("Please enter name")
      .transform((value) => value.trim())
      .matches(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/, {
        message:
          "Name should only contain letters and a single space between words",
        excludeEmptyString: true,
      })
      .min(3, "Name should be of 3-25 characters only")
      .max(25, "Name should be of 3-25 characters only"),
    email: yup.string().email().required("Please enter an email"),
    contactNo: yup
      .string()
      .required("Please enter phone number")
      .min(6, "Phone Number must be at least 6 characters")
      .max(14, "Phone Number can't exceed 14 characters"),
    // gender: yup.string().required("Please enter gender"),
    // language: yup.string().required("Please enter language"),
    // location: yup.string().required("Please enter address"),
    // dob: yup.string().required("Please enter age"),

    dob: dobSchema,
  })

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm<EditAccountUser, any>({
    resolver: yupResolver(schemaResolver),
  })

  const onSubmit = (data: any) => {
    const formData = new FormData()
    formData.append("name", data?.name)
    formData.append("email", data?.email)
    formData.append("contactNo", data?.contactNo)
    formData.append("gender", genderOption)
    formData.append("address.location", loactionSearchData || "")
    formData.append("language", selectedLanguages as any)
    formData.append("dob", data?.dob)
    // Append the file to FormData
    if (fileUpload) {
      formData.append("profilePic", fileUpload)
    }

    if (loactionSearchData !== "") {
      accountUpdate(formData)
    } else {
      setErrorMsg("please enter your location")
    }
  }

  useEffect(() => {
    if (getProfile && getProfile?.data) {
      setValue("name", getProfile?.data.name)
      setValue("email", getProfile?.data.email)
      setValue("contactNo", getProfile?.data.contactNo)
      // setValue("location" as any, getProfile?.data?.address?.location as any)
      setLocationSearchData(getProfile?.data?.address?.location)
      setGenderOption(getProfile?.data?.gender || "")

      setSelectedLanguages(getProfile?.data.language || [])

      const dateFormat = moment(getProfile?.data?.dob).format("YYYY-MM-DD")
      let formattedDate

      if (getProfile?.data?.dob) {
        // If the date from the backend is defined, format it
        formattedDate = moment(getProfile?.data?.dob).format("YYYY-MM-DD")
      } else {
        // If the date from the backend is undefined, set formattedDate to null or any default value you prefer
        formattedDate = null // You can set it to null or any other default value
      }

      // setValue("gender", getProfile.data.gender || " ")
      // const inputDate = new Date(getProfile.data.dob)
      // const formattedDate = inputDate.toISOString().split("T")[0]
      setValue("dob" as any, formattedDate || "")
    }
  }, [getProfile, isInputFocused])

  // for modal changepasswords
  const tagStyle = {
    border: "1px solid #F475A4",
    padding: "4px 8px",
    borderRadius: "8px",
    marginRight: "8px", // Add space between tags
    marginBottom: "8px", // Add space below each row of tags
    display: "inline-block",
    backgroundColor: "#F475A4", // Light background color
    color: " #fff",
  }

  useEffect(() => {
    if (updateData?.message) {
      toast.success(updateData?.message)
      setEditSave(!editSave)
    } else {
      toast.error((error as any)?.data?.message)
    }
  }, [updateData, error])

  return (
    <>
      {" "}
      {isLoading ? (
        <>
          <CenteredSpinner />
        </>
      ) : (
        <Style.Overview>
          <Grid container>
            <Grid xs={12} className="ps-2 ps-xl-0">
              <Box className="review-card p-4">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container className="mb-4">
                    <Grid xs={4}>
                      <Typography variant="body1">Profile Photo</Typography>
                    </Grid>
                    <Grid xs={8}>
                      <Box className="d-inline-block position-relative">
                        <img
                          src={
                            previewImage || getProfile?.data?.profilePic
                              ? previewImage || getProfile?.data?.profilePic
                              : dummyImage
                          }
                          className="edit-profile"
                        />

                        <input
                          type="file"
                          id="upload-profile-pic"
                          className="d-none"
                          onChange={onChnage}
                        />
                        <label
                          htmlFor="upload-profile-pic"
                          className="upload-label"
                        >
                          <IoCameraOutline />
                        </label>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container className="mb-1">
                    <Grid xs={4}>
                      <Typography variant="body1">
                        Name <span className="text-danger">*</span>
                      </Typography>
                    </Grid>
                    <Grid xs={8}>
                      <Input
                        placeholder="Name"
                        className="feature-field font-16"
                        fullWidth
                        {...register("name")}
                      />
                      <p className="text-danger">{errors?.name?.message}</p>
                    </Grid>
                  </Grid>
                  <Grid container className="mb-1">
                    <Grid xs={4}>
                      <Typography variant="body1">
                        E-Mail<span className="text-danger">*</span>
                      </Typography>
                    </Grid>
                    <Grid xs={8}>
                      <Input
                        placeholder="Email"
                        className="feature-field icon-field font-16"
                        fullWidth
                        {...register("email")}
                        startAdornment={
                          <InputAdornment position="start">
                            {/* <MdOutlineMail /> */}
                          </InputAdornment>
                        }
                        disabled
                      />
                      <p className="text-danger">{errors.email?.message}</p>
                    </Grid>
                  </Grid>
                  <Grid container className="mb-1">
                    <Grid xs={4}>
                      <Typography variant="body1">
                        Phone Number<span className="text-danger">*</span>
                      </Typography>
                    </Grid>
                    <Grid xs={8}>
                      <Input
                        type="number"
                        placeholder="Phone Number"
                        className="feature-field font-16 icon-field"
                        inputProps={{ maxLength: 14 }}
                        fullWidth
                        {...register("contactNo")}
                        startAdornment={
                          <InputAdornment position="start">
                            {/* <BsTelephone /> */}
                          </InputAdornment>
                        }
                      />
                      <p className="text-danger">{errors.contactNo?.message}</p>
                    </Grid>
                  </Grid>
                  <Grid container className="mb-1">
                    <Grid xs={4}>
                      <Typography variant="body1">Gender</Typography>
                    </Grid>
                    <Grid md={8}>
                      <FormControl fullWidth>
                        <InputLabel id="select-lan" className="bg-white">
                          Select Your Gender
                        </InputLabel>
                        <Select
                          className="feature-field font-16"
                          {...register("gender" as any)}
                          value={genderOption}
                          onChange={handleGender}
                          fullWidth
                        >
                          <MenuItem value="Male">Male</MenuItem>
                          <MenuItem value="Female">Female</MenuItem>
                          <MenuItem value="Others">Others</MenuItem>
                        </Select>
                      </FormControl>
                      <p className="text-danger">
                        {(errors as any).gender?.message}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container className="mb-3">
                    <Grid xs={4}>
                      <Typography variant="body1">Language</Typography>
                    </Grid>

                    <Grid md={8}>
                      <FormControl fullWidth>
                        <InputLabel id="select-lan" className="bg-white">
                          Select Language
                        </InputLabel>
                        <Select
                          placeholder="Language"
                          className="feature-field font-16"
                          {...register("language" as any)}
                          value={selectedLanguages}
                          multiple
                          onChange={handleLanguageChange as any}
                          fullWidth
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 250,
                                width: 150,
                                overflowY: "auto", // Enable vertical scrolling
                                scrollbarWidth: "thin", // Width of the scrollbar
                                scrollbarColor: "#f07fa9 transparent", // Color of the scrollbar thumb
                                borderRadius: "4px", // Border radius of the scrollbar thumb
                                cursor: "pointer", // Change cursor to pointer
                              },
                            },
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                          }}
                          renderValue={(selected) => (
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              {selected.map((value) => (
                                <Chip
                                  key={value}
                                  label={value}
                                  style={{ marginRight: 5, marginBottom: 5 }}
                                />
                              ))}
                            </div>
                          )}
                        >
                          {languages?.map((item: any) => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      {/* <p className="text-danger">
                          {(errors as any).language?.message}
                        </p> */}
                    </Grid>
                  </Grid>
                  <Grid container className="mb-1">
                    <Grid xs={4}>
                      <Typography variant="body1">Date of Birth</Typography>
                    </Grid>
                    <Grid xs={8}>
                      <Input
                        type={"date"}
                        placeholder="DOB"
                        className="feature-field font-16"
                        fullWidth
                        {...register("dob" as any)}
                      />
                      <p className="text-danger">
                        {(errors as any).dob?.message}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container className="mb-1">
                    <Grid xs={4}>
                      <Typography variant="body1">
                        Location<span className="text-danger">*</span>
                      </Typography>
                    </Grid>
                    <Grid xs={8}>
                      {/* <Input
                          placeholder="Location"
                          className="feature-field font-16"
                          fullWidth
                          multiline
                          rows={3}
                          {...register("location" as any)}
                        /> */}

                      <LocationSelector
                        setLocationSearchData={setLocationSearchData}
                        placeholder="Enter location"
                        setErrorMsg={setErrorMsg as any}
                        loactionSearchData={loactionSearchData}
                        bookingDetailsShow={false}
                        disabled={false}
                      />
                      <p className="text-danger">
                        {errorMsg}
                        {/* {(errors as any).location?.message} */}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container className="mb-1">
                    <Grid xs={12}>
                      <Box className="text-end">
                        <Button
                          className="view-profile active text-capitalize"
                          type="submit"
                        >
                          {updateIsloading ? (
                            <Typography fontSize={1}>
                              <CircularProgress
                                style={{ color: "pink", fontSize: 24 }}
                                size={24}
                              />
                            </Typography>
                          ) : (
                            "Save Changes"
                          )}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Grid>
            {/* )} */}
          </Grid>
        </Style.Overview>
      )}
      {/* <ChangePasswordModal opens={open} handleClose={handleClose} /> */}
    </>
  )
}
export default EditAccount
