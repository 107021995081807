import React, { useEffect, useState } from "react"
import { BiSolidOffer } from "react-icons/bi"
import dayjs from "dayjs"
import {
  Box,
  Button,
  Grid,
  Typography,
  Input,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  colors,
} from "@mui/material"
import { FaChevronLeft } from "react-icons/fa"
import * as Style from "./paymentCss"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { useAppSelector } from "../../app/hooks"
import { usePaymentBookingMutation } from "../../features/AuthPayment/paymentApi"
import { useAppDispatch } from "../../app/store"
import { toast } from "react-toastify"
import { _paymentDetails } from "../../features/Auth/authSlice"
const Payment = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const postDetails = useAppSelector(
    (state: any) => state?.auth?.paymentDetails,
  )

  const totalPayment =
    postDetails?.hourlyrate * postDetails?.servicehours * postDetails?.provider

  const discountPercentage = postDetails?.Discount ? postDetails?.Discount : 0
  const discountAmount = (discountPercentage / 100) * totalPayment

  const [paymentPost, { data, isLoading: paymentIsloading }] =
    usePaymentBookingMutation()

  const handlePayment = async () => {
    const payloadPayment = {
      postId: postDetails?.postId,
      customerId: postDetails?.customerId,
      stripepricekey: postDetails?.Totalpayment,
    }
    try {
      const res: any = await paymentPost(payloadPayment)
      dispatch(_paymentDetails(null as any))
      const url = res?.data?.url
      window.location.href = url
    } catch (paymentError: any) {
      toast.error(
        paymentError.message || "An error occurred during payment processing",
      )
    }
  }

  useEffect(() => {
    if (postDetails == null) {
      navigate("/dashboard")
    }
  }, [postDetails])

  return (
    <Style.Overview>
      <React.Fragment>
        <Box className=" feature-card px-1 py-md-4 px-md-5 position-relative">
          {postDetails !== null && (
            <Grid container>
              <Grid item md={6}>
                <Box>
                  <Button
                    className="apply-btn"
                    style={{
                      display: "flex",

                      color: "#F475A4",
                      gap: "10px",
                      fontWeight: "600",
                    }}
                  >
                    <BiSolidOffer
                      style={{ color: "#F475A4", fontSize: "20px" }}
                    />{" "}
                    {postDetails?.Discount}% Off{" "}
                  </Button>
                </Box>
                <Box className="total-table">
                  <Grid container>
                    <Grid item xs={10}>
                      <Typography className="total-text">
                        Total number of service provider
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        className="total-text"
                        style={{ fontWeight: "400" }}
                      >
                        {postDetails?.provider}
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography className="total-text">
                        Total number of service hours
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        className="total-text"
                        style={{ fontWeight: "400" }}
                      >
                        {postDetails?.servicehours}
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography className="total-text">
                        Per person Hourly rate
                      </Typography>
                    </Grid>

                    <Grid item xs={2}>
                      <Typography
                        className="total-text"
                        style={{ fontWeight: "400" }}
                      >
                        CAD${postDetails?.hourlyrate}
                      </Typography>
                    </Grid>

                    <Grid item xs={10}>
                      <Typography className="total-text">Discount</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        className="total-text"
                        style={{ fontWeight: "400" }}
                      >
                        CAD$-{discountAmount || "0"}
                      </Typography>
                    </Grid>
                    <Grid item xs={10} className="total-col">
                      <Typography
                        className="total-text"
                        style={{ color: "#24AAE3", fontWeight: "600" }}
                      >
                        Total Payment
                      </Typography>
                    </Grid>
                    <Grid item xs={2} className="total-col">
                      <Typography
                        className="total-text"
                        style={{ color: "#24AAE3", fontWeight: "600" }}
                      >
                        CAD${postDetails?.Totalpayment}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item md={6}></Grid>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  pt: 2,
                }}
              >
                <Button
                  className="view-profile"
                  color="inherit"
                  sx={{ mr: 1 }}
                  onClick={handlePayment}
                >
                  {paymentIsloading ? (
                    <Typography fontSize={1}>
                      <CircularProgress
                        style={{ color: "pink", fontSize: 20 }}
                        size={20}
                      />
                    </Typography>
                  ) : (
                    "Payment"
                  )}
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
              </Box>
            </Grid>
          )}
        </Box>
      </React.Fragment>
    </Style.Overview>
  )
}

export default Payment
