// import React, { useState, useEffect } from "react"

// interface Props {
//   from: string
//   till: string
// }

// const CountdownTimer: React.FC<Props> = ({ from, till }) => {
//   const [currentTime, setCurrentTime] = useState<Date>(new Date())
//   const [endTime, setEndTime] = useState<Date>(new Date(till))

//   useEffect(() => {
//     const timer = setInterval(() => {
//       setCurrentTime(new Date())
//     }, 1000)

//     return () => clearInterval(timer)
//   }, [])

//   const calculateRemainingTime = () => {
//     const difference = endTime.getTime() - currentTime.getTime()
//     if (difference <= 0) {
//       return "Time's up!"
//     }

//     const seconds = Math.floor((difference / 1000) % 60)
//     const minutes = Math.floor((difference / (1000 * 60)) % 60)
//     const hours = Math.floor((difference / (1000 * 60 * 60)) % 24)

//     return `${hours.toString().padStart(2, "0")}:${minutes
//       .toString()
//       .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
//   }

//   return (
//     <div
//       style={{
//         display: "flex",
//         justifyContent: "center",
//       }}
//     >
//       <span style={{ color: "#F475A4", fontSize: "14px", fontWeight: "500" }}>
//         {" "}
//         {calculateRemainingTime()}
//       </span>
//     </div>
//   )
// }

// export default CountdownTimer

import React, { useState, useEffect } from "react"

interface Props {
  from: any
  till: any
  postActiveTimeStop: string
}

const CountdownTimer: React.FC<Props> = ({ from, till }) => {
  const [currentTime, setCurrentTime] = useState<Date>(new Date())
  const [endTime, setEndTime] = useState<Date>()

  useEffect(() => {
    const startTime = new Date(from)
    const endTime = new Date(startTime.getTime() + 15 * 60 * 1000) // Adding 15 minutes to the start time
    setEndTime(endTime)

    const timer = setInterval(() => {
      setCurrentTime(new Date())
    }, 1000)

    return () => clearInterval(timer)
  }, [from])

  const calculateRemainingTime = () => {
    if (!endTime) return "Loading..."

    const difference = endTime.getTime() - currentTime.getTime()
    if (difference <= 0) {
      return "Time's up!"
    }

    const seconds = Math.floor((difference / 1000) % 60)
    const minutes = Math.floor((difference / (1000 * 60)) % 60)
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24)

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <span style={{ color: "#F475A4", fontSize: "14px", fontWeight: "500" }}>
        {calculateRemainingTime()}
      </span>
    </div>
  )
}

export default CountdownTimer

interface Props {
  from: any
  till: any
  postActiveTimeStop: string
}

// const TimeCounter: React.FC<Props> = ({ from, till, postActiveTimeStop }) => {
//   const [time, setTime] = useState(new Date())

//   const stopTime = postActiveTimeStop

//   useEffect(() => {
//     const interval = setInterval(() => {
//       const currentTime = new Date()
//       setTime(currentTime)

//       const endTime = new Date(till)
//       // Clear the interval if the current time exceeds the end time
//       if (currentTime >= endTime) {
//         clearInterval(interval)
//       }
//     }, 1000) // Update every second

//     return () => clearInterval(interval)
//   }, [till])

//   const startTimeObj = new Date(from)
//   const diffInMilliseconds = time.getTime() - startTimeObj.getTime()
//   const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60))
//   const diffInMinutes = Math.floor(
//     (diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60),
//   )
//   const diffInSeconds = Math.floor((diffInMilliseconds % (1000 * 60)) / 1000)

//   const formatTime = (value: number) => {
//     return value < 10 ? `0${value}` : value
//   }

//   return (
//     <div
//       style={{
//         display: "flex",
//         justifyContent: "center",
//       }}
//     >
//       {from !== undefined && from !== "" ? (
//         <span style={{ color: "#F475A4", fontSize: "14px", fontWeight: "500" }}>
//           {formatTime(diffInHours)}:{formatTime(diffInMinutes)}:
//           {formatTime(diffInSeconds)}
//         </span>
//       ) : (
//         <span style={{ color: "#F475A4", fontSize: "14px", fontWeight: "500" }}>
//           00:00:00
//         </span>
//       )}
//     </div>
//   )
// }
const TimeCounter: React.FC<Props> = ({ from, till, postActiveTimeStop }) => {
  const [time, setTime] = useState(new Date())
  const [timerStopped, setTimerStopped] = useState(false)

  const stopTime = postActiveTimeStop

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date()
      setTime(currentTime)

      const endTime = new Date(till)
      // Clear the interval if the current time exceeds the end time or stopTime is "END_OTP_VERIFIED"
      if (currentTime >= endTime || stopTime === "END_OTP_VERIFIED") {
        clearInterval(interval)
        setTimerStopped(true) // Set state to indicate timer has stopped
      }
    }, 1000) // Update every second

    return () => clearInterval(interval)
  }, [till, stopTime]) // Include stopTime in dependencies

  const startTimeObj = new Date(from)
  const diffInMilliseconds = time.getTime() - startTimeObj.getTime()
  const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60))
  const diffInMinutes = Math.floor(
    (diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60),
  )
  const diffInSeconds = Math.floor((diffInMilliseconds % (1000 * 60)) / 1000)

  const formatTime = (value: number) => {
    return value < 10 ? `0${value}` : value.toString()
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      {!timerStopped ? (
        <span
          style={{
            color: "#F475A4",
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          {formatTime(diffInHours)}:{formatTime(diffInMinutes)}:
          {formatTime(diffInSeconds)}
        </span>
      ) : (
        <span
          style={{
            color: "#F475A4",
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          Service Completed
        </span>
      )}
    </div>
  )
}

export { TimeCounter }
