import { useState } from "react"
import {
  Box,
  Button,
  Grid,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"
import * as Style from "./style"
import reviewImg from "../../images/babysitting.png"
import { IoFilter, IoStar, IoStarOutline } from "react-icons/io5"
import { FaStar } from "react-icons/fa6"
import tableCellImg from "../../images/table-img.png"
import { FaPencilAlt } from "react-icons/fa"
import { Link, useNavigate } from "react-router-dom"
import { CiSearch } from "react-icons/ci"
import { TbArrowsSort } from "react-icons/tb"
import {
  useGetReviewResentQuery,
  useReceivedReviewQuery,
} from "../../features/OverViewAuth/overViewApi"
import moment from "moment"
import CircularIndeterminate from "../Loading/Loading"
import dummy from "../../images/dummy.png"
import noDataHistory from "../../images/no-history.png"

import nodata from "../../images/no-data.jpg"
import {
  FormatChangeTime,
  FormatDate,
  FormatTime,
} from "../common/utctolocalDate"
import CenteredSpinner from "../common/CenteredSpinner"
import { MdOutlineLocationOn } from "react-icons/md"
import noDataUpcoming from "../../images/no-results.svg"
interface Array {}
const SeekerProvider = () => {
  const [selectedPage, setSelectedPage] = useState(1)
  const [showMore, setShowMore] = useState<Array>([])
  const navigate = useNavigate()
  const handlePageChange = (event: any, value: any) => {
    setSelectedPage(value)
  }

  const {
    data: resentReview,
    isFetching: reisFetching,
    isLoading: reisLoading,
  } = useGetReviewResentQuery({})
  const {
    data: getProviderReviews,
    isLoading,
    isFetching,
  } = useReceivedReviewQuery({
    pageNo: selectedPage,
  })

  const RatingStars = ({ rating }: props) => {
    const starsArray = Array.from({ length: rating }, (_, index) => index + 1)
    let emptyArray: JSX.Element[] = []

    if (rating < 5) {
      for (let i = 0; i < 5 - rating; i++) {
        emptyArray.push(
          <IoStarOutline
            key={rating + i}
            style={{ color: "rgb(175 174 174)" }}
          />,
        )
      }
    }
    return (
      <Box className="d-flex review-stars gap-1">
        {starsArray.map((star) => (
          <IoStar key={star} className="active" />
        ))}
        {emptyArray}
      </Box>
    )
  }

  const toggleShowMore = (index: any) => {
    setShowMore((prevShowMore: any) => {
      const newShowMore = [...prevShowMore] // Create a copy of the previous state
      newShowMore[index] = !newShowMore[index] // Toggle the visibility at the specified index
      return newShowMore // Return the updated state
    })
  }

  return (
    <>
      {" "}
      {isLoading || isFetching || reisFetching || reisLoading ? (
        <CenteredSpinner />
      ) : (
        <Style.Overview>
          <Grid container>
            <Grid item xs={12}>
              {resentReview?.alreadyReviewed !== true && (
                <>
                  <Box className="d-flex justify-content-between">
                    <Typography variant="h2" className="pink-text mb-3 pb-2">
                      Review
                    </Typography>
                  </Box>
                  <TableContainer
                    component={Paper}
                    className="dashboard-table-wrapper custom-scrollbar mb-1"
                  >
                    {resentReview?.data?.length > 0 ? (
                      <Table
                        style={{
                          minWidth: 650,
                          border: "none",
                          backgroundColor: "#fff !important",
                          borderRadius: "16px",
                          overflow: "hidden",
                        }}
                        className="dashboard-table"
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableCell component="th"></TableCell>
                          <TableCell component="th" className="service-head">
                            Service provider
                          </TableCell>
                          <TableCell component="th" align="center">
                            Email
                          </TableCell>
                          <TableCell component="th" align="center">
                            Service required
                          </TableCell>
                          <TableCell component="th" align="center">
                            <MdOutlineLocationOn /> Location
                          </TableCell>
                          <TableCell component="th" align="center">
                            Date & Time
                          </TableCell>
                          <TableCell component="th" align="center">
                            Action
                          </TableCell>
                        </TableHead>
                        <TableBody>
                          {resentReview?.data?.map((row: any) => (
                            <TableRow
                              key={row.assignedTo?._id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                navigate(
                                  `/dashboard/write-review/${row?._id}?postId=${row?.assignedTo?.[0]?._id}`,
                                )
                              }
                            >
                              <TableCell>
                                <div
                                  style={{
                                    width: "74px",
                                    height: "74px",
                                    objectFit: "cover",
                                  }}
                                >
                                  <img src={row?.assignedTo?.[0]?.profilePic} />
                                </div>
                              </TableCell>
                              <TableCell
                                component="td"
                                align="center"
                                scope="row"
                              >
                                <span className="text flex-column gap-2">
                                  {row?.assignedTo?.[0]?.name}
                                  <span className="minh-1 gold ">
                                    <RatingStars
                                      rating={
                                        row?.assignedTo?.[0]?.rating
                                          ?.$numberDecimal
                                      }
                                    />
                                    {}
                                    {/* <FaStar /> */}
                                  </span>
                                </span>
                              </TableCell>
                              <TableCell align="center">
                                <span>{row?.assignedTo?.[0]?.email}</span>
                              </TableCell>
                              <TableCell align="center">
                                <span>{row?.service?.name}</span>
                              </TableCell>
                              <TableCell align="center">
                                <span className="location">
                                  <span>{row?.location}</span>
                                </span>
                              </TableCell>
                              <TableCell
                                align="center"
                                // style={{ verticalAlign: "middle" }}
                              >
                                <span
                                  className="datetime"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    height: "fit-content  !important",
                                  }}
                                >
                                  <span className="datetime fw-bold">
                                    <FormatDate dateString={row?.from} />
                                  </span>
                                  <span>
                                    <FormatTime dateString={row?.from} />
                                  </span>
                                  <span>
                                    <FormatChangeTime dateString={row?.from} />
                                  </span>
                                </span>
                              </TableCell>
                              <TableCell align="center">
                                <span>
                                  <Link
                                    to={`/dashboard/write-review/${row?._id}?postId=${row?.assignedTo?.[0]?._id}`}
                                    className="view-profile d-flex align-items-center gap-1 "
                                  >
                                    Write Review
                                    <FaPencilAlt />
                                  </Link>
                                </span>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    ) : (
                      ""
                    )}
                  </TableContainer>
                </>
              )}

              <Box className="mb-2  custom-scrollbar overflow-auto">
                <TableContainer
                  component={Paper}
                  className="dashboard-table-wrapper custom-scrollbar mb-2"
                >
                  <Table
                    sx={{
                      minWidth: 650,
                      border: "none",
                      backgroundColor: "#fff",
                    }}
                    className="dashboard-table"
                    aria-label="simple table"
                  >
                    {/* {getProviderReviews?.data?.length > 0 && ( */}
                    <TableHead>
                      <TableCell component="th"></TableCell>
                      <TableCell component="th" className="service-head">
                        Service Provider
                      </TableCell>
                      <TableCell component="th" align="center">
                        Service
                      </TableCell>
                      <TableCell component="th" align="center">
                        Review
                      </TableCell>
                      <TableCell component="th" align="center">
                        Date & Time
                      </TableCell>
                      <TableCell component="th" align="center">
                        Rating
                      </TableCell>
                    </TableHead>
                    {/* )} */}
                    <TableBody>
                      {getProviderReviews?.data?.length > 0 ? (
                        getProviderReviews?.data?.map(
                          (item: any, index: number) => (
                            <>
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell>
                                  <div
                                    style={{
                                      width: "74px",
                                      height: "74px",
                                      objectFit: "cover",
                                    }}
                                  >
                                    <img
                                      src={
                                        item?.providerData?.profilePic || dummy
                                      }
                                      style={{
                                        width: "74px",
                                        height: "74px",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </div>
                                </TableCell>
                                <TableCell
                                  component="td"
                                  align="center"
                                  scope="row"
                                >
                                  <span className=" d-flex justify-content-start">
                                    {item?.providerData?.name
                                      ? item.providerData.name
                                      : "-----------------"}

                                    <span className="minh-1 gold ">
                                      <RatingStars
                                        rating={
                                          item?.seekerData?.rating
                                            ?.$numberDecimal
                                        }
                                      />
                                    </span>
                                  </span>
                                </TableCell>

                                <TableCell align="center">
                                  <span>{item?.postId?.service?.name}</span>
                                </TableCell>

                                <TableCell align="center">
                                  <Box
                                    style={{
                                      maxWidth: "320px",
                                      overflowWrap: "anywhere",
                                      margin: "0 auto",
                                    }}
                                  >
                                    <Typography
                                      variant="body1"
                                      className="review-text minh-1"
                                    >
                                      {(showMore as any)[index] ||
                                      item?.message?.length <= 40 ? (
                                        <>
                                          {item?.message}
                                          {item?.message?.length > 40 && (
                                            <span
                                              className="cursor-pointer fw-bold minh-1"
                                              onClick={() =>
                                                toggleShowMore(index)
                                              }
                                            >
                                              ... See less
                                            </span>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {item?.message?.slice(0, 40)}
                                          {item?.message?.length > 40 && (
                                            <>
                                              ...
                                              <span
                                                className="cursor-pointer fw-bold minh-1"
                                                onClick={() =>
                                                  toggleShowMore(index)
                                                }
                                              >
                                                See more
                                              </span>
                                            </>
                                          )}
                                        </>
                                      )}
                                    </Typography>
                                  </Box>
                                </TableCell>
                                <TableCell align="center">
                                  <span className="datetime">
                                    <span className="d-flex flex-column">
                                      <span className="weekday">
                                        {" "}
                                        <FormatDate
                                          dateString={item?.createdAt}
                                        />
                                      </span>
                                      <span className="">
                                        <FormatChangeTime
                                          dateString={item?.createdAt}
                                        />
                                      </span>
                                      <span className="">
                                        <FormatTime
                                          dateString={item?.createdAt}
                                        />
                                      </span>
                                    </span>
                                  </span>{" "}
                                </TableCell>
                                <TableCell align="center">
                                  <span style={{ color: "#FFB800" }}>
                                    <RatingStars rating={item?.rating} />
                                  </span>
                                </TableCell>
                              </TableRow>
                            </>
                          ),
                        )
                      ) : (
                        <>
                          <TableRow
                            sx={{
                              height: "60vh",
                              backgroundColor: "white",
                            }}
                          >
                            <TableCell
                              align="center"
                              colSpan={7}
                              sx={{
                                width: "100%",
                                pointerEvents: "none",
                                border: "none",
                              }}
                            >
                              <img
                                src={nodata}
                                style={{
                                  width: "100%",
                                  maxWidth: "180px",
                                }}
                              />
                              <span className="no-data-text">
                                No data found
                              </span>
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          </Grid>
          {getProviderReviews?.data?.length > 0 && (
            <Box className="d-md-flex justify-content-between">
              <Typography variant="body1" className="w-auto white-space">
                {" "}
                Showing {getProviderReviews?.totalDocument?.showingData} /{" "}
                {getProviderReviews?.totalDocument?.totalCount} Total
              </Typography>
              <Grid container justifyContent="flex-end">
                <Grid item md={3}>
                  <Box display="flex" className="flex-column">
                    <Stack spacing={2} className="mb-3">
                      <Pagination
                        count={getProviderReviews?.totalDocument?.totalPages}
                        color="secondary"
                        className="custom-pagination"
                        page={selectedPage}
                        onChange={handlePageChange}
                        sx={{
                          "& .MuiPaginationItem-page.Mui-selected": {
                            backgroundColor: "#F475A4",
                            boxSizing: "border-box",
                          },
                          "& .css-wjh20t-MuiPagination-ul": {
                            justifyContent: "end",
                            flexWrap: "nowrap",
                          },
                          "& .css-nhb8h9": {
                            justifyContent: "end",
                            flexWrap: "nowrap",
                          },
                        }}
                      />
                    </Stack>
                    {/* <Typography className="page-text">
                    Showing {getProviderReviews?.totalDocument?.totalCount} -
                    {getProviderReviews?.totalDocument?.totalPages} of list
                  </Typography> */}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Style.Overview>
      )}
    </>
  )
}

interface props {
  rating: number
}
export default SeekerProvider
