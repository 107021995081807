import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Typography,
} from "@mui/material"
import { Fragment, useEffect, useRef, useState } from "react"
import RocketIcon from "../../images/rocket.png"
import stopImgIcon from "../../images/no-trips.png"
import startedImgIcon from "../../images/service-started.png"
import successImgIcon from "../../images/payment-success.png"

interface props {
  genrateOtp: () => void
  otpFill: string
  isloadingShow: boolean
  generateOtp: { activeJobflow: string }
  handleOtpgeanerate: (value: string) => void
}
const GenRateotpCustomer = ({
  genrateOtp,
  otpFill,
  isloadingShow,
  generateOtp,
  handleOtpgeanerate,
}: props) => {
  const [otp, setOtp] = useState(Array(6).fill("")) // Initialize with empty array of length 6
  const inputRefs = useRef([])

  useEffect(() => {
    if (!otpFill) {
      setOtp(Array(6).fill(""))
    } else {
      setOtp(otpFill.split("").concat(Array(6 - otpFill.length).fill("")))
    }
  }, [otpFill])

  useEffect(() => {
    if (
      generateOtp?.activeJobflow == "STOP_SERVICE"
      // generateOtp?.activeJobflow == "CUSTOMER_STOP_OTP"
    ) {
      handleOtpgeanerate("")
    }
  }, [generateOtp?.activeJobflow])

  console.log("generateOtp?.activeJobflow", generateOtp?.activeJobflow)

  return (
    <Box>
      {(generateOtp?.activeJobflow == "JOB_ACTIVE" ||
        generateOtp?.activeJobflow == "STOP_SERVICE" ||
        generateOtp?.activeJobflow == "CUSTOMER_STOP_OTP") && (
        <Grid container spacing={2}>
          <Grid item xs={12} lg={7}>
            <Box className="otp-box text-center otp-box-1">
              <>
                <Box pb={3}>
                  <FormControl className="d-flex justify-content-center gap-3 flex-row">
                    {otp?.map((digit, index) => (
                      <input
                        disabled
                        key={index}
                        ref={(el) => ((inputRefs as any).current[index] = el)}
                        type="text"
                        value={digit}
                        className="otp-boxes"
                        maxLength={1}
                        pattern="[0-9]"
                        inputMode="numeric"
                        required
                      />
                    ))}
                  </FormControl>
                </Box>
                <Button
                  className="view-profile py-2 text-capitalize"
                  style={{ minWidth: "118px" }}
                  onClick={genrateOtp}
                >
                  {isloadingShow ? (
                    <Typography fontSize={1}>
                      <CircularProgress
                        style={{ color: "pink", fontSize: 20 }}
                        size={20}
                      />
                    </Typography>
                  ) : (
                    "Generate Otp"
                  )}
                </Button>
              </>
            </Box>
          </Grid>
          <Grid item xs={12} lg={5} className="d-none d-xl-block m-auto">
            <Box
              sx={{
                maxWidth: 300, // set width as needed
                height: "auto", // set height as needed
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={stopImgIcon}
                alt="Description"
                style={{ width: "200px", height: "auto" }}
              />
            </Box>{" "}
          </Grid>
        </Grid>
      )}
      {/* <>
        <Typography
          align="left"
          variant="h2"
          className=" fw-medium font-24"
          mb={1}
        >
          Service stopped{" "}
        </Typography>
        <Box className="d-flex justify-content-between">
          <Box
            className="otp-box h-100 text-center d-flex flex-column align-items-center justify-content-center"
            sx={{ mt: 1 }}
          >
            <Typography
              align="center"
              variant="body1"
              className="green-heading"
              mb={2}
            >
              Your service is completed successfully{" "}
            </Typography>
          </Box>
          <Box sx={{}}>
            <img
              src={startedImgIcon}
              alt="service started"
              className="otp-box-img"
            />
          </Box>{" "}
        </Box>
      </> */}
    </Box>
  )
}
export default GenRateotpCustomer

interface propsServiceStart {
  isloadingShow: boolean
  blankOtp: (value: string) => void
  serviceStop: () => void

  postDetails: { activeJobflow: string }
}

const ServiceStart = ({
  isloadingShow,
  blankOtp,
  serviceStop,
  postDetails,
}: propsServiceStart) => {
  useEffect(() => {
    blankOtp("")
  }, [])
  return (
    <>
      {postDetails?.activeJobflow == "START_OTP_VERIFIED" && (
        <Fragment>
          <Typography
            align="left"
            variant="h2"
            className=" fw-medium font-24"
            mb={1}
          >
            Otp generated{" "}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={7}>
              <Box
                className="otp-box h-100 text-center d-flex flex-column align-items-center justify-content-center"
                sx={{ mt: 1 }}
              >
                <Typography
                  align="center"
                  variant="body1"
                  className="green-heading p-4"
                  mb={2}
                >
                  Your service has started{" "}
                </Typography>
                {/* <Typography align="center" variant="body1" className="" mb={2}>
              To stop your service, click here{" "}
            </Typography> */}
                <Button
                  className="view-profile py-2 text-capitalize"
                  onClick={serviceStop}
                >
                  {isloadingShow ? (
                    <Typography fontSize={1}>
                      <CircularProgress
                        style={{ color: "pink", fontSize: 20 }}
                        size={20}
                      />
                    </Typography>
                  ) : (
                    "Stop Service"
                  )}
                </Button>
              </Box>{" "}
            </Grid>
            <Grid item xs={12} lg={5} className="d-none d-xl-block m-auto">
              <Box
                sx={{
                  maxWidth: 300, // set width as needed
                  height: "auto", // set height as needed
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={startedImgIcon}
                  alt="Description"
                  style={{ width: "200px", height: "auto" }}
                />
              </Box>{" "}
            </Grid>
          </Grid>
          <Box className="d-flex gap-1 justify-content-between "></Box>
        </Fragment>
      )}
    </>
  )
}

export { ServiceStart }

interface propsPay {
  handlePayment: () => void
  postDetailsForPay: {
    activeJobflow: string
    paymentDetail: { totalAmount: number }
    data: { activeJobflow: string }
  }
}

const PayPriceForService = ({ handlePayment, postDetailsForPay }: propsPay) => {
  return (
    <>
      {postDetailsForPay?.data?.activeJobflow == "END_OTP_VERIFIED" && (
        <Fragment>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={7}>
              <Box className="otp-box text-center otp-box-1">
                <>
                  <Box pb={1}>
                    {" "}
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "20px",
                        color: "#000",
                        fontWeight: "600",
                      }}
                    >
                      Total Amount{" "}
                      <span style={{ color: "#009C35" }}>
                        {" "}
                        CAD${postDetailsForPay?.paymentDetail?.totalAmount}
                      </span>{" "}
                    </p>
                  </Box>
                  <Button
                    className="view-profile py-2 text-capitalize"
                    style={{ minWidth: "118px" }}
                    onClick={handlePayment}
                  >
                    Pay
                  </Button>
                </>
              </Box>
            </Grid>
            <Grid item xs={12} lg={5} className="d-none d-xl-block m-auto">
              <Box>
                <Typography
                  align="center"
                  variant="h2"
                  className=" fw-medium font-24"
                  mb={1}
                >
                  Service Completed Successfully!
                </Typography>
              </Box>
              <Box
                sx={{
                  // set width as needed
                  height: "auto", // set height as needed
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={successImgIcon}
                  alt="Description"
                  style={{ width: "110px", height: "auto", marginTop: "20px" }}
                />
              </Box>{" "}
            </Grid>
          </Grid>
        </Fragment>
      )}
    </>
  )
}

export { PayPriceForService }
