import React, { useEffect, useState } from "react"
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Rating,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"
import { LiaAngleRightSolid } from "react-icons/lia"
import * as Style from "./style"
import moment from "moment"
import { Stack, Table } from "react-bootstrap"
import tableCellImg from "../../images/table-img.png"
import { Upcoming } from "@mui/icons-material"
import singleSeekerImg from "../../images/img-rounded.png"
import { FaStar } from "react-icons/fa6"
import { IoMailOutline } from "react-icons/io5"
import { FiPhone } from "react-icons/fi"
import { SlLocationPin } from "react-icons/sl"
import { Link, useNavigate, useParams } from "react-router-dom"
import { FaChevronLeft } from "react-icons/fa"
import { IoStar } from "react-icons/io5"
import StarIcon from "@mui/icons-material/Star"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import {
  useCreateReviewsMutation,
  useGetProviderViewQuery,
} from "../../features/OverViewAuth/overViewApi"
import CircularIndeterminate from "../Loading/Loading"
import { toast } from "react-toastify"
import {
  FormatChangeTime,
  FormatDate,
  FormatTime,
} from "../common/utctolocalDate"
import CenteredSpinner from "../common/CenteredSpinner"

interface ReviewProps {
  message: string
}

const WriteReview = () => {
  const [values, setValues] = useState<number | null>(null)
  const [errorloading, setErrorLoading] = useState<boolean>(false)
  const urlSearchParams = new URLSearchParams(window.location.search)
  const postId = urlSearchParams.get("postId")

  const { id } = useParams()

  const [createReview, { data: reviewData, error, isLoading }] =
    useCreateReviewsMutation()
  const {
    data: providerView,
    isLoading: providerisLoading,
    isFetching: providerisFetching,
  } = useGetProviderViewQuery({ postId: id, providerId: postId })

  const navigate = useNavigate()

  const schemaResolver = yup.object().shape({
    message: yup.string().required("Please fill the Mandatory fields."),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ReviewProps, any>({
    resolver: yupResolver(schemaResolver),
  })

  // const onSubmit = (data: any) => {
  //   const trimmedMessage = data?.message?.trim()
  //   const wordCount = trimmedMessage.split(/\s+/).length
  //   const payload = {
  //     rating: values && values,
  //     message: data?.message,
  //     providerData: postId,
  //     postId: id,
  //   }
  //   if (
  //     values !== null &&
  //     values >= 0 &&
  //     trimmedMessage.length > 0 &&
  //     wordCount <= 200
  //   ) {
  //     createReview(payload)
  //   } else {
  //     toast.error("Please fill the Mandatory fields.", { autoClose: 800 })
  //     setErrorLoading(true)
  //     setTimeout(() => {
  //       setErrorLoading(false)
  //     }, 1000)
  //   }
  // }

  const onSubmit = (data: any) => {
    const trimmedMessage = data?.message?.trim() || ""
    const wordCount = trimmedMessage.split(/\s+/).filter(Boolean).length

    if (values === null || values < 0) {
      toast.error("Please provide a valid rating.", { autoClose: 800 })
      setErrorLoading(true)
      setTimeout(() => {
        setErrorLoading(false)
      }, 1000)
      return
    }

    if (trimmedMessage.length === 0) {
      toast.error("Message is required.", { autoClose: 800 })
      setErrorLoading(true)
      setTimeout(() => {
        setErrorLoading(false)
      }, 1000)
      return
    }

    if (wordCount > 200) {
      toast.error("Message exceeds the maximum length of 200 words.", {
        autoClose: 800,
      })
      setErrorLoading(true)
      setTimeout(() => {
        setErrorLoading(false)
      }, 1000)
      return
    }

    const payload = {
      rating: values,
      message: trimmedMessage,
      providerData: postId,
      postId: id,
    }

    createReview(payload)
  }

  useEffect(() => {
    if (reviewData?.message) {
      navigate(`/dashboard/rating-reviews`)
    }
  }, [reviewData])

  useEffect(() => {
    if ((errors as any)?.message) {
      toast.error((errors as any)?.message?.message, { autoClose: 800 })
      setErrorLoading(true)
      setTimeout(() => {
        setErrorLoading(false)
      }, 1000)
    }
  }, [errors])

  return (
    <>
      {providerisLoading || isLoading ? (
        <CenteredSpinner />
      ) : (
        <>
          <Style.Overview>
            <Box className="d-flex align-items-center gap-4 mb-4">
              <Button
                className="back-btn mb-0"
                onClick={() => navigate(`/dashboard/rating-reviews`)}
              >
                <FaChevronLeft />
              </Button>
              <Typography className="font-20 service-text pink-text fw-semibold mb-0">
                Write Review
              </Typography>
            </Box>
          </Style.Overview>
          <Grid container spacing={2}>
            {providerView?.data?.map((item: any) => (
              <Grid item xs={4}>
                <Style.Overview>
                  <Box>
                    <Typography
                      variant="body1"
                      className="text-start text-black fw-bold  pt-2 pb-3"
                    >
                      Service Seeker
                    </Typography>
                    <Box className="seeker-card">
                      {item?.assignedTo?.map((profile: any) => (
                        <Box className="d-flex gap-2 align-items-center mb-3">
                          <Box>
                            <img
                              src={profile?.profilePic}
                              className="seeker-img rounded"
                              style={{ width: "50px", height: "50px" }}
                            />
                          </Box>
                          <Box>
                            <Typography
                              variant="body1"
                              className="mb-2 text-black"
                            >
                              {profile?.name}
                            </Typography>
                            <Typography variant="body1" className="mb-2">
                              <IoMailOutline /> {profile?.email}
                            </Typography>
                            <Typography variant="body1">
                              <FiPhone /> {profile?.contactNo}
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                      <Box>
                        <Box className="py-3 listing-seeker">
                          <Typography variant="body1" className=" fw-semibold">
                            Service - {item?.service?.name}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Box className="py-3 listing-seeker">
                          <Typography
                            variant="body1"
                            className=" fw-semibold d-flex"
                          >
                            Date - {moment(item.from).format("ddd-DD-MMM")}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Box className="py-3 listing-seeker">
                          <Typography
                            variant="body1"
                            className=" fw-semibold d-flex"
                          >
                            Time - <FormatChangeTime dateString={item.from} /> -
                            <FormatChangeTime dateString={item.till} />
                          </Typography>
                        </Box>
                      </Box>
                      <Box></Box>
                      <Box>
                        <Box className="py-3 listing-seeker">
                          <Typography variant="body1" className=" fw-semibold">
                            Number of provider - {item?.personRequired}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Box className="py-3 listing-seeker">
                          <Typography
                            variant="body1"
                            className="d-flex gap-2 lh-sm fw-semibold"
                          >
                            Location: {item?.location}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Box className="py-3 listing-seeker">
                          <Typography variant="body1" className="fw-semibold">
                            Status -{" "}
                            <span className="status-text completed">
                              {item?.status}
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Style.Overview>
              </Grid>
            ))}
            <Grid item xs={8}>
              <Style.Overview>
                <Box className="write-review-card">
                  <Typography
                    variant="body1"
                    className="text-center pink-text mb-4 fw-semibold"
                  >
                    How would you rate service provider{" "}
                    <span className="text-danger">*</span>
                  </Typography>
                  <Box className="d-flex justify-content-center align-items-center gap-3 mb-5">
                    <Rating
                      name="hover-feedback"
                      value={values}
                      onChange={(event, newValue) => {
                        setValues(newValue)
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant="body1"
                      className="pink-text mb-3 fw-semibold"
                    >
                      Write a review <span className="text-danger">*</span>
                    </Typography>
                    <TextField
                      id="outlined-textarea"
                      placeholder="Write your review here...."
                      multiline
                      rows="4"
                      className="review-field w-100 mb-1"
                      {...register("message")}
                    />
                    {/* <span className="text-danger">
                      {errors?.messages?.message}
                    </span> */}
                    <Box className="text-end">
                      <Button
                        disabled={errorloading}
                        className="view-profile text-capitalize"
                        onClick={handleSubmit(onSubmit)}
                      >
                        {isLoading ? (
                          <Typography fontSize={1}>
                            <CircularProgress
                              style={{ color: "pink", fontSize: 24 }}
                              size={24}
                            />
                          </Typography>
                        ) : (
                          "Send"
                        )}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Style.Overview>
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}
export default WriteReview
