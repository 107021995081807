import React, { useEffect, useState } from "react"
import {
  InputGroup,
  FormControl,
  Button,
  Row,
  Col,
  Container,
  Badge,
  Stack,
} from "react-bootstrap"
import styles from "./ChatUi.module.css"
import { IoSearchSharp } from "react-icons/io5"
import { useAppSelector } from "../../app/hooks"
import { useDispatch } from "react-redux"
import { useAppDispatch } from "../../app/store"
import {
  _setTicket,
  _setSearchTickets,
} from "../../features/ChatAuth/chatSlice"
import { Ticket } from "../ChatenterFace/chatinterface"

interface searchProps {
  searchList: (value: string) => void
}
interface search { }

const ChatBar = ({ searchList }: searchProps) => {
  const dispatch = useAppDispatch()
  const [searchNameUser, setSearchNameUser] = useState("")

  let debounceTimeout: NodeJS.Timeout | null = null
  const handleChange = (e: any) => {
    const { value } = e.target
    if (debounceTimeout) {
      clearTimeout(debounceTimeout)
    }
    debounceTimeout = setTimeout(() => {
      setSearchNameUser(value)
      dispatch(_setSearchTickets(value as any))
    }, 1000)
  }
  return (
    <Row className={`${styles["chat-bar"]} py-1`}>
      <Col className="m-auto gap-2">
        <div className="position-relative ">
          <input
            placeholder="Search by service name"
            type="text"
            className={` form-control ${styles["search-field-2"]}`}
            onChange={handleChange}
          />
          <button
            type="button"
            className={` btn btn-transparent ${styles["search-btn"]} ${styles["search-icon-type--2"]}`}
          >
            <IoSearchSharp />
          </button>
        </div>
      </Col>
    </Row>
  )
}

export default ChatBar
