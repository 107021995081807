import { styled } from "@mui/material"

const Upcoming = styled("div")(({ theme }) => ({
  marginBottom: "20px",
  "& .MuiTypography-h2": {
    color: theme.palette.pink.main,
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: " normal",
  },
  "& .Headingbox": {
    borderRadius: "16px 16px 0 0",

    display: "flex",
    justifyContent: "space-between",
    background: theme.palette.white.main,
    boxShadow: "0px 4px 27px 0px rgba(0, 0, 0, 0.07)",
    padding: "15px",
  },
  "& .ContentBox": {
    display: "flex",
    justifyContent: "space-between",
    padding: "15px",
    background: theme.palette.white.main,
    "& .MuiGrid-root": {
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
      marginTop: "20px",
    },
    "& .MuiButton-root": {
      borderColor: theme.palette.pink.main,
      color: theme.palette.pink.main,
      fontFamily: "Montserrat",
    },
  },
  "& .flex-none": {
    flex: "none",
  },
  "& .max-w-550": {
    maxWidth: "550px",
  },
  "& .search-field": {
    width: "88%",
    flex: "none",
    "& label.Mui-focused": {
      color: theme.palette.pink.main,
    },
    "& label.MuiFormLabel-filled": {
      color: theme.palette.pink.main,
    },
    "& input": {
      padding: "16px 20px",
      fontSize: "16px",
      fontFamily: "Montserrat",
      color: theme.palette.black.main,
    },
    "&  .MuiOutlinedInput-notchedOutline": {
      borderRight: "none",
      borderRadius: "11px 0px 0 11px",
      borderColor: theme.palette.pink.main,
      "&.Mui-focused": {
        borderColor: theme.palette.pink.main,
      },
    },
  },
  "& .search-btn": {
    backgroundColor: theme.palette.pink.main,
    borderRadius: "0px 11px 11px 0px",
    boxShadow: "none",
    "& svg": {
      width: "25px",
      height: "25px",
    },
  },
}))
const Overview = styled("div")(({ theme }) => ({
  "& .MuiTypography-h2": {
    color: theme.palette.pink.main,
    fontFamily: "Montserrat",
    fontSize: " 20px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: " normal",
  },
  "& .MuiTypography-body3": {
    color: theme.palette.gray.main700,
    fontFamily: "Montserrat",
    fontSize: " 14px",
    fontStyle: "normal",
    fontWeight: "400",
    display: "block",
    lineHeight: " 13px",
    "&.font-20": {
      fontSize: "20px",
      lineHeight: "initial",
    },
  },
  "& .font-16": {
    fontSize: "16px",
  },
  "& .font-25": {
    fontSize: "25px",
  },
  "& .pink-text": {
    color: theme.palette.pink.main,
  },
  "& .MuiDrawer-paper": {
    backgroundColor: theme.palette.pink.main,
    "& .MuiListItem-root ": {
      color: theme.palette.white.main,
      "& svg": {
        color: theme.palette.white.main,
      },
    },
  },
  "& .desc-card": {
    padding: "21px 18px",
    border: "1px solid #E9E9E9",
    borderRadius: "16px",
  },
  "& .person-text": {
    fontSize: "20px",
    fontWeight: "500",
    fontFamily: "Montserrat",
    "@media (max-width: 600px)": {
      fontSize: "16px",
    },
  },
  "& .lh-initial": {
    lineHeight: "initial",
  },
  "& .Advertisement": {
    borderRadius: " 16px",
    background: "linear-gradient(90deg, #F475A4 38.96%, #DD5084 99.94%)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "end",
    "& .MuiButtonBase-root": {
      background: theme.palette.white.main,
      color: theme.palette.pink.main,
    },
  },
  "& .seeker-card": {
    padding: "16px",
    background: theme.palette.white.main,
    borderRadius: "13px",
    "& .listing-seeker": {
      borderTop: "1px solid #e3e3e3",
    },
  },
  "& .assign-btn": {
    fontSize: "14px",
    lineHeight: "18px",
    fontWeight: "400",
    backgroundColor: theme.palette.green.main,
    color: theme.palette.white.main,
    boxShadow: "none",
    fontFamily: "Montserrat",
    padding: "15px 10px",
    textTransform: "capitalize",
  },
  "& .rejected-btn": {
    fontSize: "14px",
    lineHeight: "18px",
    fontWeight: "400",
    backgroundColor: "transparent",
    color: theme.palette.red.main,
    borderColor: theme.palette.red.main,
    boxShadow: "none",
    fontFamily: "Montserrat",
    padding: "15px 10px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.palette.red.main,
      color: theme.palette.white.main,
    },
    "&.reject-fill-btn": {
      backgroundColor: theme.palette.red.main,
      color: theme.palette.white.main,
    },
  },
  "& .feature-field": {
    "&:before": {
      display: "none",
    },
    "&:after": {
      display: "none",
    },
    "& .MuiFilledInput-underline": {
      fontSize: "16px",
      border: "1px solid #C1C1C1",
      padding: "16px 21px",
      borderRadius: "11px",
      color: theme.palette.black.main,
      backgroundColor: "transparent",
      fontFamily: "Montserrat",
      fontWeight: "400",
    },
    "& .MuiInputBase-input": {
      padding: "20px 0",
    },
    "& .css-cvaeo7-MuiInputBase-root-MuiFilledInput-root:before": {
      display: "none",
    },
    "& .css-cvaeo7-MuiInputBase-root-MuiFilledInput-root:after": {
      display: "none",
    },
    "&.title_field": {
      "& .MuiInputBase-input": {
        fontSize: "20px",
      },
    },
    "&.icon-field": {
      "& .MuiInputAdornment-positionStart": {
        position: "absolute",
        top: "50%",
        left: "20px",
        transform: "translateY(-50%)",
        "& svg": {
          width: "23px",
          height: "23px",
        },
      },
      "& input": {
        paddingLeft: "53px",
      },
    },
    "&.font-14": {
      "& .MuiInputBase-input": {
        padding: "5px 10px 28px",
        maxWidth: "94px",
        fontSize: "14px",
        textAlign: "center",
      },
    },
  },
  "& .view-profile": {
    display: "inline-block",
    width: "135px",
    padding: "12px 10px",
    border: "1px solid",
    borderRadius: "8px",
    borderColor: theme.palette.pink.main,
    fontFamily: "Montserrat",
    color: theme.palette.pink.main,
    fontSize: "14px",
    textAlign: "center",
    textDecoration: "none",
    transition: "all 0.3s ease",
    "&:hover": {
      backgroundColor: theme.palette.pink.main, // Apply hover background color
      color: "white", // Change text color on hover
      cursor: "pointer", // Change cursor to pointer on hover
    },
  },
  "& .reject-card": {
    padding: "30px 42px",
    background: theme.palette.white.main,
    borderRadius: "20px",
    "& .yes-btn": {
      fontSize: "14px",
      lineHeight: "18px",
      fontWeight: "400",
      backgroundColor: "transparent",
      color: theme.palette.gray.main500,
      borderColor: theme.palette.gray.main500,
      boxShadow: "none",
      fontFamily: "Montserrat",
      padding: "7px 10px",
      textTransform: "capitalize",
    },
    "& .no-btn": {
      fontSize: "14px",
      lineHeight: "18px",
      fontWeight: "400",
      backgroundColor: theme.palette.pink.main,
      color: theme.palette.white.main,
      borderColor: theme.palette.pink.main,
      boxShadow: "none",
      fontFamily: "Montserrat",
      padding: "7px 10px",
      textTransform: "capitalize",
    },
  },
  "& .offer-box": {
    background: "linear-gradient(90.06deg, #F475A4 38.96%, #DD5084 99.94%);",
    borderRadius: "16px",
    padding: "19px 39px",
    position: "relative",
    "& .logo-white": {
      maxWidth: "148px",
      width: "100%",
      marginBottom: "12px",
    },
    "& .off-discount": {
      fontSize: "48px",
      color: theme.palette.white.main,
      letterSpacing: "4.8px",
      marginBottom: "22px",
      fontFamily: "Racing Sans One",
    },
    "& .valid-text": {
      color: "#FFD4E4",
      fontSize: "12px",
      fontFamily: "Montserrat",
    },
    "& .valid-date-text": {
      fontSize: "16px",
      color: theme.palette.white.main,
      fontFamily: "Montserrat",
      fontWeight: "500",
    },
    "& .book-text": {
      fontSize: "12px",
      color: theme.palette.white.main,
      fontFamily: "Montserrat",
      marginBottom: "9px",
    },
    "& .book-now-btn": {
      maxWidth: "135px",
      width: "100%",
      textAlign: "center",
      textDecoration: "none",
      borderRadius: "4px",
      padding: "12px 7px",
      background: theme.palette.white.main,
      color: theme.palette.pink.main,
      fontSize: "14px",
      fontFamily: "Montserrat",
      display: "inline-block",
    },
    "& .offer-img": {
      position: "absolute",
      bottom: 0,
      right: "20px",
      maxWidth: "200px",
      width: "100%",
      borderRadius: "11px",
      top: "45px",
      "@media (max-width: 677px)": {
        display: "none",
      },
    },
  },
  "& .feature-card": {
    backgroundColor: theme.palette.white.main,
    borderRadius: "16px",
    boxShadow: "0px 4px 20px 2px #00000008",
    "&.single-feature-card": {
      padding: "81px 75px",
      "@media (max-width: 1000px)": {
        padding: "26px",
      },
      "& .single-feature-img": {
        width: "164px",
        height: "133px",
        objectFit: "cover",
        borderRadius: "16px",
      },
    },
    "& .feature-card-img img": {
      width: "120px",
      borderRadius: "16px 0 0 16px    ",
    },
    "& .feature-card-2": {
      minHeight: "80px",
    },
    "& .service-img img": {
      height: "80px",
      maxWidth: "120px",
    },
    "& .font-15": {
      fontSize: "15px",
    },
    "& .font-13": {
      fontSize: "13px",
    },
    "& .services-desc": {
      color: "#7C7C7C",
      lineHeight: "1",
    },
    "& .feature-card-content": {
      padding: "30px",
    },
    "& .field-label": {
      fontSize: "16px",
      fontFamily: "Montserrat",
      color: theme.palette.black.main,
      "& svg": {
        fill: theme.palette.pink.main,
      },
    },
    "& .person-text": {
      fontSize: "20px",
      color: theme.palette.pink.main,
      fontWeight: "500",
      fontFamily: "Montserrat",
      "@media (max-width: 600px)": {
        fontSize: "16px",
      },
    },
  },
  "& .book-selectbx": {
    minWidth: "310px",
    marginTop: "0px",
    marginBottom: "0px",
    "& .MuiInputBase-root": {
      borderRadius: "8px",
      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.pink.main,
        },
      },
    },
    "& label": {
      fontSize: "16px",
      color: theme.palette.gray.main400,
      "&.Mui-focused": {
        color: theme.palette.pink.main,
      },
      "&.MuiFormLabel-filled": {
        color: theme.palette.pink.main,
      },
    },
    "& .MuiSelect-select": {
      backgroundColor: "#F0F0F0",
      fontSize: "16px",
      color: theme.palette.gray.main400,
    },
  },
  "& .date-picker-field": {
    "& .MuiTypography-root": {
      fontSize: "14px",
      color: "#C3C3C3",
      fontFamily: "Montserrat",
      fontWeight: "400",
      lineHeight: "22px",
    },
    "& .MuiStack-root": {
      alignItems: "center",
      flexDirection: "column-reverse",
      paddingTop: "0px",
      overflow: "visible",
    },
    "& .MuiFormControl-root": {
      marginTop: "0px",
    },
    "& .MuiInputBase-input": {
      padding: "10px",
      fontSize: "14px",
      color: theme.palette.black.main,
      backgroundColor: "#F0F0F0",
      borderRadius: "6px",
    },
    "& fieldset": {
      borderColor: "transparent",
    },
  },
  "& .btn-save": {
    minWidth: "130px",
    padding: "15px 10px",
    border: "1px solid",
    borderColor: theme.palette.pink.main,
    borderRadius: "8px",
    fontSize: "14px",
    color: theme.palette.pink.main,
    backgroundColor: theme.palette.white.main,
    fontFamily: "Montserrat",
  },
  "& .delete-feature-btn": {
    minWidth: "166px",
    padding: "15px 10px",
    border: "1px solid",
    borderColor: "#EDEDED",
    borderRadius: "8px",
    fontSize: "14px",
    color: "#7E7E7E",
    fontWeight: "400",
    textTransform: "capitalize",
    boxShadow: "none",
    backgroundColor: "#EDEDED",
    fontFamily: "Montserrat",
  },
  "& .active-toggle": {
    "& .MuiSwitch-sizeMedium": {
      marginBottom: "0px",
      marginTop: "0px",
    },
    "& .MuiFormControlLabel-label": {
      fontSize: "12px",
      color: theme.palette.black.main,
      fontFamily: "Montserrat",
    },
  },
  "& .back-btn": {
    backgroundColor: "#F4F4F4",
    minWidth: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    marginBottom: "21px",
    "& svg": {
      fill: "#3A3A3A",
    },
  },
  "& .feature-add-image": {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "165px",
    height: "133px",
    width: "100%",
    borderRadius: "16px",
    backgroundColor: "#EEEEEE",
    fontSize: "14px",
    color: "#7C7C7C",
    gap: "5px",
    fontFamily: "Montserrat",
    cursor: "pointer",
    marginBottom: "38px",
    "& svg": {
      fill: "#B1B1B1",
    },
  },
  "& .add-more-btn": {
    width: "100%",
    padding: "25px",
    textAlign: "center",
    fontSize: "15px",
    color: "#868686",
    display: "flex",
    gap: "18px",
    backgroundColor: theme.palette.white.main,
    borderRadius: "16px",
    boxShadow: "0px 4px 20px 2px #00000008",
    textTransform: "capitalize",
    fontWeight: "400",
    "& svg": {
      width: "50px",
      height: "50px",
      fill: "#DDDDDD",
    },
  },
}))
const Flexbox = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  // "& .MuiTypography-body2": {
  //     color: theme.palette.grey.main200,
  //     fontFamily: "Nunito Sans",
  //     fontSize: " 14px",
  //     fontStyle: "normal",
  //     fontWeight: "400",
  //     lineHeight: " 18px",
  // },
  "& .MuiTypography-body1": {
    color: theme.palette.pink.main,
    fontFamily: "Montserrat",
    fontSize: " 24px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: " 18px",
  },
  "& .Status.MuiBox-root": {
    background: theme.palette.white.main,
    boxShadow: "0px 4px 20px 2px rgba(0, 0, 0, 0.03)",
    padding: "20px",
    borderRadius: "16px",
    width: "110px",
  },
}))

export { Upcoming, Overview, Flexbox }
