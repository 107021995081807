import * as React from "react"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Button from "@mui/material/Button"
import user from "../../images/user.jpeg"
import { BsFillInfoCircleFill } from "react-icons/bs"
import styles from "./ChatUi.module.css"
import {
  FormatChangeTime,
  FormatDate,
  FormatTime,
} from "../common/utctolocalDate"
import RatingStars from "../common/rating"
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "550px",
  bgcolor: "background.paper",
  borderRadius: "15px",
  // boxShadow: 24,
  pt: 3,
  px: 4,
  pb: 3,
}

interface props {
  onShowDeatils: any
  open: boolean
  handleClose: () => void
}

export default function ChatDeatilsModal({
  onShowDeatils,
  open,
  handleClose,
}: props) {
  const assignedTo = onShowDeatils?.postId?.assignedTo ?? []
  const displayNames = assignedTo.slice(0, 1) // Only the first name
  const additionalNamesCount = assignedTo.length > 1 ? assignedTo.length - 1 : 0
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        className={` ${styles["chat-bg"]}`}
      >
        <Box sx={{ ...style, width: 600 }}>
          <h2
            className={`mb-5 ${styles["chat-modal-head"]}`}
            id="parent-modal-title text-align-center"
          >
            Booking Detail
          </h2>
          <div className="d-flex gap-3 justify-content-center">
            <h5 className={`${styles["chat-modal-text"]}`}>
              Service Booked{" "}
              <span
                className={`${styles["chat-modal-details"]}`}
                style={{ color: "#000" }}
              >
                {onShowDeatils?.postId?.serviceDetails?.name}
              </span>
            </h5>
            <h5 className={`${styles["chat-modal-text"]}`}>
              Service Provider
              <div
                className={` d-flex gap-3 align-items-center mt-2 ${styles["chat-modal-details"]}`}
              >
                <img
                  src={onShowDeatils?.postId?.serviceDetails?.image}
                  style={{
                    width: "60px",
                    height: "60px",
                    marginTop: "10px",
                    borderRadius: "15px",
                  }}
                />
                <div className={`${styles["details"]}`}>
                  {displayNames.map((name: any) => (
                    <p
                      className="mb-0 mt-2"
                      style={{ color: "#000" }}
                      key={name.name}
                    >
                      {name?.name &&
                        name?.name.charAt(0).toUpperCase() +
                          name?.name.slice(1)}
                      <RatingStars rating={name?.rating?.$numberDecimal} />
                    </p>
                  ))}

                  {additionalNamesCount > 0 && (
                    <>
                      <span style={{ color: "green" }}>
                        <br />
                      </span>
                      <span style={{ color: "green" }}>
                        + {additionalNamesCount} Others
                      </span>
                    </>
                  )}
                </div>
              </div>
            </h5>
            <h5 className={`borderRight-none ${styles["chat-modal-text"]}`}>
              Service date & time
              <span className={` ${styles["chat-modal-details"]}`}>
                <span className="d-flex flex-column text-black">
                  <span className="weekday">
                    {/* {activePost?.data.availabeJobs} */}
                    <FormatDate dateString={onShowDeatils?.postId?.from} />
                  </span>
                  {/* <span> */}
                  <span>
                    <FormatChangeTime
                      dateString={onShowDeatils?.postId?.from}
                      // timezone={"india"}
                    />
                  </span>
                  <span>
                    <FormatTime dateString={onShowDeatils?.postId?.from} />
                  </span>
                </span>
              </span>
            </h5>
          </div>
        </Box>
      </Modal>
    </div>
  )
}
