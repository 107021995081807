import * as React from "react"

import Modal from "@mui/material/Modal"
import { Box, Button, Grid, TextField, Typography } from "@mui/material"
import {
  useEditReviewMutation,
  useUpdateReviewMutation,
} from "../../features/Auth/authApi"
import SimpleBackdrop from "../Loder/Loder"


const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
}


interface proptyps {
  open: boolean
  handleClose : ()=> void
  providerId: string
  


}

export default function EditReviews({
  open,
  handleClose,
  providerId,
 
}: proptyps) {
  const [loading, setLoading] = React.useState(true)
  const [fields, setFields] = React.useState({
    review: "",
    rating: "",
  })



  const [editReview, { error, data }] = useEditReviewMutation()

  const [updateReview, { error: updateError, data: updateData }] =
    useUpdateReviewMutation()

  const handleChnage = (e : any) => {
    const { name, value } = e.target
    setFields((pre) => ({ ...pre, [name]: value }))
  }

  const submitreview = () => {
    const payload = {
      providerData: providerId,
      rating: fields?.rating,
      message: fields?.review,
    }
    setLoading(true)
    
    updateReview(payload)
  }

  React.useEffect(() => {
    if (data?.message) {
      setFields((pre) => ({
        ...pre,
        review: data?.data?.message,
        rating: data?.data?.rating,
      }))
      setLoading(false)
    }
  }, [data])

  React.useEffect(() => {
    if (updateData?.message) {
      setLoading(false)
      handleClose()
    }
  }, [updateData])

  React.useEffect(() => {
    editReview(open)
  }, [open])

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid xs={12}>
            <TextField
              id="outlined-basic"
              name="review"
              type="text"
              variant="outlined"
              sx={{ width: "100%", marginBottom: "10px" }}
              value={fields?.review}
              onChange={handleChnage}
            />
          </Grid>

          <Grid xs={12}>
            <TextField
              id="outlined-basic"
              type="number"
              name="rating"
              inputProps={{
                max: 5,
                min: 0,
                maxLength: 1,
                minLength: 1,
              }}
              variant="outlined"
              value={fields?.rating}
              sx={{ width: "100%", marginBottom: "10px" }}
              onChange={handleChnage}
            />
          </Grid>
          <Button onClick={submitreview}>Submit</Button>
          {loading && <SimpleBackdrop />}
        </Box>
      </Modal>
    </>
  )
}
