import React, { useEffect, useRef, useState } from "react"
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  colors,
} from "@mui/material"
import { FaChevronLeft, FaStar } from "react-icons/fa6"
import * as Style from "./style"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"
import tableCellImg from "../../images/table-img.png"
import babyImg from "../../images/babysitting.png"
import { FaChevronRight } from "react-icons/fa6"
import { IoCalendarClearOutline } from "react-icons/io5"
import { CiClock2 } from "react-icons/ci"
import { IoLocationOutline } from "react-icons/io5"
import successImgIcon from "../../images/payment-success.png"
import {
  overViewApi,
  useActivePostQuery,
  useGenOtpMutation,
  useGetBookingAllQuery,
  useGetProviderListQuery,
  useVerifyOtpMutation,
} from "../../features/OverViewAuth/overViewApi"
import {
  Timeline,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
  TimelineContent,
} from "@mui/lab"

import moment from "moment"
import CircularIndeterminate from "../Loading/Loading"
import { toast } from "react-toastify"
import {
  FormatChangeTime,
  FormatDate,
  FormatTime,
} from "../common/utctolocalDate"
import CenteredSpinner from "../common/CenteredSpinner"
import RatingStars from "../common/rating"
import dummyImage from "../../images/dummy.png"
import chat from "../../images/chat.png"
import chatimage from "../../images/chatimage.png"
import GenRateotpCustomer, {
  PayPriceForService,
  ServiceStart,
} from "./genrateOtpCus"
import dummy from "../../images/dummy.png"
import StopService, {
  ColumnGroupingTable,
} from "./StopServiceModal/StopService"
import { TbRuler2Off } from "react-icons/tb"
import { useAppSelector } from "../../app/hooks"
import { api, useLogoutCustomerMutation } from "../../features/Auth/authApi"
import { useAppDispatch } from "../../app/store"
import { useCreateTicketMutation } from "../../features/ChatAuth/chatApi"
import { usePaymentBookingMutation } from "../../features/AuthPayment/paymentApi"
import { _paymentDetails } from "../../features/Auth/authSlice"
import { paymenterFace } from "../Booking/postInterFace"
const ViewProfile = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const urlSearchParams = new URLSearchParams(window.location.search)
  const userId = urlSearchParams.get("userId")
  const userLoginId = useAppSelector(
    (state: any) => state.auth?.userInfo?.user?._id,
  )
  const [logoutProvider, { data: logoutData }] = useLogoutCustomerMutation()
  const [
    otpGenrate,
    { data: otpData, error: otpError, isLoading: otpLoading },
  ] = useGenOtpMutation()
  const {
    data: activePost,
    isFetching,
    isLoading,
    refetch,
    error,
  } = useActivePostQuery({ postId: id })

  const [
    submitOtpProvider,
    { data: submitotpData, error: submitotpError, isLoading: submitotpLoading },
  ] = useVerifyOtpMutation()

  // const [paymentPost, { data, isLoading: paymentIsloading }] =
  //   usePaymentBookingMutation()

  const navigate = useNavigate()

  const [open, setOpen] = React.useState(false)
  const [genOtpModal, setGenOtpModal] = useState(false)
  const [fillOtp, setFillOtp] = useState<string>()
  const otpRef = useRef<HTMLInputElement[]>([])
  const [otp, setOtp] = useState(["", "", "", "", "", ""])
  const [jobPayment, setjobPayment] = useState(false)
  const inputRefs = useRef([])
  const [reasonlist, setReasonList] = React.useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const socketId = useAppSelector((state) => state.auth)
  // timeLine Show more Button

  const [showFullTimeline, setShowFullTimeline] = useState(false)

  const handleToggleTimeline = () => {
    setShowFullTimeline(!showFullTimeline)
  }

  // end

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleVerifyClickOpen = () => {
    setReasonList(true)
    setOpen(false)
  }

  const reasonListClose = () => {
    setReasonList(false)
  }

  // const handleChange = (index: any, event: any) => {
  //   const newOtp = [...otp]
  //   newOtp[index] = event.target.value
  //   setOtp(newOtp)
  //   if (event.target.nextSibling) {
  //     event.target.nextSibling.focus()
  //   }
  // }

  const handleChange = (index: any, event: any) => {
    const newOtp = [...otp]
    const newValue = event.target.value

    if (!otp[index]) {
      newOtp[index] = newValue
    } else {
      newOtp[index] = newValue
    }

    setOtp(newOtp)

    // Focus next input field if available
    if (event.target.nextSibling && newValue !== "") {
      event.target.nextSibling.focus()
    }
  }

  const handleClick = (index: number) => {
    // Focus on the clicked input field
    ;(otpRef as any).current[index].focus()
  }

  const handleKeyDown = (index: any, event: any) => {
    if (event.keyCode === 8 && !otp[index] && otpRef.current[index - 1]) {
      ;(otpRef as any).current[index - 1].focus()
    }
  }

  // const handleKeyUp = (index: any, event: any) => {
  //   if (event.keyCode === 8 && index !== 0 && otp[index] === "") {
  //     ;(inputRefs.current[index - 1] as any).focus()
  //   }
  // }

  const handleOtp = () => {
    const payload = {
      postId: activePost?.data?._id && activePost?.data?._id,
      jobAction: activePost?.data?.status == "Completed" ? "End" : "Start",
    }
    otpGenrate(payload)
  }

  // otp genrate funtion

  useEffect(() => {
    // Your effect code
    if (otpData?.message) {
      setGenOtpModal(true)
      setFillOtp(otpData?.data?.otp as any)
      toast.success(otpData?.message)
    } else {
      toast.error((otpError as any)?.data?.message)
    }
  }, [otpData, otpError])

  // end otp function

  // navigate then job completed on share otp

  // otp genrate by ptovider then submit by seeker
  const submitOtpFromProvider = async () => {
    const payload = {
      postId: activePost?.data?._id && activePost?.data?._id,
      jobAction: "End",
      otp: otp.join(""),
    }

    submitOtpProvider(payload)
    // try {
    //   const res: any = await submitOtpProvider(payload)

    //   if (res?.data?.data?.jobAction == "End") {
    //     setjobPayment(true)
    //   }
    // } catch (error: any) {
    //   toast.error(error.message || "An error occurred")
    // }
  }

  useEffect(() => {
    if (submitotpData?.message) {
      toast.success(submitotpData?.message)
      // navigate("/dashboard")
      // setSearchParams({ type: "review-post" })
    } else {
      toast.error((submitotpError as any)?.data?.message)
    }
  }, [submitotpData, submitotpError])

  useEffect(() => {
    if (activePost?.message) {
      setReasonList(false)
      // navigate("/dashboard")
      // setSearchParams({ type: "review-post" })
    } else if ((error as any)?.data?.status == 400) {
      navigate("/dashboard")
    }
  }, [activePost, error])

  useEffect(() => {
    const payload = {
      chatId: socketId?.socketId,
      notificationId: socketId?.notificationId,
    }
    if (userId == userLoginId || userId == null) {
      console.log("first")
    } else {
      logoutProvider(payload)
      dispatch(api.util.resetApiState()) as any
      dispatch(overViewApi.util.resetApiState()) as any
    }
  }, [userId, userLoginId])

  const [chatView, { data: CharacterData }] = useCreateTicketMutation()

  // const handleChatView = async () => {
  //   const payload = {
  //     postId: id,
  //   }

  //   try {
  //     const response: any = await chatView(payload)
  //     console.log("response", response)

  //     if (response?.data?.status === 200) {
  //       navigate(
  //         `/dashboard/help-center?chatId=${response.data?.data?._id}&adminId=${response.data?.data?.adminstration}`,
  //       )
  //     } else {
  //       console.error("API call was not successful:", response)
  //     }
  //   } catch (error) {
  //     console.error("Error making API call:", error)
  //   }
  // }

  const handleChatView = async () => {
    const payload = {
      postId: id,
    }

    try {
      const response: any = await chatView(payload)
      if (response?.data?.status === 200) {
        const chatId = response.data?.data?._id
        const adminId = response.data?.data?.adminstration

        if (chatId && adminId) {
          navigate(`/dashboard/help-center?chatId=${chatId}&adminId=${adminId}`)
        } else {
          console.error("Invalid chatId or adminId in response:", response)
        }
      } else {
        console.error("API call was not successful:", response)
      }
    } catch (error) {
      console.error("Error making API call:", error)
    } finally {
    }
  }

  const handlePayment = async () => {
    let from = new Date(activePost?.data?.from)
    let till = new Date(activePost?.data?.till)
    let totalhours = till.getHours() - from.getHours()
    const payload: paymenterFace = {
      provider: activePost?.data?.personRequired,
      servicehours: totalhours,
      hourlyrate: activePost?.data?.service?.price,
      Discount: activePost?.paymentDetail?.offerAmount,
      Totalpayment: activePost?.paymentDetail?.totalAmount,
      postId: activePost?.paymentDetail?.postId,
      customerId: activePost?.paymentDetail?.customerId,
    }
    dispatch(_paymentDetails(payload as any))
    navigate("/dashboard/payment")
  }

  useEffect(() => {
    if (activePost?.message == "Active post completed successfully") {
      navigate("/dashboard")
    }
  }, [activePost])

  console.log("activePost", activePost)

  return (
    <>
      {isLoading ? (
        <CenteredSpinner />
      ) : (
        <Box>
          <Style.Overview>
            <Box className="d-flex justify-content-between align-items-center pb-5">
              <Box className="d-flex align-items-center gap-4">
                <Button className="back-btn mb-0" onClick={() => navigate(-1)}>
                  <FaChevronLeft />
                </Button>
              </Box>
              <Box className="d-flex  align-items-center gap-3">
                {/* <p style={{ marginBottom: "0px" }}>
                  Do you want to pay now or after the completing of service?
                </p> */}
                <Typography className="status-text mb-0">
                  Status-{" "}
                  <span className="completed fw-bold">
                    {activePost?.data?.status}
                  </span>{" "}
                  {/* <span className="cancelled">Cancelled</span> */}
                </Typography>
              </Box>
            </Box>

            {/* in half servie stop for this code */}

            <ServiceStart
              isloadingShow={otpLoading}
              serviceStop={handleClickOpen}
              blankOtp={setFillOtp}
              postDetails={activePost?.data}
            />

            <GenRateotpCustomer
              genrateOtp={handleOtp}
              otpFill={fillOtp as any}
              isloadingShow={otpLoading}
              generateOtp={activePost?.data}
              handleOtpgeanerate={setFillOtp}
            />

            {/* stop service modal */}
            <StopService
              open={open}
              handleClose={handleClose}
              openReason={handleVerifyClickOpen}
              stopService={activePost?.data}
            />
            {/* ******* */}

            {/* reason modal hanlde */}

            <ColumnGroupingTable
              reasonlist={reasonlist}
              reasonListClose={reasonListClose}
              postId={id as any}
              stopService={activePost?.data}
            />
            {/* ***** */}

            {/* for submit opt then generate by provider */}
            {activePost?.data?.activeJobflow == "PROVIDER_STOP_OTP" && (
              <Box pb={10}>
                <Box className="otp-box text-center">
                  <Box pb={1}>
                    <FormControl className="d-flex justify-content-center gap-3 flex-row">
                      {otp.map((digit: any, index: number) => (
                        <input
                          key={index}
                          type="text"
                          maxLength={1}
                          value={digit}
                          onChange={(e) => handleChange(index, e)}
                          onKeyDown={(e) => handleKeyDown(index, e)}
                          onClick={() => handleClick(index)}
                          onKeyPress={(event) => {
                            const keyCode = event.keyCode || event.which
                            const keyValue = String.fromCharCode(keyCode)
                            const numericRegex = /^[0-9]+$/

                            if (!numericRegex.test(keyValue)) {
                              event.preventDefault()
                            }
                          }}
                          ref={(ref: any) =>
                            (otpRef.current[index] = ref as any)
                          }
                          style={{
                            maxWidth: "clamp(30px, 3vw, 46px)",
                            height: "clamp(30px, 3vw, 56px)",
                            textAlign: "center",
                            fontFamily: "Montserrat",
                            color: "#F475A4",
                            border: "1px solid #F475A4",
                            borderRadius: "6px",
                            fontSize: "20px",
                          }}
                        />
                      ))}
                    </FormControl>
                  </Box>
                  <Button
                    className="view-profile py-2 text-capitalize"
                    onClick={submitOtpFromProvider}
                  >
                    {submitotpLoading ? <CircularIndeterminate /> : " Submit"}
                  </Button>
                </Box>
              </Box>
            )}

            {/* total amount code/ */}
            {/* {activePost?.data?.isJobEndOtpVerified == true &&
              activePost?.data?.ispaymentVeified == false && (
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={7}>
                    <Box className="otp-box text-center otp-box-1">
                      <>
                        <Box pb={3}>
                          {" "}
                          <p
                            style={{
                              textAlign: "center",
                              fontSize: "20px",
                              color: "#000",
                              fontWeight: "600",
                            }}
                          >
                            Total Amount{" "}
                            <span style={{ color: "#009C35" }}>
                              {" "}
                              CAD${activePost?.paymentDetail?.totalAmount}
                            </span>{" "}
                          </p>
                        </Box>
                        <Button
                          className="view-profile py-2 text-capitalize"
                          style={{ minWidth: "118px" }}
                          onClick={handlePayment}
                        >
                          Pay
                        </Button>
                      </>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={5}
                    className="d-none d-xl-block m-auto"
                  >
                    <Box>
                      <Typography
                        align="left"
                        variant="h2"
                        className=" fw-medium font-24"
                        mb={1}
                      >
                        Service Completed Successfully!
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        maxWidth: 300, // set width as needed
                        height: "auto", // set height as needed
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={successImgIcon}
                        alt="Description"
                        style={{ width: "112px", height: "auto", marginTop: "20px" }}
                      />
                    </Box>{" "}
                  </Grid>
                </Grid>
              )} */}

            <PayPriceForService
              handlePayment={handlePayment}
              postDetailsForPay={activePost}
            />

            <Divider className="dark-divider my-5" />
            <Typography
              align="left"
              variant="h2"
              className=" fw-medium font-24"
              mb={1}
            >
              Service details
            </Typography>
            <Grid container className="mb-5 p-4 service-detail-box-lg gap-4">
              <Grid item xs={12} lg={3}>
                <Box className="review-card text-center p-2 p-lg-1  d-flex flex-column align-items-center">
                  <Box className="mb-4 profile-img-2 overflow-hidden">
                    <img
                      src={activePost?.data?.service?.image || dummy}
                      className="img-fluid rounded"
                    />
                  </Box>
                  <Typography variant="h6" align="center" className="mb-1">
                    {activePost?.data?.service?.name}
                  </Typography>
                  <Typography variant="h6" align="center" className="mb-1">
                    {/* {activePost?.data?.service?.price}$ */}
                  </Typography>
                </Box>{" "}
              </Grid>
              <Grid item lg={4}>
                <Box>
                  <Typography
                    variant="body1"
                    mb={3}
                    className="text-black d-flex gap-3"
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <span className="fw-bold">Service required</span>{" "}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <span> {activePost?.data?.service?.name}</span>
                      </Grid>
                    </Grid>
                  </Typography>
                  <Typography
                    variant="body1"
                    mb={3}
                    className="text-black d-flex gap-3"
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <span className="fw-bold">
                          {/* <IoCalendarClearOutline /> */}
                          Service date
                        </span>
                      </Grid>
                      {activePost?.data?.from && (
                        <Grid item xs={12} md={6}>
                          <span>
                            {moment(activePost?.data?.from).format(
                              "DD MMM, dddd , YYYY",
                            )}
                          </span>
                        </Grid>
                      )}
                    </Grid>
                  </Typography>
                  <Typography
                    variant="body1"
                    mb={3}
                    className="text-black d-flex gap-3"
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <span className="fw-bold">
                          {/* <CiClock2 /> */}
                          Service time
                        </span>{" "}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className="d-flex gap-4">
                          <span className="text-center">
                            <strong>
                              {/* <span className="font-12 pe-2">From</span> */}
                            </strong>

                            <FormatChangeTime
                              dateString={activePost?.data?.from}
                            />
                            {/* {moment(activePostData?.data?.from).format("h:mm A")}{" "} */}
                          </span>{" "}
                          <span className="text-center">
                            <strong>
                              {" "}
                              {/* <span className="font-12 pe-2">To</span> */}
                            </strong>
                            {/* {moment(activePostData?.data?.till).format("h:mm A")}{" "} */}
                            <FormatChangeTime
                              dateString={activePost?.data?.till}
                            />
                          </span>
                        </div>
                      </Grid>
                    </Grid>{" "}
                  </Typography>

                  <Typography
                    variant="body1"
                    mb={1}
                    className="text-black d-flex gap-3"
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        {" "}
                        <span className="fw-bold">Language</span>{" "}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <span> {activePost?.data?.language?.join(" ")}</span>
                      </Grid>
                    </Grid>
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={4}>
                <Box>
                  <Typography variant="body1" mb={3} className="text-black">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        {" "}
                        <span className="fw-bold">
                          {/* <IoLocationOutline /> */}
                          Location
                        </span>{" "}
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <span> {activePost?.data?.location}</span>
                      </Grid>
                    </Grid>
                  </Typography>
                  <Typography variant="body1" mb={3} className="text-black">
                    {activePost?.data?.description && (
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                          {" "}
                          <span
                            className="fw-bold"
                            style={{ overflowWrap: "anywhere" }}
                          >
                            Additional instructions
                          </span>{" "}
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <span> {activePost?.data?.description}</span>
                        </Grid>
                      </Grid>
                    )}
                  </Typography>
                  {activePost?.data?.attachments?.length > 0 && (
                    <>
                      <Typography
                        variant="body1"
                        mb={1}
                        className="text-black d-flex gap-3"
                      >
                        <span className="fw-bold">Photos</span>
                      </Typography>
                      {activePost?.data?.attachments?.map((imagePost: any) => (
                        <img src={imagePost} className="photo-upload me-2" />
                      ))}
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
            <Divider className="dark-divider mb-5" />
            <Typography
              align="left"
              variant="h2"
              className=" fw-medium font-24"
              mb={1}
            >
              Service Provider details
            </Typography>
            <TableContainer
              component={Paper}
              className="dashboard-table-wrapper custom-scrollbar"
            >
              <Table
                style={{
                  minWidth: 650,
                  border: "none",
                  backgroundColor: "#fff",
                  borderRadius: "16px",
                  overflow: "hidden",
                }}
                className="dashboard-table"
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center" className="service-head">
                      Service Provider
                    </TableCell>
                    <TableCell align="center">Email</TableCell>
                    <TableCell align="center">Phone</TableCell>
                    <TableCell align="center">Language</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {activePost?.data?.assignedTo.map((row: any) => (
                    <TableRow
                      key={row?.name}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell className="user-img">
                        <div className="img-wrapper ms-1">
                          <img
                            src={row?.profilePic || dummyImage}
                            style={{
                              width: "74px",
                              height: "74px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      </TableCell>

                      <TableCell component="td" align="center" scope="row">
                        <span className="text flex-column gap-2">
                          {row?.name}
                          <span className="minh-1">
                            <RatingStars rating={row?.rating?.$numberDecimal} />
                          </span>
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="email">
                          <span>{row?.email}</span>
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="email">
                          <span>{row?.contactNo}</span>
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="language">
                          <span>{row?.language?.join(", ")}</span>
                        </span>
                      </TableCell>

                      <TableCell>
                        <span>
                          <Link
                            to={`/dashboard/provider-profile/${row?._id}`}
                            className="view-profile"
                          >
                            View Profile
                          </Link>
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Divider className="dark-divider mb-5" />
            <Box
              className="d-flex mb-5"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography align="left" variant="h2" mb={2}>
                Timeline
              </Typography>
            </Box>

            <Box display="flex" justifyContent="end" marginBottom={4}>
              <Button className="timeline-btn me-4" onClick={handleChatView}>
                Chat
                <img src={chatimage} alt="img" style={{ marginLeft: "10px" }} />
              </Button>
            </Box>

            <Box className="timeline-graph">
              {activePost?.postTimeline
                ?.slice(
                  0,
                  showFullTimeline ? activePost?.postTimeline?.length : 3,
                )
                .map((timeline: any, index: number) => (
                  <Timeline
                    key={timeline._id}
                    position={index % 2 === 0 ? "alternate" : "left"}
                  >
                    <TimelineItem>
                      <TimelineOppositeContent
                        sx={{ m: "10px 0 0" }}
                        align="right"
                        className="timeline-desc"
                        color="text.secondary"
                      >
                        {/* {new Date(timeline.createdAt).toLocaleDateString()} */}
                        {/* new date format */}
                        <span className="datetime">
                          <span className="d-flex flex-column">
                            <span className="weekday">
                              <FormatDate dateString={timeline.createdAt} />
                            </span>
                            <span>
                              <FormatChangeTime
                                dateString={timeline.createdAt}
                              />
                            </span>
                            <span>
                              <FormatTime dateString={timeline.createdAt} />
                            </span>
                          </span>
                        </span>

                        <br />
                        {/* {moment(timeline.updatedAt).format("h:mm a")} */}
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot>
                          <Typography className="timeline-step">
                            0{activePost?.postTimeline?.length - index}
                          </Typography>
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent sx={{ py: "12px", px: 0.5 }}>
                        <Box className="timeline-content ">
                          <Typography className="timeline-heading">
                            Status - {timeline?.postStatus}{" "}
                          </Typography>
                          <Typography className="timeline-desc"></Typography>
                          <Typography className="timeline-desc">
                            {timeline?.message}{" "}
                          </Typography>
                        </Box>
                      </TimelineContent>
                    </TimelineItem>
                  </Timeline>
                ))}
              <div className="d-flex justify-content-center">
                <Button
                  onClick={handleToggleTimeline}
                  className="view-profile py-2 text-capitalize"
                >
                  {showFullTimeline ? "Show Less" : "Show More"}
                </Button>
              </div>
            </Box>
          </Style.Overview>
        </Box>
      )}
    </>
  )
}
export default ViewProfile
