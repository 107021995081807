import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { api } from "./authApi"
import { toast } from "react-toastify"
import { notificationsApi } from "../Socket/Socketio"
import { Payload, paymenterFace } from "../../components/Booking/postInterFace"
import { apiPayment } from "../AuthPayment/paymentApi"

const slice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    token: null,
    userInfo: null,
    socketId: null,
    notificationId: null,
    userProfile: null,
    newUserDemo: null,
  } as {
    user: null | any
    token: null | string
    userInfo: null | boolean
    navBarSelect: null | any
    navbarSelectPath: null | any
    bellNotification: null | any
    activeSocket: null | any
    paymentBooking: null | any
    paymentDetails: paymenterFace | null
    socketId: null | string
    notificationId: null | string
    userProfile: null | string
    newUserDemo: null | boolean
  },
  reducers: {
    updateToken: (state: any, action: any) => {
      state.user = null
      state.token = action.payload
    },

    token: (state: any, action: any) => {
      state.user = null
      state.token = action.payload
    },

    emailVerifyToken: (state: any, action: any) => {
      state.user = null
      state.token = action.payload
    },
    logout: (state) => {
      state.user = null
      state.token = null
    },

    userInfo: (state: any, action: any) => {
      state.user = action.payload
    },

    setuserProfile: (state: any, action: any) => {
      state.userProfile = action.payload
    },
    alreadyRated: (state: any, action: any) => {
      state.user = null
      state.alreadyRated = action.payload
    },

    setNavBarSelect: (state: any, action: any) => {
      state.navBarSelect = null
      state.navBarSelect = action.payload
    },
    setPathNavBar: (state: any, action: any) => {
      state.navbarSelectPath = null
      state.navbarSelectPath = action.payload
    },
    setActiveQuery: (state: any, action: any) => {
      state.activeSocket = null
      state.activeSocket = action.payload
    },

    _paymentDetails: (state: any, action: any) => {
      state.paymentDetails = null
      state.paymentDetails = action.payload
    },

    setSocketId: (state: any, action: any) => {
      state.socketId = null
      state.socketId = action.payload
    },

    setNotificationId: (state: any, action: any) => {
      state.notificationId = null
      state.notificationId = action.payload
    },

    setNewUserdemo: (state: any, action: any) => {
      state.newUserDemo = null
      state.newUserDemo = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.userInfo = payload

        state.newUserDemo = payload?.user?.newUser
        // toast.success("Login successfully", { autoClose: 1000 })

        // state.user = payload.result.user
      },
    )

    builder.addMatcher(
      api.endpoints.logoutCustomer.matchFulfilled,
      (state, { payload }) => {
        // toast.success("Logged out successfully!", { autoClose: 1000 })

        state.token = null
        state.userInfo = null
        state.paymentBooking = null
        state.user = null
        state.paymentDetails == null
        state.userProfile == null
      },
    )

    builder.addMatcher(
      api.endpoints.getNotification.matchFulfilled,
      (state, { payload }) => {
        state.bellNotification = payload
      },
    ),
      builder.addMatcher(
        notificationsApi.endpoints.socketNotifications.matchFulfilled,
        (state, { payload }) => {
          state.activeSocket = payload.activeSocket
        },
      )

    builder.addMatcher(
      apiPayment.endpoints.paymentBooking.matchFulfilled,
      (state, { payload }) => {
        state.paymentDetails = null
      },
    )
  },
})

export default slice.reducer
export const {
  logout,
  updateToken,
  token,
  emailVerifyToken,
  userInfo,
  setuserProfile,
  alreadyRated,
  setNavBarSelect,
  setPathNavBar,
  _paymentDetails,
  setSocketId,
  setNotificationId,
  setNewUserdemo,
} = slice.actions

export const selectCurrentUser = (state: RootState) => state.auth.token
