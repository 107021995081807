import React, { useState } from "react"
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Pagination,
  Paper,
  Rating,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import { LiaAngleRightSolid } from "react-icons/lia"
import * as Style from "./style"
import moment from "moment"
import { Table } from "react-bootstrap"

import { FaStar } from "react-icons/fa6"
import { useGetGenratePostQuery } from "../../features/OverViewAuth/overViewApi"
import { useNavigate } from "react-router-dom"

const Manage = () => {
  const navigate = useNavigate()
  const { data: genratePost } = useGetGenratePostQuery()

  const [selectedPage, setSelectedPage] = useState(1)

  const handlePageChange = (event: any, value: any) => {
    setSelectedPage(value)
  }

  const viewProfileList = (id: any) => {
    navigate(`single-seeker/${id}`)
  }

  return (
    <>
      {" "}
      <Style.Upcoming
        style={{
          display: "flex",
          columnGap: "50px",
          paddingTop: "20px",
          paddingBottom: "20px",
          margin: "0px 0px 27px",
        }}
      >
        <Typography variant="h2" align="left" mb={2}>
          Booking Request
        </Typography>
        <Typography variant="h2" align="left" mb={2}>
          Filter
        </Typography>
      </Style.Upcoming>
      <Style.Overview>
        <TableContainer component={Paper} className="dashboard-table-wrapper">
        <Table
                      style={{
                        minWidth: 650,
                        border: "none",
                          backgroundColor: "#fff",
                          borderRadius: "16px",
                          overflow: "hidden",
                      }}
                      className="dashboard-table"
                      aria-label="simple table"
                    >
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="left" className="service-head">
                  Service seeker
                </TableCell>
                <TableCell align="center">Phone</TableCell>
                <TableCell align="center">Location</TableCell>
                <TableCell align="center">Service Required</TableCell>
                <TableCell align="center">Date & Time</TableCell>
                <TableCell align="center">Request Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {genratePost?.data?.map((row: any) => (
                <TableRow
                  key={row.serviceseeker}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={() => viewProfileList(row?._id)}
                >
                  <TableCell>
                    <div>
                      <img src={row.userimage} />
                    </div>
                  </TableCell>
                  <TableCell component="th" align="center" scope="row">
                    <span>
                      {row?.seekerData?.name}
                      <span>
                        {row.rating}
                        <FaStar />
                      </span>
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span>{row?.seekerData?.contactNo}</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="location">
                      <span>{row.location}</span>
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span>{row?.service?.name}</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="datetime">
                      <span>
                        {moment(row.from).format("YYYY-MM-DD HH:mm:ss")}
                      </span>
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className={row.requeststatus}>{row.status}</span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <span>Showing Results {genratePost?.data?.length}</span>
        <Grid container justifyContent="flex-end">
          <Grid md={3}>
            <Box display="flex" flexDirection="column">
              <Stack spacing={2} className="mb-3">
                <Pagination
                  count={10}
                  color="secondary"
                  className="custom-pagination"
                  page={selectedPage}
                  onChange={handlePageChange}
                  sx={{
                    "& .MuiPaginationItem-page.Mui-selected": {
                      backgroundColor: "#F475A4",
                      boxSizing: "border-box",
                    },
                    "& .css-wjh20t-MuiPagination-ul": {
                      flexWrap: "nowrap",
                      justifyContent: "end",
                    },
                  }}
                />
              </Stack>
              <Typography className="page-text">Showing 1 -25 of list</Typography>
            </Box>
          </Grid>
        </Grid>
      </Style.Overview>
    </>
  )
}
export default Manage
