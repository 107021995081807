import React, { useState } from "react"
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Rating,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"
import { Link } from "react-router-dom"
import { useGetJobServiceQuery } from "../../features/OverViewAuth/overViewApi"
import CircularIndeterminate from "../Loading/Loading"
import * as Style from "./style"
import CenteredSpinner from "../common/CenteredSpinner"

const Features = () => {
  const { data: getService, isFetching, isLoading } = useGetJobServiceQuery({})
  const [displayCount, setDisplayCount] = useState(10)

  const handleShowMore = () => {
    setDisplayCount(displayCount + 10)
  }

  return (
    <>
      {isFetching || isLoading ? (
        <CenteredSpinner />
      ) : (
        <Box className="pt-md-2 px-md-5">
          <Style.Overview>
            <Grid container>
              <Grid item xs={12}>
                <Typography align="left" variant="h2" mb={2}>
                  Services
                </Typography>
              </Grid>
              {getService?.data?.slice(0, displayCount).map((item: any) => (
                <Grid item xs={12} lg={6} className="mb-4" key={item._id}>
                  <Link
                    to={`/dashboard/single-feature/${item._id}`}
                    className="text-decoration-none d-block pe-md-4"
                  >
                    <Box className="feature-card feature-card-2 d-flex">
                      <Box className="feature-card-img service-img">
                        <img src={item.image} alt={item.name} />
                      </Box>
                      <Box className="feature-card-content p-0 px-4 my-auto">
                        <Typography
                          variant="body1"
                          className="text-black font-15 my-2 lh-1"
                        >
                          {item.name}
                        </Typography>
                        <Typography
                          variant="body1"
                          className="services-desc mb-1 font-13"
                        >
                          <Typography
                            variant="body1"
                            className="services-desc mb-0 font-13"
                          >
                            {item.description && item.description.length > 80
                              ? `${item.description.substring(0, 80)}...`
                              : item.description}
                          </Typography>
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </Grid>
              ))}
            </Grid>
            {getService?.data?.length > displayCount && (
              <div className="text-center">
                <Button
                  className="view-profile"
                  style={{ padding: "6px 10px" }}
                  onClick={handleShowMore}
                >
                  Show More
                </Button>
              </div>
            )}
            <Typography variant="body1">
              Showing {Math.min(displayCount, getService?.data?.length)}{" "}
              elements
            </Typography>
          </Style.Overview>
        </Box>
      )}
    </>
  )
}

export default Features
