import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { Payload } from "../../components/Booking/postInterFace"
import { number } from "yup"

interface PostCreateState {
  bookingDetails: Payload
}

const initialState: PostCreateState = {
  bookingDetails: {
    location: "",
    service: "",
    ageRange: "",
    from: "",
    till: "",
    description: "",
    personRequired: "",
    language: [],
    attachment: [],
    hours: 0,
    postPrice: undefined,
    stripepricekey: [],
    timeUpdate: "",
    updateEndTime: "",
    serviceName: "",
  },
}

const slice = createSlice({
  name: "postCreate",
  initialState,
  reducers: {
    setBookingDetails: (state, action) => {
      state.bookingDetails = action.payload
    },
  },
})

export const { setBookingDetails } = slice.actions

export const selectBookingDetails = (state: RootState) =>
  state.postCreate.bookingDetails

export default slice.reducer
