import * as React from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import { Avatar } from "@mui/material"
import { InputLabel } from "@mui/material"
import bellIcon from "../../../images/notification.svg"
import * as Style from "./style.css"
import classes from "./style.module.css"
import { Grid, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import { boxStyle } from "./notificationStyle"
import { flexboxStyle } from "./notificationStyle"
import logoIcon from "../../../images/momy_logo.svg"
import { BorderBottom } from "@mui/icons-material"
import { useGetNotificationQuery } from "../../../features/Auth/authApi"
import { CalculateTimeDifference } from "../../Notification/timeNotification"
import { useAppSelector } from "../../../app/hooks"
import { useStore } from "react-redux"
import bellIconNoti from "../../../images/notificationicon.svg"
import NotificationModal from "./notificationModal"
import { useGetGenratePostQuery } from "../../../features/OverViewAuth/overViewApi"

export default function DialogSelect() {
  const store: any = useStore()
  const cachedQueries = store.getState().notificationsApi.queries
  // const getNotifications :any = useAppSelector((state)=> state.notificationsApi.queries)
  let dashboardResult: { [key: string]: any } = {}
  Object.values(cachedQueries).forEach((item: any) => {
    dashboardResult = {
      ...dashboardResult,
      ...{ [item?.endpointName]: item?.data },
    }
  })

  const [open, setOpen] = React.useState(false)
  const [showBellIcon, setShowBellIcon] = React.useState([])

  // const notificationData = useAppSelector(
  //   (state) => state.auth.bellNotification,
  // )

  const { data: showCount } = useGetGenratePostQuery()

  const handleClickOpen = () => {
    setOpen(true)
    setShowBellIcon([])
  }

  const handleClose = (reason?: string) => {
    if (reason !== "backdropClick") {
      setOpen(false)
    }
  }

  React.useEffect(() => {
    setShowBellIcon(dashboardResult?.socketNotifications)
  }, [dashboardResult?.socketNotifications])

  const paperStyle = {
    position: "absolute",
    right: "50px",
    borderRadius: "16px",
    top: "45px",
    backgroundColor: "white",
    boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.03)",
    "@media (max-width: 600px)": {
      right: "-14px",
      top: "15px",
    },
  }
  return (
    <>
      <>
        <Box>
          <Button onClick={handleClickOpen} className={classes.btn_cover}>
            <Avatar
              src={bellIconNoti}
              className={classes.logoIcon}
              alt="Profile Picture"
            />

            {showCount?.unSeen !== 0 && showCount?.unSeen !== undefined && (
              <span
                className={classes.count_circle}
                style={{
                  backgroundColor: "#f475a4",
                  color: "#fff",
                  width: "18px",
                  height: "18px",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  top: "1px",
                  left: "28px",
                  fontSize: "10px",
                }}
              >
                {showCount?.unSeen}
              </span>
            )}
          </Button>
          {open && (
            <NotificationModal
              open={open}
              handleClose={handleClose as any}
              setOpen={setOpen}
            />
          )}
        </Box>
      </>
    </>
  )
}
