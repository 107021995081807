import * as React from "react"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import * as Style from "./style"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  Grid,
  Pagination,
  Stack,
  TextField,
  CircularProgress,
} from "@mui/material"
import { FaStar } from "react-icons/fa6"
import tableCellImg from "../../images/table-img.png"
import { Button, Spinner } from "react-bootstrap"
import { FiDownload } from "react-icons/fi"
import { useState } from "react"
import { CiSearch } from "react-icons/ci"
import {
  useGetBookingHistoryQuery,
  useInvoicePdDownloadMutation,
} from "../../features/OverViewAuth/overViewApi"
import moment from "moment"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import noData from "../../images/nobooking.png"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import dummyImage from "../../images/dummy.png"
import RatingStars from "../common/rating"
import Dropdown from "react-bootstrap/Dropdown"
import {
  FormatChangeTime,
  FormatDate,
  FormatDateMonthDay,
  FormatTime,
} from "../common/utctolocalDate"
import noDataHistory from "../../images/no-history.png"
import CenteredSpinner from "../common/CenteredSpinner"
import { TbArrowsSort } from "react-icons/tb"

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  className: string
}

const statusButton = ["All", "Completed", "Expired", "Declined"]
export default function BasicTabs() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchQuery, setSearchQuery] = useState<string>()
  const [enterSearchQueery, setEnterSearchQuerry] = useState<string>("")
  const [listCount, setListCount] = useState<string>("10")
  const [statusChangePayment, setStatusChangePayment] = useState<string>("")
  const [statusIndex, setStatusIndex] = useState<number>()
  const [shortdate, setShortDate] = useState<number>(-1)

  const { type } = useParams()

  const navigate = useNavigate()

  const [selectedPage, setSelectedPage] = useState(1)

  const searchTypeStatus = searchParams.get("type")

  const {
    data: bookingHistory,
    isFetching,
    isLoading,
  } = useGetBookingHistoryQuery(
    {
      postStatus: searchParams.get("type"),
      pageNo: selectedPage,
      from: shortdate,
      searchName: enterSearchQueery.trim(),
      pageLimit: listCount,
      paymentStatus: statusChangePayment,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  )

  const handlePageChange = (event: any, value: any) => {
    setSelectedPage(value)
  }

  const searchBarHandle = (e: any) => {
    setSearchQuery(e.target.value)
  }

  const enterSearch = (event: any) => {
    if (event.key == "Enter") {
      SearchQuerryhandle()
    }
  }
  const SearchQuerryhandle = () => {
    setEnterSearchQuerry(searchQuery as any)
  }

  const handleListCount = (e: any) => {
    setListCount(e.target.value)
  }

  const handleButton = (name: any, index: number) => {
    setListCount("10")
    setStatusIndex(index)
    setSearchParams({ type: name })
    if (name !== "Completed") {
      setStatusChangePayment("")
    }
  }

  React.useEffect(() => {
    if (!searchParams.get("type")) setSearchParams({ type: "All" })
  }, [])

  const shortingDatelist = () => {
    setShortDate(shortdate == 1 ? -1 : 1)
  }

  const handleDownload = (
    event: React.MouseEvent<HTMLElement>,
    imageUrl: any,
  ) => {
    event.stopPropagation()
    const url = imageUrl
    const newTab = window.open(url, "_blank")
    if (newTab) {
      newTab.focus()
    } else {
      console.error("Failed to open new tab.")

      window.location.href = url
    }
  }

  const handleSelectPayment = (eventKey: any) => {
    setStatusChangePayment(eventKey)
  }

  return (
    <>
      <Style.Overview>
        <Box sx={{ width: "100%" }}>
          <Box
            className="d-flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              {/* Search Field and Button */}
              <Box className="d-flex flex-none w-100 max-w-550">
                <TextField
                  id="outlined-basic"
                  className="search-field"
                  label="Service Provider"
                  variant="outlined"
                  onChange={searchBarHandle}
                  onKeyDown={enterSearch}
                />
                <Button
                  variant="contained"
                  className="search-btn"
                  onClick={SearchQuerryhandle}
                  onKeyDown={enterSearch}
                >
                  <CiSearch />
                </Button>
                <Dropdown
                  onSelect={handleSelectPayment}
                  aria-placeholder="Payment"
                  style={{ marginLeft: "20px" }}
                >
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    style={{
                      marginLeft: "10px",
                      border: "1px solid #878787",
                      backgroundColor: "#fff",
                      padding: "15px 30px",
                      color: "#000",
                      borderRadius: "12px",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    disabled={
                      searchTypeStatus == "Expired" ||
                      searchTypeStatus == "Declined"
                    }
                  >
                    {statusChangePayment == "Unpaid"
                      ? "Pending"
                      : statusChangePayment
                      ? statusChangePayment
                      : "Payment"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    className="payment-dropdown"
                    style={{
                      marginTop: "10px",
                      width: "200px",
                      border: "unset",
                      backgroundColor: "#fff",
                      borderRadius: "12px",
                      padding: "14px 8px",
                      boxShadow: "2px 2px 2px 1px rgb(0 0 0 / 20%)",
                    }}
                  >
                    <Dropdown.Item eventKey={"Paid"}>Paid</Dropdown.Item>
                    <Dropdown.Item eventKey={"Unpaid"}>Pending</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Box>

              {/* Status Buttons */}
              <Box sx={{ display: "flex", marginTop: "10px" }}>
                {statusButton?.map((item, index) => (
                  <Button
                    key={index}
                    variant="transparent"
                    className="all-button mb-1"
                    style={{
                      backgroundColor:
                        searchParams.get("type") === item
                          ? "#F475A4"
                          : ("white" as any),
                      color:
                        searchParams.get("type") === item
                          ? "white"
                          : ("black" as any),
                      marginRight: "10px",
                      padding: "6px 30px",
                      boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.07)",
                    }}
                    onClick={() => handleButton(item, index)}
                  >
                    {item}
                  </Button>
                ))}
              </Box>
            </Box>
            <Box>
              <div className="w-100">
                <FormControl
                  sx={{ m: 1, width: 100, mt: 3 }}
                  className="custom-field view-field"
                >
                  <InputLabel id="demo-simple-select-label bg-white">
                    View
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={handleListCount}
                    value={listCount}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </FormControl>
              </div>{" "}
            </Box>
          </Box>

          <Box
            className="d-flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography align="left" variant="h2" mt={2} mb={2}>
              Booking History{" "}
            </Typography>
          </Box>

          <TableContainer
            component={Paper}
            className="dashboard-table-wrapper custom-scrollbar overflow-y-hidden"
          >
            <Table
              style={{
                minWidth: 650,
                border: "none",
                backgroundColor: "#fff",
                borderRadius: "16px",
                overflow: "hidden",
              }}
              className="dashboard-table"
              aria-label="simple table"
            >
              {bookingHistory?.data?.length > 0 && (
                <TableHead>
                  <TableRow>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center" className="service-head">
                      Service Provider
                    </TableCell>
                    <TableCell align="center">Phone Number</TableCell>
                    <TableCell align="center">Service</TableCell>
                    <TableCell align="center">Language</TableCell>
                    <TableCell align="center" onClick={shortingDatelist}>
                      Date & Time <TbArrowsSort />
                    </TableCell>
                    {/* <TableCell align="center">End Time</TableCell> */}
                    <TableCell align="center">Status</TableCell>
                    {searchTypeStatus !== "Declined" &&
                      searchTypeStatus !== "Expired" && (
                        <TableCell align="center">Payment</TableCell>
                      )}
                  </TableRow>
                </TableHead>
              )}

              {isLoading || isFetching ? (
                <>
                  {" "}
                  <CenteredSpinner />
                </>
              ) : (
                <TableBody>
                  {bookingHistory?.data?.length > 0 ? (
                    bookingHistory?.data?.map((row: any, index: number) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          navigate(`/dashboard/bookings/details/${row._id}`)
                        }
                      >
                        <TableCell className="user-img">
                          {row?.assignedTo?.length > 0 ? (
                            row?.assignedTo?.slice(0, 1)?.map((assign: any) => (
                              <div className="img-wrapper ms-1">
                                <img
                                  src={assign?.profilePic || dummyImage}
                                  style={{
                                    width: "74px",
                                    height: "74px",
                                    objectFit: "cover",
                                  }}
                                />
                              </div>
                            ))
                          ) : (
                            <img src={dummyImage} style={{ width: "74px" }} />
                          )}
                        </TableCell>
                        <TableCell component="td" align="center" scope="row">
                          {row?.assignedTo?.length > 0 ? (
                            row?.assignedTo?.slice(0, 1)?.map((assign: any) => (
                              <span className="text flex-column gap-2">
                                {assign?.name || "--"}
                                <span className="minh-1">
                                  {row?.assignedTo?.length > 1 && (
                                    <span
                                      className="minh-1"
                                      style={{ color: "grey" }}
                                    >
                                      + {row?.assignedTo?.slice(1)?.length}{" "}
                                      Others
                                    </span>
                                  )}
                                </span>
                                <span className="minh-1">
                                  <RatingStars
                                    rating={assign?.rating?.$numberDecimal}
                                  />
                                </span>
                              </span>
                            ))
                          ) : (
                            <span className="text-danger">
                              No Provider Assigned
                            </span>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {row?.assignedTo?.length > 0 ? (
                            row?.assignedTo?.slice(0, 1)?.map((assign: any) => (
                              <span className="email">
                                <span>{assign.contactNo || "-----"}</span>
                              </span>
                            ))
                          ) : (
                            <span>{"-----"}</span>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <span>{row?.service?.name || "-----"}</span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="language">
                            {row.language.join(", ") || "-----"}
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="datetime">
                            <span className="d-flex flex-column">
                              <span className="weekday">
                                <FormatDate dateString={row?.from} />
                              </span>

                              <span>
                                <FormatTime dateString={row?.from} />
                              </span>

                              <span>
                                <FormatChangeTime dateString={row?.from} />
                              </span>
                            </span>
                          </span>
                        </TableCell>

                        <TableCell align="center">
                          <span
                            className={row.requeststatus}
                            style={{
                              color:
                                row.status == "Completed"
                                  ? "green"
                                  : row.status == "Cancelled"
                                  ? "red"
                                  : row.status == "Expired"
                                  ? "#FF9900"
                                  : "",
                              fontWeight: "bold",
                            }}
                          >
                            {row.status}
                          </span>
                        </TableCell>
                        {searchTypeStatus !== "Declined" &&
                          searchTypeStatus !== "Expired" && (
                            <TableCell align="center">
                              <span
                                className={row.payment}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  className={`${
                                    row?.ispaymentVeified == true
                                      ? "payment-btn"
                                      : row.ispaymentVeified == false &&
                                        row.status == "Completed"
                                      ? "payment-unpaid"
                                      : row.status == "Expired" ||
                                        row.status == "Declined"
                                      ? "payment-other"
                                      : "payment-other"
                                  } `}
                                >
                                  {row?.ispaymentVeified == true
                                    ? "Paid"
                                    : row.ispaymentVeified == false &&
                                      row.status == "Completed"
                                    ? "Pending"
                                    : row.status == "Expired" ||
                                      row.status == "Declined"
                                    ? "......"
                                    : "......"}
                                </Button>
                                {row?.receipt_url && (
                                  <FiDownload
                                    style={{
                                      marginLeft: "5px",
                                      fontSize: "20px",
                                    }}
                                    onClick={(e: any) =>
                                      handleDownload(e, row?.receipt_url)
                                    }
                                  />
                                )}
                              </span>
                            </TableCell>
                          )}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={7}
                        sx={{
                          width: "100%",
                          pointerEvents: "none",
                          border: "none",
                          height: "498px",
                        }}
                      >
                        <img
                          src={noDataHistory}
                          style={{
                            width: "100%",
                            maxWidth: "208px",
                          }}
                        />
                        <span className="no-data-text">No booking history</span>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {/* </CustomTabPanel> */}
        </Box>
        {bookingHistory?.data?.length > 0 && (
          <Box className="d-md-flex justify-content-between align-items-center mt-3">
            <Typography variant="body1" className="white-space">
              {" "}
              Showing {bookingHistory?.totalDocument?.showingData} /{" "}
              {bookingHistory?.totalDocument?.totalCount} Total
            </Typography>
            <Grid container justifyContent="flex-end">
              <Grid item md={3}>
                <Box display="flex" className="flex-column">
                  <Stack spacing={2} className="mb-3">
                    <Pagination
                      count={bookingHistory?.totalDocument?.totalPages}
                      color="secondary"
                      className="custom-pagination"
                      page={selectedPage}
                      onChange={handlePageChange}
                      sx={{
                        "& .MuiPaginationItem-page.Mui-selected": {
                          backgroundColor: "#F475A4",
                          boxSizing: "border-box",
                        },
                        "& .css-wjh20t-MuiPagination-ul": {
                          flexWrap: "nowrap",
                          justifyContent: "end",
                        },
                      }}
                    />
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      </Style.Overview>
    </>
  )
}
