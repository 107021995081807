import React, { Fragment, useEffect, useState } from "react"

import dayjs from "dayjs"
import {
  Box,
  Button,
  Grid,
  Typography,
  Input,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  colors,
} from "@mui/material"

import Form from "react-bootstrap/Form"
import { IoIosArrowDown } from "react-icons/io"
import Dropdown from "react-bootstrap/Dropdown"
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo"
import { FaChevronLeft } from "react-icons/fa6"
import greenChecked from "../../images/checked-green.svg"
import notifyIcon from "../../images/notify-icon.svg"
import paymentFailedImg from "../../images/payment-failed.png"
import * as Style from "./style"
import offerImg from "../../images/cooking.png"
import { BiSolidOffer } from "react-icons/bi"
import { IoLocationOutline } from "react-icons/io5"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { useAppSelector } from "../../app/hooks"
import { FaChevronDown } from "react-icons/fa6"
import {
  useCreatepaymentMutation,
  usePaymentBookingMutation,
} from "../../features/AuthPayment/paymentApi"
import { useAppDispatch } from "../../app/store"

import { toast } from "react-toastify"

import {
  useCreatePostApiMutation,
  useDiscountOffersQuery,
  useUpdatePostTimeMutation,
} from "../../features/OverViewAuth/overViewApi"
import { setBookingDetails } from "../../features/PostCreat/postcreateSlice"
import ConfirmPost from "../common/commonModal/commonModal"
import { Modal } from "react-bootstrap"
import { flexboxStyle } from "../AdminHeader/notificationModal/notificationStyle"
import money from "../../images/money.png"
const steps = ["Booking Summary", "Offer", "Request sent"]

const BookOverview = () => {
  const [activeStep, setActiveStep] = React.useState(1)
  const [openModal, setOpenModal] = useState(false)
  const [isLoadings, setIsLoadings] = useState(false)

  const [skipped, setSkipped] = React.useState(new Set<number>())
  const [searchParams, setSearchParams] = useSearchParams()
  const [paymentPost, { data, isLoading }] = useCreatepaymentMutation()
  const [selectedOffer, setSelectedOffer] = useState<number>(0)
  const [offerBooks, setOffersBooks] = useState(false)
  const [searchparams] = useSearchParams()
  const postId = searchparams.get("postId")

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [showPop, setShowPop] = useState(false)
  const [timeChange, setTimeChange] = useState<string>("")
  const handleClose = () => {
    setShowPop(false)
  }
  const bookingDetails = useAppSelector(
    (state: any) => state.postCreate?.bookingDetails,
  )

  const { data: discountOffers } = useDiscountOffersQuery()
  const [updateTime, { data: updatesData, isLoading: isLoadingUpdate }] =
    useUpdatePostTimeMutation()
  const [
    postCreate,
    {
      isError: bookingisError,
      error: bookingError,
      data: bookingData,
      isLoading: isloadingBooking,
    },
  ] = useCreatePostApiMutation()

  const isStepOptional = (step: number) => {
    return step === 1
  }

  const isStepSkipped = (step: number) => {
    return skipped.has(step)
  }

  const totalPayment =
    (Number(bookingDetails?.postPrice) || 0) *
    (bookingDetails?.hours || 0) *
    (bookingDetails?.personRequired || 0)

  const discountPercentage = selectedOffer ? selectedOffer : 0
  const discountAmount = (discountPercentage / 100) * totalPayment

  const totalPrice = selectedOffer
    ? totalPayment - discountAmount
    : totalPayment

  const handleNext = async () => {
    setIsLoadings(true) // Start loading

    const payload = new FormData()
    payload.append("location", bookingDetails?.location)
    payload.append("service", bookingDetails?.service as any)
    payload.append("serviceName", bookingDetails?.serviceName as any)
    payload.append("ageRange", bookingDetails?.ageRange?.toString())
    payload.append("from", bookingDetails?.from)
    payload.append("till", bookingDetails?.till)
    payload.append("description", bookingDetails?.description)
    payload.append("personRequired", bookingDetails?.personRequired?.toString())
    payload.append("language", bookingDetails?.language as any)
    bookingDetails?.attachment?.forEach((image: any, index: number) => {
      const key = index === 0 ? "attachment" : `attachment${index}`
      payload.append(key, image as any)
    })

    try {
      const response: any = await postCreate(payload)

      if (response?.data?.status === 200) {
        const firstElementAsString: string | undefined = (
          bookingDetails as any
        )?.stripepricekey[0]?.toString()
        const postpayload = {
          postId: response?.data?.data?.createdPost?._id,
          offerAmount: selectedOffer,
          totalAmount: totalPrice,
        }

        try {
          const res: any = await paymentPost(postpayload)

          if (res?.data?.status === 200) {
            handleClose()
            navigate("/dashboard/booking-completed")
            dispatch(setBookingDetails(null as any))
          }
        } catch (paymentError: any) {
          toast.error(
            paymentError.message ||
              "An error occurred during payment processing",
          )
        }
      } else {
        if (response?.error?.status === 400) {
          toast.error(response?.error?.data?.message)
        } else {
          toast.error("Unexpected response from server")
        }
      }
    } catch (error: any) {
      toast.error(error.message || "An error occurred")
    } finally {
      setIsLoadings(false) // Stop loading
    }
  }

  // for update time api call function

  const handleupdateTimeApi = async () => {
    setIsLoadings(true)
    const updateTimePayload = {
      from: bookingDetails?.from,
      till: bookingDetails?.till,
      postId: bookingDetails?.postId,
      offerAmount: selectedOffer,
      totalAmount: totalPrice,
    }
    try {
      const res: any = await updateTime(updateTimePayload)

      if (res?.data?.status == 200) {
        handleClose()
        navigate("/dashboard/booking-completed")
        dispatch(setBookingDetails(null as any))
      }
    } catch (error: any) {
      toast.error(error.message || "An error occurred")
    } finally {
      setIsLoadings(false)
    }
  }

  const handleReset = () => {
    setActiveStep(1)
  }

  useEffect(() => {
    if (bookingDetails?.personRequired == "") {
      setOpenModal(true)
      navigate("/dashboard")
    }
  }, [bookingDetails?.personRequired])

  const handleCheckboxChange = (ammount: number) => {
    setSelectedOffer(ammount)
  }

  const handleBookingsDeatils = () => {
    navigate(`/dashboard/edit-bookings`)
    // setSearchParams({ bookingdeatils: "true" })
  }

  useEffect(() => {
    const isRefreshed = sessionStorage.getItem("isRefreshed")
    if (isRefreshed) {
      setOpenModal(true)
      sessionStorage.removeItem("isRefreshed")
    } else {
      sessionStorage.setItem("isRefreshed", "true")
    }
  }, [])

  return (
    <>
      <Box>
        <Style.Overview>
          <Box className="feature-card px-1 py-md-4 px-md-5 position-relative">
            <Box>
              <Style.Overview>
                <Box className=" px-1 py-md-4 px-md-5 position-relative">
                  <Button
                    className="back-btn mb-0 step-back-btn"
                    onClick={handleBookingsDeatils}
                  >
                    <FaChevronLeft />
                  </Button>
                  {bookingDetails?.personRequired !== "" && (
                    <Stepper
                      activeStep={activeStep}
                      className="stepper-booking pt-3"
                    >
                      {steps.map((label, index) => (
                        <Step key={label} completed={isStepSkipped(index)}>
                          <StepLabel optional={isStepOptional(2) ? "" : null}>
                            {label}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  )}
                  {activeStep === steps.length ? (
                    <React.Fragment>
                      <Typography sx={{ mt: 2, mb: 1 }}>
                        All steps completed - you&apos;re finished
                      </Typography>
                      <Box
                        sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                      >
                        <Box sx={{ flex: "1 1 auto" }} />
                        <Button onClick={handleReset}>Reset</Button>
                      </Box>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Box className="mt-5">
                        <Grid container className="choose-service">
                          <Grid item lg={8} md={8}>
                            <div className="booking-text ">
                              <div className="inner-booking-text">
                                <p className="payment-text3">
                                  Thank you for choosing our service!
                                </p>
                                <p className="payment-text2">
                                  We believe in ensuring your satisfaction
                                  before requesting payment. Please proceed with
                                  the payment only after your service has been
                                  completed to your full satisfaction.
                                </p>
                              </div>
                            </div>
                          </Grid>
                          <Grid item lg={4} md={4}>
                            <div className="booking-text-img">
                              <img src={money} />
                            </div>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box className="mt-5">
                        <Grid container>
                          {bookingDetails && (
                            <Grid item lg={6} md={5}>
                              <Box style={{ marginRight: "20px" }}>
                                <Dropdown
                                  onClick={() => setOffersBooks(!offerBooks)}
                                >
                                  <Dropdown.Toggle
                                    variant="success"
                                    className="apply-btn"
                                    id="dropdown-basic"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span>
                                      <BiSolidOffer /> Apply Offer{" "}
                                    </span>
                                    <FaChevronDown
                                      style={{
                                        color: "#000",
                                      }}
                                    />
                                  </Dropdown.Toggle>
                                </Dropdown>
                                {offerBooks && (
                                  <div className="discount-dropdown">
                                    {discountOffers?.data?.map(
                                      (offersPrice: any, index: number) => (
                                        <Fragment key={index}>
                                          <div className="offer offer d-flex justify-content-between">
                                            <div className="offer-text">
                                              <h5>
                                                Get {offersPrice?.offerAmount} %
                                                Offer
                                              </h5>
                                              <p>{offersPrice?.name}</p>
                                            </div>
                                            <div className="offer-button">
                                              <Form>
                                                <div className="mb-3">
                                                  <input
                                                    style={{
                                                      border:
                                                        "2px solid #cacaca",
                                                      fontSize: "20px",
                                                    }}
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={
                                                      offersPrice?.offerAmount ==
                                                      selectedOffer
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={() =>
                                                      handleCheckboxChange(
                                                        offersPrice?.offerAmount,
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </Form>
                                            </div>
                                          </div>
                                        </Fragment>
                                      ),
                                    )}
                                  </div>
                                )}
                              </Box>
                            </Grid>
                          )}
                          <Grid item lg={6} md={7}>
                            <Box className="total-table">
                              <Grid container>
                                <Grid item xs={10}>
                                  <Typography className="total-text">
                                    Total number of service provider
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography
                                    className="total-text"
                                    style={{ fontWeight: "400" }}
                                  >
                                    {bookingDetails?.personRequired}
                                  </Typography>
                                </Grid>
                                <Grid item xs={10}>
                                  <Typography className="total-text">
                                    Total number of service hours
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography
                                    className="total-text"
                                    style={{ fontWeight: "400" }}
                                  >
                                    {bookingDetails?.hours}
                                  </Typography>
                                </Grid>
                                <Grid item xs={10}>
                                  <Typography className="total-text">
                                    Per person Hourly rate
                                  </Typography>
                                </Grid>

                                <Grid item xs={2}>
                                  <Typography
                                    className="total-text"
                                    style={{
                                      fontWeight: "400",
                                      marginLeft: "-30px",
                                    }}
                                  >
                                    CAD${bookingDetails?.postPrice}
                                  </Typography>
                                </Grid>

                                <Grid item xs={10}>
                                  <Typography className="total-text">
                                    Discount
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography
                                    className="total-text"
                                    style={{
                                      fontWeight: "400",
                                      marginLeft: "-30px",
                                    }}
                                  >
                                    CAD$-{discountAmount.toFixed(2)}
                                  </Typography>
                                </Grid>
                                <Grid item xs={10} className="total-col">
                                  <Typography
                                    className="total-text"
                                    style={{
                                      color: "#24AAE3",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Total Payment
                                  </Typography>
                                </Grid>
                                <Grid item xs={2} className="total-col">
                                  <Typography
                                    className="total-text"
                                    style={{
                                      color: "#24AAE3",
                                      fontWeight: "600",
                                      marginLeft: "-30px",
                                    }}
                                  >
                                    CAD${totalPrice.toFixed(2)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>

                      {bookingDetails?.postId !== postId ? (
                        <Box
                          sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                        >
                          <Box sx={{ flex: "1 1 auto" }} />
                          {activeStep !== steps.length - 1 && (
                            <Button
                              className="view-profile"
                              style={{ padding: "10px 30px" }}
                              onClick={() => setShowPop(true)}
                            >
                              {activeStep === steps.length - 2
                                ? "Submit"
                                : "Payment"}
                            </Button>
                          )}
                        </Box>
                      ) : (
                        <Box
                          sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                        >
                          <Box sx={{ flex: "1 1 auto" }} />
                          {activeStep !== steps.length - 1 && (
                            <Button
                              className="view-profile"
                              style={{ padding: "10px 30px" }}
                              onClick={() => setShowPop(true)}
                            >
                              Update
                            </Button>
                          )}
                        </Box>
                      )}
                    </React.Fragment>
                  )}
                  <ConfirmPost
                    OpenModal={showPop}
                    handleClose={handleClose}
                    conFirmPost={handleNext as any}
                    postLoading={isloadingBooking}
                    paymentLoading={isLoadings}
                    updateTimeBooking={handleupdateTimeApi}
                    postid={postId as any}
                    postId2={bookingDetails?.postId}
                  />
                </Box>
              </Style.Overview>
            </Box>
          </Box>
        </Style.Overview>
      </Box>
    </>
  )
}

export default BookOverview
