import { configureStore, combineReducers } from "@reduxjs/toolkit"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import { api } from "../features/Auth/authApi"
import auth from "../features/Auth/authSlice"
import storage from "redux-persist/lib/storage"
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist"
import authModalSlice from "../features/AuthModal/authModalSlice"
import overViewAuth from "../features/OverViewAuth/overViewSlice"
import { overViewApi } from "../features/OverViewAuth/overViewApi"
import { manageViewApi } from "../features/ManageAuth/manageApi"
import manageViewAuth from "../features/ManageAuth/manageViewSlice"
import { notificationsApi } from "../features/Socket/Socketio"
import { setupListeners } from "@reduxjs/toolkit/query"
import { apiPayment } from "../features/AuthPayment/paymentApi"
import { locationApi } from "../features/locationFunc/locationApi"
import { chatMessageApi } from "../features/ChatSocket/chat"
import { chatViewApi } from "../features/ChatAuth/chatApi"
import chatAuth from "../features/ChatAuth/chatSlice"
import postcreateSlice from "../features/PostCreat/postcreateSlice"
import { useAppSelector } from "./hooks"
import unauthorizedMiddleware from "./unauthorizedMiddleware"

const persistConfig = {
  key: "root",
  whitelist: ["funnelData", "auth"],
  version: 1,
  storage,
}

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  [manageViewApi.reducerPath]: manageViewApi.reducer,
  [overViewApi.reducerPath]: overViewApi.reducer,
  [notificationsApi.reducerPath]: notificationsApi.reducer,
  [chatMessageApi.reducerPath]: chatMessageApi.reducer,
  [chatViewApi.reducerPath]: chatViewApi.reducer,
  [apiPayment.reducerPath]: apiPayment.reducer,
  [locationApi.reducerPath]: locationApi.reducer,

  auth,
  authModal: authModalSlice,
  authManageView: manageViewAuth,
  authOverView: overViewAuth,
  authChatView: chatAuth,
  postCreate: postcreateSlice,
})

// const unauthorizedMiddleware = (store: any) => (next: any) => (action: any) => {
//   // const socketId = useAppSelector((state) => state.auth)
//   if (action.type.endsWith("rejected")) {
//     // Assuming your error structure has a status field indicating the HTTP status

//     // const payload = {
//     //   chatId: socketId?.socketId,
//     //   notificationId: socketId?.notificationId,
//     // }
//     let status = action.error?.status || action.payload?.status
//     if (status === 401) {
//       // Dispatch a logout action or handle the error globally
//       console.log("Unauthorized access detected, handling logout.")
//       // window.location.reload()
//       if (store.getState().auth.token) {
//         store.dispatch(api.endpoints.logoutCustomer.initiate())
//         store.dispatch(api.util.resetApiState())
//         store.dispatch(overViewApi.util.resetApiState())
//       }

//       // Optionally, redirect or handle other UI effects
//     }
//   }
//   return next(action)
// }

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      api.middleware,
      overViewApi.middleware,
      notificationsApi.middleware,
      chatViewApi.middleware,
      chatMessageApi.middleware,
      apiPayment.middleware,
      locationApi.middleware,
      unauthorizedMiddleware,
    ]),
})

export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export type RootState = ReturnType<typeof store.getState>
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
setupListeners(store.dispatch)
