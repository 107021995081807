import React from "react"
import { Avatar, Box, Button, Divider, Grid, Typography } from "@mui/material"
import * as Style from "./style"
import moment from "moment"
import { FaChevronLeft } from "react-icons/fa6"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useGetProviderListQuery } from "../../features/OverViewAuth/overViewApi"
import { CalculateTimeDifference } from "../Notification/timeNotification"
import RatingStars from "../common/rating"
import CenteredSpinner from "../common/CenteredSpinner"
import dummy from "../../images/dummy.png"
const ProviderProfile = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const {
    data: profileView,
    isFetching,
    isLoading,
  } = useGetProviderListQuery({ id })

  const servicesArray = profileView?.data?.providerData?.services?.map(
    (item: any) => item?.name,
  )

  const currentDate = moment()
  const tagStyle = {
    border: "1px solid #F475A4",
    padding: "4px 8px",
    borderRadius: "8px",
    marginRight: "8px", // Add space between tags
    marginBottom: "8px", // Add space below each row of tags
    display: "inline-block",
    backgroundColor: "#F475A4", // Light background color
    color: " #fff",
  }

  return (
    <>
      {" "}
      {isFetching || isLoading ? (
        <CenteredSpinner />
      ) : (
        <Style.Overview>
          <Box className="position-relative">
            <Button
              className="back-btn mb-0 profile-back"
              onClick={() => navigate(-1)}
            >
              <FaChevronLeft />
            </Button>
            <Grid container className="justify-content-center">
              <Grid xs={12} lg={8} className="pe-md-4">
                <Grid container>
                  <Grid xs={12}>
                    <Box className="profile-page-header">
                      <Typography className="profile-heading">
                        Profile Page
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Box className="review-card p-4 mt-0 d-flex flex-column align-items-center  single-review-card">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={7} sx={{ order: { xs: 2, sm: 0 } }}>
                      <Grid container className="mb-4">
                        <Grid md={5}>
                          <Typography variant="body1" className="pe-2">
                            <strong> Email </strong>
                          </Typography>
                        </Grid>
                        <Grid md={7}>
                          <Typography
                            variant="body1"
                            style={{ wordWrap: "break-word" }}
                          >
                            {profileView?.data?.providerData?.email}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container className="mb-4">
                        <Grid md={5}>
                          <Typography variant="body1" className="pe-2">
                            <strong> Phone number </strong>
                          </Typography>
                        </Grid>
                        <Grid md={7}>
                          <Typography variant="body1">
                            {profileView?.data?.providerData?.contactNo}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container className="mb-4">
                        <Grid md={5}>
                          <Typography variant="body1" className="pe-2">
                            <strong> Rating </strong>
                          </Typography>
                        </Grid>
                        <Grid md={7}>
                          <Typography
                            variant="body1"
                            className="  gap-2 justify-content-center align-items-center mb-1"
                            style={{ color: "gold" }}
                          >
                            <RatingStars
                              rating={
                                profileView?.data?.providerData?.rating
                                  ?.$numberDecimal || "--"
                              }
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container className="mb-4">
                        <Grid md={5}>
                          <Typography variant="body1" className="pe-2">
                            <strong> Date of birth </strong>{" "}
                          </Typography>
                        </Grid>
                        <Grid md={7}>
                          {profileView?.data?.providerData?.dob ? (
                            <Typography variant="body1" className="pe-2">
                              {moment(
                                profileView?.data?.providerData?.dob,
                              ).format("DD-MM-YYYY")}
                            </Typography>
                          ) : (
                            "--"
                          )}
                        </Grid>
                      </Grid>
                      <Grid container className="mb-4">
                        <Grid md={5}>
                          <Typography variant="body1" className="pe-2">
                            <strong> Gender </strong>{" "}
                          </Typography>
                        </Grid>
                        <Grid md={7}>
                          <Typography variant="body1">
                            {profileView?.data?.providerData?.gender || "--"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container className="mb-4">
                        <Grid md={5}>
                          <Typography variant="body1" className="pe-2">
                            <strong> Language </strong>
                          </Typography>
                        </Grid>
                        <Grid md={7}>
                          <Typography variant="body1" className="pe-2">
                            {profileView?.data?.providerData?.language?.join(
                              " ",
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container className="mb-4">
                        <Grid md={5}>
                          <Typography variant="body1" className="pe-2">
                            <strong> Origin Country </strong>
                          </Typography>
                        </Grid>
                        <Grid md={7}>
                          <Typography variant="body1">
                            {profileView?.data?.providerData?.region}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={5} sx={{ order: { xs: 1, sm: 0 } }}>
                      {" "}
                      <Box className="mb-3">
                        <img
                          src={profileView?.data?.providerData?.profilePic}
                          className="profile-img "
                          style={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "12px",
                          }}
                        />
                      </Box>
                      <Box className="mb-4">
                        <Typography className="service-provider-name">
                          {profileView?.data?.providerData?.name}
                        </Typography>
                        <Typography className="service-provider-bio">
                          {profileView?.data?.providerData?.description}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container className="mb-4">
                    <Grid md={5}>
                      <Typography variant="body1" className="pe-2">
                        <strong> Services Offered </strong>
                      </Typography>
                    </Grid>
                    <Grid md={12}>
                      <Typography variant="body1">
                        {servicesArray.map((service: string, index: number) => (
                          <span key={index} style={tagStyle}>
                            {service}{" "}
                          </span>
                        ))}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container className="justify-content-center">
                    <Grid md={12}>
                      <Divider className="dark-divider my-4" />
                      <Typography
                        variant="body1"
                        align="left"
                        className="text-dark mb-3"
                      >
                        <strong> Reviews </strong>{" "}
                      </Typography>
                      <Grid container className="mb-4">
                        {profileView?.data?.providersReview?.map(
                          (item: any) => (
                            <Grid xs={12}>
                              <Box className="inner-review-card my-3 me-2">
                                <Box className="d-flex justify-content-between align-items-center mb-1">
                                  <Box className="d-flex gap-2 align-items-center">
                                    <img
                                      src={
                                        item?.seekerData?.profilePic || dummy
                                      }
                                      className="inner-profile-img"
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                        borderRadius: "12px",
                                      }}
                                    />
                                    <Typography variant="body1">
                                      {item?.seekerData?.name}
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography
                                      variant="body1"
                                      className="d-flex gap-2 align-items-center"
                                      style={{ color: "gold" }}
                                    >
                                      <RatingStars rating={item?.rating} />
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography variant="body1">
                                      <CalculateTimeDifference
                                        createdAt={item?.createdAt}
                                      />
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box>
                                  <Typography variant="body1">
                                    {item?.message}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                          ),
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Style.Overview>
      )}
    </>
  )
}
export default ProviderProfile
