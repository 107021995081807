import React, { useEffect, useState } from "react"
import * as Style from "./style"
import { FaChevronLeft } from "react-icons/fa6"
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Pagination,
  Stack,
  Typography,
} from "@mui/material"
import notificationIcons from "../../images/notificationIcons.svg"
import { useNavigate } from "react-router-dom"
import Paper from "@mui/material/Paper"
import { styled } from "@mui/material/styles"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"

import moment from "moment"
import { CalculateTimeDifference } from "./timeNotification"
import CenteredSpinner from "../common/CenteredSpinner"
import { useGetNotificationQuery } from "../../features/Auth/authApi"
import { overViewApi } from "../../features/OverViewAuth/overViewApi"
import { useAppDispatch } from "../../app/store"

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}))

const Notification = () => {
  const [value, setValue] = React.useState(0)

  const [selectedPage, setSelectedPage] = useState(1)

  const [pageLimit, setPageLimit] = useState(5)
  const dispatch = useAppDispatch()
  // isSeenKey

  const {
    data: notificationData,
    isLoading,
    isFetching,
  } = useGetNotificationQuery(
    {
      // isSeenKey: seenValue,
      // pageNo: selectedPage,
      pageLimit: pageLimit,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  )

  function samePageLinkNavigation(
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) {
    if (
      event.defaultPrevented ||
      event.button !== 0 ||
      event.metaKey ||
      event.ctrlKey ||
      event.altKey ||
      event.shiftKey
    ) {
      return false
    }
    return true
  }
  interface LinkTabProps {
    label?: string
    href?: string
    selected?: boolean
    onClick?: () => void
  }

  function LinkTab(props: LinkTabProps) {
    return (
      <Tab
        component="a"
        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
          if (samePageLinkNavigation(event)) {
            event.preventDefault()
          }
        }}
        aria-current={props.selected && "page"}
        {...props}
      />
    )
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (
      event.type !== "click" ||
      (event.type === "click" &&
        samePageLinkNavigation(
          event as React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        ))
    ) {
      setValue(newValue)
    }
  }
  const navigate = useNavigate()

  const handleShowMore = () => {
    setSelectedPage(selectedPage + 1)
  }
  useEffect(() => {
    if (selectedPage) {
      setPageLimit(selectedPage * 5)
    }
  }, [selectedPage])

  useEffect(() => {
    dispatch(overViewApi.util.invalidateTags(["countNotification"]))
  }, [notificationData])

  return (
    <>
      <Style.Overview>
        <Button
          className="back-btn mb-0"
          onClick={() => navigate(`/dashboard`)}
        >
          <FaChevronLeft />
        </Button>

        <Grid
          className="pt-lg"
          container
          justifyContent="center"
          md={10}
          xl={7}
          sm={12}
        >
          <Box className="notification-msg-topbar">
            <Grid className="notification-grid" container spacing={2}>
              <Grid item xs={8}>
                <Box sx={{ width: "100%" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="nav tabs example"
                    role="navigation"
                    className="notification-tabs"
                    indicatorColor="secondary"
                    textColor="primary"
                  >
                    <LinkTab label="All" />
                  </Tabs>
                </Box>
              </Grid>

              <Grid item xs={4}>
                {/* datePicker */}

                {/* <DateRangePickerValue/> */}
              </Grid>
            </Grid>
          </Box>

          {isLoading ? (
            <CenteredSpinner />
          ) : (
            <Grid className="pt-lg-inner" item sm={12} md={10}>
              <Box className="pt-lg-0 pt-4">
                {notificationData?.slice(0, pageLimit).map((noti: any) => (
                  <Box
                    className="d-lg-flex align-items-center justify-content-center gap-4 mb-3"
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      if (
                        noti?.postId?.status === "Accepted" ||
                        noti?.postId?.status === "Expired" ||
                        noti?.postId?.status === "Completed" ||
                        noti?.postId?.status === "Declined"
                      ) {
                        navigate(
                          `/dashboard/bookings/details/${noti?.postId?._id}`,
                        )
                      } else if (noti?.postId?.status === "Active") {
                        navigate(`/dashboard/active-view/${noti?.postId?._id}`)
                      }
                    }}
                  >
                    {/* <Box
                      className={`feature-card w-100 d-flex align-items-start ${
                        noti?.actionRequired == false && `bg-light`
                      } gap-3 mb-3 mx-lg-3`}
                    > add this bg-light class*/}
                    <Box className="feature-card w-100 d-flex align-items-start bg-light gap-3 mb-3 mx-lg-3">
                      <img
                        src={notificationIcons}
                        className="notification-img"
                      />
                      <Box>
                        <Typography className=" mb-2 lh-sm text-black noti-text">
                          {/* <span
                            style={{
                              color:
                                noti?.postId?.status === "Expired" ? "red" : "", // Conditional color based on status
                            }}
                          > */}
                          {noti?.type}
                          {/* </span> */}
                        </Typography>
                        <Typography
                          variant="body1"
                          className="lh-sm text-black noti-content"
                        >
                          {noti?.content}
                        </Typography>
                        <Typography
                          variant="body1"
                          className="lh-sm text-black noti-time mt-1"
                        >
                          <CalculateTimeDifference
                            createdAt={noti?.createdAt}
                          />
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
              {notificationData?.length > 4 && (
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="center">
                    {/* {notificationData?.data?.length > displayCount && ( */}
                    <Typography
                      onClick={handleShowMore}
                      fontWeight="bold"
                      color="textPrimary"
                      sx={{ cursor: "pointer" }}
                      className="view-profile"
                    >
                      {isFetching ? (
                        <CircularProgress
                          style={{ color: "pink", fontSize: 20 }}
                          size={10}
                        />
                      ) : (
                        "Show More"
                      )}
                    </Typography>
                    {/* )} */}
                  </Box>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Style.Overview>
    </>
  )
}
export default Notification
