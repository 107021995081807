import React, { useState } from "react"
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Rating,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"
import { LiaAngleRightSolid } from "react-icons/lia"
import * as Style from "./style"
import moment from "moment"

import { Spinner, Table } from "react-bootstrap"
import tableCellImg from "../../images/table-img.png"
import { Upcoming } from "@mui/icons-material"
import singleSeekerImg from "../../images/img-rounded.png"
import { FaStar } from "react-icons/fa6"
import { IoMailOutline } from "react-icons/io5"
import { FiPhone } from "react-icons/fi"
import { SlLocationPin } from "react-icons/sl"
import { Link, useParams } from "react-router-dom"
import {
  useGetGenratedPostSeekerQuery,
  useGetProfileSeekerQuery,
  usePostAcceptProviderMutation,
} from "../../features/OverViewAuth/overViewApi"
import CenteredSpinner from "../common/CenteredSpinner"

const SeekerProfile = () => {
  const [selectAccept, setSelectAccept] = useState(false)
  const [providerId, setProviderId] = useState<string[]>([])
  const { id } = useParams()

  const { data: geratedPost } = useGetGenratedPostSeekerQuery({ id })
  // const postIdProvider = geratedPost?.data?.[0]?._id || ""
  const {
    data: seekerProfileList,
    isLoading,
    isFetching,
  } = useGetProfileSeekerQuery({ id })

  const [postAccept, { data, error }] = usePostAcceptProviderMutation()

  const onSumbitPost = () => {
    const payload = {
      postId: id,
      assignedTo: providerId,
    }

    postAccept(payload)
  }

  return (
    <>
      {isLoading || isFetching ? (
        <CenteredSpinner />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Style.Overview>
              {geratedPost?.data?.map((post: any) => (
                <Box>
                  <Typography variant="body1" className="text-center pt-2 pb-3">
                    Service Seeker
                  </Typography>

                  <Box className="seeker-card">
                    <Box className="d-flex gap-2 align-items-center mb-3">
                      <Box>
                        <img src={singleSeekerImg} className="seeker-img" />
                      </Box>
                      <Box>
                        <Typography variant="body1" className="mb-2 text-black">
                          {post?.seekerData?.name}
                        </Typography>
                        <Typography variant="body1" className="mb-2">
                          <IoMailOutline /> {post?.seekerData?.email}
                        </Typography>
                        <Typography variant="body1">
                          <FiPhone /> {post?.seekerData?.contactNo}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Box className="py-3 listing-seeker">
                        <Typography variant="body1">
                          Service Seeker : {post?.service?.name}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Box className="py-3 listing-seeker">
                        <Typography variant="body1">
                          Date & Time : {post?.till}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Box className="py-3 listing-seeker">
                        <Typography variant="body1">
                          Number of provider - 2
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Box className="py-3 listing-seeker">
                        <Typography variant="body1">
                          Age of provider - 24yrs - 30yrs
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Box className="py-3 listing-seeker">
                        <Typography variant="body1">
                          Language of provider - Eng/French
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Box className="py-3 listing-seeker">
                        <Typography variant="body1" className="d-flex gap-2">
                          <SlLocationPin /> Location: {post?.location}
                        </Typography>
                      </Box>
                    </Box>
                    <Typography variant="body1" className="mt-4">
                      Post at : {post?.from}
                    </Typography>
                  </Box>

                  {/* <Box className="d-flex gap-2 mt-3">
                <Button variant="contained" className="w-100 assign-btn">
                  Assign
                </Button>
                <Button variant="outlined" className="w-100 rejected-btn">
                  Reject
                </Button>
              </Box> */}
                  {/* <Box className="reject-card mt-4">
                <Typography
                  variant="body1"
                  className="text-black text-center mb-3"
                >
                  Do you want to reject a service request?
                </Typography>

                <Box className="d-flex gap-2 mt-3 justify-content-center">
                  <Button variant="outlined" className="yes-btn">
                    Yes
                  </Button>
                  <Button variant="contained" className="no-btn">
                    No
                  </Button>
                </Box>
              </Box> */}
                  <Box className="mt-3">
                    <Button
                      variant="contained"
                      className="assign-btn px-5"
                      onClick={onSumbitPost}
                    >
                      {" "}
                      Provider Assigned
                    </Button>
                  </Box>
                  <Box className="mt-3">
                    <Button
                      variant="outlined"
                      className="rejected-btn reject-fill-btn px-5"
                    >
                      Request Rejected
                    </Button>
                  </Box>
                </Box>
              ))}
            </Style.Overview>
          </Grid>
          <Grid item xs={8}>
            <Style.Overview>
              <TableContainer
                component={Paper}
                className="dashboard-table-wrapper"
              >
                <Table
                  style={{
                    minWidth: 650,
                    border: "none",
                    backgroundColor: "#fff",
                    borderRadius: "16px",
                    overflow: "hidden",
                  }}
                  className="dashboard-table"
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell align="left" className="service-head">
                        Name
                      </TableCell>
                      <TableCell align="center">Email</TableCell>
                      <TableCell align="center">Phone</TableCell>
                      <TableCell align="center">Age</TableCell>
                      <TableCell align="center">Language</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {seekerProfileList?.data?.map((row: any) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          <Box>
                            <img src={row.userimage} />
                          </Box>
                        </TableCell>
                        <TableCell component="th" align="center" scope="row">
                          <span>
                            {row.name}
                            <span>
                              {row.rating?.$numberDecimal}
                              <FaStar />
                            </span>
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="email">
                            <span>{row.email}</span>
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          <span>{row.contactNo}</span>
                        </TableCell>
                        <TableCell align="center">
                          <span>{row.age}</span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="language">
                            <span>{row.language}</span>
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          <span>
                            <button
                              className="view-profile"
                              style={{
                                backgroundColor: selectAccept ? "green" : "",
                              }}
                              onClick={() => {
                                setProviderId((previousIds: any) => [
                                  ...(previousIds as string),
                                  row?._id as any,
                                ]),
                                  setSelectAccept(!selectAccept)
                              }}
                            >
                              Accept
                            </button>
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          <span>
                            <button className="view-profile">Reject</button>
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Style.Overview>
          </Grid>
        </Grid>
      )}
    </>
  )
}
export default SeekerProfile
