import * as React from "react"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import * as Style from "./style"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  Grid,
  Pagination,
  Stack,
  TextField,
  CircularProgress,
  Input,
} from "@mui/material"
import { FaStar } from "react-icons/fa6"
import tableCellImg from "../../images/table-img.png"
import { Button, Spinner } from "react-bootstrap"
import { FiDownload } from "react-icons/fi"
import { CiCalendar } from "react-icons/ci"
import { useState, useEffect } from "react"
import { CiSearch } from "react-icons/ci"
import { FaFileInvoice } from "react-icons/fa6"
import { IoReload } from "react-icons/io5"
import {
  useGetBookingHistoryQuery,
  useGetiNvoiceQuery,
} from "../../features/OverViewAuth/overViewApi"
import moment from "moment"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"

import {
  FormatChangeTime,
  FormatDate,
  FormatDateMonthDay,
  FormatTime,
} from "../common/utctolocalDate"
import noDataHistory from "../../images/no-history.png"
import CenteredSpinner from "../common/CenteredSpinner"
import { TbArrowsSort } from "react-icons/tb"

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  className: string
}

export default function BasicTabs() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchQuery, setSearchQuery] = useState<string>()
  const [enterSearchQueery, setEnterSearchQuerry] = useState<string>("")
  const [listmonth, setListMonth] = useState<string>("")
  const [statusChange, setStatusChange] = useState<string>("")
  const [statusIndex, setStatusIndex] = useState<number>()
  const [shortdate, setShortDate] = useState<number>(-1)

  // Time Zone funtion

  const [timeZone, setTimeZone] = useState("")

  useEffect(() => {
    let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    if (timeZone === "Asia/Calcutta") {
      timeZone = "Asia/Kolkata"
    }
    setTimeZone(timeZone)
  }, [])

  // for select date

  const [minDate, setMinDate] = useState("")
  const [maxDate, setMaxDate] = useState("")

  const handleMonth = (e: any) => {
    setSelectedPage(1)

    setListMonth(e.target.value)
  }

  useEffect(() => {
    const now = new Date()
    const currentMonth = new Date(now.getFullYear(), now.getMonth(), 1)
    const max = `${currentMonth.getFullYear()}-${String(
      currentMonth.getMonth() + 1,
    ).padStart(2, "0")}`

    const min = `${now.getFullYear()}-01`

    setMaxDate(max)
    setMinDate(min)
  }, [])

  useEffect(() => {
    const now = new Date()
    const year = now.getFullYear()
    const month = String(now.getMonth() + 1).padStart(2, "0")
    const currentMonthValue = `${year}-${month}`
    setListMonth(currentMonthValue)
  }, [])

  const [selectedPage, setSelectedPage] = useState(1)

  const {
    data: invoiceGet,
    isFetching,
    isLoading,
  } = useGetiNvoiceQuery(
    {
      pageNo: selectedPage,
      timeZone: timeZone,
      filterDate: listmonth + "-01",
      pageLimit: 10,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  )

  const handlePageChange = (event: any, value: any) => {
    setSelectedPage(value)
  }

  // View payment details

  const viewDetailsPayment = (
    event: React.MouseEvent<HTMLElement>,
    imageUrl: any,
  ) => {
    event.stopPropagation()
    const url = imageUrl
    const newTab = window.open(url, "_blank")
    if (newTab) {
      newTab.focus()
    } else {
      console.error("Failed to open new tab.")

      window.location.href = url
    }
  }

  return (
    <>
      <Style.Overview>
        <Box style={{ marginBottom: "20px" }}>
          <div className="w-100">
            <FormControl
              sx={{ m: 1, width: 100, mt: 3 }}
              className="custom-field view-field"
            >
              <input
                id="demo-simple-select"
                type="month"
                onChange={handleMonth}
                placeholder="Select Date"
                style={{
                  borderRadius: "12px",
                  width: "150px",
                  backgroundColor: "#fff",
                  padding: "13px 17px",
                  border: "1px solid #C1C1C1",
                  color: "#000",
                }}
                min={minDate}
                max={maxDate}
                value={listmonth}
              />
            </FormControl>
          </div>{" "}
        </Box>
      </Style.Overview>

      <Style.Overview>
        <Box sx={{ width: "100%" }}>
          <TableContainer
            component={Paper}
            className="dashboard-table-wrapper custom-scrollbar overflow-y-hidden"
          >
            <Table
              style={{
                minWidth: 650,
                border: "none",
                backgroundColor: "#fff",
                borderRadius: "16px",
                overflow: "hidden",
              }}
              className="dashboard-table1"
              aria-label="simple table"
            >
              {invoiceGet?.message?.length > 0 && (
                <TableHead>
                  <TableRow>
                    <TableCell align="center" className="service-head">
                      Billing Date
                    </TableCell>
                    <TableCell align="center">Booked Service</TableCell>
                    <TableCell align="center"> Billing Amount</TableCell>

                    {/* <TableCell align="center">End Time</TableCell> */}
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">Download</TableCell>
                  </TableRow>
                </TableHead>
              )}

              {isLoading || isFetching ? (
                <>
                  {" "}
                  <CenteredSpinner />
                </>
              ) : (
                <TableBody>
                  {invoiceGet?.message?.length > 0 ? (
                    invoiceGet?.message?.map((row: any, index: number) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          cursor: "pointer",
                        }}
                      >
                        <TableCell align="center">
                          <span className="datetime">
                            {row?.postId[0]?.from ? (
                              <span className="d-flex flex-column">
                                <span className="weekday">
                                  <FormatDate
                                    dateString={row?.postId[0]?.from}
                                  />
                                </span>
                                <span>
                                  <FormatTime
                                    dateString={row?.postId[0]?.from}
                                  />
                                </span>
                                <span>
                                  <FormatChangeTime
                                    dateString={row?.postId[0]?.from}
                                  />
                                </span>
                              </span>
                            ) : (
                              <span>Invalid</span>
                            )}
                          </span>
                        </TableCell>

                        <TableCell align="center">
                          <span
                            className={row.payment}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              color: !row?.postId[0]?.service[0]?.name
                                ? "red"
                                : "inherit",
                            }}
                          >
                            {row?.postId[0]?.service[0]?.name
                              ? row?.postId[0]?.service[0]?.name
                              : "Not Service"}
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          <span
                            className={row.payment}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            CAD$ {row?.totalAmount}
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          <span
                            className={row.payment}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              className="payment-btn"
                              style={{
                                color:
                                  row?.paymentStatus == "Pending"
                                    ? "red"
                                    : "green",
                              }}
                            >
                              {row?.paymentStatus == "Pending"
                                ? "Pending"
                                : row?.paymentStatus == "Success"
                                ? "Paid"
                                : row?.paymentStatus}
                            </Button>

                            {/* <Button className="payment-btn1" style={{}}>Failed </Button> */}
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          {row?.postId[0]?.receipt_url ? (
                            <span
                              className={row.payment}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                              onClick={(e: any) =>
                                viewDetailsPayment(
                                  e,
                                  row?.postId[0]?.receipt_url as any,
                                )
                              }
                            >
                              <FiDownload
                                style={{ marginLeft: "5px", fontSize: "20px" }}
                              />

                              {/* <Button className="payment-btn1" style={{}}>Failed </Button> */}
                            </span>
                          ) : (
                            <span>Pending</span>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={7}
                        sx={{
                          width: "100%",
                          pointerEvents: "none",
                          border: "none",
                          height: "498px",
                        }}
                      >
                        <img
                          src={noDataHistory}
                          style={{
                            width: "100%",
                            maxWidth: "208px",
                          }}
                        />
                        <span className="no-data-text">No booking history</span>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {/* </CustomTabPanel> */}
        </Box>
        {invoiceGet?.message?.length > 0 && (
          <Box className="d-md-flex justify-content-between align-items-center mt-3">
            <Typography variant="body1" className="white-space">
              {" "}
              Showing {invoiceGet?.paginationData?.showingData} /{" "}
              {invoiceGet?.paginationData?.total} Total
            </Typography>
            <Grid container justifyContent="flex-end">
              <Grid item md={3}>
                <Box display="flex" className="flex-column">
                  <Stack spacing={2} className="mb-3">
                    <Pagination
                      count={invoiceGet?.paginationData?.totalPages}
                      color="secondary"
                      className="custom-pagination"
                      page={selectedPage}
                      onChange={handlePageChange}
                      sx={{
                        "& .MuiPaginationItem-page.Mui-selected": {
                          backgroundColor: "#F475A4",
                          boxSizing: "border-box",
                        },
                        "& .css-wjh20t-MuiPagination-ul": {
                          flexWrap: "nowrap",
                          justifyContent: "end",
                        },
                      }}
                    />
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      </Style.Overview>
    </>
  )
}
