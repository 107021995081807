import React, { useEffect, useState } from "react"
import dayjs from "dayjs"
import {
  Box,
  Button,
  Grid,
  Typography,
  Input,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material"
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo"
import { FaChevronLeft } from "react-icons/fa6"
import greenChecked from "../../images/checked-green.svg"
import notifyIcon from "../../images/notify-icon.svg"
import * as Style from "./style"
import offerImg from "../../images/cooking.png"
import { IoLocationOutline } from "react-icons/io5"
import { Link, useNavigate } from "react-router-dom"
import { useAppSelector } from "../../app/hooks"
import { usePaymentBookingMutation } from "../../features/AuthPayment/paymentApi"
import { useCreatePostApiMutation } from "../../features/OverViewAuth/overViewApi"
import { toast } from "react-toastify"

import { useAppDispatch } from "../../app/store"

const steps = ["Booking Summary", "Payment", "Request sent"]

const BookOverviewCompleted = () => {
  const [activeStep, setActiveStep] = React.useState(2)
  const [skipped, setSkipped] = React.useState(new Set<number>())
  const [priceBooking, setPriceBooking] = useState<string>()
  const navigate = useNavigate()

  const bookingDeatils = useAppSelector(
    (state: any) => state.auth.paymentBooking,
  )

  useEffect(() => {
    const pricesArray = bookingDeatils?.price?.join()

    setPriceBooking((pricesArray * bookingDeatils?.personRequired) as any)
  }, [])

  const isStepOptional = (step: number) => {
    return step === 1
  }

  const isStepSkipped = (step: number) => {
    return skipped.has(step)
  }

  const handleReset = () => {
    setActiveStep(1)
  }

  const getStepContent = (step: number) => {
    switch (step) {
      case 2:
        return (
          <Box>
            <Box className="request-sent-wrapper">
              <img src={greenChecked} className="checkedimg" />
              <Typography className="request-sent-heading">
                Booking Request Sent
              </Typography>
              <Typography className="text-body">
                Your request has successfully sent to service provider
              </Typography>
              <Link
                to="/dashboard/my-bookings"
                className="view-profile w-auto px-4"
              >
                See your request
              </Link>
              <Box className="notify-wrapper mt-5">
                <Typography className="notify-text">
                  <img src={notifyIcon} /> We will notify you once service
                  provider is assigned to you
                </Typography>
              </Box>
            </Box>
          </Box>
        )
      default:
        return null
    }
  }

  return (
    <>
      <Box>
        <Style.Overview>
          <Box className="feature-card px-1 py-md-4 px-md-5 position-relative">
            <Stepper activeStep={activeStep} className="stepper-booking pt-3">
              {steps.map((label, index) => (
                <Step key={label} completed={isStepSkipped(index)}>
                  <StepLabel optional={isStepOptional(index) ? "" : null}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  {/* <Button
                                        color="inherit"
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        sx={{ mr: 1 }}
                                    >
                                        Back
                                    </Button> */}
                  <Box sx={{ flex: "1 1 auto" }} />
                </Box>
              </React.Fragment>
            )}
          </Box>
        </Style.Overview>
      </Box>
    </>
  )
}

export default BookOverviewCompleted
