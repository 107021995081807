import { styled } from "@mui/material";

const ServiceSupportBox = styled("div")(({theme}) => ({
    '& .BoxQuery':{
        background: theme.palette.white?.main,
    /* padding: 22px; */
    width: '160px',
    height: '160px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    justifyContent: 'center',
    color: theme.palette.pink?.main,
    fontSize:'16px'
    },
    '& .MuiInputBase-root MuiInputBase-colorPrimary':{
        border: '1px solid #ccc',
        borderRadius: '20px',
        margin:' 0 36px 0 0'
    }
}))

export {ServiceSupportBox}
