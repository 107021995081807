import { styled } from "@mui/material"

const Bookedlog = styled("div")(({ theme }) => ({
  padding: "45px 0",
  "& .MuiTypography-h2": {
    color: theme.palette.pink.main,
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: " normal",
  },
}))
const Overview = styled("div")(({ theme }) => ({
  "& .choose-service": {
    background: "#F475A426",
    borderRadius: "19px",
    padding: "20px",
    height: "147px",

  },
  "& .booking-text-img img": {
    marginTop: "-36px",
    width: "175px",
    marginLeft: "60px",
  },
  "& .payment-text3": {
    marginBottom: "10px",
    fontWeight: "700",
    fontSize: "20px",
    color: "#F475A4",
  },
  "& .payment-text2": {

    fontWeight: "500",
    fontSize: "16px",
    lineHeight: " 20px",
    color: "#252525",
    marginBotom: "15px",
  },
  "& .MuiTypography-h2": {
    color: theme.palette.pink.main,
    fontFamily: "Montserrat",
    fontSize: " 20px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: " normal",
  },
  "& .MuiTypography-body5": {
    color: theme.palette.black.main,
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: " normal",
  },
  "& .MuiTypography-body1": {
    color: "#626262",
    fontFamily: "Montserrat",
    fontSize: " 14px",
    fontStyle: "normal",
    fontWeight: "400",
    display: "block",
    lineHeight: " 15px",
  },
  "& .MuiDrawer-paper": {
    backgroundColor: theme.palette.pink.main,
    "& .MuiListItem-root ": {
      color: theme.palette.white.main,
      "& svg": {
        color: theme.palette.white.main,
      },
    },
  },
  "& .css-1v3eikq-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root": {
    borderRadius: "11px!important",
  },
  "& .css-unqupv-MuiInputBase-root-MuiOutlinedInput-root": {
    borderRadius: "11px!important",
  },
  "& .css-1q0euvd": {
    borderRadius: "11px!important",
  },
  "& .css-ozhrbz": {
    borderRadius: "11px!important",
  },
  "& .css-nhb8h9": {
    justifyContent: "end!important",
    flexWrap: "nowrap",
  },
  "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
    padding: "17px",
  },
  "& .date-field": {
    border: "1px solid #C1C1C1",
    borderRadius: "11px",
    fontSize: "16px",
    fontWeight: "500",
    padding: "17px",
    color: "#000",
    backgroundColor: theme.palette.white.main,
  },
  "& .count-field": {
    width: "57px!important",
    "& input": {
      textAlign: "center",
      padding: "16px 0px!important",
      "@media (max-width: 600px)": {
        padding: "16px 10px!important",
      },
    },
  },
  "& .select-time-field": {
    minWidth: "50px",
  },
  "& .display-contents": {
    display: "contents",
  },
  "& .small-text": {
    fontSize: "12px",
  },
  "& .custom-field": {
    width: "100%",
    maxWidth: "200px",
  },
  "& .view-field": {
    minWidth: "100px",
    maxWidth: "100px",
  },
  "& .font-14": {
    fontSize: "14px!important",
  },
  "& .custom-field label": {
    backgroundColor: "#fff",
    maxWidth: "calc(100% - 40px)",
  },
  "& .custom-field .MuiSelect-outlined": {
    backgroundColor: "#fff",
    borderRadius: "20px",
  },
  "& .custom-field .MuiInputBase-root": {
    borderRadius: "20px",
  },
  "& .custom-field fieldset": {
    border: "1px solid transparent",
    transition: "all 0.4s ease",
  },
  "& .feature-card": {
    backgroundColor: theme.palette.white.main,
    borderRadius: "16px",
    boxShadow: "0px 4px 20px 2px #00000008",
    "& .feature-card-img img": {
      width: "120px",
      borderRadius: "16px 0 0 16px    ",
    },
    "& .font-15": {
      fontSize: "15px",
    },
    "& .feature-card-content": {
      padding: "30px",
    },
    // "& .feature-card span svg": {
    //   position: "relative",
    //   bottom: "120px",
    //   left: "60px",
    // },
    "& .left-btn svg": {
      position: "relative",
      bottom: "120px",
      left: "60px",
    },
    "& .field-label": {
      fontSize: "16px",
      fontFamily: "Montserrat",
      color: theme.palette.black.main,
      fontWeight: "700",
      "& svg": {
        fill: theme.palette.pink.main,
      },
      "&.age-label": {
        marginTop: "-25px",
        fontSize: "14px",
        fontWeight: "300",
      },
      "&.pink-text": {
        fontSize: "15px",
        color: theme.palette.pink.main,
        fontWeight: "500",
      },
    },
    "& .total-time input": {
      color: "#F475A4!important",
      background: "#F6F6F6",
      fontWeight: "600!important",
    },

    "& .feature-field": {
      "&:before": {
        display: "none",
      },
      "&:after": {
        display: "none",
      },
      "& .MuiInputBase-input": {
        fontSize: "16px",
        border: "1px solid #C1C1C1",
        padding: "16px 21px",
        borderRadius: "11px",
        color: theme.palette.black.main,
        fontFamily: "Montserrat",
        fontWeight: "400",
      },
      "&.title_field": {
        "& .MuiInputBase-input": {
          fontSize: "20px",
        },
      },
      "&.font-14": {
        "& .MuiInputBase-input": {
          padding: "5px 10px 28px",
          maxWidth: "94px",
          fontSize: "14px",
          textAlign: "center",
        },
      },
    },
    "& .person-text": {
      fontSize: "20px",
      color: theme.palette.pink.main,
      fontWeight: "500",
      fontFamily: "Montserrat",
    },
  },
  "& .service-detail-box-lg": {
    background: theme.palette.white.main,
    borderRadius: "16px",
    border: "1px solid #EDEDED",
    flexWrap: "unset !important"
  },
  "& .photo-upload": {
    width: "60px",
    height: "60px",
    objectFit: "cover",
    borderRadius: "8px",
  },
  "& .approved-card": {
    backgroundColor: theme.palette.white.main,
    padding: "9px 19px 14px",
    boxShadow: "0px 4px 20px 0px #00000008",
    borderRadius: "16px",
    "&.pending-card": {
      borderColor: theme.palette.yellow.main,
    },
    "&.rejected-card": {
      borderColor: theme.palette.red.main,
    },
  },
  "& .offer-box": {
    background: "linear-gradient(90.06deg, #F475A4 38.96%, #DD5084 99.94%);",
    borderRadius: "16px",
    padding: "19px 39px",
    position: "relative",
    "& .logo-white": {
      maxWidth: "148px",
      width: "100%",
      marginBottom: "12px",
    },

    "& .off-discount": {
      fontSize: "48px",
      color: theme.palette.white.main,
      letterSpacing: "4.8px",
      marginBottom: "22px",
      lineHeight: "initial",
      fontFamily: "Racing Sans One",
    },
    "& .valid-text": {
      color: "#FFD4E4",
      fontSize: "12px",
      fontFamily: "Montserrat",
    },
    "& .valid-date-text": {
      fontSize: "16px",
      color: theme.palette.white.main,
      fontFamily: "Montserrat",
      fontWeight: "500",
      "&.font-20": {
        fontSize: "20px",
      },
    },
    "& .book-text": {
      fontSize: "12px",
      color: theme.palette.white.main,
      fontFamily: "Montserrat",
      marginBottom: "9px",
    },
    "& .book-now-btn": {
      maxWidth: "135px",
      width: "100%",
      textAlign: "center",
      textDecoration: "none",
      borderRadius: "4px",
      padding: "12px 7px",
      background: theme.palette.white.main,
      color: theme.palette.pink.main,
      fontSize: "14px",
      fontFamily: "Montserrat",
      display: "inline-block",
    },
    "& .offer-img": {
      position: "absolute",
      bottom: 0,
      right: "20px",
      maxWidth: "285px",
      width: "100%",
    },
  },
  "& .divided-dots": {
    border: "1px dashed #C0C0C0",
    opacity: 1,
  },
  "& .range-text": {
    fontSize: "12px",
    color: theme.palette.black.main,
    fontWeight: 300,
    fontFamily: "Montserrat",
  },
  "& .optional-text": {
    fontSize: "12px",
    fontWeight: "400",
    fontFamily: "Montserrat",
    color: "#B8B8B8",
  },
  "& .img-delete-btn": {
    position: "absolute",
    top: "-14px",
    right: "-14px",
    width: "25px",
    height: "25px",
    cursor: "pointer",
    // "@media (max-width: 600px)": {
    //   width: "20px",
    //   height: "20px",
    //   top: "-12px",
    //   right: "-10px",
    // },
    backgroundColor: theme.palette.pink.main,
    borderRadius: "50%",
    color: theme.palette.white.main,
    fontSize: "10px",
    fontFamily: "Montserrat",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .track-service-btn": {
    maxWidth: "200px",
    width: "100%",
    display: "block",
    padding: "8px 2px",
    textAlign: "center",
    background: theme.palette.pink.main,
    color: theme.palette.white.main,
    borderRadius: "8px",
    fontSize: "14px",
    fontFamily: "Montserrat",
    textDecoration: "none",
  },
  "& .uploaded-photo": {
    width: "151px",
    height: "151px",
    objectFit: "cover",
    borderRadius: "13px",
  },
  "& .photo-upload-field": {
    width: "100px",
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "13px",
    border: "1px dashed",
    background: "rgba(244, 117, 164, 0.1)",
    borderColor: theme.palette.pink.main,
    cursor: "pointer",
    // "@media (max-width: 600px)": {
    //   width: "70px",
    //   height: "70px",
    //   borderRadius: "8px",
    // },
    "& img": {
      width: "31px",
      "@media (max-width: 600px)": {
        width: "21px",
      },
      "&.small-img": {
        width: "21px",
      },
    },
  },
  "& .error-text": {
    fontFamily: "Montserrat",
    fontSize: "12px",
  },
  "& .preview-img-group": {
    position: "absolute",
    left: "-7px",
    top: "43px",
  },
  "& .preview-img": {
    position: "relative",
    maxWidth: "98px",
    width: "100%",
    height: "auto",
    flex: "none",
    padding: "10px",
    // border: "1px solid #efefef",
    borderRadius: "10px",
    "@media (max-width: 600px)": {
      maxWidth: "69px",
    },
    "& .preview-count": {
      position: "absolute",
      top: "-14px",
      left: "-14px",
      width: "30px",
      height: "30px",
      backgroundColor: theme.palette.pink.main,
      borderRadius: "50%",
      color: theme.palette.white.main,
      fontSize: "10px",
      fontFamily: "Montserrat",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& p": {
      width: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontFamily: "Montserrat",
      fontSize: "14px",
      marginBottom: "3px",
    },
    "& img": {
      maxWidth: "100%",
      maxHeight: "84px",
      height: "auto",
      objectFit: "cover",
      borderRadius: "4px",
      "@media (max-width: 600px)": {
        maxHeight: "54px",
      },
    },
  },
  "& .people-card": {
    textDecoration: "none",
    "& img": {
      borderRadius: "16px",
      objectPosition: "center",
      height: "270px",
      objectFit: "cover",
    },
    "& p": {
      fontSize: "16px",
      color: "#373737",
      fontFamily: "Montserrat",
    },
  },
  "& .service-provider-field": {
    width: "auto",
    "&::-webkit-outer-spin-button": {
      appearance: "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      appearance: "none",
      margin: 0,
    },
    "&[type=number]": {
      appearance: "textfield",
    },
  },
  "& .text-success-provider": {
    color: theme.palette.green.main,
  },
  "& .track-btn": {
    width: "100%",
    padding: "9px",
    textAlign: "center",
    color: theme.palette.white.main,
    fontSize: "14px",
    backgroundColor: theme.palette.pink.main,
    borderColor: theme.palette.pink.main,
    fontFamily: "Montserrat",
    "& svg": {
      marginLeft: "9px",
    },
    "&:disabled": {
      color: "#B9B9B9",
      border: "1px solid #B9B9B9",
      backgroundColor: "transparent",
    },
  },
  "& .control-btn": {
    width: "auto",
    height: "auto",
    padding: "0px",
    minWidth: "25px",
    "&:focus": {
      backgroundColor: "transparent",
    },
    "& img": {
      width: "100%",
    },
  },
  "& .minusImgfilter": {
    filter:
      "invert(31%) sepia(85%) hue-rotate(292deg) brightness(100%) contrast(100%)",
  },
  "& .age-slider": {
    marginTop: "50px",
    "& .MuiSlider-rail": {
      backgroundColor: "#EEEEEE",
      height: "6px",
    },
    "& .MuiSlider-track": {
      height: "6px",
      background:
        "linear-gradient(90deg, rgb(244, 117, 164) -0.78%, rgb(201, 60, 112) 100%)",
      border: "none",
    },
    "& .MuiSlider-valueLabel": {
      transform: "translateY(-100%)",
      fontSize: "14px",
      fontWeight: "300",
      fontFamily: "Montserrat",
      color: theme.palette.black.main,
      backgroundColor: theme.palette.white.main,
      borderRadius: "50%",
      width: "32px",
      height: "32px",
      border: "1px solid",
      borderColor: theme.palette.pink.main,
      "&:before": {
        display: "none",
      },
    },
    "& .MuiSlider-thumb": {
      width: "13px",
      height: "13px",
      "&:before": {
        width: "13px",
        height: "13px",
        background: "#F475A4",
        boxShadow: "0px 0px 15px 0px #E276BE54",
      },
      "&:after": {
        width: "13px",
        height: "13px",
        background: "#CB3F75",
        boxShadow: "0px 0px 15px 0px #C93C7054",
      },
    },
  },
  "& .stepper-booking": {
    maxWidth: "640px",
    margin: "0px auto 20px",
    "@media (max-width: 600px)": {
      margin: "0px auto 28px",
      flexDirection: "column",
    },
    "& .MuiStepLabel-label": {
      fontSize: "16px",
      fontWeight: "500",
      fontFamily: "Montserrat",
      color: "#B8B8B8",
      "@media (max-width: 600px)": {
        fontSize: "13px",
      },
      "&.Mui-active": {
        color: theme.palette.pink.main,
      },
      "&:not(.Mui-disabled)": {
        color: theme.palette.pink.main,
      },
    },
    "& .MuiStepConnector-horizontal": {
      "& .MuiStepConnector-line": {
        position: "relative",
        borderTopStyle: "dashed",
        borderColor: "#B8B8B8",
        "&:before": {
          content: '""',
          position: "absolute",
          top: "-1px",
          left: 0,
          width: "100%",
          height: "1px",
          background: "transparent",
          zIndex: 2,
        },
      },
      "&.Mui-active": {
        "& .MuiStepConnector-line": {
          borderColor: theme.palette.pink.main,
          "&:before": {
            background: "transparent",
          },
        },
      },
      "&:not(.Mui-disabled)": {
        "& .MuiStepConnector-line": {
          borderColor: theme.palette.pink.main,
          "&:before": {
            background: "transparent",
          },
        },
      },
    },
    "& .MuiStepLabel-iconContainer": {
      "& svg": {
        "& circle": {
          fill: "#F4F4F4",
        },
        "& text": {
          fill: "#B8B8B8",
        },
      },
      "&.Mui-active": {
        "& svg": {
          "& circle": {
            fill: theme.palette.pink.main,
          },
          "& text": {
            fill: theme.palette.white.main,
          },
        },
      },
      "&:not(.Mui-disabled)": {
        "& svg": {
          "& circle": {
            fill: theme.palette.pink.main,
          },
          "& text": {
            fill: theme.palette.white.main,
          },
        },
      },
    },
  },
  "& .date-picker-field": {
    width: "100%",
    fontSize: "14px",
    // color: "#C3C3C3",
    fontFamily: "Montserrat",
    fontWeight: "400",
    border: "1px solid #C1C1C1",
    borderRadius: "11px",
    lineHeight: "22px",
    padding: "16px 21px",
    color: theme.palette.black.main,
    backgroundColor: "transparent",
  },
  "& .timeline-graph": {
    "& .MuiTimelineConnector-root": {
      display: "none",
    },
    "& .MuiTimelineDot-root": {
      margin: 0,
      width: "65px",
      height: "65px",
      borderRadius: "16px",
      padding: "0",
      border: "none",
      backgroundColor: theme.palette.white.main,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: "0px 4px 21px 0px #00000008",
      "& .timeline-step": {
        width: "40px",
        height: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: " 9px",
        backgroundColor: theme.palette.pink.main,
        fontSize: "16px",
        fontWeight: "500",
        fontFamily: "Montserrat",
        color: theme.palette.white.main,
      },
    },
    "& .timeline-content": {
      // maxWidth: "500px",
      borderRadius: "16px",
      backgroundColor: theme.palette.white.main,
      padding: "17px 24px",
      marginTop: "-27px",
      marginRight: "auto",
    },

    "& ul": {
      position: "relative",
      "&:before": {
        content: '""',
        position: "absolute",
        top: "10px",
        left: "50%",
        transform: "translateX(-50%)",
        width: "1px",
        height: "100%",
        backgroundColor: "#AAAAAA",
      },
      "& li": {
        marginBottom: "60px",
        "&:nth-child(2n)": {
          "& .timeline-content": {
            marginLeft: "auto",
          },
        },
      },
    },
    "& .timeline-heading": {
      fontSize: "16px",
      fontFamily: "Montserrat",
      fontWeight: "400",
      color: theme.palette.pink.main,
      marginBottom: "12px",
    },
    "& .timeline-desc": {
      fontSize: "16px",
      fontFamily: "Montserrat",
      fontWeight: "400",
      color: "#818181",
      marginBottom: "12px",
      lineHeight: "19px",
    },
  },
  "& .timeline-btn": {
    padding: "9px 25px",
    fontSize: "16px",
    fontWeight: "600",
    fontFamily: "Montserrat",
    color: "#F475A4",
    border: "1px solid #F475A4",
    backgroundColor: theme.palette.white.main,
    borderRadius: "8px",
    textTransform: "capitalize",
    "& svg": {
      marginLeft: "9px",
      fill: "#A0A0A0",
    },
  },
  "& .profile-img-2": {
    width: "228px",
    height: "225px",
    borderRadius: "10%",
    objectFit: "cover",
    "@media (max-width: 1100px)": {
      width: "80px",
      height: "80px",
    },
  },
  "& .custom-pagination": {
    "& li": {
      "& button": {
        width: "32px",
        height: "32px",
        borderRadius: "4px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.white.main,
        boxShadow: "0px 10px 10px 0px #0000000D",
        border: "1px solid",
        borderColor: theme.palette.white.main,
        fontSize: "11px",
        margin: "0 4px",
        color: theme.palette.black.main,
        "&.MuiPaginationItem-page.Mui-selected": {
          backgroundColor: theme.palette.white.main,
          borderColor: theme.palette.pink.main,
        },
        "&.MuiPaginationItem-previousNext": {
          backgroundColor: theme.palette.pink.main,
          color: theme.palette.white.main,
          borderColor: theme.palette.pink.main,
        },
      },
      "&:first-child": {
        "&.MuiPaginationItem-previousNext": {
          marginRight: "19px",
        },
      },
      "&:last-child": {
        "&.MuiPaginationItem-previousNext": {
          marginLeft: "19px",
        },
      },
    },
  },
  "& .white-space": {
    whiteSpace: "nowrap",
  },
  "& .page-text": {
    fontSize: "16px",
    fontFamily: "Montserrat",
    color: theme.palette.black.main,
    opacity: 0.63,
  },
  "& .not-assigned": {
    backgroundColor: "#FFF5DA",
    padding: "12px 51px",
    width: "55%",
    borderRadius: "28px",
  },
  "& .not-assigned-wrapper h4": {
    fontSize: "20px",
    fontWeight: "600",
    color: "#ECAA00",
  },
  "& .not-assigned-wrapper p": {
    fontSize: "16px",
    fontWeight: "500",
    color: "#595959",
  },
  "& .pending-icon img": {
    width: "54px",
    height: "54px",
  },
  "& .time-picker-field": {
    "& .MuiTypography-root": {
      fontSize: "14px",
      color: "#C3C3C3",
      fontFamily: "Montserrat",
      fontWeight: "400",
    },
    input: {
      lineHeight: "22px",
      fontSize: "14px",
      border: "1px solid #C1C1C1",
      borderRadius: "11px",
      fontWeight: "400",
      color: theme.palette.black.main,
      fontFamily: "Montserrat",
      backgroundColor: "transparent",
      padding: "16px 21px",
    },
  },
  "& .btn-save": {
    minWidth: "130px",
    padding: "15px 10px",
    border: "1px solid",
    borderColor: theme.palette.pink.main,
    borderRadius: "8px",
    fontSize: "14px",
    color: theme.palette.pink.main,
    backgroundColor: theme.palette.white.main,
    fontFamily: "Montserrat",
  },
  "& .delete-feature-btn": {
    minWidth: "166px",
    padding: "15px 10px",
    border: "1px solid",
    borderColor: "#EDEDED",
    borderRadius: "8px",
    fontSize: "14px",
    color: "#7E7E7E",
    fontWeight: "400",
    textTransform: "capitalize",
    boxShadow: "none",
    backgroundColor: "#EDEDED",
    fontFamily: "Montserrat",
  },
  "& .active-toggle": {
    "& .MuiSwitch-sizeMedium": {
      marginBottom: "0px",
      marginTop: "0px",
    },
    "& .MuiFormControlLabel-label": {
      fontSize: "12px",
      color: theme.palette.black.main,
      fontFamily: "Montserrat",
    },
  },
  "& .back-btn": {
    backgroundColor: "#F4F4F4",
    minWidth: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    marginBottom: "21px",
    "&.step-back-btn": {
      position: "absolute",
      top: "30px",
      left: "50px",
      "@media (max-width: 600px)": {
        top: "3px",
        left: "3px",
      },
    },
    "& svg": {
      fill: "#3A3A3A",
    },
    "&.profile-back": {
      position: "absolute",
      top: 0,
      left: 0,
    },
  },
  "& .feature-add-image": {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "165px",
    height: "133px",
    width: "100%",
    borderRadius: "16px",
    backgroundColor: "#EEEEEE",
    fontSize: "14px",
    color: "#7C7C7C",
    gap: "5px",
    fontFamily: "Montserrat",
    cursor: "pointer",
    marginBottom: "38px",
    "& svg": {
      fill: "#B1B1B1",
    },
  },
  "& .service-text": {
    fontSize: "20px",
    fontWeight: "400",
    fontFamily: "Montserrat",
    color: theme.palette.black.main,
    lineHeight: "1",
    "@media (max-width: 600px)": {
      fontSize: "14px",
    },
  },
  "& .status-text": {
    fontSize: "19px",
    fontWeight: "400",
    fontFamily: "Montserrat",
    border: "1px solid #dddddd",
    boxShadow: "rgba(0, 0, 0, 0.23) 0px 4px 25px 0px",
    padding: "10px",
    borderRadius: "8px",
    "@media (max-width: 600px)": {
      fontSize: "14px",
      padding: "5px",
    },
    color: theme.palette.black.main,
    "& .completed": {
      color: theme.palette.green.main,
    },
    "& .cancelled": {
      color: theme.palette.red.main,
    },
  },
  "& .Advertisement": {
    borderRadius: " 16px",
    background: "linear-gradient(90deg, #F475A4 38.96%, #DD5084 99.94%)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "end",
    "& .MuiButtonBase-root": {
      background: theme.palette.white.main,
      color: theme.palette.pink.main,
    },
  },
  "& .book-tab-wrapper": {
    "& .MuiTabs-flexContainer": {
      gap: "21px",
    },
    "& .book-tab": {
      backgroundColor: theme.palette.white.main,
      border: "1px solid #e1e1e1",
      borderRadius: "8px",
      padding: "7px 26px",
      fontSize: "16px",
      color: theme.palette.gray.main400,
      letterSpacing: "0.5px",
      fontFamily: "Montserrat",
      fontWeight: "400",
      transition: ".4s",
      textTransform: "capitalize",
      "&.Mui-selected": {
        border: "1px solid",
        borderColor: theme.palette.pink.main,
        backgroundColor: theme.palette.pink.main,
        color: theme.palette.white.main,
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
  "& .tab-content-wrapper > .MuiBox-root": {
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  "& .book-selectbx": {
    "& .MuiInputBase-root": {
      borderRadius: "8px",
      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.pink.main,
        },
      },
    },
    "& label": {
      fontSize: "16px",
      color: theme.palette.gray.main400,
      "&.Mui-focused": {
        color: theme.palette.pink.main,
      },
      "&.MuiFormLabel-filled": {
        color: theme.palette.pink.main,
      },
    },
    "& .MuiSelect-select": {
      backgroundColor: theme.palette.white.main,
      fontSize: "16px",
      color: theme.palette.gray.main400,
    },
  },
  "& .service-provider-name": {
    fontSize: "20px",
    color: theme.palette.black.main,
    fontWeight: "500",
    marginBottom: "12px",
    fontFamily: "Montserrat",
  },
  "& .service-provider-bio": {
    fontSize: "14px",
    color: "#555555",
    fontWeight: "500",
    marginBottom: "12px",
    fontFamily: "Montserrat",
  },
  "& .profile-page-header": {
    padding: "28px",
    textAlign: "center",
    boxShadow: "0px 4px 21px 0px #00000008",
    borderRadius: "48px 48px 0 0",
    backgroundColor: theme.palette.white.main,
    "& .profile-heading": {
      fontSize: "20px",
      fontWeight: "500",
      fontFamily: "Montserrat",
      color: "#969696",
    },
  },
  "& .edit-profile": {
    width: "100px",
    height: "100px",
    objectFit: "cover",
    borderRadius: "10px",
    boxShadow: "0px 4px 21px 0px #0000000F",
  },
  // "& .service-text": {
  //   fontSize: "20px",
  //   color: theme.palette.black.main,
  //   fontFamily: "Montserrat",
  //   marginBottom: "20px",
  // },
  "& .view-profile": {
    display: "inline-block",
    minWidth: "86px",
    padding: "12px 10px",
    border: "1px solid",
    borderRadius: "8px",
    borderColor: theme.palette.pink.main,
    fontFamily: "Montserrat",
    color: theme.palette.pink.main,
    fontSize: "14px",
    textAlign: "center",
    textDecoration: "none",
    transition: "all 0.3s ease",
    "&:hover": {
      backgroundColor: theme.palette.pink.main, // Apply hover background color
      color: "white", // Change text color on hover
      cursor: "pointer", // Change cursor to pointer on hover
    },
  },
  "& .no-track-color": {
    pointerEvents: "none",
    border: "1px solid grey!important",
    color: "grey!important",
  },
  "& .dashboard-table-wrapper.mh-1": {
    minHeight: "1px",
  },
  "& .dashboard-table-wrapper": {
    backgroundColor: "#fff",
    minHeight: "70vh",
    borderRadius: "16px",
    boxShadow: "none",
    "& .dashboard-table": {
      // backgroundColor: "transparent",
      boxShadow: "none",
      "& thead": {
        "& th": {
          color: theme.palette.gray.main700,
          fontFamily: "Montserrat",
          fontSize: " 15px",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: " 13px",
          minHeight: "75px",
          minWidth: "66px",
          "@media (max-width: 900px)": {
            fontSize: "13px",
          },
        },
      },
      "& .minh-1": {
        minHeight: "1px",
      },
      "& .no-data-text": {
        fontSize: "20px",
        fontWeight: "600",
        color: "#000",
      },
      "& .language": {
        maxWidth: "unset",
        minWidth: "200px",
        fontSize: "14px",
        OverflowWrap: "anywhere",
      },

      "& .datetime": {
        "& span": {
          width: "150px",
          lineHeight: "23px",
          minHeight: "21px",
        },
        "& .weekday": {
          fontWeight: "bold",
          color: "#777",
        },
      },
      "& .Pending": {
        color: "#FFA639",
      },
      "& .Assigned": {
        color: "#009C35",
      },
      "& .Rejected": {
        color: "#FE2323",
      },
      "& .Active": {
        color: "#FFA639",
      },
      "& .Completed": {
        color: "#009C35",
      },
      "& .Cancelled": {
        color: "#FE2323",
      },
      "& .location": {
        "& span": {
          maxWidth: "184px",
          lineHeight: "23px",
          fontSize: "12px",
        },
      },
      "& .action-btn": {
        gap: "20px",
      },
      "& .view-profile": {
        display: "inline-block",
        minWidth: "86px",
        padding: "12px 10px",
        border: "1px solid",
        borderRadius: "8px",
        borderColor: theme.palette.pink.main,
        fontFamily: "Montserrat",
        color: theme.palette.pink.main,
        fontSize: "14px",
        textAlign: "center",
        textDecoration: "none",
        transition: "all 0.3s ease",
        "&:hover": {
          backgroundColor: theme.palette.pink.main, // Apply hover background color
          color: "white", // Change text color on hover
          cursor: "pointer", // Change cursor to pointer on hover
        },
      },
      "& .user-img": {
        width: "85px",
        "& div": {
          width: "85px",
          "& img": {
            width: "100%",
            borderRadius: "5px 0px 0px 5px",
          },
        },
      },
      "& tbody tr ": {
        borderRadius: "16px ",
        overflow: "hidden",
        backgroundColor: "#fff",
        boxShadow: "0px 8px 6px -3px #00000008",
        transition: "all 0.4s ease",
      },
      "& tbody tr:hover ": {
        backgroundColor: "#f1f1f1",
      },
      "& tbody tr td:first-child": {
        width: "85px",
        overflow: "hidden",
        "& div": {
          "& img": {
            width: "100%",
          },
        },
      },
      "& tbody tr td:last-child": {
        "& span": {
          borderRadius: "0px 16px 16px 0",
        },
      },
      "& td": {
        padding: "0",
        paddingRight: "10px",
        // borderBottom: "none",
        backgroundColor: "transparent",
        "& span": {
          position: "relative",
          minHeight: "75px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "transparent",
          color: theme.palette.gray.main700,
          fontFamily: "Montserrat",
          fontSize: " 14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: " 13px",
          //   boxShadow: "0px 8px 6px -3px #00000008",
          "@media (max-width: 900px)": {
            fontSize: "12px",
          },
        },
      },
      "& th": {
        paddingLeft: 0,
        paddingRight: 0,
        borderBottom: "none",
        backgroundColor: "transparent",
        "& span": {
          position: "relative",
          minHeight: "75px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "start",
          backgroundColor: "#fff",
          color: theme.palette.black,
          fontFamily: "Montserrat",
          fontSize: " 14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: " 13px",
          gap: "6px",
          padding: "0 20px",
          boxShadow: "0px 8px 6px -3px #00000008",
          zIndex: "2",
          "& span": {
            minHeight: "auto",
            flexDirection: "row",
            alignItems: "center",
            gap: "2px",
            fontSize: "12px",
            color: theme.palette.gray.main700,
            padding: 0,
            "& svg": {
              fill: theme.palette.yellow.main,
            },
          },
        },
      },
    },
  },
  "& .apply-btn": {
    width: "100%",
    padding: "10px 15px",
    fontSize: "16px",
    textAlign: "left",
    color: "#F475A4",
    fontWeight: 600,
    fontFamily: "Montserrat",
    backgroundColor: theme.palette.white.main,
    border: "1px solid",
    borderColor: theme.palette.pink.main,
    borderRadius: "16px",
    justifyContent: "start",
    marginBottom: "23px",
    textTransform: "capitalize",
  },
  "& .apply-btn:active": {
    backgroundColor: "unset !important",
  },
  "& .apply-btn span svg": {
    fontSize: "20px",
  },
  "& .payment-text": {
    width: "100%",
    display: "flex",
    justifyContent: "end",
    marginBottom: "25px",
  },

  "& .payment-btn": {
    backgroundColor: "#009C353B",
    color: "#009C35",
    fontSize: "14px",
    fontWeight: "600",
    borderRightColor: "unset",
    border: "unset",
    borderRadius: "48px",
    padding: "8px 30px",
    textAlign: "center",
  },

  "& .payment-unpaid": {
    backgroundColor: "#EB366C26",
    color: "#EB366C",
    fontSize: "14px",
    fontWeight: "600",
    borderRightColor: "unset",
    border: "unset",
    borderRadius: "48px",
    padding: "8px 30px",
    textAlign: "center",
  },

  "& .payment-other": {
    backgroundColor: "#de87055c",
    color: "#DE8705",
    fontSize: "14px",
    fontWeight: "600",
    borderRightColor: "unset",
    border: "unset",
    borderRadius: "48px",
    padding: "8px 30px",
    textAlign: "center",
  },

  // "& .payment-btn1": {
  //   backgroundColor: "#EB366C26", color: "#009C35", fontSize: "14px", fontWeight: "600", borderRightColor: "unset", border: "unset", borderRadius: "48px", padding: "8px 30px", textAlign: "center"
  // },
  "& .offer-dropdown": {
    border: "unset",
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
    transform: "none !important",
    minWidth: "416px",
    backgroundColor: "#fff",
    maxHeight: "273px",
    width: "155px !important",
    top: "58px !important",

    padding: "15px",
    borderRadius: "16px",
    position: "absolute",
  },
  "& .back-btn svg": {
    position: "absolute",
    top: "12px",
  },
  "& .offer-dropdown .form-check-input:active": {
    backgroundColor: "#F475A4 !important",
  },
  "& .offer-dropdown. form-check-input:active": {
    borderColor: "unset !important",
    boxShadow: "unset !important",
  },
  "& .offer-dropdown .dropdown-item:active": {
    backgroundColor: "unset !important",
  },
  "& .dropdown-toggle::after": {
    display: "none !important",
  },
  "& .search-field": {
    width: "88%",
    flex: "none",
    "& label.Mui-focused": {
      color: theme.palette.pink.main,
    },
    "& label.MuiFormLabel-filled": {
      color: theme.palette.pink.main,
    },
    "& input": {
      padding: "16px 20px",
      fontSize: "16px",
      fontFamily: "Montserrat",
      backgroundColor: theme.palette.white.main,
      color: theme.palette.black.main,
      borderRadius: "11px 0px 0 11px",
    },
    "&  .MuiOutlinedInput-notchedOutline": {
      borderRight: "none",
      borderRadius: "11px 0px 0 11px",
      border: "transparent",
      boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.07)",
      "&.Mui-focused": {
        borderColor: theme.palette.pink.main,
      },
    },
  },
  "& .search-btn": {
    backgroundColor: theme.palette.pink.main,
    borderRadius: "0px 11px 11px 0px",
    boxShadow: "none",
    "& svg": {
      width: "25px",
      height: "25px",
      fill: theme.palette.white.main,
    },
  },
  "& .max-w-550": {
    maxWidth: "550px",
  },

  "& .payment-dropdown a": {
    backgroundColor: "#F6F6F6",
    borderRadius: "9px",
    marginBottom: "5px",
    padding: "8px 16px",
    fontSize: "14px",
    fontWeight: 500,
  },
  "& .step-back-btn": {
    position: "relative",
    top: "36px",
    left: "10px",
    backgroundColor: "#F6F6F6",
    borderRadius: "50%",
    padding: "10px",
  },
  "& .discount-dropdown": {
    backgroundColor: "#fff",
    boxShadow: " 0px 4px 21px 0px #0000000F",
    padding: "29px 22px",
    borderRadius: "16px",
  },
  "& .offer-text h5": {
    color: "#F475A4",
    fontSize: "16px",
    fontWeight: 600,
    marginBottom: "5px",
  },

  "& .offer-text p": {
    color: "#000",
    fontSize: "14px",
    fontWeight: 500,
  },
  "& .offer-button input:active": {
    backgroundColor: "#F475A4",
  },
  "& .offer-button input:checked": {
    backgroundColor: "#F475A4 !important",
    borderColor: "#F475A4 !important",
  },
  "& .offer-button input:focus": {
    boxShadow: "none !important",
  },
  "& .step-back-btn svg": {
    verticalAlign: "middle",
  },
  "& .payment-dropdown a:hover": {
    backgroundColor: "#F475A4 !important",
    color: "#fff !important",
  },
  "& .payment-dropdown a:active": {
    backgroundColor: "#F475A4 !important",
    color: "#fff !important",
  },
  "& .total-table": {
    borderRadius: "16px",
    border: "1px dashed #B8B8B8",
    padding: "22px 30px",
    "@media (max-width: 600px)": {
      marginTop: "10px",
    },
    "& .total-text": {
      fontSize: "16px",
      color: theme.palette.black.main,
      fontWeight: 500,
      fontFamily: "Montserrat",
      marginBottom: "23px",
      lineHeight: "1",
      "@media (max-width: 600px)": {
        fontSize: "14px",
      },

    },
    "& .chat-icon": {
      marginLeft: "10px",
    },
    "& .total-col": {
      borderTop: "1px dashed #B8B8B8",
      paddingTop: "15px",
      "& .total-text": {
        marginBottom: "0px",
      },
    },
  },
  "& .request-sent-wrapper": {
    padding: "30px 0",
    textAlign: "center",
    "& .checkedimg": {
      width: "100%",
      maxWidth: "clamp(100px, 22vw, 170px)",
      marginBottom: "20px",
    },
    "& .request-sent-heading": {
      fontSize: "24px",
      fontWeight: "500",
      fontFamily: "Montserrat",
      color: theme.palette.black.main,
      marginBottom: "13px",
    },
    "& .text-body": {
      fontSize: "16px",
      fontWeight: "400",
      fontFamily: "Montserrat",
      color: theme.palette.black.main,
      marginBottom: "34px",
      lineHeight: "1",
    },
  },
  "& .notify-wrapper": {
    borderTop: "1px dashed #C0C0C0",
    paddingTop: "41px",
    textAlign: "center",
    "& img": {
      width: "18px",
    },
    "& .notify-text": {
      fontSize: "16px",
      fontWeight: "400",
      fontFamily: "Montserrat",
      color: theme.palette.black.main,
      "@media (max-width: 600px)": {
        fontSize: "14px",
      },
    },
  },
  "& .dashboard-table-wrapper2": {
    backgroundColor: "transparent",
    boxShadow: "none",
    marginBottom: "96px",
    "& .dashboard-table": {
      backgroundColor: "transparent",
      boxShadow: "none",
      "& thead": {
        "& th": {
          color: theme.palette.gray.main700,
          fontFamily: "Montserrat",
          fontSize: " 14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: " 13px",
          minHeight: "75px",
          maxWidth: "unset !importnat",
        },
      },
      "& .service-head": {
        paddingLeft: "20px",
        paddingRight: "20px",
      },
      "& .datetime": {
        "& span": {
          width: "130px",
          lineHeight: "23px",
        },
      },
      "& .Pending": {
        color: "#FFA639",
      },
      "& .Assigned": {
        color: "#009C35",
      },
      "& .Rejected": {
        color: "#FE2323",
      },
      "& .location": {
        "& span": {
          width: "300px",
          lineHeight: "23px",
        },
      },
      "& .action-btn": {
        gap: "20px",
      },
      "& .view-profile": {
        display: "inline-block",
        minWidth: "86px",
        padding: "12px 10px",
        border: "1px solid",
        borderRadius: "8px",
        borderColor: theme.palette.pink.main,
        fontFamily: "Montserrat",
        color: theme.palette.pink.main,
        fontSize: "14px",
        textAlign: "center",
        textDecoration: "none",
        transition: "all 0.3s ease",
        "&:hover": {
          backgroundColor: theme.palette.pink.main, // Apply hover background color
          color: "white", // Change text color on hover
          cursor: "pointer", // Change cursor to pointer on hover
        },
      },
      "& .user-img": {
        width: "85px",
        "& div": {
          width: "85px",
          "& img": {
            width: "100%",
          },
        },
      },
      "& tbody tr td:last-child": {
        "& span": {
          borderRadius: "0px 16px 16px 0",
        },
      },
      "& td": {
        paddingLeft: 0,
        paddingRight: 0,
        borderBottom: "none",
        backgroundColor: "transparent",
        "& span": {
          position: "relative",
          minHeight: "75px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#fff",
          color: theme.palette.gray.main700,
          fontFamily: "Montserrat",
          fontSize: " 14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: " 13px",
          boxShadow: "0px 8px 6px -3px #00000008",
          zIndex: "2",
        },
      },
      "& th": {
        paddingLeft: 0,
        paddingRight: 0,
        borderBottom: "none",
        backgroundColor: "transparent",
        "& span": {
          position: "relative",
          minHeight: "75px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "start",
          backgroundColor: "#fff",
          color: theme.palette.black,
          fontFamily: "Montserrat",
          fontSize: " 14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: " 13px",
          gap: "6px",
          padding: "0 20px",
          boxShadow: "0px 8px 6px -3px #00000008",
          zIndex: "2",
          "& span": {
            minHeight: "auto",
            flexDirection: "row",
            alignItems: "center",
            gap: "2px",
            fontSize: "12px",
            color: theme.palette.gray.main700,
            padding: 0,
            "& svg": {
              fill: theme.palette.yellow.main,
            },
          },
        },
      },
    },
  },
  "& .review-star": {
    fill: theme.palette.yellow.main,
  },
  "& .dropdown-item:hover": {
    backgroundColor: "unset !important",
  },
  "& .inner-review-card": {
    padding: "15px",
    border: "1px solid #D1D1D1",
    borderRadius: "16px",
    "& .inner-profile-img": {
      width: "24px",
      height: "24px",
      objecFit: "cover",
      borderRadius: "50%",
    },
  },
  "& .profile-banner-img": {
    borderRadius: "16px 16px 0 0",
    boxShadow: "0px 4px 21px 0px #0000000F",
    marginBottom: "-20px",
  },
  "& .review-card": {
    background: theme.palette.white.main,
    borderRadius: "16px",
    boxShadow: "0px 4px 20px 0px #00000008",
    "&.single-review-card": {
      borderRadius: "0px 0px 48px 48px",
    },
    "& .profile-img": {
      width: "230px",
      height: "230px",
      borderRadius: "22px",
      boxShadow: "0px 4px 21px 0px #0000000F",
      objectFit: "cover",
    },
    "& .dark-divider": {
      border: "1px dashed #C0C0C0",
    },
    "& .accountIcon": {
      width: "20px",
      height: "20px",
    },
    "& .reviewimg": {
      height: "74px",
      objectFit: "cover",
      width: "85px",
      borderRadius: "16px 0 0 16px",
    },
    "& .review-stars svg": {
      fill: "#d3d3d3",
      "&.active ": {
        fill: theme.palette.yellow.main,
      },
    },
    "& .review-text": {
      maxWidth: "443px",
      lineHeight: "20px",
      display: "-webkit-box",
      overflow: "hidden",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 2,
      textAlign: "left",
    },
  },
}))
const Flexbox = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "15px",
  flexWrap: "wrap",
  // "& .MuiTypography-body2": {
  //   color: theme.palette.grey.main,
  //   fontFamily: "Nunito Sans",
  //   fontSize: " 14px",
  //   fontStyle: "normal",
  //   fontWeight: "400",
  //   lineHeight: " 18px",
  // },
  "& .MuiTypography-body1": {
    color: theme.palette.pink.main,
    fontFamily: "Montserrat",
    fontSize: " 24px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: " 18px",
  },
  "& .Status.MuiBox-root": {
    background: theme.palette.white.main,
    boxShadow: "0px 4px 20px 2px rgba(0, 0, 0, 0.03)",
    borderRadius: "16px",
  },
}))
const Toprated1 = styled("div")(({ theme }) => ({
  padding: "45px 0",
  "& .MuiTypography-h2": {
    color: theme.palette.pink.main,
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: " normal",
  },
}))
const Toprated = styled("div")(({ theme }) => ({
  display: "flex",

  gap: "10px",
  img: {
    height: "113px",
    objectFit: "Cover",
    width: "100%",
    borderRadius: " 8px",
  },
  "& .flexdiv": {
    display: "flex",
    gap: "16px",
    padding: "16px",
    backgroundColor: theme.palette.white?.main,
    borderRadius: "8px",
  },
  "& .MuiTypography-root ": {
    fontFamily: "Poppins",
    fontSize: " 12px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: " normal",
    textAlign: "start",
  },
}))
const Upcoming = styled("div")(({ theme }) => ({
  "& .MuiTypography-h2": {
    color: theme.palette.pink.main,
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: " normal",
  },
  "& .Headingbox": {
    borderRadius: "16px 16px 0 0",

    display: "flex",
    justifyContent: "space-between",
    background: theme.palette.white.main,
    boxShadow: "0px 4px 27px 0px rgba(0, 0, 0, 0.07)",
    padding: "15px",
  },
  "& .ContentBox": {
    display: "flex",
    justifyContent: "space-between",
    padding: "15px",
    background: theme.palette.white.main,
    "& .MuiGrid-root": {
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
      marginTop: "20px",
    },
    "& .MuiButton-root": {
      borderColor: theme.palette.pink.main,
      color: theme.palette.pink.main,
      fontFamily: "Montserrat",
    },
  },
}))
const Timeline = styled("div")(({ theme }) => ({
  "& .MuiTimelineDot-root ": {
    display: "flex",
    width: " 40px",
    height: "40px",
    justifyContent: " center",
    alignItems: " center",
    background: theme.palette.pink.main,
    flexShrink: "0",
  },
  "& .MuiTypography-h2": {
    color: theme.palette.pink.main,
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: " normal",
  },
}))
export { Bookedlog, Overview, Flexbox, Toprated1, Toprated, Upcoming, Timeline }
