import React, { useState } from "react"
import styles from "./ChatUi.module.css"
import Form from "react-bootstrap/Form"
import {
  InputGroup,
  FormControl,
  Button,
  Row,
  Col,
  Container,
  Badge,
  Stack,
} from "react-bootstrap"
// import Chatimg from "../../images/Chatimg.png"
// import Elipse5 from "../../images/Ellipse5.png"
import dummy from "../../images/dummy-image.jpg"
import PreviewImage from "../common/previewImage"

interface imageProps {
  imageUpload: {
    mediaFiles: [] | any
    profilePic: string
  }
  isAdmin: string
}
const ChatImage = ({ imageUpload, isAdmin }: imageProps) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null)
  const [showModal, setShowModal] = useState<boolean>(false)

  const handleImageClick = (image: string) => {
    setSelectedImage(image)
    setShowModal(true)
  }

  const handleClose = () => {
    setShowModal(false)
    setSelectedImage(null)
  }

  return (
    <div
      className={`d-flex ${
        isAdmin ? "justify-content-end" : ""
      } mt-0 gap-0 w-100 ${styles["Chat-image-sec"]}`}
    >
      {imageUpload?.mediaFiles?.map((media: any, index: number) => (
        <img
          className={`${styles["image-upload-sec"]}`}
          onClick={() => handleImageClick(media)}
          key={index} // Ensure each img has a unique key
          src={media}
          style={{
            width: imageUpload.mediaFiles.length === 1 ? "150x" : "120px", // Different widths based on the number of images
            height: imageUpload.mediaFiles.length === 1 ? "150px" : "120px", // Different heights based on the number of images
            objectFit: "cover",
            marginTop: "30px",
          }}
        />
      ))}

      <PreviewImage
        selectedImage={selectedImage}
        handleClosePreview={handleClose}
      />

      {/* {imageUpload?.mediaFiles.length > 0 && (
        <img
          src={imageUpload?.profilePic || dummy}
          className={`${styles["chat-circle-img"]}`}
        />
      )} */}
    </div>
  )
}

export default ChatImage
