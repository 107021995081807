import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { RootState } from "../../app/store"

// const BASE_URL = "http://192.168.29.239:5000"
const BASE_URL = import.meta.env.VITE_REACT_APP_API_URL
// const BASE_URL = "https://mommyshelp-dev.ddns.net"
// const BASE_URL = "http://192.168.29.64:5000"
// const BASE_URL = "http://172.20.10.11:5000"

export const overViewApi = createApi({
  reducerPath: "overViewAuth",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/api/`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token
      if (token) {
        headers.set("authorization", `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: [
    "appointments",
    `upComingappointment`,
    `changePasswordTags`,
    `receivedReview`,
    `bookingCreate`,
    `loginUser`,
    `activejob`,
    "notifications",
    "stopservice",
    "countNotification",
    "otpverify",
    "updateTimeBooking",
  ],
  endpoints: (builder) => ({
    getGenratePost: builder.query<any, void>({
      query: (args: void) => "seeker/get-post-status-count",
      providesTags: [
        "upComingappointment",
        "bookingCreate",
        "countNotification",
        "notifications",
        "updateTimeBooking",
      ],
    }),

    getUpcomingBookings: builder.query<any, any>({
      query: () => ({
        url: "seeker/get-upcoming-confirmed-posts",
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
    }),

    getUserCount: builder.query<any, any>({
      query: () => ({
        url: "seeker/get-users-count",
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
    }),

    createPostApi: builder.mutation<any, any>({
      query: (payload) => ({
        url: "seeker/create-post",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["bookingCreate"],
    }),

    updatePostTime: builder.mutation<any, any>({
      query: (payload) => ({
        url: "seeker//update-booking",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["updateTimeBooking"],
    }),

    acceptGenrateBooking: builder.query<any, any>({
      query: (data) => ({
        url: `seeker/get-accepted-generated-posts?postId=${
          data?.id || ""
        }&pageNo=${data?.pageNo || ""}`,
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
      providesTags: [
        "bookingCreate",
        `notifications`,
        "loginUser",
        "updateTimeBooking",
      ],
    }),

    receivedReview: builder.query<any, any>({
      query: (data) => ({
        url: `seeker/get-providers-review?pageNo=${data?.pageNo}`,
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
      providesTags: ["receivedReview"],
    }),

    getOffers: builder.query<any, any>({
      query: (data) => ({
        url: `seeker/get-offers?limit=${data?.limit || ""}&offerId=${
          data?.id || ""
        }`,
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
    }),

    getJobService: builder.query<any, any>({
      query: (data) => ({
        url: `seeker/get-job-services?serviceId=${data?.id || ""}`,
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
    }),
    getReviewResent: builder.query<any, any>({
      query: (data) => ({
        url: `seeker/get-my-recent-provider`,
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
      providesTags: ["receivedReview"],
    }),

    createReviews: builder.mutation<any, any>({
      query: (data) => ({
        url: `seeker/create-update-providers-review`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [`receivedReview`],
    }),

    // overView

    getServiceSuggestion: builder.query<any, any>({
      query: (data) => ({
        url: `seeker/get-service-suggestions`,
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
    }),

    activePost: builder.query<any, any>({
      query: (data) => ({
        url: `seeker/get-active-posts?postId=${data?.postId || ""}`,
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
      providesTags: [
        "activejob",
        `notifications`,
        "loginUser",
        "stopservice",
        "otpverify",
      ],
    }),

    // overViewProfile

    getProviderList: builder.query<any, any>({
      query: (data) => ({
        url: `seeker/get-provider-profile-and-reviews?providerId=${data?.id}`,
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
    }),

    getPostTimeLine: builder.query<any, any>({
      query: (data) => ({
        url: `seeker/get-post-timeline?postId=${data?.id || ""}`,
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
      providesTags: [`notifications`, "activejob"],
    }),

    // booking history

    getBookingHistory: builder.query<any, any>({
      query: (data) => ({
        url: `seeker/get-booking-history?postStatus=${
          data?.postStatus || ""
        }&pageNo=${data?.pageNo || ""}&from=${data?.from || ""}&searchName=${
          data?.searchName || ""
        }&paymentStatus=${data?.paymentStatus}&pageLimit=${data?.pageLimit}`,
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
    }),

    // provider profile view

    getProviderView: builder.query<any, any>({
      query: (data) => ({
        url: `seeker/get-post?postId=${data?.postId || ""}&providerId=${
          data?.providerId || ""
        }`,
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
      providesTags: ["receivedReview"],
    }),

    // ratus

    createRatus: builder.mutation<any, any>({
      query: (data) => ({
        url: `seeker/create-feedback`,
        method: "POST",
        body: data,
      }),
    }),

    // old api data
    getBookingCount: builder.query<any, any>({
      query: () => ({
        url: "seeker/get-bookings-count",
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
    }),

    getReportCount: builder.query<any, any>({
      query: () => ({
        url: "seeker/get-reports-count",
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
    }),

    getReviews: builder.query<any, any>({
      query: () => ({
        url: "seeker/get-reviews?userType=SEEKER",
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
    }),

    getReviewsPro: builder.query<any, any>({
      query: () => ({
        url: "seeker/get-reviews?userType=PROVIDER",
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
    }),

    getProviderReviews: builder.query<any, any>({
      query: () => ({
        url: "provider/get-seekers-review",
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
    }),

    getBookingAll: builder.query<any, any>({
      query: (data) => ({
        url: `seeker/get-all-posts?postStatus=${data?.bookingType || ""}`,
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
        providesTags: ["appointments"],
      }),
    }),

    getSeekerList: builder.query<any, any>({
      query: (data) => ({
        url: `seeker/get-seekers-list`,
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
    }),

    getGenratedPostSeeker: builder.query<any, any>({
      query: (data) => ({
        url: `seeker/get-generated-posts?postId=${data?.id || " "} `,
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
    }),
    getProfileSeeker: builder.query<any, any>({
      query: (data) => ({
        url: `seeker/get-eligible-providers?postId=${data?.postIdProvider}`,
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
    }),

    postAcceptProvider: builder.mutation<any, any>({
      query: (data) => ({
        url: `seeker/accept-and-assign-post`,
        method: "POST",
        body: data,
      }),
    }),

    postCreateJob: builder.mutation<any, any>({
      query: (data) => ({
        url: `seeker/create-job-services`,
        method: "POST",
        body: data,
      }),
    }),

    // otp api
    genOtp: builder.mutation<any, any>({
      query: (data) => ({
        url: `seeker/gen-otp`,
        method: "POST",
        body: data,
      }),
    }),

    verifyOtp: builder.mutation<any, any>({
      query: (data) => ({
        url: `seeker/verify-otp`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["otpverify"],
    }),

    // language Api

    getLanguage: builder.query<any, any>({
      query: (data) => ({
        url: "seeker/get-country-info",
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
    }),

    discountOffers: builder.query<any, void>({
      query: (data) => ({
        url: "seeker/get-offers",
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
    }),

    // getLanguage: builder.query<any, any>({
    //   query: (data) => ({
    //     url: "seeker/country-language",
    //     method: "GET",
    //     onSuccess: (data: any) => {
    //       return data
    //     },
    //   }),
    // }),

    stopService: builder.mutation<any, any>({
      query: (data) => ({
        url: "seeker/stop-service",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["stopservice"],
    }),

    invoicePdDownload: builder.mutation<any, any>({
      query: (data) => ({
        url: "seeker/downlod-file",
        method: "POST",
        body: data,
      }),
    }),

    // get invoice

    getiNvoice: builder.query<any, any>({
      query: (data) => ({
        url: `seeker/get-invoice-detail?pageNo=${data?.pageNo || ""}&timeZone=${
          data?.timeZone || ""
        }&filterDate=${data?.filterDate || ""}&pageLimit=${
          data?.pageLimit || ""
        }`,
        method: "GET",
      }),
    }),

    protected: builder.mutation<{ message: string }, void>({
      query: () => "protected",
    }),
  }),
})

export const {
  useGetGenratePostQuery,
  useGetUpcomingBookingsQuery,
  useCreatePostApiMutation,
  useUpdatePostTimeMutation,
  useReceivedReviewQuery,
  useAcceptGenrateBookingQuery,
  useGetOffersQuery,
  useGetJobServiceQuery,
  useGetReviewResentQuery,
  useCreateReviewsMutation,
  useGetServiceSuggestionQuery,
  useGetProviderListQuery,
  useGetPostTimeLineQuery,
  useGetBookingHistoryQuery,
  useGetProviderViewQuery,
  useCreateRatusMutation,
  useActivePostQuery,
  useGetUserCountQuery,
  useGetBookingCountQuery,
  useGetReportCountQuery,
  useGetReviewsQuery,
  useGetReviewsProQuery,
  useGetProviderReviewsQuery,
  useGetBookingAllQuery,
  useGetSeekerListQuery,
  useGetGenratedPostSeekerQuery,
  useGetProfileSeekerQuery,
  usePostAcceptProviderMutation,
  usePostCreateJobMutation,
  useGenOtpMutation,
  useVerifyOtpMutation,
  useGetLanguageQuery,
  useDiscountOffersQuery,
  useStopServiceMutation,
  useInvoicePdDownloadMutation,
  useGetiNvoiceQuery,
} = overViewApi
