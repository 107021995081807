import React, { useEffect, useState } from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import { DemoItem } from "@mui/x-date-pickers/internals/demo"
import { CircularProgress, Grid, Input } from "@mui/material"
import Form from "react-bootstrap/Form"

import { toast } from "react-toastify"
import { bool, boolean } from "yup"
import "./style.css"
import minusImg from "../../../images/minus-fill.svg"
import PinkMinusImg from "../../../images/pink-minus-fill.svg"
import plusImg from "../../../images/plus-fill.svg"
import { useAppSelector } from "../../../app/hooks"
import moment from "moment"
import { useUpdatePostTimeMutation } from "../../../features/OverViewAuth/overViewApi"
import { setBookingDetails } from "../../../features/PostCreat/postcreateSlice"
import { useAppDispatch } from "../../../app/store"
import { useNavigate } from "react-router-dom"

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid #999",
  borderRadius: "20px",
  boxShadow: 24,
  width: "500px",
  height: "390px",
  p: 4,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}

const viewProfileBtn = {
  display: "inline-block",
  width: "120px",
  padding: "8px 8px",
  border: "1px solid #F475A4",
  borderRadius: "8px",
  color: "#F475A4",
  fontSize: "14px",
  textAlign: "center",
  textDecoration: "none",
}

const viewProfileBtnTwo = {
  display: "inline-block",
  width: "120px",
  padding: "8px 8px",

  borderRadius: "8px",

  fontSize: "14px",
  textAlign: "center",
  textDecoration: "none",
  backgroundColor: "#E4E4E4",
  color: "#7D7D7D",
  "&:hover": {
    color: "#F475A4",
  },
}
interface props {
  OpenModal: boolean
  handleClose: () => void
  bookingInfo: any
}

interface ErrorProps {
  startOnTimer: string | undefined
}
export default function TimeChangeModal({
  OpenModal,
  handleClose,
  bookingInfo,
}: props) {
  const [hoursSend, setHoursSend] = React.useState<number>(1)
  const [count, setCount] = React.useState<number>(1)
  const [selectedDate, setSelectedDate] = useState("")
  const [endTimeShow, setEndTimeShow] = useState("")

  const [bookingDetailsShow, setBookingDetailsShow] = useState(false)
  const [selectedStartTime, setSelectedStartTime] = useState("")
  const [startTimeOptions, setStartTimeOptions] = useState([])
  const [endTimeOptions, setEndTimeOptions] = useState([])
  const [selectedEndTime, setSelectedEndTime] = useState("")
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [errorMsg, setErrorMsg] = useState<ErrorProps>({
    startOnTimer: "",
  })

  const postDetails = useAppSelector(
    (state: any) => state.postCreate.bookingDetails,
  )

  const handleNoClick = () => {
    handleClose()
    dispatch(setBookingDetails(null as any))
    setHoursSend(1)
  }
  const handleIncrement = (key: any) => {
    if (key == "count" && count < 7) {
      setCount(count + 1)
    } else if (key == "hours" && hoursSend < 12) {
      setHoursSend(hoursSend + 1)
    }
  }

  // change time code

  const handleStartTimeChange = (e: any) => {
    const newStartTime = e.target.value
    setSelectedStartTime(newStartTime)
    setErrorMsg((prevErrors) => ({
      ...prevErrors,
      startOnTimer: "",
    }))
    updateEndTimeOptions(newStartTime)
  }

  const updateAvailableTimes = (date: any, isInitialSetup = false) => {
    const times = generateTimes(date)
    setStartTimeOptions(times as any)
    if (times.length > 0) {
      const initialTime = times[0]

      setSelectedStartTime(postDetails?.timeUpdate || initialTime)

      if (isInitialSetup) {
        updateEndTimeOptions(initialTime, times as any)
      }
    }
  }

  const updateEndTimeOptions = (
    startTime: any,
    startTimes = startTimeOptions,
  ) => {
    if (startTime === "23:00") {
      setEndTimeOptions(["23:59"] as any)
      setSelectedEndTime("23:59")
    } else {
      const startTimeIndex = (startTimes as any).indexOf(startTime)
      const newEndTimeOptions = startTimes.slice(startTimeIndex + 1)
      setEndTimeOptions(newEndTimeOptions)
      if (newEndTimeOptions.length > 0) {
        setSelectedEndTime(newEndTimeOptions[0])
      } else {
        setSelectedEndTime("")
      }
    }
  }

  useEffect(() => {
    let initialDate
    if (postDetails?.from) {
      initialDate = moment(postDetails.from).format("YYYY-MM-DD")
    } else {
      const today = new Date()
      initialDate = today.toISOString().split("T")[0]
    }
    setSelectedDate(initialDate)
    updateAvailableTimes(initialDate, true)
  }, [postDetails?.from])
  const handleDateChange = (e: any) => {
    const newDate = e.target.value

    setSelectedDate(newDate)
    updateAvailableTimes(newDate, true)
  }

  useEffect(() => {
    const [hoursStr, minutesStr] = selectedStartTime?.split(":")

    let hours = parseInt(hoursStr, 10)

    hours = (hours + hoursSend) % 24

    const minutes = parseInt(minutesStr, 10)

    const newHoursStr = hours.toString().padStart(2, "0")
    const newTimeString = `${newHoursStr}:${minutesStr}`

    setEndTimeShow(newTimeString as any)
  }, [selectedStartTime, hoursSend])

  const generateTimes = (date: any) => {
    const times = []
    const now = new Date()
    const selectedDay = new Date(date)
    const isToday =
      now.toISOString().split("T")[0] ===
      selectedDay.toISOString().split("T")[0]

    let startHour = 0

    if (isToday) {
      if (now.getHours() >= 22 && now.getMinutes() >= 30) {
        // Current time is 10:30 PM or later, start from midnight of the next day
        selectedDay.setDate(selectedDay.getDate() + 1) // Move to the next day
        startHour = 0
      } else {
        // Start from the next hour
        startHour =
          now.getMinutes() < 30 ? now.getHours() + 1 : now.getHours() + 2
      }
    }

    for (let hour = startHour; hour < 24; hour++) {
      times.push(`${hour.toString().padStart(2, "0")}:00`)
    }
    return times
  }

  const handleDecriment = (key: any) => {
    if (key == "count" && count > 1) {
      setCount(count - 1)
    } else if (key == "hours" && hoursSend > 1) {
      setHoursSend(hoursSend - 1)
    }
  }

  // for change time post booking

  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async () => {
    setIsLoading(true) // Set loading to true when starting the submission

    try {
      // Combine date and time into a single Date object
      const selectedDateTimeString = `${selectedDate}T${selectedStartTime}`
      const selectedDateTime = new Date(selectedDateTimeString)

      // Convert to UTC string
      const utcString = selectedDateTime.toISOString()

      // Calculate end date and time
      const endDate = new Date(selectedDateTime)
      endDate.setHours(endDate.getHours() + hoursSend)

      // Convert updated end date to UTC string
      const updatedDateStr = endDate.toISOString()

      // Create payload
      const payload = {
        personRequired: bookingInfo?.personRequired,
        postPrice: bookingInfo?.service?.price, // Fixed to use postPrice instead of personRequired
        hours: hoursSend,
        from: utcString,
        till: updatedDateStr,
        postId: bookingInfo?._id,

        offerAmount: "",
        totalAmount: bookingInfo?.service?.price,
      }

      // Dispatch action
      const res: any = await dispatch(setBookingDetails(payload))

      if (res?.payload?.from) {
        navigate(`/dashboard/booking-overview?postId=${bookingInfo?._id}`)
      }
    } catch (err) {
      // Handle errors here
      console.error("Error submitting booking details:", err)
      toast.error(
        "An error occurred while submitting your booking. Please try again." as any,
      )
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <Modal
        open={OpenModal as any}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container>
          <Grid item xs={12}>
            <Box sx={style}>
              <Box className="d-flex flex-column gap-2 justify-content-center align-items-center">
                <Box>
                  <h5
                    style={{
                      fontSize: "20px",
                      fontWeight: "700",
                      marginBottom: "30px",
                      color: "#000",
                    }}
                  >
                    Change Service Timing
                  </h5>
                </Box>

                <Box className="d-flex align-items-center gap-1 gap-md-4 p-2 mb-0">
                  <Grid container>
                    <Grid item xs={12} sm={4} className="mb-0">
                      <Typography className="field-label mb-2 mt-3">
                        <span className="change-time">Select time</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Box className="d-flex gap-4">
                        <Box className="d-flex flex-column justify-content-center align-items-center gap-1">
                          <Box className="time-picker-field">
                            <DemoItem>
                              <select
                                disabled={bookingDetailsShow}
                                id="startTimeSelect"
                                className="date-field time-select-text"
                                value={selectedStartTime}
                                onChange={handleStartTimeChange}

                                // {...register("from")} // Register "from" field
                              >
                                {startTimeOptions.map((time, index) => (
                                  <option
                                    key={index}
                                    value={time}
                                    style={{
                                      padding: "10px",
                                    }}
                                  >
                                    {time}
                                  </option>
                                ))}
                              </select>
                            </DemoItem>
                            <p className="text-danger error-text mb-0">
                              {errorMsg?.startOnTimer}
                            </p>
                          </Box>
                          <Box>
                            {" "}
                            <Typography className="field-label font-14">
                              Start time
                            </Typography>
                          </Box>
                        </Box>

                        <Box className="d-flex flex-column gap-2 justify-content-center align-items-center">
                          <Box className="d-flex align-items-center gap-1">
                            <Button
                              className="control-btn p-0 bg-transparent minus-btn"
                              onClick={() => handleDecriment("hours")}
                            >
                              <img
                                src={hoursSend == 1 ? minusImg : PinkMinusImg}
                                className=""
                              />
                            </Button>
                            <Input
                              type="text"
                              inputMode="numeric"
                              placeholder="Give title to service"
                              className="feature-field service-provider-field count-field d-flex font-16 select-time-field hours-text modal-hours-text"
                              value={hoursSend}
                            />
                            <Button
                              className="control-btn p-0 bg-transparent plus-btn"
                              onClick={() => handleIncrement("hours")}
                            >
                              <img src={plusImg} />
                            </Button>
                          </Box>
                          <Box>
                            {" "}
                            <Typography className="field-label font-14">
                              Hours{" "}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box className="d-flex align-items-center gap-2 p-2 mt-md-2 mb-0">
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={4} sm={2}>
                      {/* <Typography className="field-label ">
                        Total Time
                      </Typography> */}
                    </Grid>
                    <Grid item xs={8} sm={10} alignItems="center">
                      <Input
                        disabled={bookingDetailsShow}
                        placeholder="total time"
                        className="feature-field font-16 total-time pe-none time-required"
                        fullWidth
                        // value="20pm - 3am"asd
                        value={selectedStartTime + " - " + endTimeShow}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between !important",
                  gap: 2,
                  marginTop: "20px",
                }}
              >
                <Button
                  sx={viewProfileBtnTwo}
                  className="me-2"
                  onClick={handleNoClick}
                >
                  Cancel
                </Button>
                <Button sx={viewProfileBtn} onClick={onSubmit}>
                  {isLoading ? (
                    <Typography fontSize={1}>
                      <CircularProgress
                        style={{ color: "pink", fontSize: 20 }}
                        size={20}
                      />
                    </Typography>
                  ) : (
                    "Save"
                  )}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Modal>
    </div>
  )
}
