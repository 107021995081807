import React from "react"
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Rating,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"
import { LiaAngleRightSolid } from "react-icons/lia"
import * as Style from "../style"
import moment from "moment"

import { FaCirclePlus } from "react-icons/fa6"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import whiteLogo from "../../../images/logo-white.svg"
import offerImg from "../../../images/ads.png"
import { useGetOffersQuery } from "../../../features/OverViewAuth/overViewApi"
import CircularIndeterminate from "../../Loading/Loading"
import CenteredSpinner from "../../common/CenteredSpinner"
import OfferPage from "./OfferPage"
const Offers = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const {
    data: getOffers,
    isFetching,
    isLoading,
  } = useGetOffersQuery({ limit: 100 })

  return (
    <>
      {" "}
      {isFetching || isLoading ? (
        <CenteredSpinner />
      ) : (
        <Box className="pt-5 px-md-5">
          <Style.Overview>
            <Typography
              variant="body1"
              className="font-25 pink-text mb-1 fw-semibold"
            >
              Offers
            </Typography>
            {/* <Grid container className="mb-4">
            <Grid md={3} className="mb-4">
              <Box className="pe-md-4 h-100">
                <Box className="feature-card h-100 d-flex justify-content-center align-items-center">
                  <Box className="feature-card-content text-center">
                    <Typography variant="body1 text-black mb-3 font-15">
                      20% OFF on Booking 2 services
                    </Typography>
                    <Typography variant="body1 mb-0">
                      Valid till 31st July
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid md={3} className="mb-4">
              <Box className="pe-md-4 h-100">
                <Box className="feature-card h-100 d-flex justify-content-center align-items-center">
                  <Box className="feature-card-content text-center">
                    <Typography variant="body1 text-black mb-3 font-15">
                      20% OFF on Booking 2 services
                    </Typography>
                    <Typography variant="body1 mb-0">
                      Valid till 31st July
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid md={3} className="mb-4">
              <Box className="pe-md-4 h-100">
                <Box className="feature-card h-100 d-flex justify-content-center align-items-center">
                  <Box className="feature-card-content text-center">
                    <Typography variant="body1 text-black mb-3 font-15">
                      20% OFF on Booking 2 services
                    </Typography>
                    <Typography variant="body1 mb-0">
                      Valid till 31st July
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid> */}
            <Grid container className="mb-4">
              {getOffers?.data?.map((item: any) => (
                <Grid item xs={12} xl={6}>
                  <Box>
                    {/* <Typography align="left" variant="h2" mb={2}>
                  Special Offers
                </Typography> */}
                    <Box className="offer-box me-2 mb-2">
                      <Box className="offer-box-content">
                        <img src={whiteLogo} className="logo-white" />
                        <Typography className="off-discount">
                          {item?.offerAmount}% OFF
                        </Typography>
                        <Typography className="valid-text">
                          Valid upto
                        </Typography>
                        <Typography className="mb-4 valid-date-text">
                          {moment(item?.validDetails?.expiryDate).format(
                            "DD MMMM",
                          )}
                        </Typography>
                        <Typography className="book-text">
                          Book Your Services Now!
                        </Typography>
                        <Link
                          to={`/dashboard/create-booking?type=${item?._id}`}
                          className="book-now-btn"
                        >
                          Book Now
                        </Link>
                      </Box>
                      <img src={item?.image} className="offer-img" />
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
            {getOffers?.data?.length > 0 && (
              <Typography variant="body1" className="mb-4">
                Showing {getOffers?.data?.length} elements
              </Typography>
            )}
            <OfferPage />
          </Style.Overview>
        </Box>
      )}
    </>
  )
}
export default Offers
