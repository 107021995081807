import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { overViewApi } from "./overViewApi"
import { notificationsApi } from "../Socket/Socketio"
import { api } from "../Auth/authApi"

const slice = createSlice({
  name: "overViewAuth",
  initialState: {
    user: null,
    token: null,
    alreadyRated: null,
    offersSelect: null,
    currenTimeActive: null,
    newMessage: null,
  } as {
    user: null | any
    token: null | string
    alreadyRated: null | string
    offersSelect: null | string
    currenTimeActive: null | string
    newMessage: null | any
  },
  reducers: {
    token: (state: any, action: any) => {
      state.user = null
      state.token = action.payload
    },

    alreadyRated: (state: any, action: any) => {
      state.user = null
      state.token = action.payload
    },

    offersSelect: (state: any, action: any) => {
      state.user = null
      state.offersSelect = action.payload
    },
    setCurrenTimeActive: (state: any, action: any) => {
      state.currenTimeActive = null
      state.currenTimeActive = action.payload
    },

    setnewMessage: (state: any, action: any) => {
      state.newMessage = null
      state.newMessage = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      overViewApi.endpoints.getUserCount.matchFulfilled,
      (state, { payload }) => {
        state.token = payload.token
        state = { ...state }
      },
    )
    builder.addMatcher(
      overViewApi.endpoints.createRatus.matchFulfilled,
      (state, { payload }) => {
        state.alreadyRated = payload.alreadyRated
      },
    )

    builder.addMatcher(
      overViewApi.endpoints.getOffers.matchFulfilled,
      (state, { payload }) => {
        state.offersSelect = payload.offersSelect
      },
    )
    builder.addMatcher(
      overViewApi.endpoints.verifyOtp.matchFulfilled,
      (state, { payload }) => {
        state.currenTimeActive = payload
      },
    )
    builder.addMatcher(
      api.endpoints.logoutCustomer.matchFulfilled,
      (state, { payload }) => {
        // state._searchedTickets = null
        // state._search = null
      },
    )
  },
})

export default slice.reducer
export const {
  token,
  alreadyRated,
  offersSelect,
  setCurrenTimeActive,
  setnewMessage,
} = slice.actions

export const selectCurrentUser = (state: RootState) => state?.overViewAuth
