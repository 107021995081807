import React, { Fragment, memo, useEffect, useRef, useState } from "react"
import styles from "./ChatUi.module.css"
import { FiCheck } from "react-icons/fi"
import Logo from "../../images/momy_logo.svg"
import ChatImage from "./ChatImage"
import { Message, Ticket } from "../ChatenterFace/chatinterface"
import { CalculateTimeDifferenceChat } from "../Notification/timeNotification"
import { useAppSelector, useAppDispatch } from "../../app/hooks"
import { useSearchParams } from "react-router-dom"
import { Button, CircularProgress, Typography } from "@mui/material"
import {
  useGetChatsMessageUpdateQuery,
  useRemoveSeenCountMutation,
} from "../../features/ChatAuth/chatApi"
import { _setMessage, _setUnseenCount } from "../../features/ChatAuth/chatSlice"
import NullScreen from "../common/NullScreen"
import DummyChatSecreen from "../AdminLayout/dummyChatSecreen"

interface DialogueProps {
  setMessageLoading: (value: boolean) => void
  scrollLimit: number
  loadingMore: boolean
  topOfChatRef: any
}

const Dialogue: React.FC<DialogueProps> = ({
  setMessageLoading,
  scrollLimit,
  loadingMore,
  topOfChatRef,
}: DialogueProps) => {
  const [searchParams] = useSearchParams()
  const _chatId = searchParams.get("chatId")
  const dispatch = useAppDispatch()
  const [countRemove] = useRemoveSeenCountMutation()
  const [shouldFetch, setShouldFetch] = useState(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)

  const state: any = useAppSelector((state) => state.authChatView)
  const TicketPath: Ticket[] =
    state._search.split("").length > 0 ? state._searchedTickets : state._Tickets
  const selectedUser = TicketPath.find((user) => user._id === _chatId)
  const messagesEndRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldFetch(true)
    }, 1000) // 2000ms = 2 seconds

    return () => clearTimeout(timer)
  }, [])

  const {
    data: allMessages,
    isLoading,
    isFetching,
  } = useGetChatsMessageUpdateQuery(
    { pageNo: 1, pageLimit: scrollLimit, ticketId: _chatId },
    {
      skip: !_chatId || !shouldFetch,
      refetchOnMountOrArgChange: true,
    },
  )

  useEffect(() => {
    if (isLoading || !shouldFetch) {
      setLoading(true)
      setError(null)
    } else {
      if (allMessages) {
        dispatch(_setMessage(allMessages.data ?? []))
        setLoading(false)
      } else {
        setLoading(false)
        setError("No messages found.")
      }
    }
  }, [isLoading, allMessages, dispatch])

  const TicketRedux: Ticket[] = useAppSelector(
    (state) => state.authChatView?._Tickets,
  )
  const ticket = TicketRedux?.find((ticket) => ticket._id === _chatId)
  const messages = ticket?.messages || []

  const [lastProcessedMessageId, setLastProcessedMessageId] = useState<
    string | null
  >(null)
  useEffect(() => {
    const lastMessage = messages[messages.length - 1] || {}
    if (
      lastMessage.ticketId === _chatId &&
      lastMessage._id !== lastProcessedMessageId
    ) {
      const payload = {
        userType: "ADMIN",
        ticketId: lastMessage.ticketId,
      }
      countRemove(payload)

      setLastProcessedMessageId(lastMessage._id)
    }
  }, [messages, _chatId, lastProcessedMessageId, countRemove])

  const findLastMessage = (messages: Message[]): Message | undefined => {
    if (!messages || messages.length === 0) return undefined

    const sortedMessages = messages.slice().sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    })

    return sortedMessages[0]
  }

  const lastMessage = findLastMessage(messages)

  useEffect(() => {
    if (lastMessage?.content) {
      dispatch(_setUnseenCount(_chatId as any))
    }
  }, [_chatId, lastMessage])

  useEffect(() => {
    if (!isLoading && allMessages) {
      setMessageLoading(false)
    }
  }, [allMessages, isLoading])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
      }
    }, 100)
    return () => clearTimeout(timer)
  }, [messages])

  return (
    <Fragment>
      {!_chatId ? (
        <DummyChatSecreen />
      ) : (
        <Fragment>
          <div>
            {loading || isFetching ? (
              <CircularProgress
                style={{
                  color: "pink",
                  fontSize: 24,
                  position: "absolute",
                  left: "50%",
                  top: loadingMore ? "none%" : "50%",
                }}
                size={24}
              />
            ) : (
              <Fragment>
                {messages.length ? (
                  messages.map((message: Message, index: number) => {
                    const isAdmin = message.sender.userType === "SEEKER"

                    return (
                      <div
                        key={index}
                        className={`${styles["messagesss"]} ${
                          isAdmin ? styles["align-self-end"] : ""
                        }`}
                        style={{
                          filter:
                            selectedUser?.status === "CLOSED"
                              ? "blur(4px)"
                              : "",
                        }}
                      >
                        <ChatImage
                          imageUpload={message}
                          isAdmin={isAdmin as any}
                        />
                        {isAdmin ? (
                          <>
                            <div
                              className={`position-relative right-2 ${styles["user"]} d-flex justify-content-end align-self-end gap-2 ms-5 mt-2`}
                            >
                              <div
                                className={`${
                                  message.content !== undefined &&
                                  styles["user-message"]
                                } ${
                                  message.content !== undefined &&
                                  styles["message"]
                                } ${
                                  message.content !== undefined &&
                                  styles["user-message"]
                                }`}
                              >
                                <div
                                  className={` ${
                                    message.content !== undefined &&
                                    styles["message-content"]
                                  }`}
                                >
                                  {""}
                                </div>
                                <div
                                  className={`${
                                    message.content !== undefined &&
                                    styles["lh-11"]
                                  } text-start`}
                                >
                                  <span
                                    className={`${
                                      message.content !== undefined &&
                                      styles["views-text"]
                                    } text-white`}
                                  >
                                    {message.content}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className={styles["chat-time"]}>
                              <CalculateTimeDifferenceChat
                                createdAts={message.createdAt}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className={`d-flex flex-column position-relative ${styles["chat-message"]}`}
                            >
                              <div
                                className={`position-absolute px-sm-1 pb-1 ${styles["chat-imgbx-sm"]} position-relative`}
                              >
                                <div
                                  className={styles["profile-pic-placeholder"]}
                                ></div>
                              </div>
                              <div
                                className={`${
                                  message.content !== undefined &&
                                  styles["message"]
                                } ${
                                  message.content !== undefined &&
                                  styles["other-message"]
                                }`}
                              >
                                <div className={styles["message-content"]}>
                                  {message.content}
                                </div>
                                <div className={`${styles["lh-11"]} text-end`}>
                                  <span className={styles["views-text"]}></span>
                                </div>
                              </div>
                              <div className={styles["other-msg-time"]}>
                                <CalculateTimeDifferenceChat
                                  createdAts={message.createdAt}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    )
                  })
                ) : (
                  <NullScreen />
                )}
                <div ref={scrollLimit <= 50 ? messagesEndRef : topOfChatRef} />
              </Fragment>
            )}
          </div>
        </Fragment>
      )}
    </Fragment>
  )
}

export default memo(Dialogue)
