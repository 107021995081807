import React from "react"
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import { FaStar } from "react-icons/fa6"
import * as Style from "./style"
import { Link, useParams } from "react-router-dom"
import tableCellImg from "../../images/table-img.png"
import { FaChevronRight } from "react-icons/fa6"
import {
  useAcceptGenrateBookingQuery,
  useGetBookingAllQuery,
  useGetPostTimeLineQuery,
  useGetProviderListQuery,
} from "../../features/OverViewAuth/overViewApi"
import {
  Timeline,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
  TimelineContent,
} from "@mui/lab"
import moment from "moment"
import CircularIndeterminate from "../Loading/Loading"
import {
  FormatChangeTime,
  FormatDate,
  FormatDateMonthDay,
  FormatTime,
} from "../common/utctolocalDate"
import CenteredSpinner from "../common/CenteredSpinner"
const BookingServiceDetails = () => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  const postId = urlSearchParams.get("postId")
  const { id } = useParams()

  const {
    data: timeLine,
    isLoading: timeisLoading,
    isFetching: timeisFetching,
  } = useGetPostTimeLineQuery({ id })

  const {
    data: acceptedGenrate,
    isLoading,
    isFetching,
  } = useAcceptGenrateBookingQuery({ id })

  return (
    <>
      {timeisLoading || timeisFetching || isLoading || isFetching ? (
        <CenteredSpinner />
      ) : (
        <Box className="pt-5 px-5">
          <Style.Overview>
            {/* <TableContainer
              component={Paper}
              className="dashboard-table-wrapper custom-scrollbar overflow-y-hidden"
              >
              <Table
                sx={{
                  minWidth: 650,
                  border: "none",
                  backgroundColor: "transparent",
                }}
                className="dashboard-table"
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center" className="service-head">
                      Service Provider
                    </TableCell>
                    <TableCell align="center">Email</TableCell>
                    <TableCell align="center">Service</TableCell>
                    <TableCell align="center">Language</TableCell>
                    <TableCell align="center">Date & Time</TableCell>
                    <TableCell align="center">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {acceptedGenrate?.data?.map((row: any) => (
                    <TableRow
                      key={row.providerassign}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center">
                        {row?.assignedTo?.map((assineProVider: any) => (
                          <>
                            <div className="user-img">
                              <Box>
                                <img src={assineProVider?.profilePic} />
                              </Box>
                            </div>
                            <div>
                              <span className="text-start">
                                {assineProVider?.name}
                                <br />
                                <p className="mb-0">{row.profession}</p>
                                <span>
                                  {row.providerrating}
                                  <FaStar />
                                </span>
                              </span>
                            </div>
                            <div>
                              <span className="email">
                                <span>{assineProVider.email}</span>
                              </span>
                            </div>
                          </>
                        ))}
                      </TableCell>
                      <TableCell align="center">
                        <span>{row?.service?.name}</span>
                      </TableCell>
                      <TableCell align="center">
                        <span>{row?.language?.join() || "--"}</span>
                      </TableCell>
                      <TableCell align="center">
                          <span className="datetime">
                            <span className="d-flex flex-column">
                              <span className="">
                                <FormatDateMonthDay dateString={row.from} />
                              </span>
                              <span>
                                <FormatChangeTime dateString={row.from} />
                              </span>
                              <span>
                                <FormatChangeTime dateString={row.till} />
                              </span>
                            </span>
                          </span>
                        </TableCell>
                      <TableCell align="center">
                        <span className={row.requeststatus}>{row.status}</span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> */}
            <Box
              className="d-flex mb-5"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography align="left" variant="h2" mb={2}>
                Timeline
              </Typography>
              {/* <Box display="flex">
                <Button className="timeline-btn me-4">
                  Refund <FaChevronRight />
                </Button>
                <Button className="timeline-btn">
                  Raise a ticket <FaChevronRight />
                </Button>
              </Box> */}
            </Box>
            <Box className="timeline-graph">
              {timeLine?.data?.map((timeline: any, index: number) => (
                <Timeline
                  key={timeline._id}
                  position={index % 2 === 0 ? "alternate" : "left"}
                >
                  <TimelineItem>
                    <TimelineOppositeContent
                      sx={{ m: "10px 0 0" }}
                      align="right"
                      className="timeline-desc"
                      color="text.secondary"
                    >
                      {new Date(timeline.createdAt).toLocaleDateString()}
                      <br />
                      {moment(timeline.updatedAt).format("h:mm a")}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineConnector />
                      <TimelineDot>
                        <Typography className="timeline-step">
                          {++index}
                        </Typography>
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: "12px", px: 2 }}>
                      <Box className="timeline-content">
                        <Typography className="timeline-heading">
                          Status {timeline?.postStatus}
                        </Typography>

                        <Typography className="timeline-desc"></Typography>
                        <Typography className="timeline-desc">
                          {timeline?.message}{" "}
                        </Typography>
                      </Box>
                    </TimelineContent>
                  </TimelineItem>
                </Timeline>
              ))}
            </Box>
          </Style.Overview>
        </Box>
      )}
    </>
  )
}
export default BookingServiceDetails
