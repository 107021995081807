import React, { useEffect, useState } from "react"
import {
  Box,
  Collapse,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
} from "@mui/material"
import { NavLink, useLocation, useParams } from "react-router-dom"
import ListItemIcon from "@mui/material/ListItemIcon"
import PropTypes from "prop-types"
import {
  MdOutlineAccountBalance,
  MdOutlineContactSupport,
} from "react-icons/md"
import { RxCalendar, RxDashboard } from "react-icons/rx"
import { CiFolderOn } from "react-icons/ci"
import { FiBook } from "react-icons/fi"
import { BiSolidOffer } from "react-icons/bi"
import { RiUserSettingsLine } from "react-icons/ri"
import { LuUser } from "react-icons/lu"
import { MdOutlineLocalOffer } from "react-icons/md"
import { IoCalendarOutline } from "react-icons/io5"
import { MdOutlineFeedback } from "react-icons/md"
import {
  logout,
  setNavBarSelect,
  setPathNavBar,
} from "../../features/Auth/authSlice"
import { useAppDispatch } from "../../app/store"
import { HiOutlineLogout } from "react-icons/hi"
import { PiClockCounterClockwiseFill } from "react-icons/pi"
import { useAppSelector } from "../../app/hooks"
import { number } from "yup"
import SignoutModal from "../common/logoutmodal"
import LogoutModal from "./logout"
import LogoutConfirm from "./logout"

interface propsNav {
  open: boolean
}

const Navbar = ({ open }: propsNav) => {
  const alreadyRated = useAppSelector(
    (state: any) => state.auth.userInfo?.alreadyRated,
  )
  const navList = [
    {
      label: "Overview",
      to: "/dashboard",
      icon: <RxDashboard fontSize={25} />,
      showItem: true,
    },
    {
      label: "Create Booking",
      to: "/dashboard/create-booking",
      icon: <CiFolderOn fontSize={25} />,
      showItem: true,
    },
    {
      label: "My Booking",
      to: "/dashboard/my-bookings",
      icon: <FiBook fontSize={25} />,
      showItem: true,
    },

    {
      label: "Booking History",
      to: "/dashboard/booking-history/",
      icon: <PiClockCounterClockwiseFill fontSize={20} />,
      showItem: true,
    },

    {
      label: "Services",
      to: "/dashboard/features",
      icon: <BiSolidOffer fontSize={25} />,
      showItem: true,
    },
    // {
    //   label: "Offers",
    //   to: "/dashboard/features/offers",
    //   icon: <MdOutlineLocalOffer fontSize={20} />,
    //   showItem: true,
    // },

    {
      label: "Rating & Reviews",
      to: "/dashboard/rating-reviews",
      icon: <IoCalendarOutline fontSize={25} />,
      showItem: alreadyRated ? false : true,
      // children: alreadyRated
      //   ? []
      //   : [
      //       {
      //         label: "Rate Us",
      //         to: "/dashboard/rate-us",
      //         icon: <MdOutlineFeedback fontSize={20} />,
      //         showItem: true,
      //       },
      //     ],
    },
    {
      label: "My Account",
      to: "/dashboard/myaccount",
      icon: <MdOutlineAccountBalance fontSize={25} />,
      showItem: true,
    },
    // {
    //   label: "Service Support",
    //   to: "/dashboard/service-support",
    //   icon: <MdOutlineContactSupport fontSize={25} />,
    //   showItem: true,
    // },
    {
      label: "Logout",
      // to: dispatch(logout()),
      icon: <HiOutlineLogout fontSize={25} />,
      showItem: true,
    },
  ]

  const dispatch = useAppDispatch()

  const [openItems, setOpenItems] = useState<number[]>([])
  const [openLogout, setOpenlogout] = React.useState(false)
  const [updatePath, setUpdatePath] = useState<string>("")

  const { pathname } = useLocation()

  const handleOpen = () => setOpenlogout(true)
  const handleClose = () => setOpenlogout(false)

  const handleToggle = (index: number) => {
    if (openItems.includes(index)) {
      setOpenItems(openItems.filter((item) => item !== index))
    } else {
      setOpenItems([...openItems, index])
    }
  }

  const renderByType = (eachItem: any, index: number) => {
    const hasChildren = eachItem.children && eachItem.children.length > 0

    return (
      <>
        <Box pt={1} key={eachItem.label}>
        <Tooltip title={open ? "" : eachItem.label} placement="right" arrow>
          <ListItem
            className={`${pathname === eachItem.to ? "sidebar-item" : ""}`}
            component={(eachItem.to as any) ? (NavLink as any) : undefined}
            to={(eachItem.to as any) ? (eachItem.to as any) : undefined}
            style={{ cursor: `${eachItem.link && "pointer"}`, padding: "0" }}
            onClick={() => {
              if (eachItem.link) {
                window.open(eachItem.link, "_blank")
              }
              if (hasChildren) {
                handleToggle(index)
              }
              if (eachItem.label === "Logout") {
                handleOpen()
              }
            }}
          >
            <ListItemButton
              className="sidebar-button"
              sx={{
                minHeight: 48,
                justifyContent: (open as any) ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: (open as any) ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {eachItem.icon}
              </ListItemIcon>
              <ListItemText
                primary={eachItem.label}
                sx={{
                  opacity: (open as any) ? 1 : 0,
                  fontFamily: "Montserrat",
                }}
              />
            </ListItemButton>
            </ListItem>
            </Tooltip>

          {hasChildren && (
            <Collapse
              in={openItems.includes(index)}
              className="ps-1"
              timeout="auto"
              unmountOnExit
            >
              {eachItem.children.map((childItem: any, childIndex: number) =>
                renderByType(childItem, childIndex),
              )}
            </Collapse>
          )}
        </Box>
        {/* <SignoutModal/> */}
      </>
    )
  }

  return (
    <>
      {/* {navList.map((eachItem, index) => renderByType(eachItem, index))} */}

      {navList.map((eachItem, index) => (
        <React.Fragment key={index}>
          {renderByType(eachItem, index)}
        </React.Fragment>
      ))}

      <LogoutConfirm
        logoutModal={openLogout as any}
        handleClose={handleClose}
      />
    </>
  )
}

export default Navbar

Navbar.propTypes = {
  open: PropTypes.bool.isRequired,
}
