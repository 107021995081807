import React, { useEffect, useState } from "react"
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  Snackbar,
  Alert,
  Checkbox,
  FormControlLabel,
} from "@mui/material"
import Logo from "../../images/logo.png"
import {
  AiFillLock,
  AiFillUnlock,
  AiOutlineMail,
  AiOutlineUser,
} from "react-icons/ai"
import { FcGoogle } from "react-icons/fc"
import * as yup from "yup"
import { Controller, useForm } from "react-hook-form"

import { Link, useNavigate } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"
import mommylogo from "../../images/login-logo.png"
import physioTherapy1 from "../../images/physioTherapy1.jpg"
import physioTherapy2 from "../../images/physioTherapy2.jpg"
import physioTherapy3 from "../../images/physioTherapy3.jpg"
import physioTherapy4 from "../../images/physioTherapy4.jpg"

import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { useLoginMutation } from "../../features/Auth/authApi"
import CircularProgress from "@mui/material/CircularProgress"
import * as Style from "./style"
import { toast } from "react-toastify"
import { token, updateToken, userInfo } from "../../features/Auth/authSlice"
import { _setTicket } from "../../features/ChatAuth/chatSlice"

interface loginUser {
  email: string
  password: string
}
interface loginProps {}

const Login = ({}: loginProps) => {
  const [showPassword, setShowPassword] = React.useState(false)

  const dispatch = useAppDispatch()

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
  }
  const navigate = useNavigate()
  const [userLogin, { data, isError, error, isLoading, isSuccess }] =
    useLoginMutation()
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  const schemaResolver = yupResolver(
    yup.object().shape({
      email: yup
        .string()
        .required("Please enter an email")
        .test("valid-email", "Please enter a valid email", (value) =>
          emailRegex.test(value),
        ),
      password: yup.string().required("Please fill in a password"),
    }),
  )

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<loginUser>({
    resolver: schemaResolver,
  })

  const onSubmit = async (values: any) => {
    const payload = {
      email: values.email,
      password: values.password,
    }

    try {
      const res: any = await userLogin(payload)

      if (res?.data?.message == "Login success") {
        toast.success("Login success", { autoClose: 1000 })
        dispatch(token(res?.data?.token))
        dispatch(userInfo(res?.data?.user))
        reset({ email: "", password: "" })
      } else {
        if (res?.error?.status == 400) {
          toast.error(res?.error?.data?.message, { autoClose: 1000 })
        } else if (res?.data?.status == 200) {
          toast.error(res?.data?.message, { autoClose: 1000 })
        } else {
          toast.error(res?.error?.data?.message, { autoClose: 1000 })
        }
      }
    } catch (error) {
      console.error("Error logging in:", error)
    }
  }

  return (
    <>
      <Grid container>
        <Grid
          item
          lg={6}
          className="d-none d-lg-block"
          display="flex"
          alignItems="center"
        >
          <Box className="box-wrapper">
            <Typography
              sx={{
                textAlign: "center",
                color: " #858585",
                fontFamily: "Montserrat",
                fontSize: "30px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              Welcome to
            </Typography>
            <Typography sx={{
                textAlign: "center",
                fontFamily: "Montserrat",
                fontSize: "40px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
              }}
            >
              Send A Therapist
              {/* <img src={mommylogo} width="300" height="auto" /> */}
            </Typography>
            <Box
              className="Imgbox"
              display="flex"
              justifyContent="center"
              flexWrap="wrap"
              gap={2}
            >
              <Box>
                <img src={physioTherapy1} className="login-left-img1"/>
              </Box>
              <Box mt={5}>
                <img src={physioTherapy2} className="login-left-img2"/>
              </Box>

              <Box>
                <img src={physioTherapy3} className="login-left-img3"/>
              </Box>

              <Box mt={5}>
                <img src={physioTherapy4} className="login-left-img4"/>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={6}>
          <Box className="Loginform">
            <Typography
              variant="h4"
              mt={5}
              fontFamily="Montserrat"
              fontSize={22}
              fontWeight="600"
              color="pink.main"
              textAlign="center"
            >
              Login to your account
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box>
                <FormControl sx={{ mt: 2 }} variant="outlined" fullWidth>
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    className=" rounded"
                  >
                    Email
                  </InputLabel>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }: any) => (
                      <OutlinedInput
                        {...field}
                        type="email"
                        label="Email"
                        id="outlined-adornment-password"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton edge="end">
                              <AiOutlineMail />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                  <p className="text-danger">{errors.email?.message}</p>
                </FormControl>
                <FormControl sx={{ mt: 2 }} variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }: any) => (
                      <OutlinedInput
                        {...field}
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <AiFillUnlock /> : <AiFillLock />}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                      />
                    )}
                  />
                  <p className="text-danger">{errors.password?.message}</p>
                </FormControl>
                <Box
                  mt={1}
                  className="d-flex justify-content-between align-items-center"
                >
                  <Link
                    to=""
                    className="text-login text-decoration-none"
                    onClick={() => navigate(`forget-password`)}
                  >
                    Forgot Password?
                  </Link>
                </Box>
              </Box>
              <Box>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  sx={{ mt: 9, display: "block" }}
                  fullWidth
                  className="auth-btn mx-auto"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Typography fontSize={1}>
                      <CircularProgress
                        style={{ color: "pink", fontSize: 25 }}
                        size={25}
                      />
                    </Typography>
                  ) : (
                    "Login"
                  )}
                </Button>
              </Box>
            </form>
            <Box mb={0} mt={3} className="text-center">
              <Typography
                sx={{ color: "#7B7B7B", margin: "8px 0 0 13px" }}
                className="text-login"
              >
                Don’t have an account?{" "}
                <Typography
                  component="span"
                  className="text-login text-black"
                  sx={{ color: "#181818", cursor: "pointer" }}
                  onClick={() => navigate("/signup")}
                >
                  Sign up
                </Typography>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default Login
