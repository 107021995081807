import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import io, { Socket } from "socket.io-client"
import { RootState, store, useAppDispatch } from "../../app/store"
import { toast } from "react-toastify"
import { useGetBookingHistoryQuery } from "../OverViewAuth/overViewApi"
import { setNotificationId, setSocketId } from "../Auth/authSlice"

let socket: Socket
export const notificationsApi = createApi({
  reducerPath: "notificationsApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/" }),
  tagTypes: ["notifications", "activejob"],
  endpoints: (builder) => ({
    // socketNotifications: builder.query<any, string | null>({
    //   query: () => "",

    socketNotifications: builder.query<any, any>({
      queryFn: (arg: string | null) => ({ data: [] }),
      // Dummy query to satisfy RTK Query
      async onCacheEntryAdded(
        token,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        if (!token) {
          return // Exit early if token is null or undefined
        }

        const BASE_URL = import.meta.env.VITE_REACT_APP_API_URL
        socket = io(`${BASE_URL}/base?token=${token}`, {
          reconnection: true,
          reconnectionAttempts: Infinity,
          reconnectionDelay: 1000,
          reconnectionDelayMax: 1000,
          timeout: 20000,
          autoConnect: true,
        })

        socket.on("connect", () => {
          const id: any = socket.id
          store.dispatch(setNotificationId(id))
        })
        socket.on("ping", () => {
          socket.emit("pong") // Respond with pong to server
        })
        socket.on("notifications", (notifications: any) => {
          toast.success(notifications?.content, {
            autoClose: 5000,
          })
          updateCachedData((draft) => {
            draft.push(notifications)
          })
        })

        // socket.on("notifications", (notifications: any) => {
        //   toast.success(notifications?.content, {
        //     autoClose: 5000,
        //   })
        //   updateCachedData((draft) => {
        //     console.log("notifications", notifications)
        //     draft?.push(notifications)
        //   })
        // })
        socket.on("active-jobs-data", (activePostData: any) => {
          updateCachedData((draft) => {
            draft.push(activePostData)
          })
        })

        await cacheDataLoaded
        await new Promise((resolve) => socket.on("disconnect", resolve))

        // await cacheDataLoaded
        // await cacheEntryRemoved
        // socket.disconnect()

        // try {
        //   await cacheDataLoaded
        //   await cacheEntryRemoved
        // } finally {
        //   socket.disconnect()
        // }
      },

      providesTags: ["notifications", "activejob"],
    }),
  }),
})

export const { useSocketNotificationsQuery } = notificationsApi
