import { styled } from "@mui/material"

const Overview = styled("div")(({ theme }) => ({
  "& .text-sec": {
    fontSize: "30px",
    lineHeight: "38px",
    fontWeight: "500",
    color: "#5D5D5D",
  },
  "& .text-sec span": {
    display: "block",
    fontSize: "48px",
    lineHeight: "58px",
    color: "#000",
  },

  "& .card_sec": {
    display: "flex",
    gap: "20px",
    justifyContent: "center",
    marginTop: "10px",
  },
  "& .card_content": {
    textAlign: "center",
    padding: "20px",
    background: "#fff",
    boxShadow: "0px 4px 21px 0px #0000000A",
    borderRadius: "27px",
    width: "100%",
    maxWidth: "350px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems:"center",
  },
  "& .card_content h3": {
    marginTop: "8px",
    fontSize: "24px",
    lineHeight: "24px",
    fontWeight: "700",
    marginBottom: "15px",
    color: "#000",
  },
  "& .card_content ul": {
    listStyle: "none",
    paddingLeft: "15px!important",
    marginBottom:"0",
  },
  "& .card_content ul li": {
    fontSize: "14px",
    fontWeight: "500",
    color: "#565656",
    marginBottom: "2px",
    textAlign: "left",
  },
  "& .card_content ul li img": {
    width: "25px",
    height: "25px",
    marginRight: "5px",
  },
  "& .card_content img": {
    width: "50px",
    height: "50px",
  },
  "& .MuiTypography-h2": {
    color: theme.palette.pink.main,
    fontFamily: "Poppins",
    fontSize: " 20px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: " normal",
    marginBottom: "0px",
    "@media (max-width: 900px)": {
      fontSize: "16px",
    },
  },
  "& .MuiTypography-body1": {
    color: "#626262",
    fontFamily: "Montserrat",
    fontSize: " 14px",
    fontStyle: "normal",
    fontWeight: "400",
    display: "block",
    lineHeight: " 15px",
  },
  "& .MuiDrawer-paper": {
    backgroundColor: theme.palette.pink.main,
    "& .MuiListItem-root ": {
      color: theme.palette.white.main,
      "& svg": {
        color: theme.palette.white.main,
      },
    },
  },
  "& .text-success-provider": {
    color: theme.palette.green.main,
  },
  "& .review-card": {
    background: theme.palette.white.main,
    borderRadius: "16px",
    boxShadow: "0px 4px 20px 2px #00000008",
  },
  "& .profile-img-2": {
    width: "228px",
    maxHeight: "200px",
    borderRadius: "13px",
    objectFit: "cover",
    overflow: "hidden",
    "@media (max-width: 1100px)": {
      width: "80px",
      height: "80px",
    },
  },
  "& .dashboard-table-wrapper": {
    backgroundColor: "#fff",
    borderRadius: "16px",
    boxShadow: "none",
    "& .dashboard-table": {
      backgroundColor: "#fff",
      boxShadow: "none",
      "& thead": {
        "& th": {
          color: theme.palette.gray.main700,
          fontFamily: "Montserrat",
          fontSize: " 15px",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: " 13px",
          minHeight: "50px",
          minWidth: "66px",
          "@media (max-width: 900px)": {
            fontSize: "13px",
          },
        },
      },
      "& .minh-1": {
        minHeight: "1px",
      },
      "& .no-data-text": {
        fontSize: "20px",
        fontWeight: "600",
        color: "#000",
      },
      "& .language": {
        maxWidth: "200px",
        minWidth: "200px",
        fontSize: "14px",
        OverflowWrap: "anywhere",
        margin: "0 auto",
      },

      "& .datetime": {
        "& span": {
          width: "130px",
          lineHeight: "23px",
          minHeight: "21px",
        },
        "& .weekday": {
          fontWeight: "bold",
          color: "#777",
        },
      },
      "& .Pending": {
        color: "#FFA639",
      },
      "& .Assigned": {
        color: "#009C35",
      },
      "& .Rejected": {
        color: "#FE2323",
      },
      "& .Active": {
        color: "#FFA639",
      },
      "& .Completed": {
        color: "#009C35",
      },
      "& .Cancelled": {
        color: "#FE2323",
      },
      "& .location": {
        "& span": {
          maxWidth: "184px",
          lineHeight: "23px",
          fontSize: "12px",
        },
      },
      "& .action-btn": {
        gap: "20px",
      },
      "& .view-profile": {
        display: "inline-block",
        minWidth: "86px",
        padding: "12px 10px",
        border: "1px solid",
        borderRadius: "8px",
        borderColor: theme.palette.pink.main,
        fontFamily: "Montserrat",
        color: theme.palette.pink.main,
        fontSize: "14px",
        textAlign: "center",
        textDecoration: "none",
        transition: "all 0.3s ease",
        "&:hover": {
          backgroundColor: theme.palette.pink.main, // Apply hover background color
          color: "white", // Change text color on hover
          cursor: "pointer", // Change cursor to pointer on hover
        },
      },
      "& .user-img": {
        width: "85px",
        "& div": {
          width: "85px",
          "& img": {
            width: "100%",
            borderRadius: "5px 0px 0px 5px",
          },
        },
      },
      "& tbody tr ": {
        borderRadius: "16px ",
        overflow: "hidden",
        backgroundColor: "#fff",
        boxShadow: "0px 8px 6px -3px #00000008",
        transition: "all 0.4s ease",
      },
      "& tbody tr:hover ": {
        backgroundColor: "#f1f1f1",
      },
      "& tbody tr td:first-child": {
        width: "85px",
        overflow: "hidden",
        "& div": {
          "& img": {
            width: "100%",
          },
        },
      },
      "& tbody tr td:last-child": {
        "& span": {
          borderRadius: "0px 16px 16px 0",
        },
      },
      "& td": {
        padding: "0",
        paddingRight: "10px",
        // borderBottom: "none",
        backgroundColor: "transparent",
        "& span": {
          position: "relative",
          minHeight: "75px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "transparent",
          color: theme.palette.gray.main700,
          fontFamily: "Montserrat",
          fontSize: " 14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: " 13px",
          //   boxShadow: "0px 8px 6px -3px #00000008",
          "@media (max-width: 900px)": {
            fontSize: "12px",
          },
        },
      },
      "& th": {
        paddingLeft: 0,
        paddingRight: 0,
        borderBottom: "none",
        backgroundColor: "transparent",
        "& span": {
          position: "relative",
          minHeight: "75px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "start",
          backgroundColor: "#fff",
          color: theme.palette.black,
          fontFamily: "Montserrat",
          fontSize: " 14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: " 13px",
          gap: "6px",
          padding: "0 20px",
          boxShadow: "0px 8px 6px -3px #00000008",
          zIndex: "2",
          "& span": {
            minHeight: "auto",
            flexDirection: "row",
            alignItems: "center",
            gap: "2px",
            fontSize: "12px",
            color: theme.palette.gray.main700,
            padding: 0,
            "& svg": {
              fill: theme.palette.yellow.main,
            },
          },
        },
      },
    },
  },
  "& .track-btn": {
    width: "100%",
    padding: "9px",
    textAlign: "center",
    color: theme.palette.white.main,
    fontSize: "14px",
    backgroundColor: theme.palette.pink.main,
    borderColor: theme.palette.pink.main,
    fontFamily: "Montserrat",
    borderRadius: "8px",
    "& svg": {
      marginLeft: "9px",
    },
    "&:disabled": {
      color: "#B9B9B9",
      border: "1px solid #B9B9B9",
      backgroundColor: "transparent",
    },
  },
  "& .Advertisement": {
    borderRadius: " 16px",
    background: "linear-gradient(90deg, #F475A4 38.96%, #DD5084 99.94%)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "end",
    "& .MuiButtonBase-root": {
      background: theme.palette.white.main,
      color: theme.palette.pink.main,
    },
  },
  "& .white-space": {
    whiteSpace: "nowrap",
  },
  "& .photo-upload": {
    width: "60px",
    height: "60px",
    objectFit: "cover",
    borderRadius: "8px",
  },
  "& .back-btn": {
    backgroundColor: "#F4F4F4",
    minWidth: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    marginBottom: "21px",
    "&.step-back-btn": {
      position: "absolute",
      top: "30px",
      left: "50px",
    },
    "& svg": {
      fill: "#3A3A3A",
    },
    "& .profile-back": {
      position: "absolute",
      top: 0,
      left: 0,
    },
  },
  "& .service-text": {
    fontSize: "32px",
    fontWeight: "500",
    "&.started": {
      color: "#00C14D",
    },
    "&.stopped": {
      color: "#F83939",
    },
  },
  "& .otp-box": {
    maxWidth: "520px",
    padding: "47px 10px",
    background: theme.palette.white.main,
    borderRadius: "16px",
    border: "1px solid #EDEDED",
  },
  "& .otp-box-1": {
    "@media (min-width: 700px)": {
      width: "520px",
    },
  },
  "& .otp-boxes": {
    maxWidth: "46px",
    height: "56px",
    textAlign: "center",
    fontFamily: "Montserrat",
    color: "#F475A4",
    border: "1px solid #F475A4",
    borderRadius: "6px",
    fontSize: "20px",
    "@media (max-width: 600px)": {
      maxWidth: "28px",
      height: "33px",
      fontSize: "14px",
    },
  },

  "& .service-detail-box-lg": {
    background: theme.palette.white.main,
    borderRadius: "16px",
    border: "1px solid #EDEDED",
    flexWrap: "unset !important"
  },
  "& .status-text": {
    fontSize: "20px",
    fontWeight: "400",
    fontFamily: "Montserrat",
    border: "1px solid #dddddd",
    boxShadow: "rgba(0, 0, 0, 0.23) 0px 4px 25px 0px",
    padding: "10px",
    borderRadius: "8px",
    "@media (max-width: 600px)": {
      fontSize: "14px",
      padding: "5px",
    },
    color: theme.palette.black.main,
    "& .completed": {
      color: theme.palette.green.main,
    },
    "& .cancelled": {
      color: theme.palette.red.main,
    },
  },
  "& .green-heading": {
    color: "#000",
    fontSize: "24px",
    fontWeight: "600",
    lineHeight: "1",
    padding: "unset !important",
    "@media (max-width: 600px)": {
      fontSize: "20px",
      padding: "5px",
    },
  },
  "& .ls-8": {
    letterSpacing: "8px",
  },
  "& .otp-box-img": {
    width: " 300",
    height: "300",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .approved-card": {
    backgroundColor: theme.palette.white.main,
    padding: "9px 19px 14px",
    boxShadow: "0px 4px 20px 0px #00000008",
    borderRadius: "16px",
    "&.pending-card": {
      borderColor: theme.palette.yellow.main,
    },
    "&.rejected-card": {
      borderColor: theme.palette.red.main,
    },
  },
  "& .view-profile": {
    display: "inline-block",
    minWidth: "86px",
    padding: "12px 10px",
    border: "1px solid",
    borderRadius: "8px",
    borderColor: theme.palette.pink.main,
    fontFamily: "Montserrat",
    color: theme.palette.pink.main,
    fontSize: "14px",
    textAlign: "center",
    textDecoration: "none",
    transition: "all 0.3s ease",
    "&:hover": {
      backgroundColor: theme.palette.pink.main, // Apply hover background color
      color: "white", // Change text color on hover
      cursor: "pointer", // Change cursor to pointer on hover
    },
  },
  "& .no-track-color": {
    pointerEvents: "none",
    border: "1px solid grey!important",
    color: "grey!important",
  },
  "& .upcoming-booking-slider": {
    // changed maxWidth
    paddingBottom: "10px",
    maxWidth: "1000px",
    "& .slick-track": {
      display: "flex",
      gap: "16px",
      margin: "0",
    },
    "& .slick-dots": {
      "& li": {
        margin: "0",
        "& button": {
          "&:before": {
            fontSize: "12px",
            color: "#DEDEDE",
            opacity: 1,
          },
        },
        "&.slick-active": {
          "& button": {
            "&:before": {
              color: theme.palette.pink.main,
            },
          },
        },
      },
    },
    "& .slick-arrow": {
      "&:before": {
        color: theme.palette.pink.main,
        fontSize: "30px",
        opacity: 1,
      },
      "&.slick-prev": {
        left: "-18px",
        zIndex: 2,
      },
      "&.slick-next": {
        right: "-14px",
        zIndex: 2,
      },
      "&.slick-disabled": {
        display: "none !important",
      },
    },
  },
  "& .dark-divider": {
    border: "1px dashed #C0C0C0",
  },

  "& .timeline-graph": {
    "& .MuiTimelineConnector-root": {
      display: "none",
    },
    "& .MuiTimelineDot-root": {
      margin: 0,
      width: "65px",
      height: "65px",
      borderRadius: "16px",
      padding: "0",
      border: "none",
      backgroundColor: theme.palette.white.main,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: "0px 4px 21px 0px #00000008",
      "& .timeline-step": {
        width: "40px",
        height: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: " 9px",
        backgroundColor: theme.palette.pink.main,
        fontSize: "16px",
        fontWeight: "500",
        fontFamily: "Montserrat",
        color: theme.palette.white.main,
      },
    },
    "& .timeline-content": {
      // maxWidth: "500px",
      borderRadius: "16px",
      backgroundColor: theme.palette.white.main,
      padding: "17px 24px",
      marginTop: "-27px",
      "@media (max-width: 600px)": {
        padding: "17px 5px",
      },
      "& p": {
        "@media (max-width: 600px)": {
          fontSize: "14px",
        },
      },
    },
    "& ul": {
      position: "relative",
      "&:before": {
        content: '""',
        position: "absolute",
        top: "10px",
        left: "50%",
        transform: "translateX(-50%)",
        width: "1px",
        height: "100%",
        backgroundColor: "#AAAAAA",
      },
      "& li": {
        marginBottom: "60px",
        "&:nth-child(2n)": {
          "& .timeline-content": {
            marginLeft: "auto",
          },
        },
      },
    },
    "& .timeline-heading": {
      fontSize: "16px",
      fontFamily: "Montserrat",
      fontWeight: "400",
      color: theme.palette.pink.main,
      marginBottom: "12px",
    },
    "& .timeline-desc": {
      fontSize: "16px",
      fontFamily: "Montserrat",
      fontWeight: "400",
      color: "#818181",
      marginBottom: "12px",
      lineHeight: "19px",
      "@media (max-width: 600px)": {
        lineHeight: "17px",
      },
    },
  },
  "& .timeline-btn": {
    padding: "9px 25px",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "Montserrat",
    color: "#f475a4",
    border: "1px solid #f475a4",
    backgroundColor: "#fff",
    borderRadius: "8px",
    textTransform: "capitalize",
    "& svg": {
      marginLeft: "9px",
      fill: "#A0A0A0",
    },
  },
  "& .rounded-sm": {
    borderRadius: "16px",
  },
  "& .offer-box": {
    background: "linear-gradient(90.06deg, #F475A4 38.96%, #DD5084 99.94%);",
    borderRadius: "16px",
    padding: "19px 39px",
    position: "relative",
    "& .logo-white": {
      maxWidth: "148px",
      width: "100%",
      marginBottom: "12px",
    },
    "& .off-discount": {
      fontSize: "48px",
      color: theme.palette.white.main,
      letterSpacing: "4.8px",
      marginBottom: "22px",
      lineHeight: "initial",
      fontFamily: "Racing Sans One",
    },
    "& .valid-text": {
      color: "#FFD4E4",
      fontSize: "12px",
      fontFamily: "Montserrat",
    },
    "& .valid-date-text": {
      fontSize: "16px",
      color: theme.palette.white.main,
      fontFamily: "Montserrat",
      fontWeight: "500",
    },
    "& .book-text": {
      fontSize: "12px",
      color: theme.palette.white.main,
      fontFamily: "Montserrat",
      marginBottom: "9px",
    },
    "& .book-now-btn": {
      maxWidth: "135px",
      width: "100%",
      textAlign: "center",
      textDecoration: "none",
      borderRadius: "4px",
      padding: "12px 7px",
      background: theme.palette.white.main,
      color: theme.palette.pink.main,
      fontSize: "14px",
      fontFamily: "Montserrat",
      display: "inline-block",
    },
    "& .offer-img": {
      position: "absolute",
      bottom: 0,
      right: "20px",
      maxWidth: "285px",
      width: "100%",
    },
  },
}))
const Flexbox = styled("div")(({ theme }) => ({
  display: "flex",
  columnGap: "15px",
  "& .MuiTypography-body1": {
    color: theme.palette.pink.main,
    fontFamily: "Nunito Sans",
    fontSize: " 18px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: " 18px",
    textAlign: "center",
    marginTop: "10px",
  },
  // "& .MuiTypography-body1":  {
  //   color: theme.palette.grey.main200,
  //   fontFamily: "Montserrat",
  //   fontSize: " 19px",
  //   fontStyle: "normal",
  //   fontWeight: "400",
  //   lineHeight: " 18px",
  //   textAlign: "center",
  // },
  // "& .MuiTypography-body1": {
  //   color: theme.palette.gray.main500,
  //   fontFamily: "Montserrat",
  //   fontSize: " 14px",
  //   fontStyle: "normal",
  //   fontWeight: "400",
  //   display: "block",
  //   lineHeight: " 13px",
  // },
  "& .overview-card": {
    background: theme.palette.white.main,
    boxShadow: "0px 4px 20px 2px rgba(0, 0, 0, 0.03)",
    padding: "20px 17px",
    width: "auto",
    minWidth: "205px",
    margin: "10px 0",
    borderRadius: "16px",
    transition: ".2s ease",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  // Media query for smaller screens
  "@media (max-width: 1200px)": {
    "& .overview-card": {
      width: "auto", // Make the cards take full width on smaller screens
      margin: "5px 0", // Add some margin for better spacing
      borderRadius: "8px",
      minWidth: "100px",
      padding: "5px",
      "& p ": {
        fontSize: "12px", // Adjust border radius for smaller cards
      },
      "& img ": {
        width: "33px", // Adjust border radius for smaller cards
      },
    },
  },
  "& .pink-text": {
    color: theme.palette.pink.main,
  },
  "& .darkpink-text": {
    color: "#AD2B82",
  },
  "& .font-24": {
    fontSize: "24px",
  },
}))
const Upcoming = styled("div")(({ theme }) => ({
  maxWidth: "500px",

  "& .MuiTypography-h2": {
    color: theme.palette.pink.main,
    fontFamily: "Poppins",
    fontSize: " 16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: " normal",
  },
  "& .Headingbox": {
    borderRadius: "16px 16px 0 0",

    display: "flex",
    justifyContent: "space-between",
    background: theme.palette.white.main,
    boxShadow: "0px 4px 27px 0px rgba(0, 0, 0, 0.07)",
    padding: "15px",
  },
  "& .ContentBox": {
    display: "flex",
    justifyContent: "space-between",
    padding: "15px",
    background: theme.palette.white.main,
    "& .MuiGrid-root": {
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
      marginTop: "20px",
    },
    "& .MuiButton-root": {
      borderColor: theme.palette.pink.main,
      color: theme.palette.pink.main,
      fontFamily: "Montserrat",
    },
  },
}))
const Bookedlog = styled("div")(({ theme }) => ({
  padding: "45px 0",

  "& .MuiTypography-h2": {
    color: theme.palette.pink.main,
    fontFamily: "Poppins",
    fontSize: " 16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: " normal",
  },
}))
const Toprated = styled("div")(({ theme }) => ({
  display: "flex",

  gap: "10px",
  img: {
    height: "113px",
    objectFit: "Cover",
    width: "100%",
    borderRadius: " 8px",
  },
  "& .flexdiv": {
    display: "flex",
    gap: "16px",
    padding: "16px",
    backgroundColor: theme.palette.white.main,
    borderRadius: "8px",
  },
  "& .MuiTypography-root ": {
    fontFamily: "Poppins",
    fontSize: " 12px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: " normal",
    textAlign: "start",
  },
}))
const Toprated1 = styled("div")(({ theme }) => ({
  padding: "45px 0",
  "& .MuiTypography-h2": {
    color: theme.palette.pink.main,
    fontFamily: "Poppins",
    fontSize: " 16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: " normal",
  },
}))
const TableAppointment = styled("div")(({ theme }) => ({
  "& .MuiTableCell-root": {
    fontFamily: " Nunito Sans",
    padding: "0",
    borderBottom: "0",
  },
  "th.MuiTableCell-root.MuiTableCell-head ": {
    color: "#7c7c7c",
    padding: "10px 0",
  },
  td: {
    background: theme.palette.white.main,
  },
}))

export {
  Overview,
  Flexbox,
  Upcoming,
  Bookedlog,
  Toprated,
  Toprated1,
  TableAppointment,
}
