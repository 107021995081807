import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { Outlet } from "react-router-dom"

interface Props {}

const AppWrapper = (props: Props) => {
  return (
    <>
      <main>
        <Outlet />
      </main>
    </>
  )
}

export default AppWrapper
