import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const CenteredSpinner: React.FC = () => {
  const spinnerContainerStyles: React.CSSProperties = {
    position: 'fixed',
    top: '50%',
    left: '57%',
    transform: 'translate(-50%, -50%)',
  };

  const spinnerStyles: React.CSSProperties = {
    color: "pink",
    width: "32px",
    height: "32px",
  };

  return (
    <div style={spinnerContainerStyles}>
      <Spinner animation="grow" style={spinnerStyles} />
    </div>
  );
};

export default CenteredSpinner;
