import React, { useEffect, useState } from "react"
import { useAppDispatch } from "../../app/store"
import { useNavigate } from "react-router-dom"

import CenteredSpinner from "../common/CenteredSpinner"
import { token } from "../../features/Auth/authSlice"
type Props = {}

export default function Reminderjobs({}: Props) {
  const dispatch = useAppDispatch()
  const urlSearchParams = new URLSearchParams(window.location.search)
  const tokens = urlSearchParams.get("token")

  const navigate = useNavigate()

  useEffect(() => {
    dispatch(token(tokens as any))
    const url = "https://mommys-customer.web.app/dashboard/my-bookings"
    if (tokens) {
      window.location.href = url
    }
  }, [tokens])

  return <>Loding.......</>
}

export function ActivePost({}: Props) {
  const dispatch = useAppDispatch()
  const urlSearchParams = new URLSearchParams(window.location.search)
  const tokens = urlSearchParams.get("token")

  const navigate = useNavigate()

  useEffect(() => {
    dispatch(token(tokens as any))
    const url = "https://mommys-customer.web.app/dashboard/"
    if (tokens) {
      window.location.href = url
    }
  }, [tokens])

  return <>Loding.......</>
}
