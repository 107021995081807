import ServiceSupport from "./ServiceSupport/ServiceSupport"
import Myaccount from "./Myaccount/Myaccount"
import Feedback from "./Feedback/Feedback"
import Manage from "./Manage/Manage"
import SingleSeeker from "./Manage/SingleSeeker"
import ServiceProvider from "./Manage/ServiceProvider/ServiceProvider"
import ServiceSeeker from "./Manage/ServiceSeeker/ServiceSeeker"
import Features from "./Features/Features"
import SingleFeatures from "./Features/SingleFeature"
import VerifyOtp from "./AdminLayout/VerifyOtp"
import Offers from "./Features/Offers/Offer"
import AddOffers from "./Features/Offers/AddOffer"
import BookingHistory from "./Booking/BookingHistory"
import CreateBooking from "./Booking/CreateBooking"
import ErrorPage from "./common/ErrorPage"
import BookingOverview from "./Booking/BookingOverview"
import MyBookings from "./Booking/MyBooking"
import BookingRequest from "./Booking/BookingRequest"
import BookingTimeline from "./Booking/BookingTimeline"
import WriteReview from "./Rating/WriteReview"
import ThankYou from "./Rating/ThankYou"
import EditAccount from "./Myaccount/EditAccount"
import AuthWrapper from "./Auth/AuthWrapper"
import SignUpAuth from "./Auth/Signup"
import LoginAuth from "./Auth/Login"
import ForgetPasswordAuth from "./Auth/ForgetPassword"
import ResetPasswordAuth from "./Auth/ResetPassword"
import CustomerLayout from "./AdminLayout/AdminLayout"
import Rating from "./Rating/Rating"
import AdminFeedback from "./Rating/RateUs"
import ServiceSeekerDetails from "./Manage/ServiceSeeker/view"
import ServiceProviderrDetails from "./Manage/ServiceProvider/view"
import BookingServiceDetails from "./Booking/BookingRequest"
import ProviderProfile from "./Myaccount/ProviderProfile"
import Notification from "./Notification/Notification"
import ViewProfile from "./AdminLayout/ViewProfile"
import BookOverviewCompleted from "./Booking/BookingSucces"
import Reminderjobs, { ActivePost } from "./Auth/reminderJobs"
import PaymentFailed from "./Booking/paymentFailed"
import ChatWrapper from "./chatUimommy/ChatWrapper"
import NullScreen from "./common/NullScreen"
import Payment from "./Payment/Payment"
import Invoice from "./Myaccount/Invoice"
import EditBookingPage from "./Booking/EditBookingPage"

const authRoute = [
  { index: true, element: <LoginAuth /> },
  {
    element: <SignUpAuth />,
    path: "signup",
  },
  {
    element: <ForgetPasswordAuth />,
    path: "forget-password",
  },

  {
    element: <ResetPasswordAuth />,
    path: "reset-password",
  },

  {
    element: <Reminderjobs />,
    path: "job-reminder",
  },
  {
    element: <ActivePost />,
    path: "active-jobs-reminder",
  },
]
const customerDashboard = [
  {
    index: true,
    element: <CustomerLayout />,
  },
  // {
  //   path: "/dashboard/otp-verify",
  //   element: <VerifyOtp />,
  // },
  {
    path: "/dashboard/booking-history",
    element: <BookingHistory />,
  },
  {
    path: "/dashboard/bookings/details/:id",
    element: <BookingServiceDetails />,
  },
  {
    path: "/dashboard/booking-request",
    element: <BookingRequest />,
  },
  {
    path: "/dashboard/booking-timeline/:id",
    element: <BookingTimeline />,
  },
  {
    path: "/dashboard/create-booking",
    element: <CreateBooking />,
  },

  {
    path: "/dashboard/write-review/:id",
    element: <WriteReview />,
  },
  {
    path: "/dashboard/thankyou",
    element: <ThankYou />,
  },
  {
    path: "/dashboard/booking-overview",
    element: <BookingOverview />,
  },
  {
    path: "/dashboard/my-bookings",
    element: <MyBookings />,
  },
  {
    path: "/dashboard/edit-bookings",
    element: <EditBookingPage />,
  },
  {
    element: <Manage />,
    path: "/dashboard/manage",
  },
  {
    element: <SingleSeeker />,
    path: "/dashboard/manage/single-seeker/:id",
  },
  {
    element: <ServiceProvider />,
    path: "/dashboard/management/service-provider",
  },

  {
    element: <ServiceProviderrDetails />,
    path: "/dashboard/management/service-provider/details/:id",
  },
  {
    element: <ServiceSeeker />,
    path: "/dashboard/management/service-seeker",
  },
  {
    element: <ServiceSeekerDetails />,
    path: "/dashboard/management/service-seeker/details/:id",
  },
  {
    element: <Features />,
    path: "/dashboard/features",
  },
  {
    element: <SingleFeatures />,
    path: "/dashboard/single-feature/:id",
  },
  {
    element: <Offers />,
    path: "/dashboard/features/offers",
  },
  {
    element: <AddOffers />,
    path: "/dashboard/features/offers/add",
  },
  {
    element: <Rating />,
    path: "/dashboard/rating-reviews",
  },
  {
    element: <AdminFeedback />,
    path: "/dashboard/rate-us",
  },
  {
    element: <Feedback />,
    path: "/dashboard/feedback",
  },

  {
    element: <Myaccount />,
    path: "/dashboard/myaccount",
  },

  {
    element: <Invoice />,
    path: "/dashboard/invoice",
  },
  // {
  //   element: <EditAccount />,
  //   path: "/dashboard/myaccount/edit",
  // },
  {
    element: <ProviderProfile />,
    path: "/dashboard/provider-profile/:id",
  },
  {
    element: <ServiceSupport />,
    path: "/dashboard/service-support",
  },
  {
    element: <Notification />,
    path: "/dashboard/notification",
  },
  {
    element: <ViewProfile />,
    path: "/dashboard/active-view/:id",
  },
  {
    element: <BookOverviewCompleted />,
    path: "/dashboard/booking-completed",
  },
  {
    element: <Payment />,
    path: "/dashboard/payment",
  },
  {
    element: <ErrorPage />,
    path: "*",
  },
  // {
  //   element: <NullScreen />,
  //   path: "/dashboard/NullScreen",
  // },
  {
    element: <PaymentFailed />,
    path: "/dashboard/failed-payment",
  },
  {
    element: <ChatWrapper />,
    path: "/dashboard/help-center",
  },
]

export { authRoute, customerDashboard }
