import React from "react"
import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  LinearProgress,
  Paper,
  Radio,
  Rating,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"
import { LiaAngleRightSolid } from "react-icons/lia"
import * as Style from "./style"
import moment from "moment"
import profileImg from "../../../images/babysitting.png"
import { FaStar } from "react-icons/fa6"
import { FaChevronLeft } from "react-icons/fa6"
import { Link, useParams } from "react-router-dom"
import {
  useGetReviewsProQuery,
  useGetSeekerListQuery,
} from "../../../features/OverViewAuth/overViewApi"
const ServiceSeekerDetails = () => {
  const { data: requestSeeker } = useGetSeekerListQuery({})
  const { data: reviews } = useGetReviewsProQuery({})

  const { id } = useParams()

  const detailsSeeker = requestSeeker?.data?.seekersList?.filter(
    (item: any) => item?._id === id,
  )

  return (
    <>
      {" "}
      <Style.Overview>
        <Box className="position-relative">
          <Button className="back-btn mb-0 profile-back">
            <FaChevronLeft />
          </Button>
          <Grid container className="justify-content-center">
            <Grid md={9} className="pe-md-4">
              <Grid container>
                <Grid md={12}>
                  <Box className="profile-page-header">
                    <Typography className="profile-heading">
                      Profile Page
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Box className="review-card p-4 mt-0 d-flex flex-column align-items-center single-review-card p-5">
                {detailsSeeker?.map((item: any) => (
                  <Grid container className="justify-content-center">
                    <Grid md={8}>
                      <Grid container className="mb-4">
                        <Grid md={4}>
                          <Typography variant="body1" className="text-dark">
                            Name
                          </Typography>
                        </Grid>
                        <Grid md={4}>
                          <Typography variant="body1" className="text-dark">
                            {item?.name}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container className="mb-4">
                        <Grid md={4}>
                          <Typography variant="body1" className="text-dark">
                            E-Mail
                          </Typography>
                        </Grid>
                        <Grid md={4}>
                          <Typography variant="body1" className="text-dark">
                            {item?.email}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container className="mb-4">
                        <Grid md={4}>
                          <Typography variant="body1" className="text-dark">
                            Phone Number
                          </Typography>
                        </Grid>
                        <Grid md={4}>
                          <Typography variant="body1" className="text-dark">
                            {item?.contactNo}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container className="mb-4">
                        <Grid md={4}>
                          <Typography variant="body1" className="text-dark">
                            Gender
                          </Typography>
                        </Grid>
                        <Grid md={4}>
                          <Typography variant="body1" className="text-dark">
                            {item?.gender}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container className="mb-4">
                        <Grid md={4}>
                          <Typography variant="body1" className="text-dark">
                            Rating
                          </Typography>
                        </Grid>
                        <Grid md={4}>
                          <Typography variant="body1" className="text-dark">
                            4.5 <FaStar className="review-star" />
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container className="mb-4">
                        <Grid md={4}>
                          <Typography variant="body1" className="text-dark">
                            Services Providing
                          </Typography>
                        </Grid>
                        <Grid md={4}>
                          <Typography variant="body1" className="text-dark">
                            Cooking & House helping
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container className="mb-4">
                        <Grid md={4}>
                          <Typography variant="body1" className="text-dark">
                            Age
                          </Typography>
                        </Grid>
                        <Grid md={4}>
                          <Typography variant="body1" className="text-dark">
                            28 yrs
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container className="mb-4">
                        <Grid md={4}>
                          <Typography variant="body1" className="text-dark">
                            Date of birth
                          </Typography>
                        </Grid>
                        <Grid md={4}>
                          <Typography variant="body1" className="text-dark">
                            06 / 11 / 96
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container className="mb-4">
                        <Grid md={4}>
                          <Typography variant="body1" className="text-dark">
                            Language
                          </Typography>
                        </Grid>
                        <Grid md={4}>
                          <Typography variant="body1" className="text-dark">
                            English & French
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container className="mb-4">
                        <Grid md={4}>
                          <Typography variant="body1" className="text-dark">
                            Availability
                          </Typography>
                        </Grid>
                        <Grid md={4}>
                          <Typography variant="body1" className="text-dark">
                            Mon-Sat 9AM to 10PM
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid md={4}>
                      <Box className="mb-5">
                        <img src={profileImg} className="profile-img" />
                      </Box>
                      <Box className="mb-4">
                        <Typography className="service-provider-name">
                          Joey Tribbiani
                        </Typography>
                        <Typography className="service-provider-bio">
                          “Lorem ipsum dolor sit amet consectetur. Erat sapien
                          neque in egestas ipsum. Turpis phasellus turpis amet
                          etiam ac in eget. Sapien lacus porttitor risus at
                          lacus leo. At morbi interdum a laoreet.”
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                ))}

                <Divider className="dark-divider my-4" />
                <Grid container className="justify-content-center">
                  <Grid md={12}>
                    <Typography
                      variant="body1"
                      align="left"
                      className="text-dark mb-3"
                    >
                      Reviews
                    </Typography>
                    <Grid container className="mb-4">
                      {reviews?.data?.map((item: any) => (
                        <Grid md={6} className="pe-md-3">
                          <Box className="inner-review-card my-3">
                            <Box className="d-flex justify-content-between align-items-center mb-4">
                              <Box className="d-flex gap-2 align-items-center">
                                <img
                                  src={profileImg}
                                  className="inner-profile-img"
                                />
                                <Typography variant="body1">Rahul</Typography>
                              </Box>
                              <Box>
                                <Typography
                                  variant="body1"
                                  className="d-flex gap-2 align-items-center"
                                >
                                  {item?.rating}{" "}
                                  <FaStar className="review-star" />
                                </Typography>
                              </Box>
                              <Box>
                                <Typography variant="body1">
                                  3 days ago
                                </Typography>
                              </Box>
                            </Box>
                            <Box>
                              <Typography variant="body1">
                                {item?.message}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Style.Overview>
    </>
  )
}
export default ServiceSeekerDetails
