import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Typography,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import { useStopServiceMutation } from "../../../features/OverViewAuth/overViewApi"
import { toast } from "react-toastify"

interface props {
  open: boolean
  handleClose: () => void
  openReason: () => void
  stopService: { activeJobflow: string }
}

export default function StopService({ open, handleClose, openReason }: props) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="popup_service"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        borderRadius: "18px",
        maxWidth: "100%", // Set the maximum width to 500px
        width: "100%", // Ensure it takes up the full width up to the maxWidth
        "& .MuiDialog-paper": {
          maxWidth: "500px",
          borderRadius: "18px",
          // Apply maxWidth to the dialog paper

          // Apply maxWidth to the dialog paper
        },
      }}
    >
      <DialogContent>
        <DialogContentText
          pt={5}
          className="text-center"
          id="alert-dialog-description"
        >
          Are you sure you want to stop your service?
        </DialogContentText>
      </DialogContent>
      <Box pb={5}>
        <DialogActions className="justify-content-center gap-4">
          <Button
            onClick={openReason}
            className="verify-profile"
            style={{
              border: "1px solid #F475A4",
              borderRadius: "8px",
              background: "#F475A4",
              fontSize: "16px",
              fontFamily: "Montserrat",
              color: "#fff",
              textTransform: "capitalize",
              padding: "7px 28px",
            }}
            autoFocus
          >
            Yes
          </Button>
          <Button
            style={{
              border: "1px solid #F475A4",
              borderRadius: "8px",
              background: "#fff",
              fontSize: "16px",
              fontFamily: "Montserrat",
              color: "#F475A4",
              textTransform: "capitalize",
              padding: "7px 28px",
            }}
            onClick={handleClose}
          >
            No
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

interface Column {
  id: "name"
  label: string
  minWidth?: number
  align?: "right"
  format?: (value: number) => string
}

const columns: Column[] = [{ id: "name", label: "Reason type", minWidth: 170 }]

interface Data {
  name: string
}

function createData(name: string): Data {
  return { name }
}

const rows = [
  createData("Service didn't meet my expectations"),
  createData("Dissatisfied with the level of support"),
  createData("Not using the service frequently enough"),
  createData("Service completed"),
  createData("Other"),
]

interface reasonProps {
  reasonlist: boolean
  reasonListClose: () => void
  postId: string
  stopService: { activeJobflow: string }
}

export function ColumnGroupingTable({
  reasonlist,
  reasonListClose,
  postId,
  stopService,
}: reasonProps) {
  const [selectedRow, setSelectedRow] = React.useState<string>("")
  const [addReason, setAddReason] = useState(false)
  const [additionalReason, setAdditionalReason] = useState("")
  const [error, setError] = useState<string | null>(null)
  const [stopServiceConfiem, { data, isLoading }] = useStopServiceMutation()

  const selectReasonHandle = (reason: string) => {
    if (reason === "Other") {
      setSelectedRow(reason)
      setAddReason(true)
    } else {
      setSelectedRow(reason)
      setAddReason(false)
    }
  }

  const handleAdditionalReasonChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value
    if (value.length <= 300) {
      setAdditionalReason(value)
      setError(null) // Clear any previous errors
    } else {
      setError("Reason cannot exceed 250 characters.")
    }
  }

  const handleAdditionalReasonBlur = () => {
    if (additionalReason.length > 300) {
      setError("Reason cannot exceed 300 characters.")
    }
  }

  const stopServiceHandle = () => {
    const payload = {
      reason: selectedRow === "Other" ? additionalReason : selectedRow,
      postId: postId,
    }
    stopServiceConfiem(payload)
  }

  useEffect(() => {
    if (data?.status === 200) {
      toast.success("Please Share OTP with Provider")
      reasonListClose()
    }
  }, [data])

  const isButtonDisabled = () => {
    return (
      selectedRow === "" ||
      (selectedRow === "Other" && additionalReason.trim() === "")
    )
  }

  return (
    <Dialog
      open={reasonlist}
      onClose={reasonListClose}
      className="popup_service"
      maxWidth="sm"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          borderRadius: "18px",
        },
      }}
    >
      <Paper sx={{ width: "100%", padding: "20px" }}>
        <TableContainer
          sx={{ maxHeight: 440 }}
          className="custom-scrollbar pe-1"
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={2}
                  sx={{ fontSize: "16px", fontWeight: "600" }}
                >
                  Select specific reason to stop your service
                </TableCell>
              </TableRow>
              <TableRow></TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow
                    key={row.name}
                    onClick={() => selectReasonHandle(row.name)}
                    sx={{
                      display: "flex",
                      margin: "10px 0",
                      borderRadius: "9px",
                    }}
                  >
                    {columns.map((column) => {
                      const value = row[column.id]
                      return (
                        <TableCell
                          sx={{
                            backgroundColor:
                              selectedRow === row.name ? "#F475A4" : "#E6E6E6",
                            width: "100%",
                            borderRadius: "9px",
                            "&:hover": {
                              color:
                                selectedRow === row.name
                                  ? "#FFFFFF"
                                  : "#FFFFFF",
                              backgroundColor:
                                selectedRow === row.name
                                  ? "#F475A4"
                                  : "#F475A4",
                              cursor: "pointer",
                            },
                          }}
                          key={column.id}
                          align={column.align}
                        >
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
              <TableRow>
                {addReason && (
                  <TableCell
                    sx={{
                      width: "100%",
                      borderRadius: "9px",
                      padding: "0",
                      "&:hover": {
                        cursor: "text",
                      },
                    }}
                  >
                    <TextField
                      multiline
                      minRows={3}
                      variant="outlined"
                      fullWidth
                      value={additionalReason}
                      placeholder="Please write issue you are facing..."
                      onChange={handleAdditionalReasonChange}
                      sx={{
                        borderRadius: "9px",
                        ".css-mwzjwb-MuiInputBase-root-MuiOutlinedInput-root": {
                          borderRadius: "9px",
                        },
                      }}
                      onBlur={handleAdditionalReasonBlur}
                      helperText={error}
                      error={!!error}
                    />
                  </TableCell>
                )}
              </TableRow>
              <TableRow className="text-center pb-2">
                <Button
                  style={{
                    border: "1px solid #F475A4",
                    borderRadius: "8px",
                    background: "#F475A4",
                    fontSize: "16px",
                    fontFamily: "Montserrat",
                    color: "#fff",
                    textTransform: "capitalize",
                    padding: "7px 28px",
                    marginTop: "10px",
                    minWidth: "157px",
                  }}
                  onClick={stopServiceHandle}
                  disabled={isButtonDisabled()}
                >
                  {isLoading ? (
                    <Typography fontSize={1}>
                      <CircularProgress
                        style={{ color: "pink", fontSize: 20 }}
                        size={20}
                      />
                    </Typography>
                  ) : (
                    "Stop Service"
                  )}
                </Button>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Dialog>
  )
}
