import React, { SetStateAction, useEffect, useState } from "react"
import {
  Box,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Typography,
  Input,
  Slider,
  OutlinedInput,
  Chip,
  Stepper,
  StepLabel,
  Step,
} from "@mui/material"
import { DemoItem } from "@mui/x-date-pickers/internals/demo"
import * as Style from "./style"
// import { Link } from "react-router-dom"
import minusImg from "../../images/minus-fill.svg"
import PinkMinusImg from "../../images/pink-minus-fill.svg"
import plusImg from "../../images/plus-fill.svg"
import { useGetJobServiceQuery } from "../../features/OverViewAuth/overViewApi"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useAppSelector } from "../../app/hooks"
import { useAppDispatch } from "../../app/store"
import { languages } from "../Languagejson/language"
import CenteredSpinner from "../common/CenteredSpinner"
import FileUpload from "../common/multipleImage"
import LocationSelector from "./SearchLocation"
import { Payload } from "./postInterFace"
import { setBookingDetails } from "../../features/PostCreat/postcreateSlice"
import moment from "moment"
import { FaChevronLeft } from "react-icons/fa"
// import YourComponent from "./Validation/validationCreatebooking"
const steps = ["Booking Summary", "Offer", "Request sent"]
function slidertext(value: number) {
  return `${value}`
}
interface createBooking {
  date: string
}
interface fileData {
  url: string
  file: File
}
interface ErrorProps {
  startOnTimer: string | undefined
  selectLocation: string | undefined
  selectLanguage: string | undefined
  selectServiceError: string | undefined
  additionalInfo: string
}

const CreateBooking = () => {
  const [slidervalue, sliderSetValue] = React.useState<number[]>([18, 45])
  const [count, setCount] = React.useState<number>(1)
  const [hoursSend, setHoursSend] = useState<number>(1)
  const [errorMsg, setErrorMsg] = useState<ErrorProps>({
    startOnTimer: "",
    selectLocation: "",
    selectLanguage: "",
    selectServiceError: "",
    additionalInfo: "",
  })
  const [priceData, setPriceData] = useState()

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [selectedDate, setSelectedDate] = useState("")
  const [selectedStartTime, setSelectedStartTime] = useState("")
  const [selectedEndTime, setSelectedEndTime] = useState("")
  const [startTimeOptions, setStartTimeOptions] = useState([])
  const [endTimeOptions, setEndTimeOptions] = useState([])
  const [endTimeShow, setEndTimeShow] = useState("")
  const [selectService, setSelectService] = useState<string>("")
  const [selectServiceName, setSelectServiceName] = useState<string>("")
  const [searchParams, setSearchParams] = useSearchParams()
  const [arrayImages, setArrayImages] = useState<fileData[]>([])
  const [val, setVal] = useState("")
  // const urlSearchParams = new URLSearchParams(window.location.search)
  // const servicetype = urlSearchParams.get("type")
  const [loactionSearchData, setLocationSearchData] = useState<string>("")
  const [typeadditionalInfo, setTypeAdditionalInfo] = useState<string>("")
  const [minusImage, setMinusImage] = useState(minusImg) // State to store the current minus image
  const [minusImagehour, setMinusImagehour] = useState(minusImg) // State to store the current minus image
  const [bookingDetailsShow, setBookingDetailsShow] = useState(false)

  const showDetails = searchParams.get("bookingdeatils")
  const servicetype = searchParams.get("type")

  // end post modla

  const handleServiceSelection = (value: any) => {
    setSelectService(value)

    setErrorMsg((prevErrors) => ({
      ...prevErrors,
      selectServiceError: "",
    }))
  }

  const handleClickName = (name: any) => {
    setSelectServiceName(name)
  }

  const handleAdditionalInfo = (value: any) => {
    setTypeAdditionalInfo(value)
    setErrorMsg((prevErrors) => ({
      ...prevErrors,
      additionalInfo: "",
    }))
  }

  useEffect(() => {
    if (servicetype !== "") {
      setVal(servicetype as any)
    }
  }, [servicetype])

  useEffect(() => {
    if (val) {
      setSelectService(val)
    }
  }, [val])

  console.log("servicetype", servicetype)

  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([])
  const [open, setOpen] = useState(false)
  const handleLanguageChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const selected = event.target.value as string[]
    if (selected.length > 2) {
      selected.splice(0, selected.length - 2)
    }
    setOpen(false)
    setSelectedLanguages(selected)
    setErrorMsg((prevErrors) => ({
      ...prevErrors,
      selectLanguage: "",
    }))
  }

  const postDetails = useAppSelector(
    (state: any) => state.postCreate.bookingDetails,
  )

  const { data: getservice, isFetching, isLoading } = useGetJobServiceQuery({})

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    sliderSetValue(newValue as number[])
  }

  const schemaResolver = yup.object().shape({
    // service: yup.string().required("Please select service"),
    date: yup
      .string()
      .required("Date is required")
      .matches(/^\d{4}-\d{2}-\d{2}$/, "Invalid date format (DD-MM-YYYY)"),
  })

  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    setValue,
  } = useForm<createBooking, any>({
    resolver: yupResolver(schemaResolver),
  })

  const onSubmit = async () => {
    const selectedDateTimeString = selectedDate + "T" + selectedStartTime
    const selectedDateTime = new Date(selectedDateTimeString)
    const utcString = selectedDateTime.toISOString()

    // End date and time

    var dateStr = utcString

    var date = new Date(dateStr)

    date.setHours(date.getHours() + hoursSend)

    var updatedDateStr = date.toISOString()

    const selectedEndDateTimeString = selectedDate + "T" + selectedEndTime
    const selectedEndDateTime = new Date(selectedEndDateTimeString)
    const utcEndString = selectedEndDateTime.toISOString()

    const payload: Payload = {
      location: loactionSearchData,
      service: selectService,
      serviceName: selectServiceName,
      ageRange: slidervalue.toString(),
      from: utcString,
      till: updatedDateStr,
      description: typeadditionalInfo || "",
      personRequired: count.toString(),
      language: selectedLanguages,
      attachment: arrayImages as any,
      hours: hoursSend,
      postPrice: priceData,
      stripepricekey: selectBookOffers?.map(
        (item: any) => item?.stripePriceKey,
      ),
      timeUpdate: selectedStartTime,
      updateEndTime: endTimeShow,
    }

    dispatch(setBookingDetails(payload as any))

    // dispatch(setPaymentBooking(payload as any))
    setBookingDetailsShow(true)

    // if (bookingDetailsShow) {
    navigate(`/dashboard/edit-bookings`)
    // }
  }

  const onValidation = () => {
    const countWords = (text: any) => text.trim().split(/\s+/).length
    switch (true) {
      case selectService === "":
        setErrorMsg((prevErrors) => ({
          ...prevErrors,
          selectServiceError: "Please select Service.",
        }))
        break
      // case selectedStartTime === "00:00":
      //   setErrorMsg((prevErrors) => ({
      //     ...prevErrors,
      //     startOnTimer: "Please select your Post time.",
      //   }))
      //   break
      case typeadditionalInfo && countWords(typeadditionalInfo) > 50:
        setErrorMsg((prevErrors) => ({
          ...prevErrors,
          additionalInfo: "Additional information cannot exceed 50 words.",
        }))
        break
      case loactionSearchData === "" || loactionSearchData === undefined:
        setErrorMsg((prevErrors) => ({
          ...prevErrors,
          selectLocation: "Please select your Location.",
        }))
        break

      case selectedLanguages?.length === 0:
        setErrorMsg((prevErrors) => ({
          ...prevErrors,
          selectLanguage: "Please select your Language.",
        }))
        break
      default:
        // setShowPop(true)
        onSubmit()
        break
    }
  }

  const handleIncrement = (key: any) => {
    if (key == "count" && count < 7) {
      setCount(count + 1)
      // setMinusImage(PinkMinusImg) // Use pinkMinusImg when incremented
    } else if (key == "hours" && hoursSend < 12) {
      setHoursSend(hoursSend + 1)
      // setMinusImagehour(PinkMinusImg) // Use pinkMinusImg when incremented
    }
  }

  const handleDecriment = (key: any) => {
    if (key == "count" && count > 1) {
      setCount(count - 1)
    } else if (key == "hours" && hoursSend > 1) {
      setHoursSend(hoursSend - 1)
    }
  }

  // confirm Modal on Submit

  const selectBookOffers = getservice?.data?.filter(
    (offers: any) => offers?._id == selectService,
  )

  useEffect(() => {
    reset({
      date: selectedDate,
    })
  }, [reset, selectedDate])
  // end function price with service

  //  start time functionality

  useEffect(() => {
    let initialDate
    if (postDetails?.from) {
      initialDate = moment(postDetails.from).format("YYYY-MM-DD")
    } else {
      const today = new Date()
      initialDate = today.toISOString().split("T")[0]
    }
    setSelectedDate(initialDate)
    updateAvailableTimes(initialDate, true)
  }, [postDetails?.from])

  const handleDateChange = (e: any) => {
    const newDate = e.target.value

    setSelectedDate(newDate)
    updateAvailableTimes(newDate, true)
  }

  const handleStartTimeChange = (e: any) => {
    const newStartTime = e.target.value
    setSelectedStartTime(newStartTime)
    setErrorMsg((prevErrors) => ({
      ...prevErrors,
      startOnTimer: "",
    }))
    updateEndTimeOptions(newStartTime)
  }

  const updateAvailableTimes = (date: any, isInitialSetup = false) => {
    const times = generateTimes(date)
    setStartTimeOptions(times as any)
    if (times.length > 0) {
      const initialTime = times[0]

      setSelectedStartTime(postDetails?.timeUpdate || initialTime)

      if (isInitialSetup) {
        updateEndTimeOptions(initialTime, times as any)
      }
    }
  }

  const updateEndTimeOptions = (
    startTime: any,
    startTimes = startTimeOptions,
  ) => {
    if (startTime === "23:00") {
      setEndTimeOptions(["23:59"] as any)
      setSelectedEndTime("23:59")
    } else {
      const startTimeIndex = (startTimes as any).indexOf(startTime)
      const newEndTimeOptions = startTimes.slice(startTimeIndex + 1)
      setEndTimeOptions(newEndTimeOptions)
      if (newEndTimeOptions.length > 0) {
        setSelectedEndTime(newEndTimeOptions[0])
      } else {
        setSelectedEndTime("")
      }
    }
  }

  const generateTimes = (date: any) => {
    const times = []
    const now = new Date()
    const selectedDay = new Date(date)
    const isToday =
      now.toISOString().split("T")[0] ===
      selectedDay.toISOString().split("T")[0]

    let startHour = 0

    if (isToday) {
      if (now.getHours() >= 22 && now.getMinutes() >= 30) {
        // Current time is 10:30 PM or later, start from midnight of the next day
        selectedDay.setDate(selectedDay.getDate() + 1) // Move to the next day
        startHour = 0
      } else {
        // Start from the next hour
        startHour =
          now.getMinutes() < 30 ? now.getHours() + 1 : now.getHours() + 2
      }
    }

    for (let hour = startHour; hour < 24; hour++) {
      times.push(`${hour.toString().padStart(2, "0")}:00`)
    }
    return times
  }

  useEffect(() => {
    const [hoursStr, minutesStr] = selectedStartTime?.split(":")

    let hours = parseInt(hoursStr, 10)

    hours = (hours + hoursSend) % 24

    const minutes = parseInt(minutesStr, 10)

    const newHoursStr = hours.toString().padStart(2, "0")
    const newTimeString = `${newHoursStr}:${minutesStr}`

    setEndTimeShow(newTimeString as any)

    // if (selectedStartTime === "00:00") {
    //   setSelectedStartTime("12:00")
    // }
  }, [selectedStartTime, hoursSend])

  // for price
  useEffect(() => {
    const bookingPrice = selectBookOffers?.map((ammount: any) => ammount?.price)
    const stringPrice = bookingPrice?.join()

    setPriceData(stringPrice)
  }, [selectBookOffers])

  // For Image Multiples

  function base64ToFile(base64Data: any, filename: any, mimeType: any) {
    const byteCharacters = atob(base64Data.split(",")[1])
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    const file = new File(byteArrays, filename, { type: mimeType })
    return file
  }
  const handleBookingsDeatils = () => {
    navigate(`/dashboard/create-booking`)
    setSearchParams({ bookingdeatils: "true" })
  }
  const imagesArray = (key: any) => {
    setArrayImages(key)
  }

  // reapeat post data

  useEffect(() => {
    setTypeAdditionalInfo(postDetails?.description)
    setSelectService(postDetails?.service || "")
    setLocationSearchData(postDetails?.location)
    setSelectedLanguages(postDetails?.language || [])
    if (postDetails?.hours) {
      const personHours = parseInt(postDetails.hours, 10)
      if (!isNaN(personHours)) {
        setHoursSend(personHours)
      }
    }
    if (postDetails?.personRequired) {
      const personRequiredNumber = parseInt(postDetails.personRequired, 10)
      if (!isNaN(personRequiredNumber)) {
        setCount(personRequiredNumber)
      }
    }

    if (postDetails?.ageRange) {
      if (postDetails?.ageRange) {
        const ages = postDetails.ageRange.split(",").map(Number)

        if (ages.length === 2 && ages[0] >= 18 && ages[1] <= 45) {
          sliderSetValue(ages)
        }
      }
    }
  }, [postDetails])

  // useEffect(() => {
  //   if (showDetails == "true") {
  //     setBookingDetailsShow(true)
  //   }
  // }, [showDetails])

  const handleRemove = (selectedList: any) => {
    setSelectedLanguages(selectedList as any)
  }

  return (
    <>
      {isFetching || isLoading ? (
        <CenteredSpinner />
      ) : (
        <Box className="">
          <Style.Overview>
            <Box className="feature-card pt-md-4 px-md-4">
              {bookingDetailsShow && (
                <span
                  className="step-back-btn"
                  onClick={() => {
                    setBookingDetailsShow(false)
                    setSearchParams({})
                  }}
                >
                  <FaChevronLeft />
                </span>
              )}
              {bookingDetailsShow && (
                <Stepper className="stepper-booking mb-5 pt-3">
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepLabel style={{ fontWeight: 700 }}>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              )}

              <form onSubmit={handleSubmit(onValidation)}>
                <Grid container>
                  <Grid xs={12} lg={5} className="mb-2 mb-md-4 h-auto pe-md-2">
                    <Box className="feature-card shadow-none p-2 mb-2 mb-md-4 d-flex align-items-center gap-4">
                      <Grid container>
                        <Grid xs={12}>
                          <Typography className="field-label mb-2">
                            <span style={{ color: "red" }}>*</span> Service
                            required
                          </Typography>
                        </Grid>

                        <Grid xs={12}>
                          <FormControl sx={{ m: 1, mx: 0 }} className="w-100">
                            <InputLabel id="service-label" className="bg-white">
                              Select a service
                            </InputLabel>

                            <Select
                              {...register("service" as any)}
                              disabled={bookingDetailsShow}
                              fullWidth
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 250,
                                    width: 150,
                                    overflowY: "auto",
                                    scrollbarWidth: "thin",
                                    scrollbarColor: "#f07fa9 transparent",
                                    borderRadius: "12px",
                                    cursor: "pointer",
                                  },
                                },
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                              }}
                              id="serviceId"
                              value={
                                selectService !== undefined && selectService
                              }
                              onChange={(event) =>
                                handleServiceSelection(event.target.value)
                              }
                            >
                              {getservice?.data?.map(
                                (option: any, index: number) => (
                                  <MenuItem
                                    key={index}
                                    value={option?._id}
                                    onClick={() => handleClickName(option.name)}
                                  >
                                    {option.name}
                                  </MenuItem>
                                ),
                              )}
                            </Select>
                          </FormControl>
                          <p className="text-danger error-text">
                            {errorMsg?.selectServiceError}
                          </p>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box className="feature-card shadow-none p-2 mb-2 mb-md-4 d-flex align-items-center gap-4">
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography className="field-label mb-2">
                            <span style={{ color: "red" }}></span> Age of
                            service provider
                            <small className="optional-text fw-bold">
                              {" "}
                              (optional)
                            </small>
                          </Typography>
                          <Typography className="optional-text mb-2">
                            (Minimum age is 18 and maximum age is 45)
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Box className="ps-3">
                            <Slider
                              disabled={bookingDetailsShow}
                              className="age-slider"
                              getAriaLabel={() => "Age Range"}
                              value={slidervalue}
                              onChange={handleSliderChange}
                              valueLabelDisplay="auto"
                              getAriaValueText={slidertext}
                              min={18}
                              max={45}
                            />
                            <Box className="d-flex justify-content-between">
                              <Typography className="range-text">
                                min
                              </Typography>
                              <Typography className="range-text">
                                max
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box className="d-flex align-items-center gap-4 p-2 mt-md-5 mb-5">
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={5} sm={4}>
                          <Typography className="field-label">
                            <span style={{ color: "red" }}>*</span> Select Date
                          </Typography>
                        </Grid>
                        <Grid item xs={7} sm={8} alignItems="center">
                          <Box className="d-flex flex-wrap gap-2">
                            <Box className="mb-2 w-100 d-flex">
                              <input
                                disabled={bookingDetailsShow}
                                type="date"
                                id="dateSelect"
                                className="w-100 date-field"
                                value={selectedDate}
                                {...register("date", {
                                  required: "Date is required",
                                })}
                                onChange={handleDateChange}
                                min={new Date().toISOString().split("T")[0]}
                              />
                              <p className="text-danger error-text">
                                {errors?.date?.message}
                              </p>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    {!bookingDetailsShow && (
                      <Box className="d-flex align-items-center gap-1 gap-md-4 p-2 mb-5">
                        <Grid container>
                          <Grid item xs={12} sm={4} className="m-auto">
                            <Typography className="field-label">
                              <span style={{ color: "red" }}>*</span> Select
                              time
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <Box className="d-flex gap-4">
                              <Box className="d-flex flex-column justify-content-center align-items-center gap-2">
                                <Box
                                  className="time-picker-field"
                                  style={{ height: "57px" }}
                                >
                                  <DemoItem>
                                    <select
                                      disabled={bookingDetailsShow}
                                      id="startTimeSelect"
                                      className="date-field"
                                      value={selectedStartTime}
                                      onChange={handleStartTimeChange}
                                      // {...register("from")} // Register "from" field
                                    >
                                      {startTimeOptions.map((time, index) => (
                                        <option key={index} value={time}>
                                          {time}
                                        </option>
                                      ))}
                                    </select>
                                  </DemoItem>
                                  <p className="text-danger error-text">
                                    {errorMsg?.startOnTimer}
                                  </p>
                                </Box>
                                <Box>
                                  {" "}
                                  <Typography className="field-label font-14">
                                    Start time
                                  </Typography>
                                </Box>
                              </Box>

                              <Box className="d-flex flex-column gap-2 justify-content-center align-items-center">
                                <Box className="d-flex align-items-center gap-3">
                                  <Button
                                    className="control-btn p-0 bg-transparent minus-btn"
                                    onClick={() => handleDecriment("hours")}
                                  >
                                    <img
                                      src={
                                        hoursSend == 1 ? minusImg : PinkMinusImg
                                      }
                                      className=""
                                    />
                                  </Button>
                                  <Input
                                    type="text"
                                    inputMode="numeric"
                                    placeholder="Give title to service"
                                    className="feature-field service-provider-field count-field d-flex font-16 select-time-field"
                                    value={hoursSend}
                                  />
                                  <Button
                                    className="control-btn p-0 bg-transparent plus-btn"
                                    onClick={() => handleIncrement("hours")}
                                  >
                                    <img src={plusImg} />
                                  </Button>
                                </Box>
                                <Box>
                                  {" "}
                                  <Typography className="field-label font-14">
                                    Hours{" "}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                    <Box className="d-flex align-items-center gap-4 p-2 mt-md-5 mb-5">
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={5} sm={4}>
                          <Typography className="field-label ">
                            Total Time
                          </Typography>
                        </Grid>
                        <Grid item xs={7} sm={8} alignItems="center">
                          <Input
                            disabled={bookingDetailsShow}
                            placeholder="total time"
                            className="feature-field font-16 total-time pe-none "
                            fullWidth
                            // value="20pm - 3am"asd
                            value={selectedStartTime + " - " + endTimeShow}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box className="feature-card shadow-none p-2 mb-2 mb-md-4 d-flex align-items-center gap-4 mb-5">
                      <Grid container>
                        <Grid item xs={12} sm={4}>
                          <Typography className="field-label lh-sm pe-2 pb-2  ">
                            <span style={{ color: "red" }}>*</span> Select
                            language (Max. Two languages are selectable)
                          </Typography>
                        </Grid>
                        <Grid xs={12} sm={8}>
                          <FormControl className="w-100">
                            <InputLabel
                              id="demo-multiple-chip-label"
                              className="bg-white"
                            >
                              Select Language
                            </InputLabel>
                            <Controller
                              name={"language" as any}
                              control={control}
                              defaultValue={[] as any}
                              rules={{
                                required: "Please select language",
                              }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  labelId="demo-multiple-chip-label"
                                  id="demo-multiple-chip"
                                  multiple
                                  disabled={bookingDetailsShow}
                                  open={open}
                                  onOpen={() => setOpen(true)}
                                  onClose={() => setOpen(false)}
                                  MenuProps={{
                                    PaperProps: {
                                      style: {
                                        maxHeight: 250,
                                        width: 150,
                                        overflowY: "auto",
                                        scrollbarWidth: "thin",
                                        scrollbarColor: "#f07fa9 transparent",
                                        borderRadius: "12px",
                                        cursor: "pointer",
                                      },
                                    },
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left",
                                    },
                                    transformOrigin: {
                                      vertical: "top",
                                      horizontal: "left",
                                    },
                                  }}
                                  value={selectedLanguages}
                                  onChange={handleLanguageChange as any}
                                  input={
                                    <OutlinedInput
                                      id="select-multiple-chip"
                                      label="Chip"
                                    />
                                  }
                                  renderValue={(selected) => (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: 0.5,
                                      }}
                                    >
                                      {selected.map((value) => (
                                        <Chip
                                          key={value}
                                          label={value}
                                          sx={{
                                            backgroundColor: "#F475A4",
                                            color: "#ffffff",
                                          }}
                                        />
                                      ))}
                                    </Box>
                                  )}
                                >
                                  {languages.map((name) => (
                                    <MenuItem
                                      key={name}
                                      value={name}
                                      sx={{
                                        "&.Mui-selected": {
                                          backgroundColor: "#F475A4",
                                          color: "#ffffff",
                                        },
                                        "&:hover": {
                                          backgroundColor: "#F475A4",
                                          color: "#000000",
                                        },

                                        color: "#000000",
                                      }}
                                    >
                                      {name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              )}
                            />
                            <p className="text-danger">
                              {errorMsg?.selectLanguage}
                            </p>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box className="feature-card shadow-none p-2 mb-2 mb-md-4 d-flex align-items-center gap-4 mb-5">
                      <Grid container>
                        <Grid item md={4} mb={2}>
                          <Box className="gap-1">
                            <Typography className="field-label lh-sm mb-2">
                              <span style={{ color: "red" }}>*</span> Number of
                              service providers
                            </Typography>
                            <Typography className="field-label pink-text">
                              (
                              {selectBookOffers?.map(
                                (ammount: any) => ammount?.price,
                              )}{" "}
                              CAD$/hr Per person)
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item md={6}>
                          <Box className="d-flex align-items-center gap-3">
                            {!bookingDetailsShow && (
                              <Button
                                className="control-btn p-0 bg-transparent minus-btn"
                                onClick={() => handleDecriment("count")}
                              >
                                <img
                                  src={count == 1 ? minusImg : PinkMinusImg}
                                  className=""
                                />
                              </Button>
                            )}
                            <Input
                              disabled={bookingDetailsShow}
                              type="text"
                              inputMode="numeric"
                              placeholder="Give title to service"
                              className="feature-field service-provider-field count-field font-16"
                              fullWidth
                              value={count}
                            />
                            {!bookingDetailsShow && (
                              <Button
                                className="control-btn p-0 bg-transparent plus-btn"
                                onClick={() => handleIncrement("count")}
                              >
                                <img src={plusImg} />
                              </Button>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={1}></Grid>
                  <Grid xs={12} lg={6} className="mb-2 mb-md-4 ps-md-2">
                    <Box className="feature-card shadow-none p-2 mb-2 mb-md-4">
                      <Typography className="field-label mb-2">
                        <span style={{ color: "red" }}>*</span> Location
                      </Typography>

                      <LocationSelector
                        bookingDetailsShow={bookingDetailsShow}
                        placeholder="Enter location"
                        loactionSearchData={loactionSearchData}
                        setLocationSearchData={setLocationSearchData as any}
                        setErrorMsg={setErrorMsg as any}
                        disabled={false}
                      />
                      <p className="text-danger error-text mt-2">
                        {errorMsg?.selectLocation}
                      </p>
                    </Box>
                    <Box className="feature-card shadow-none p-2 mb-2 mb-md-4">
                      <Typography className="field-label mb-2">
                        <span style={{ color: "red" }}> </span> Additional
                        instructions
                      </Typography>
                      <Input
                        disabled={bookingDetailsShow}
                        className="feature-field font-16"
                        placeholder="Additional instructions"
                        multiline
                        rows={"3"}
                        fullWidth
                        value={typeadditionalInfo}
                        // {...register("additionalInfo")}
                        onChange={(event) =>
                          handleAdditionalInfo(event.target.value)
                        }
                      />
                      <p className="text-danger error-text">
                        {errorMsg?.additionalInfo}
                      </p>
                    </Box>
                    <Box className="feature-card shadow-none p-2 position-relative">
                      <Typography className="field-label mb-3">
                        Add Photo{" "}
                        <small className="optional-text fw-bold">
                          {" "}
                          (optional)
                        </small>
                      </Typography>
                      <FileUpload
                        base64ToFile={base64ToFile}
                        uploadImageArray={imagesArray}
                        handleFile={"" as any}
                        setImageIndexCheck={"" as any}
                        reapeatImage={postDetails?.attachment}
                        bookingDetailsShow={bookingDetailsShow}
                      />
                    </Box>
                    {/* <MultipleImageUpload/> */}
                    <Box className="p-2">
                      <Button className="view-profile" type="submit">
                        {bookingDetailsShow ? "Next" : "Submit"}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Style.Overview>
        </Box>
      )}
    </>
  )
}
export default CreateBooking
