import React, { useState } from "react"
import dayjs from "dayjs"
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Input,
  Tooltip,
} from "@mui/material"
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker"
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker"
import { styled } from "@mui/material/styles"
import Switch, { SwitchProps } from "@mui/material/Switch"
import pending from "../../images/pending.png"
import { FaChevronLeft } from "react-icons/fa6"
import notifyIcon from "../../images/notify-icon.svg"
import { FaStar } from "react-icons/fa6"
import * as Style from "./style"
import offerImg from "../../images/cooking.png"
import { IoLocationOutline } from "react-icons/io5"
import { FaChevronRight } from "react-icons/fa6"
import { Link, useNavigate, useParams } from "react-router-dom"
import tableCellImg from "../../images/table-img.png"
import dummyImage from "../../images/dummy.png"
import babyImg from "../../images/babysitting.png"
import chatimage from "../../images/chatimage.png"
import {
  useAcceptGenrateBookingQuery,
  useGetBookingAllQuery,
  useGetPostTimeLineQuery,
  useGetProviderListQuery,
  useGetSeekerListQuery,
} from "../../features/OverViewAuth/overViewApi"
import moment from "moment"
import CircularIndeterminate from "../Loading/Loading"
import RatingStars from "../common/rating"

import {
  FormatChangeTime,
  FormatDate,
  FormatTime,
} from "../common/utctolocalDate"
import CenteredSpinner from "../common/CenteredSpinner"
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab"
import { CiMail } from "react-icons/ci"
import { LuPhone } from "react-icons/lu"
import { BsInfoCircleFill } from "react-icons/bs"
import { useCreateTicketMutation } from "../../features/ChatAuth/chatApi"
import PreviewImage from "../common/previewImage"
import TimeChangeModal from "../common/commonModal/timeChangeModal"
const BookingServiceDetails = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const urlSearch = new URLSearchParams(window.location.search)
  const chatId = urlSearch.get("chatId")
  const [showFullTimeline, setShowFullTimeline] = useState(false)
  const [selectedImage, setSelectedImage] = useState<string | null>(null)
  const [timeModal, setTimeModal] = useState<boolean>(false)

  const handleToggleTimeline = () => {
    setShowFullTimeline(!showFullTimeline)
  }
  const {
    data: upcomingBooking,

    isLoading,
  } = useAcceptGenrateBookingQuery({ id })
  const {
    data: timeLine,
    isLoading: timeisLoading,
    isFetching: timeisFetching,
  } = useGetPostTimeLineQuery({ id })

  const [chatView, { data: CharacterData }] = useCreateTicketMutation()

  const handleChatView = async () => {
    const payload = {
      postId: id,
    }

    try {
      const response: any = await chatView(payload)
      if (response?.data?.status === 200) {
        const chatId = response.data?.data?._id
        const adminId = response.data?.data?.adminstration

        if (chatId && adminId) {
          navigate(`/dashboard/help-center?chatId=${chatId}&adminId=${adminId}`)
        } else {
          console.error("Invalid chatId or adminId in response:", response)
        }
      } else {
        console.error("API call was not successful:", response)
      }
    } catch (error) {
      console.error("Error making API call:", error)
    } finally {
    }
  }

  // image preview on click functionality

  const handleImageClick = (image: string) => {
    setSelectedImage(image)
  }

  const handleClosePreview = () => {
    setSelectedImage(null)
  }

  return (
    <>
      {isLoading ? (
        <CenteredSpinner />
      ) : (
        <>
          <Box className="">
            {upcomingBooking?.data &&
              upcomingBooking?.data?.map((item: any) => (
                <Style.Overview>
                  <TimeChangeModal
                    OpenModal={timeModal}
                    handleClose={setTimeModal as any}
                    bookingInfo={item}
                  />
                  <Box className="feature-card py-4 px-2">
                    <Box className="d-flex justify-content-between align-items-center pb-3">
                      <Box className="d-flex align-items-center gap-4">
                        <Button
                          className="back-btn mb-0"
                          onClick={() => navigate(-1)}
                        >
                          <FaChevronLeft />
                        </Button>
                        <Typography variant="h2" className=" mb-0">
                          Service details
                        </Typography>
                      </Box>

                      <Box className="d-flex align-items-center gap-4">
                        {(item.status == "Applied" ||
                          item.status == "Generated") && (
                          <Typography>
                            <span
                              className="view-profile"
                              onClick={() => setTimeModal(true)}
                            >
                              Change booking timing
                            </span>
                          </Typography>
                        )}

                        <Typography className="status-text mb-0">
                          Status-{" "}
                          <span
                            className="completed fw-bold"
                            style={{
                              color:
                                item.status == "Completed"
                                  ? "green"
                                  : item.status == "Declined"
                                  ? "red"
                                  : item.status == "Expired"
                                  ? "blue"
                                  : "",
                              fontWeight: "bold",
                            }}
                          >
                            {item?.status}
                          </span>
                        </Typography>
                      </Box>
                    </Box>
                    <Grid container className="mb-5 p-4 service-detail-box-lg">
                      {item?.seekerData?.email !== "" &&
                        item?.seekerData?.email !== undefined && (
                          <Grid
                            xs={12}
                            md={5}
                            lg={4}
                            xl={3}
                            className="pe-2 pe-xl-4"
                          >
                            <Box className=" text-center d-flex flex-column align-items-center h-100">
                              <Box className="mb-2">
                                <img
                                  src={
                                    item?.seekerData?.profilePic || dummyImage
                                  }
                                  className="profile-img-2"
                                />
                              </Box>

                              <Typography
                                variant="h2"
                                align="center"
                                className="mb-2  text-capitalize fs-5 fw-600 text-black"
                              >
                                {item?.seekerData?.name || "-----"}
                              </Typography>
                              <Typography
                                variant="body1"
                                align="center"
                                className="mb-3 fw-bold d-inline-flex gap-1 align-items-center"
                              >
                                <span>
                                  {" "}
                                  <CiMail />{" "}
                                </span>{" "}
                                <span
                                  className="text-truncate"
                                  style={{
                                    width: "180px",
                                  }}
                                >
                                  {item?.seekerData?.email}
                                </span>
                                <Tooltip
                                  title={item?.seekerData?.email || "-----"}
                                  arrow
                                >
                                  <Button
                                    style={{
                                      minWidth: "10px",
                                    }}
                                    className="m-0 p-0"
                                  >
                                    <BsInfoCircleFill color="#F475A4" />
                                  </Button>
                                </Tooltip>
                              </Typography>
                              <Typography
                                variant="body1"
                                align="center"
                                className="mb-3 fw-bold"
                              >
                                <LuPhone />{" "}
                                {item?.seekerData?.contactNo || "-----"}
                              </Typography>
                            </Box>
                          </Grid>
                        )}
                      <Grid item lg={item?.seekerData?.email ? 4 : 6}>
                        <Box>
                          <Typography
                            variant="body1"
                            mb={3}
                            className="text-black d-flex gap-3"
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6}>
                                <span className="fw-bold">
                                  Service required
                                </span>{" "}
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <span> {item?.service?.name || "-----"}</span>
                              </Grid>
                            </Grid>
                          </Typography>
                          <Typography
                            variant="body1"
                            mb={3}
                            className="text-black d-flex gap-3"
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6}>
                                <span className="fw-bold">
                                  Number of provider required
                                </span>{" "}
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <span> {item?.personRequired || "-----"}</span>
                              </Grid>
                            </Grid>
                          </Typography>
                          <Typography
                            variant="body1"
                            mb={3}
                            className="text-black d-flex gap-3"
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6}>
                                <span className="fw-bold">
                                  Age of provider{" "}
                                </span>{" "}
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <span>
                                  {" "}
                                  {item?.ageRange?.join("-") || "-----"}
                                </span>
                              </Grid>
                            </Grid>
                          </Typography>
                          <Typography
                            variant="body1"
                            mb={3}
                            className="text-black d-flex gap-3"
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6}>
                                <span className="fw-bold">
                                  {/* <IoCalendarClearOutline /> */}
                                  Service date
                                </span>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                {item?.from ? (
                                  <span>
                                    {moment(item?.from).format(
                                      "DD MMM, dddd YYYY",
                                    )}
                                  </span>
                                ) : (
                                  "-----"
                                )}
                              </Grid>
                            </Grid>
                          </Typography>
                          <Typography
                            variant="body1"
                            mb={3}
                            className="text-black d-flex gap-3"
                          ></Typography>

                          <Typography
                            variant="body1"
                            mb={1}
                            className="text-black d-flex gap-3"
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6}>
                                {" "}
                                <span className="fw-bold">Language</span>{" "}
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <span> {item?.language?.join(" ")}</span>
                              </Grid>
                            </Grid>
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={item?.assignedTo?.[0]?.email ? 4 : 6}>
                        <Box style={{ marginLeft: "40px" }}>
                          <Typography
                            variant="body1"
                            mb={3}
                            className="text-black"
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={4}>
                                {" "}
                                <span className="fw-bold">
                                  {/* <IoLocationOutline /> */}
                                  Location
                                </span>{" "}
                              </Grid>
                              <Grid item xs={12} md={8}>
                                <span> {item?.location || "-----"}</span>
                              </Grid>
                            </Grid>
                          </Typography>
                          <Typography
                            variant="body1"
                            mb={3}
                            className="text-black"
                          >
                            {item?.description && (
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={4}>
                                  {" "}
                                  <span className="fw-bold">
                                    Additional instructions
                                  </span>{" "}
                                </Grid>
                                <Grid item xs={12} md={8}>
                                  <span style={{ overflowWrap: "anywhere" }}>
                                    {" "}
                                    {item?.description || "-----"}
                                  </span>
                                </Grid>
                              </Grid>
                            )}
                          </Typography>
                          {item?.attachments?.length > 0 && (
                            <Typography
                              variant="body1"
                              mb={1}
                              className="text-black d-flex gap-3"
                            >
                              <span className="fw-bold">
                                Uploaded <br />
                                Photos
                              </span>
                            </Typography>
                          )}
                          {item?.attachments?.map((imagePost: any) => (
                            <img
                              src={imagePost}
                              className="photo-upload me-2"
                              onClick={() => handleImageClick(imagePost)}
                            />
                          ))}
                          <PreviewImage
                            selectedImage={selectedImage}
                            handleClosePreview={handleClosePreview}
                          />
                        </Box>
                      </Grid>
                    </Grid>

                    {item?.assignedTo?.length > 0 ? (
                      <>
                        <Box>
                          <Typography align="left" variant="h2" mb={2}>
                            Service provider details
                          </Typography>
                        </Box>
                        <TableContainer
                          component={Paper}
                          className="dashboard-table-wrapper custom-scrollbar overflow-y-hidden mh-1"
                        >
                          <Table
                            style={{
                              minWidth: 650,
                              border: "none",
                              backgroundColor: "#fff",
                              borderRadius: "16px",
                              overflow: "hidden",
                            }}
                            className="dashboard-table"
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell></TableCell>
                                <TableCell
                                  align="center"
                                  className="service-head"
                                >
                                  Service Provider
                                </TableCell>
                                <TableCell align="center">Phone</TableCell>
                                <TableCell align="center">Email</TableCell>
                                <TableCell align="center">Language</TableCell>
                                <TableCell align="center">Action</TableCell>
                                {/* <TableCell align="center">Age</TableCell> */}
                                {/* <TableCell></TableCell> */}
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {item.assignedTo.map((row: any) => (
                                <TableRow
                                  key={row.name}
                                  sx={{ cursor: "pointer" }}
                                  onClick={() =>
                                    navigate(
                                      `/dashboard/provider-profile/${row._id}`,
                                    )
                                  }
                                >
                                  <TableCell>
                                    <div className="img-wrapper ms-1">
                                      <img
                                        src={row.profilePic || dummyImage}
                                        alt={row.name}
                                        style={{
                                          width: "74px",
                                          height: "74px",
                                          objectFit: "cover",
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    component="td"
                                    align="center"
                                    scope="row"
                                  >
                                    <span className="text flex-column gap-2">
                                      {row.name || "--"}
                                      <span className="minh-1">
                                        <RatingStars
                                          rating={row.rating?.$numberDecimal}
                                        />
                                      </span>
                                    </span>
                                  </TableCell>
                                  <TableCell align="center">
                                    <span>{row.contactNo}</span>
                                  </TableCell>
                                  <TableCell align="center">
                                    <span className="email">
                                      <span>{row.email}</span>
                                    </span>
                                  </TableCell>
                                  <TableCell align="center">
                                    <span className="language mx-auto">
                                      {row?.language &&
                                      row.language.length > 0 ? (
                                        <>
                                          {row.language.slice(0, 2).join(", ")}
                                          {row.language.length > 2 && "..."}
                                        </>
                                      ) : (
                                        "-----"
                                      )}
                                    </span>
                                  </TableCell>
                                  <TableCell align="center">
                                    <span>
                                      <Link
                                        to={`/dashboard/provider-profile/${row._id}`}
                                        className="view-profile"
                                      >
                                        View Profile
                                      </Link>
                                    </span>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    ) : item?.status == "Declined" ||
                      item?.status == "Expired" ? (
                      ""
                    ) : (
                      <Box className="not-assigned d-flex align-items-center gap-2 mt-5">
                        <div className="not-assigned-wrapper mt-2">
                          <h4>Service provider is not assigned yet</h4>
                          <p>
                            We will notify you once service provider is assigned
                            to you
                          </p>
                        </div>
                        <div className="pending-icon">
                          <img src={pending} />
                        </div>
                      </Box>
                    )}
                  </Box>
                  {(item?.status == "Accepted" || item?.status == "Active") && (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "50px",
                      }}
                    >
                      <Button
                        className="timeline-btn me-4"
                        onClick={handleChatView}
                      >
                        Chat
                        <img
                          src={chatimage}
                          alt="img"
                          style={{ marginLeft: "10px" }}
                        />
                      </Button>
                    </Box>
                  )}
                </Style.Overview>
              ))}
          </Box>
          {timeLine !== undefined && (
            <Style.Overview>
              <Box
                className="d-flex my-5"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography align="left" variant="h2" mb={2}>
                  Timeline
                </Typography>
              </Box>

              <Box className="timeline-graph">
                {timeLine?.data
                  ?.slice(0, showFullTimeline ? timeLine?.data?.length : 3)
                  .map((timeline: any, index: number) => (
                    <Timeline
                      key={timeline._id}
                      position={index % 2 === 0 ? "alternate" : "left"}
                    >
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "10px 0 0" }}
                          align="right"
                          className="timeline-desc"
                          color="text.secondary"
                        >
                          {/* {new Date(timeline.createdAt).toLocaleDateString()} */}
                          {/* new date format */}
                          <span className="datetime">
                            <span className="d-flex flex-column">
                              <span className="weekday">
                                <FormatDate dateString={timeline.createdAt} />
                              </span>
                              <span>
                                <FormatChangeTime
                                  dateString={timeline.createdAt}
                                />
                              </span>
                              <span>
                                <FormatTime dateString={timeline.createdAt} />
                              </span>
                            </span>
                          </span>

                          <br />
                          {/* {moment(timeline.updatedAt).format("h:mm a")} */}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot>
                            <Typography className="timeline-step">
                              0{timeLine?.data?.length - index}
                            </Typography>
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 0.5 }}>
                          <Box className="timeline-content ">
                            <Typography className="timeline-heading">
                              Status - {timeline?.postStatus}{" "}
                            </Typography>
                            <Typography className="timeline-desc"></Typography>
                            <Typography className="timeline-desc">
                              {timeline?.message}{" "}
                            </Typography>
                          </Box>
                        </TimelineContent>
                      </TimelineItem>
                    </Timeline>
                  ))}
                <div className="d-flex justify-content-center">
                  {timeLine?.data.length > 3 && (
                    <Button
                      onClick={handleToggleTimeline}
                      className="view-profile py-2 text-capitalize"
                    >
                      {showFullTimeline ? "Show Less" : "Show More"}
                    </Button>
                  )}
                </div>
              </Box>
            </Style.Overview>
          )}
        </>
      )}
    </>
  )
}
export default BookingServiceDetails
