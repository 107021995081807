import * as React from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"

import { CircularProgress, Grid } from "@mui/material"

import { toast } from "react-toastify"
import { bool, boolean } from "yup"

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid #999",
  borderRadius: "20px",
  boxShadow: 24,
  width: "400px",
  height: "200px",
  p: 4,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}
const viewProfileBtn = {
  display: "inline-block",
  width: "100px",
  padding: "8px 8px",
  border: "1px solid #F475A4",
  borderRadius: "8px",
  color: "#F475A4",
  fontSize: "14px",
  textAlign: "center",
  textDecoration: "none",
}
const viewProfileBtnTwo = {
  display: "inline-block",
  width: "100px",
  padding: "8px 8px",
  border: "1px solid #F475A4",
  borderRadius: "8px",
  color: "#fff",
  fontSize: "14px",
  textAlign: "center",
  textDecoration: "none",
  backgroundColor: "#F475A4",
  "&:hover": {
    color: "#F475A4",
  },
}
interface props {
  OpenModal: boolean
  handleClose: () => void
  conFirmPost: () => void
  postLoading: boolean
  paymentLoading: boolean
  updateTimeBooking: () => void
  postid: string
  postId2: string
}
export default function ConfirmPost({
  OpenModal,
  handleClose,
  conFirmPost,
  postLoading,
  paymentLoading,
  updateTimeBooking,
  postid,
  postId2,
}: props) {
  const handleNoClick = () => {
    handleClose()
  }

  return (
    <div>
      <Modal
        open={OpenModal as any}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}></Grid>
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ mb: 5, textAlign: "center", fontSize: "16px" }}
            >
              Please confirm the changes before submit. You'll not be able to
              change anything further
            </Typography>

            <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
              <Button
                sx={viewProfileBtnTwo}
                className="me-2"
                onClick={() => {
                  if (postid !== postId2) {
                    conFirmPost()
                  } else {
                    updateTimeBooking()
                  }
                }}
              >
                {postLoading || paymentLoading ? (
                  <Typography fontSize={1}>
                    <CircularProgress
                      style={{ color: "pink", fontSize: 20 }}
                      size={20}
                    />
                  </Typography>
                ) : (
                  "Yes"
                )}
              </Button>
              <Button sx={viewProfileBtn} onClick={handleNoClick}>
                No
              </Button>
            </Box>
          </Box>
        </Grid>
      </Modal>
    </div>
  )
}
