import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { manageViewApi } from "./manageApi"

const slice = createSlice({
  name: "manageViewAuth",
  initialState: { user: null, token: null } as {
    user: null | any
    token: null | string
  },
  reducers: {
    token: (state: any, action: any) => {
      state.user = null
      state.token = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      manageViewApi.endpoints.getGenratePost.matchFulfilled,
      (state, { payload }) => {
        // console.log("payload check", payload)
        state.token = payload.token
        // state.user = payload.result.user
      },
    )
  },
})

export default slice.reducer
export const { token } = slice.actions

export const selectCurrentUser = (state: RootState) => state.manageViewAuth
