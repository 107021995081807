// import moment from "moment"

// // Function to calculate the time difference and return a formatted string
// interface props {
//   createdAt: string
// }
// export const CalculateTimeDifference = ({ createdAt }: props) => {
//   const currentDate = moment()
//   const notificationTime = moment(createdAt)
//   const diffInHours = currentDate.diff(notificationTime, "hours")
//   const diffInDays = currentDate.diff(notificationTime, "days")

//   if (diffInHours < 1) {
//     return `${diffInHours} hour${diffInHours === 1 ? "" : "s"} ago`
//   } else if (diffInDays < 1) {
//     return `${diffInHours} hour${diffInHours === 1 ? "" : "s"} ago`
//   } else {
//     return `${diffInDays} day${diffInDays === 1 ? "" : "s"} ago`
//   }
// }

// import moment from "moment"

// interface Props {
//   createdAt: string
// }

// export const CalculateTimeDifference = ({ createdAt }: Props) => {
//   const currentDate = moment()
//   const notificationTime = moment(createdAt)
//   const diffInMinutes = currentDate.diff(notificationTime, "minutes")
//   const diffInHours = currentDate.diff(notificationTime, "hours")
//   const diffInDays = currentDate.diff(notificationTime, "days")

//   if (diffInMinutes < 1) {
//     return "Just now"
//   } else if (diffInMinutes < 60) {
//     return `${diffInMinutes} minute${diffInMinutes === 1 ? "" : "s"} ago`
//   } else if (diffInHours < 24) {
//     return `${diffInHours} hour${diffInHours === 1 ? "" : "s"} ago`
//   } else {
//     return `${diffInDays} day${diffInDays === 1 ? "" : "s"} ago`
//   }
// }

import moment from "moment"

interface Props {
  createdAt: string
}

export const CalculateTimeDifference = ({ createdAt }: Props) => {
  const currentDate = moment()
  const notificationTime = moment(createdAt)
  const diffInMinutes = currentDate.diff(notificationTime, "minutes")
  const diffInHours = currentDate.diff(notificationTime, "hours")
  const diffInDays = currentDate.diff(notificationTime, "days")

  if (diffInMinutes < 1) {
    return "Just now"
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes} minute${diffInMinutes === 1 ? "" : "s"} ago`
  } else if (diffInHours < 24) {
    return `${diffInHours} hour${diffInHours === 1 ? "" : "s"} ago`
  } else if (diffInDays === 1) {
    return "Yesterday"
  } else {
    return notificationTime.format("MMM DD [,] h:mm A")
  }
}

interface chatProps {
  createdAts?: string // Make createdAt optional
}

export const CalculateTimeDifferenceChat = ({ createdAts }: chatProps) => {
  if (!createdAts) {
    return "" // Return empty string if createdAt is null or undefined
  }

  const currentDate = moment()
  const notificationTime = moment(createdAts)
  const diffInMinutes = currentDate.diff(notificationTime, "minutes")
  const diffInHours = currentDate.diff(notificationTime, "hours")
  const diffInDays = currentDate.diff(notificationTime, "days")

  if (diffInMinutes < 1) {
    return "Just now"
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes} minute${diffInMinutes === 1 ? "" : "s"} ago`
  } else if (diffInHours < 24) {
    return `${diffInHours} hour${diffInHours === 1 ? "" : "s"} ago`
  } else if (diffInDays === 1) {
    return "Yesterday"
  } else if (diffInDays === 2) {
    return "2 days ago"
  } else {
    return notificationTime.format("M/D/YYYY") // Format like 5/21/2024
  }
}
