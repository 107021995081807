// import React, { useEffect, useState } from "react"
// import { useAppDispatch } from "../../app/store"
// import { useNavigate } from "react-router-dom"
// import { useEmailVerifyQuery } from "../../features/Auth/authApi"
// import { emailVerifyToken, logout } from "../../features/Auth/authSlice"
// import verifysuccess from "../../images/verifyemail.png"
// import allreadyEmail from "../../images/emailallready.png"
// import emailError from "../../images/emailerror.png"
// import { toast } from "react-toastify"
// import { CircularProgress, Typography } from "@mui/material"
// import CenteredSpinner from "../common/CenteredSpinner"
// type Props = {}

// export default function EmailVerify({}: Props) {
//   const dispatch = useAppDispatch()
//   const navigate = useNavigate()
//   const urlSearchParams = new URLSearchParams(window.location.search)
//   const token = urlSearchParams.get("token")

//   const { data, isFetching, isLoading, error } = useEmailVerifyQuery(
//     {},
//     // { skip: !token },
//   )

//   if (isLoading || isFetching) {
//     dispatch(emailVerifyToken(token as any))
//   }

//   useEffect(() => {
//     if (data?.message) {
//       dispatch(logout())
//       toast.success(data?.message, { autoClose: 1000 })
//       const timer = setTimeout(() => {
//         window.location.href = data?.redirect
//       }, 1000)

//       return () => clearTimeout(timer)
//     } else if ((error as any)?.status == 401) {
//       navigate("/")
//       toast.error((error as any)?.data?.message)
//     }
//   }, [data, error])

//   return <>{isLoading && <CenteredSpinner />}</>
// }

import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "../../app/store"
import { useEmailVerifyQuery } from "../../features/Auth/authApi"
import { emailVerifyToken, logout } from "../../features/Auth/authSlice"
import { toast } from "react-toastify"
import { CircularProgress } from "@mui/material"
import CenteredSpinner from "../common/CenteredSpinner"

const EmailVerify = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const urlSearchParams = new URLSearchParams(window.location.search)
  const token = urlSearchParams.get("token")

  const { data, isLoading, error } = useEmailVerifyQuery({})

  useEffect(() => {
    if (data) {
      dispatch(logout())

      toast.success(data.message, { autoClose: 1000 })

      const timer = setTimeout(() => {
        window.location.href = data.redirect
      }, 1000)

      return () => clearTimeout(timer)
    } else if (error) {
      if ((error as any).status === 401) {
        navigate("/")
      }
      toast.error((error as any).data.message)
    }
  }, [data, error, dispatch, navigate])

  useEffect(() => {
    if (isLoading) {
      dispatch(emailVerifyToken(token as any))
    }
  }, [isLoading, token, dispatch])

  if (isLoading) {
    return <CenteredSpinner />
  }

  return null
}

export default EmailVerify
