import { Badge, Col, Row, Stack } from "react-bootstrap"
import styles from "./ChatUi.module.css"
import { CiMail } from "react-icons/ci"
import { MdOutlineLocalPhone } from "react-icons/md"
import mailIcon from "../../images/mail.svg"
import phoneIcon from "../../images/phone.svg"
import Button from "react-bootstrap/Button"
import user from "../../images/user.svg"
import { MdKeyboardArrowLeft } from "react-icons/md"
import { useNavigate, useSearchParams } from "react-router-dom"
import Logo from "../../images/momy_logo.svg"
import { Ticket } from "../ChatenterFace/chatinterface"
import { useAppSelector } from "../../app/hooks"
import { Dropdown } from "react-bootstrap"
import { IoMdMenu } from "react-icons/io"
interface UserName {
  _id: string
  adminName: string
  postId: { status: string; _id: string }
}
interface Props {
  setChatResponsive: (value: boolean) => void
  // userName: UserName[]
}
const SelectedChatInfo = ({ setChatResponsive }: Props) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const _chatId = searchParams.get("chatId")

  const state = useAppSelector((state: any) => state.authChatView)
  const TicketPath: Ticket[] =
    state._search?.split("").length > 0
      ? state._searchedTickets
      : state._Tickets

  const selectedUser = TicketPath?.find((user) => user._id === _chatId)

  const navigateDetailsPage = (key: any) => {
    if (key?.status == "Active") {
      navigate(`/dashboard/active-view/${key?._id}`)
    } else {
      navigate(`/dashboard/bookings/details/${key?._id}`)
    }
  }

  return (
    <Row className="mt-1 me-0">
      <Col
        className={`d-flex justify-content-between align-items-center mt-2 mb-1 mt-lg-0 p-1 py-sm-2 border-0 ${styles["custom-shadow"]} ${styles["rounded-xl"]} ${styles["Chat-bar-info1"]} `}
      >
        <Stack direction="horizontal" gap={1}>
          <div className={'p-0 p-lg-0 p-sm-3  ${styles["padd-0"]}'}></div>
          {selectedUser !== undefined && (
            <div
              className={` ${styles["left-arrow"]} `}
              onClick={() => setChatResponsive(false)}
            >
              <MdKeyboardArrowLeft />
            </div>
          )}

          {/* safdghsdf */}

          <div
            className={`p-2 ms-2 d-flex align-items-center ${styles["chat-info-img"]} `}
          >
            {selectedUser !== undefined && <img src={Logo} />}
            <div className="d-flex flex-column">
              <h2 className={`ms-2 ${styles["profile-name"]}`}>
                {(selectedUser as any)?.adminstration?.name}
              </h2>
              <p className={`ms-2 ${styles["profile-desc"]} `}>
                {selectedUser?.postId?.serviceDetails?.name}
              </p>
            </div>

            {selectedUser?.postId?.status == "Active" && (
              <span className={`${styles["selected-info-badge"]} ms-4 ms-lg-2`}>
                <Badge>Active Job</Badge>
              </span>
            )}
          </div>
        </Stack>
        <div className={`d-flex gap-3 ${styles["selected-chat"]} `}>
          {/* <img src={mailIcon} />
          <img src={phoneIcon} /> */}
          {selectedUser !== undefined && (
            <Button
              className={` me-2 ${styles["Service-btn"]} `}
              onClick={() => navigateDetailsPage(selectedUser?.postId)}
            >
              Track <span className="d-none d-sm-inline-block"> Service</span>
            </Button>
          )}
        </div>
      </Col>
    </Row>
  )
}

export default SelectedChatInfo
