import React, { useEffect, useState } from "react"
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  LinearProgress,
  Paper,
  Radio,
  Rating,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"
import { LiaAngleRightSolid } from "react-icons/lia"
import * as Style from "./style"
import moment from "moment"
import profileImg from "../../images/babysitting.png"
import { CiMail } from "react-icons/ci"
import { LuPhone } from "react-icons/lu"
import { LuUserCircle } from "react-icons/lu"
import { FaPencilAlt } from "react-icons/fa"
import { FaRegUser } from "react-icons/fa6"
import { LuMapPin } from "react-icons/lu"
import { LuMail } from "react-icons/lu"
import { FaFileInvoice } from "react-icons/fa6"
import { LuLock } from "react-icons/lu"
import { Link, useNavigate } from "react-router-dom"
import {
  useChanegPasswordsMutation,
  useGetProfileQuery,
} from "../../features/Auth/authApi"
import dummy from "../../images/dummy.png"

import { toast } from "react-toastify"
import ChangePasswordModal from "./ChangePasswordModal"
import CenteredSpinner from "../common/CenteredSpinner"
import EditAccount from "./EditAccount"

const MyAccount = () => {
  const { data: getProfile, isFetching, isLoading } = useGetProfileQuery({})
  const [open, setOpen] = React.useState(false)
  const [editSave, setEditSave] = useState(true)

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Style.Overview>
        {isLoading ? (
          <CenteredSpinner />
        ) : (
          <Grid container>
            <Grid xs={12} md={5} lg={4} className="pe-2 pe-xl-4">
              <Box className="review-card text-center p-4 d-flex flex-column align-items-center h-100">
                <Box className="mb-4">
                  <img
                    src={getProfile?.data?.profilePic || dummy}
                    className="profile-img-2"
                  />
                </Box>
                <Typography variant="body1" align="center" className="mb-3">
                  {getProfile?.data?.name || "--"}
                </Typography>
                <Typography
                  variant="body1"
                  align="center"
                  className="mb-3 d-flex gap-2 align-items-center"
                >
                  <CiMail /> {getProfile?.data?.email || "--"}
                </Typography>
                <Typography variant="body1" align="center" className="mb-3">
                  <LuPhone /> {getProfile?.data?.contactNo || "--"}
                </Typography>
                <Link to={"/dashboard/Invoice"} className="view-profile mb-3">
                  My Invoice{" "}
                  <FaFileInvoice style={{ verticalAlign: "baseline" }} />
                </Link>
                <Link
                  to=""
                  onClick={() => setEditSave(!editSave)}
                  className="view-profile mb-3"
                >
                  Edit <FaPencilAlt style={{ verticalAlign: "baseline" }} />
                </Link>
                <Button
                  onClick={() => setOpen(true)}
                  className="view-profile mb-3 "
                  style={{ textTransform: "capitalize" }}
                >
                  Change Password{" "}
                  <span className="">
                    {" "}
                    <LuLock style={{ verticalAlign: "baseline" }} />
                  </span>
                </Button>
              </Box>
            </Grid>

            <Grid xs={12} md={7} lg={8} className=" ps-xl-4">
              {editSave ? (
                <Box className="review-card p-4 h-100">
                  <Grid container className="mb-4">
                    <Grid xs={4}>
                      <Typography variant="body1">
                        <strong> Name </strong>
                      </Typography>
                    </Grid>
                    <Grid xs={8}>
                      <Typography variant="body1" className=" ">
                        {getProfile?.data?.name || "--"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container className="mb-4">
                    <Grid xs={4}>
                      <Typography variant="body1">
                        <strong> E-Mail </strong>
                      </Typography>
                    </Grid>
                    <Grid xs={8}>
                      <Typography variant="body1" className=" ">
                        {getProfile?.data?.email || "--"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container className="mb-4">
                    <Grid xs={4}>
                      <Typography variant="body1">
                        <strong> Phone Number </strong>
                      </Typography>
                    </Grid>
                    <Grid xs={8}>
                      <Typography variant="body1" className=" ">
                        {getProfile?.data?.contactNo || "--"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container className="mb-4">
                    <Grid xs={4}>
                      <Typography variant="body1">
                        <strong> Gender </strong>
                      </Typography>
                    </Grid>
                    <Grid xs={8}>
                      <Typography variant="body1" className=" ">
                        {getProfile?.data?.gender || "--"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container className="mb-4">
                    <Grid xs={4}>
                      <Typography variant="body1">
                        <strong>Language </strong>
                      </Typography>
                    </Grid>
                    <Grid xs={8}>
                      <Typography variant="body1" className="overflow-anywhere">
                        {getProfile?.data?.language?.join(", ") || "--"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container className="mb-4">
                    <Grid xs={4}>
                      <Typography variant="body1">
                        <strong> Date of Birth </strong>
                      </Typography>
                    </Grid>
                    <Grid xs={8}>
                      <Typography variant="body1" className=" ">
                        {getProfile?.data?.dob ? (
                          <>
                            {moment(getProfile?.data?.dob).format("YYYY-MM-DD")}
                          </>
                        ) : (
                          "--"
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container className="mb-4">
                    <Grid xs={4}>
                      <Typography variant="body1">
                        {" "}
                        <strong> Location </strong>
                      </Typography>
                    </Grid>
                    <Grid xs={8}>
                      <Typography variant="body1" className=" ">
                        {getProfile?.data?.address?.location || "--"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <EditAccount setEditSave={setEditSave} editSave={editSave} />
              )}
            </Grid>
          </Grid>
        )}
      </Style.Overview>
      <ChangePasswordModal opens={open} handleClose={handleClose} />
    </>
  )
}
export default MyAccount
