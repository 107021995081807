import { styled } from "@mui/material";
const TableAppointment = styled("div")(({ theme }) => ({
    '& .MuiTableCell-root': {
        fontFamily: ' Nunito Sans',
        padding: '0',
        borderBottom: '0'

    },
    'th.MuiTableCell-root.MuiTableCell-head ': {
        color: '#7c7c7c',
        padding: '10px 0',
    },
    'td': {
        background: theme.palette.white.main
    }

}))
export { TableAppointment }