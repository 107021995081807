import { styled } from "@mui/material";
const Header = styled("div")(({ theme }) => ({
    
    '& .MuiDrawer-paper': {
        backgroundColor: theme.palette.pink.main,
        '& .MuiListItem-root ': {
            color: theme.palette.white.main,
            '& svg': {
                color: theme.palette.white.main,
            },
        },
        "& .sidebar-item.active" : {
            "& .sidebar-button" : {
                backgroundColor: theme.palette.white.main,
                color: theme.palette.pink.main,
                '& svg': {
                    color: theme.palette.pink.main,
                },
            }
        }
    },
    '& .MuiListItemText-primary':{
        fontFamily:'Montserrat',
        fontWeight:'300',
    },
    '& .MuiButtonBase-root':{
        'svg':{
        color: theme.palette.white.main,
    },
},
    '& .MuiButtonBase-root.MuiListItemButton-root':{
        borderRadius:'8px',
        transition:'.3s ease',
        '&:hover':{
backgroundColor: theme.palette.white.main,
color: theme.palette.pink.main,

'svg':{
    color: theme.palette.pink.main
}
        }
    }
}));

export { Header }