import * as React from "react"
import CircularProgress from "@mui/material/CircularProgress"
import { Box } from "@mui/material"
import "./Style.css"

export default function CircularIndeterminate() {
  return (
    <div className="loading-container">
      <Box
        sx={{
          display: "flex",
        }}
      >
        <CircularProgress style={{color:"pink"}} />
      </Box>
    </div>
  )
}
