import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import NotificationPage from "./notificationModal/NotificationPage"
import Dropdown from "react-bootstrap/Dropdown"
import { IoMdMenu } from "react-icons/io"
import {
  Avatar,
  Box,
  IconButton,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
  InputBase,
} from "@mui/material"
import { styled, alpha } from "@mui/material/styles"
import { Logout } from "@mui/icons-material"
import MenuIcon from "@mui/icons-material/Menu"
import MuiAppBar from "@mui/material/AppBar"
import * as Style from "./style"
import { AiOutlineSearch } from "react-icons/ai"
import { useAppSelector } from "../../app/hooks"
import { logout, setuserProfile } from "../../features/Auth/authSlice"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useAppDispatch } from "../../app/store"
import bellIcon from "../../images/notification.svg"
import addCirclePlus from "../../images/add-circle-plus.svg"
import { Button } from "react-bootstrap"
import { propTypes } from "react-bootstrap/esm/Image"
import { useGetProfileQuery } from "../../features/Auth/authApi"
import LogoutConfirm from "../Navbar/logout"

import LogoPhone from "../../images/logo-home.svg"
import message from "../../images/message.svg"
import chat from "../../images/chat.svg"
import chat2 from "../../images/chat2.png"
import { chatViewApi } from "../../features/ChatAuth/chatApi"
const drawerWidth = 300

interface typeprops {
  open: any
  theme: any
}
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }: typeprops) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...((open as any) && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const CustomerHeader = ({
  open,
  position,
  handleDrawerOpen,
  theme,
}: {
  open: boolean
  position: string
  theme: string
  handleDrawerOpen: () => void
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const { id, type } = useParams()
  const location = useLocation()
  const pathLocation = location?.pathname
  const dispatch = useAppDispatch()
  const token = useAppSelector((state: any) => state.auth.token)
  const [segamentPath, setSegamentPath] = useState()
  const [logoutModal, setLogoutModal] = React.useState(false)
  const handleLogoutOpen = () => setLogoutModal(true)
  const handlelogoutClose = () => setLogoutModal(false)

  // const { data, isFetching, isLoading } = useGetProfileQuery({})

  const userDetails: any = useAppSelector((state) => state.auth)

  const navigate = useNavigate()

  // useEffect(() => {
  //   if (data) {
  //     dispatch(setuserProfile(data))
  //   }
  // }, [data])

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    const lastPathSegment = window.location.pathname
      .split("/")
      .filter((segment: any) => segment !== "")
      .pop()

    if (lastPathSegment !== undefined) {
      const capitalizedLastPathSegment =
        lastPathSegment.charAt(0).toUpperCase() + lastPathSegment.slice(1)
      setSegamentPath(capitalizedLastPathSegment as any)
    }
  }, [window.location.pathname])

  //  remove - on path
  const updatedSegment = (segamentPath as any)?.replace("-", " ")

  const logoutButton = () => {
    handleLogoutOpen()
  }

  const viewProfilehandle = () => {
    navigate("/dashboard/myaccount")
    handleClose()
  }

  const handleChat = () => {
    navigate(`/dashboard/help-center`)
  }

  return (
    <Style.Header>
      <AppBar
        position={position as any}
        open={open as boolean}
        style={{ boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.23)" }}
        theme={"" as any}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            background: "pink?.main",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              // color="inherit"
              // aria-label="open drawer"
              // onClick={handleDrawerOpen}
              // edge="start"
              sx={{
                background: "#F475A4",
                height: "64px",
                width: "65px",
                marginLeft: -3,

                "@media (max-width:600px)": {
                  height: "56px",
                  width: "65px",
                },
                ...(open && { display: "none" }),
              }}
            >
              <Box
                ml={0}
                sx={{
                  img: { maxWidth: "40px" },
                  cursor: "pointer",
                  borderRadius: "10px",
                  marginLeft: "10px",
                  marginTop: "10px",
                  "@media (max-width:600px)": {
                    marginLeft: "15px",
                    marginTop: "5px",
                  },
                }}
                onClick={() => navigate("/dashboard")}
              >
                <img
                  src={LogoPhone}
                  alt="LOGO"
                  title="home"
                  style={{ background: "#F475A4" }}
                  className="img-fluid rounded "
                />
              </Box>{" "}
            </Box>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                fontWeight: "700",
                fontSize: "24px",
                marginLeft: open ? "0px" : "10px",
                "@media (max-width:900px)": {
                  fontSize: "16px",
                },
                "@media (max-width:575px)": {
                  fontSize: "14px",
                },
              }}
            >
              {!id ? (
                <>
                  {updatedSegment === "Dashboard"
                    ? "Overview"
                    : updatedSegment == "All"
                    ? "Booking History"
                    : updatedSegment == "Completed"
                    ? "Booking History"
                    : updatedSegment == "Declined"
                    ? "Booking History"
                    : updatedSegment == "Rating reviews"
                    ? "Rating & Reviews"
                    : id == updatedSegment
                    ? "Booking History"
                    : updatedSegment}
                </>
              ) : (
                "Profile"
              )}
              <Typography
                variant="body2"
                fontWeight="300"
                sx={{
                  "@media (max-width:600px)": {
                    fontSize: "9px",
                  },
                }}
              >
                Welcome {userDetails?.userInfo?.user?.name}
              </Typography>
            </Typography>
          </Box>
          <Box
            display="flex !important"
            sx={{ cursor: "pointer", gap: "5px" }}
            alignItems="center"
            className=""
          >
            <Button onClick={handleChat} className="notification-btn1">
              <img src={chat} alt="message" />
            </Button>

            {/* <Button
              onClick={() => navigate(`/dashboard/help-center`)}
              className="notification-btn"
            >
              <img src={message} alt="message" />
            </Button> */}
            <div className="">
              <NotificationPage />
            </div>

            <Avatar
              style={{ display: "flex !important" }}
              className=""
              src={userDetails?.userProfile?.data?.profilePic}
              onClick={handleClick}
              alt="Profile Picture"
            />

            <Box ml={1} style={{ cursor: "pointer" }}>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={viewProfilehandle}>My Profile</MenuItem>
                <MenuItem onClick={logoutButton}>Logout</MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <LogoutConfirm
        logoutModal={logoutModal as any}
        handleClose={handlelogoutClose}
      />
    </Style.Header>
  )
}

export default CustomerHeader

CustomerHeader.propTypes = {
  open: PropTypes.bool.isRequired,
  position: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  handleDrawerOpen: PropTypes.func.isRequired,
}
