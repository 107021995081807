import { styled } from "@mui/material"

const Upcoming = styled("div")(({ theme }) => ({
  marginBottom: "20px",
  "& .MuiTypography-h2": {
    color: theme.palette.pink.main,
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: " normal",
  },
  "& .Headingbox": {
    borderRadius: "16px 16px 0 0",

    display: "flex",
    justifyContent: "space-between",
    background: theme.palette.white.main,
    boxShadow: "0px 4px 27px 0px rgba(0, 0, 0, 0.07)",
    padding: "15px",
  },
  "& .ContentBox": {
    display: "flex",
    justifyContent: "space-between",
    padding: "15px",
    background: theme.palette.white.main,
    "& .MuiGrid-root": {
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
      marginTop: "20px",
    },
    "& .MuiButton-root": {
      borderColor: theme.palette.pink.main,
      color: theme.palette.pink.main,
      fontFamily: "Montserrat",
    },
  },
  "& .flex-none": {
    flex: "none",
  },
  "& .max-w-550": {
    maxWidth: "550px",
  },
  "& .search-field": {
    width: "88%",
    flex: "none",
    "& label.Mui-focused": {
      color: theme.palette.pink.main,
    },
    "& label.MuiFormLabel-filled": {
      color: theme.palette.pink.main,
    },
    "& input": {
      padding: "16px 20px",
      fontSize: "16px",
      fontFamily: "Montserrat",
      color: theme.palette.black.main,
    },
    "& .payment-btn": {
      backgroundColor: "#009C353B",
      color: "#009C35",
      fontSize: "14px",
      fontWeight: "600",
      borderRightColor: "unset",
      border: "unset",
      borderRadius: "48px",
      padding: "8px 30px",
      textAlign: "center",
    },
    "&  .MuiOutlinedInput-notchedOutline": {
      borderRight: "none",
      borderRadius: "11px 0px 0 11px",
      borderColor: theme.palette.pink.main,
      "&.Mui-focused": {
        borderColor: theme.palette.pink.main,
      },
    },
  },
  "& .search-btn": {
    backgroundColor: theme.palette.pink.main,
    borderRadius: "0px 11px 11px 0px",
    boxShadow: "none",
    "& svg": {
      width: "25px",
      height: "25px",
    },
  },
}))
const Overview = styled("div")(({ theme }) => ({
  "& .MuiTypography-h2": {
    color: theme.palette.pink.main,
    fontFamily: "Poppins",
    fontSize: " 16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: " normal",
  },
  "& .payment-btn": {
    backgroundColor: "#009C353B",
    color: "#009C35",
    fontSize: "14px",
    fontWeight: "600",
    borderRightColor: "unset",
    border: "unset",
    borderRadius: "48px",
    padding: "8px 30px",
    textAlign: "center",
  },
  "& .MuiTypography-body3": {
    color: theme.palette.gray.main700,
    fontFamily: "Montserrat",
    fontSize: " 14px",
    fontStyle: "normal",
    fontWeight: "400",
    display: "block",
    lineHeight: " 13px",
    "&.pink-text": {
      color: theme.palette.pink.main,
    },
  },
  "& .MuiTypography-body4": {
    color: theme.palette.black.main,
    fontFamily: "Montserrat",
    fontSize: " 20px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: " normal",
  },
  "& .MuiTypography-body5": {
    color: theme.palette.gray.main500,
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: " normal",
  },
  "& .text-grey": {
    color: "#555555",
  },
  "& .MuiDrawer-paper": {
    backgroundColor: theme.palette.pink.main,
    "& .MuiListItem-root ": {
      color: theme.palette.white.main,
      "& svg": {
        color: theme.palette.white.main,
      },
    },
  },
  "& .reviewradio": {
    display: "none",
  },
  "& .reviewlabel": {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    borderRadius: "10px",
    transition: ".4s",
    minWidth: "160px",
    cursor: "pointer",
    gap: "13px",
    alignItems: "center",
    "& .feedback-img": {
      width: "57px",
    },
    "& .label-text": {
      fontSize: "20px",
      color: theme.palette.black.main,
      transition: ".4s",
    },
  },
  "& .view-profile": {
    display: "inline-block",
    minWidth: "135px",
    padding: "12px 10px",
    border: "1px solid",
    borderRadius: "8px",
    borderColor: theme.palette.pink.main,
    fontFamily: "Montserrat",
    color: theme.palette.pink.main,
    fontSize: "14px",
    textAlign: "center",
    textDecoration: "none",
    backgroundColor: "#fff",
    "&.active": {
      backgroundColor: theme.palette.pink.main,
      color: theme.palette.white.main,
    },
  },
  "& .review-field": {
    "& label.Mui-focused": {
      color: theme.palette.pink.main,
    },
    "& label.MuiFormLabel-filled": {
      color: theme.palette.pink.main,
    },
    "& input": {
      padding: "16px 20px",
      fontSize: "16px",
      fontFamily: "Montserrat",
      color: theme.palette.black.main,
    },
    "&  .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.pink.main,
      "&.Mui-focused": {
        borderColor: theme.palette.pink.main,
      },
    },
  },
  "& .reviewradio:checked + .reviewlabel": {
    backgroundColor: theme.palette.pink.main,
    "& .label-text": {
      fontSize: "20px",
      color: theme.palette.white.main,
    },
  },
  "& .Advertisement": {
    borderRadius: " 16px",
    background: "linear-gradient(90deg, #F475A4 38.96%, #DD5084 99.94%)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "end",
    "& .MuiButtonBase-root": {
      background: theme.palette.white.main,
      color: theme.palette.pink.main,
    },
  },
  "& .seeker-card": {
    padding: "16px",
    background: theme.palette.white.main,
    borderRadius: "13px",
    "& .listing-seeker": {
      borderTop: "1px solid #e3e3e3",
    },
  },
  "& .assign-btn": {
    fontSize: "14px",
    lineHeight: "18px",
    fontWeight: "400",
    backgroundColor: theme.palette.green.main,
    color: theme.palette.white.main,
    boxShadow: "none",
    fontFamily: "Montserrat",
    padding: "15px 10px",
    textTransform: "capitalize",
  },
  "& .rejected-btn": {
    fontSize: "14px",
    lineHeight: "18px",
    fontWeight: "400",
    backgroundColor: "transparent",
    color: theme.palette.red.main,
    borderColor: theme.palette.red.main,
    boxShadow: "none",
    fontFamily: "Montserrat",
    padding: "15px 10px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.palette.red.main,
      color: theme.palette.white.main,
    },
    "&.reject-fill-btn": {
      backgroundColor: theme.palette.red.main,
      color: theme.palette.white.main,
    },
  },
  "& .reject-card": {
    padding: "30px 42px",
    background: theme.palette.white.main,
    borderRadius: "20px",
    "& .yes-btn": {
      fontSize: "14px",
      lineHeight: "18px",
      fontWeight: "400",
      backgroundColor: "transparent",
      color: theme.palette.gray.main500,
      borderColor: theme.palette.gray.main500,
      boxShadow: "none",
      fontFamily: "Montserrat",
      padding: "7px 10px",
      textTransform: "capitalize",
    },
    "& .no-btn": {
      fontSize: "14px",
      lineHeight: "18px",
      fontWeight: "400",
      backgroundColor: theme.palette.pink.main,
      color: theme.palette.white.main,
      borderColor: theme.palette.pink.main,
      boxShadow: "none",
      fontFamily: "Montserrat",
      padding: "7px 10px",
      textTransform: "capitalize",
    },
  },
  "& .dashboard-table-wrapper": {
    backgroundColor: "transparent",
    boxShadow: "none",
    "& .dashboard-table": {
      backgroundColor: "transparent",
      boxShadow: "none",
      "& thead": {
        "& th": {
          color: theme.palette.gray.main700,
          fontFamily: "Montserrat",
          fontSize: " 14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: " 13px",
          minHeight: "75px",
        },
      },
      "& .service-head": {
        paddingLeft: "20px",
        paddingRight: "20px",
      },
      "& .datetime": {
        "& span": {
          width: "130px",
          lineHeight: "23px",
        },
      },
      "& .Pending": {
        color: "#FFA639",
      },
      "& .Assigned": {
        color: "#009C35",
      },
      "& .Rejected": {
        color: "#FE2323",
      },
      "& .Completed": {
        color: "#009C35",
      },
      "& .Cancelled": {
        color: "#FE2323",
      },
      "& .location": {
        "& span": {
          width: "300px",
          lineHeight: "23px",
        },
      },
      "& .action-btn": {
        gap: "20px",
      },
      "& tbody tr td:first-child": {
        width: "85px",
        "& div": {
          "& img": {
            width: "100%",
          },
        },
      },
      "& tbody tr td:last-child": {
        "& span": {
          borderRadius: "0px 16px 16px 0",
        },
      },
      "& td": {
        paddingLeft: 0,
        paddingRight: 0,
        borderBottom: "none",
        backgroundColor: "transparent",
        "& span": {
          position: "relative",
          minHeight: "75px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#fff",
          color: theme.palette.gray.main700,
          fontFamily: "Montserrat",
          fontSize: " 14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: " 13px",
          boxShadow: "0px 8px 6px -3px #00000008",
          zIndex: "2",
        },
      },
      "& th": {
        paddingLeft: 0,
        paddingRight: 0,
        borderBottom: "none",
        backgroundColor: "transparent",
        "& span": {
          position: "relative",
          minHeight: "75px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "start",
          backgroundColor: "#fff",
          color: theme.palette.black,
          fontFamily: "Montserrat",
          fontSize: " 14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: " 13px",
          gap: "6px",
          padding: "0 20px",
          boxShadow: "0px 8px 6px -3px #00000008",
          zIndex: "2",
          "& span": {
            minHeight: "auto",
            flexDirection: "row",
            alignItems: "center",
            gap: "2px",
            fontSize: "12px",
            color: theme.palette.gray.main700,
            padding: 0,
            "& svg": {
              fill: theme.palette.yellow.main,
            },
          },
        },
      },
    },
  },
  "& .review-star": {
    fill: theme.palette.yellow.main,
  },
  "& .inner-review-card": {
    padding: "15px",
    border: "1px solid #D1D1D1",
    borderRadius: "16px",
    "& .inner-profile-img": {
      width: "24px",
      height: "24px",
      objecFit: "cover",
      borderRadius: "50%",
    },
  },
  "& .profile-banner-img": {
    borderRadius: "16px 16px 0 0",
    boxShadow: "0px 4px 21px 0px #0000000F",
    marginBottom: "-20px",
  },
  "& .back-btn": {
    backgroundColor: "#F4F4F4",
    minWidth: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    marginBottom: "21px",
    "& svg": {
      fill: "#3A3A3A",
    },
    "&.profile-back": {
      position: "absolute",
      top: 0,
      left: 0,
    },
  },
  "& .profile-page-header": {
    padding: "28px",
    textAlign: "center",
    boxShadow: "0px 4px 21px 0px #00000008",
    borderRadius: "48px 48px 0 0",
    backgroundColor: theme.palette.white.main,
    "& .profile-heading": {
      fontSize: "20px",
      fontWeight: "500",
      fontFamily: "Montserrat",
      color: "#969696",
    },
  },
  "& .feature-field": {
    "&:before": {
      display: "none",
    },
    "&:after": {
      display: "none",
    },
    "& .MuiInputBase-input": {
      fontSize: "16px",
      border: "1px solid #C1C1C1",
      padding: "16px 21px",
      borderRadius: "5px",
      color: theme.palette.black.main,
      fontFamily: "Montserrat",
      fontWeight: "400",
    },
    "&.title_field": {
      "& .MuiInputBase-input": {
        fontSize: "20px",
      },
    },
    "&.font-14": {
      "& .MuiInputBase-input": {
        padding: "5px 10px 28px",
        maxWidth: "94px",
        fontSize: "14px",
        textAlign: "center",
      },
    },
  },
  "& .icon-field": {
    fontSize: "16px",
    border: "1px solid #C1C1C1",
    padding: "16px 21px",
    borderRadius: "5px",
    color: theme.palette.black.main,
    fontFamily: "Montserrat",
    fontWeight: "400",
    "&.feature-field": {
      "& .MuiInputBase-input": {
        border: "none",
        padding: "0px",
        borderRadius: "0",
        color: theme.palette.black.main,
        fontFamily: "Montserrat",
        fontWeight: "400",
      },
    },
  },
  "& .edit-profile": {
    width: "81px",
    height: "81px",
    objectFit: "cover",
    borderRadius: "10px",
    boxShadow: "0px 4px 21px 0px #0000000F",
  },
  "& .field-label": {
    fontSize: "16px",
    fontFamily: "Montserrat",
    color: theme.palette.black.main,
    "& svg": {
      fill: theme.palette.pink.main,
    },
    "&.age-label": {
      marginTop: "-25px",
      fontSize: "14px",
      fontWeight: "300",
    },
    "&.pink-text": {
      fontSize: "15px",
      color: theme.palette.pink.main,
      fontWeight: "500",
    },
  },
  "& .upload-label": {
    position: "absolute",
    bottom: "-5px",
    right: "-5px",
    width: "26px",
    height: "26px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FDAF18",
    border: "2px solid",
    color: theme.palette.white.main,
    borderColor: theme.palette.white.main,
    cursor: "pointer",
    borderRadius: "50%",
    "& svg": {
      width: "18px",
      height: "18px",
      fill: theme.palette.white.main,
    },
  },
  "& .service-pill": {
    backgroundColor: theme.palette.black.main,
    borderRadius: "30px",
    padding: "7px 14px",
    display: "inline-block",
    color: theme.palette.white.main,
    fontSize: "14px",
  },
  "& .review-card": {
    background: theme.palette.white.main,
    borderRadius: "16px",
    boxShadow: "0px 4px 20px 2px #00000008",
    "&.single-review-card": {
      borderRadius: "0px 0px 16px 16px",
    },
    "& .review-card": {
      background: theme.palette.white.main,
      borderRadius: "0px 0 16px 16px",
      boxShadow: "0px 4px 20px 2px #00000008",
      "& .reviewimg": {
        height: "74px",
        objectFit: "cover",
        width: "85px",
        borderRadius: "16px 0 0 16px",
      },
      "& .review-stars svg": {
        fill: "gold",
        "&.active ": {
          fill: theme.palette.yellow.main,
        },
      },

      "& .review-text": {
        maxWidth: "443px",
        lineHeight: "20px",
        display: "-webkit-box",
        overflow: "hidden",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 2,
        textAlign: "left",
      },
    },
    "& .profile-img": {
      width: "230px",
      height: "230px",
      borderRadius: "22px",
      objectFit: "cover",
    },
    "& .profile-img-2": {
      width: "228px",
      height: "225px",
      borderRadius: "10%",
      objectFit: "cover",
      "@media (max-width: 1100px)": {
        width: "80px",
        height: "80px",
      },
    },
    "& .service-provider-name": {
      fontSize: "20px",
      color: theme.palette.black.main,
      fontWeight: "500",
      marginBottom: "12px",
      fontFamily: "Montserrat",
    },
    "& .service-provider-bio": {
      fontSize: "14px",
      color: "#555555",
      fontWeight: "500",
      marginBottom: "12px",
      fontFamily: "Montserrat",
    },
    "& .dark-divider": {
      border: "1px dashed #C0C0C0",
    },
    "& .accountIcon": {
      width: "20px",
      height: "20px",
    },
    "& .reviewimg": {
      height: "74px",
      objectFit: "cover",
      width: "85px",
      borderRadius: "16px 0 0 16px",
    },
    "& .review-stars svg": {
      fill: "gold",
      "&.active ": {
        fill: theme.palette.yellow.main,
      },
    },
    "& .review-text": {
      maxWidth: "443px",
      lineHeight: "20px",
      display: "-webkit-box",
      overflow: "hidden",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 2,
      textAlign: "left",
    },
  },
  "& .progressBar": {
    maxWidth: "100%",
    height: "15px",
    backgroundColor: theme.palette.pink.main,
    borderRadius: "14px",
  },
}))
const Flexbox = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  // "& .MuiTypography-body2": {
  //   color: theme.palette.grey.main200,
  //   fontFamily: "Nunito Sans",
  //   fontSize: " 14px",
  //   fontStyle: "normal",
  //   fontWeight: "400",
  //   lineHeight: " 18px",
  // },
  "& .MuiTypography-body1": {
    color: theme.palette.pink.main,
    fontFamily: "Montserrat",
    fontSize: " 24px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: " 18px",
  },
  "& .Status.MuiBox-root": {
    background: theme.palette.white.main,
    boxShadow: "0px 4px 20px 2px rgba(0, 0, 0, 0.03)",
    padding: "20px",
    borderRadius: "16px",
    width: "110px",
  },
}))

export { Upcoming, Overview, Flexbox }
