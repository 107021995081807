import React, { useEffect, useState } from "react"
import {
  Box,
  Button,
  InputAdornment,
  IconButton,
  OutlinedInput,
  InputLabel,
  Stack,
  Snackbar,
  Alert,
  Typography,
  Grid,
  Input,
  FormControl,
} from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"

import {
  AiFillLock,
  AiFillUnlock,
  AiOutlineMail,
  AiOutlineUser,
} from "react-icons/ai"
import { useNavigate } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import mommylogo from "../../images/login-logo.png"
import physioTherapy1 from "../../images/physioTherapy1.jpg"
import physioTherapy2 from "../../images/physioTherapy2.jpg"
import physioTherapy3 from "../../images/physioTherapy3.jpg"
import physioTherapy4 from "../../images/physioTherapy4.jpg"

import { IoIosArrowBack } from "react-icons/io"
import { useAppSelector } from "../../app/hooks"
import { useForgetPasswordMutation } from "../../features/Auth/authApi"
import { Controller, useForm } from "react-hook-form"
import { toast } from "react-toastify"

interface forgetUser {
  email: string
}

export default function ForgetPassword() {
  const [loading, setLoading] = useState(false)
  const [formReset, setFormReset] = useState(false)

  const [userLogin, { isError, error, data }] = useForgetPasswordMutation()

  const navigate = useNavigate()
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const schemaResolver = yupResolver(
    yup.object().shape({
      email: yup
        .string()
        .required("Please enter an email")
        .test("valid-email", "Please enter a valid email", (value) =>
          emailRegex.test(value),
        ),
    }),
  )
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<forgetUser>({
    resolver: schemaResolver,
  })

  const onSubmit = (values: any) => {
    const payload = {
      email: values.email,
    }

    userLogin(payload)
    setLoading(true)
  }

  useEffect(() => {
    if (data?.message) {
      setLoading(false)
      toast.success(data?.message, { autoClose: 1000 })
      setFormReset(true)
    } else if ((error as any)?.data?.message) {
      toast.error((error as any)?.data?.message, { autoClose: 1000 })
      setLoading(false)
    }
  }, [data, error])

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return
    }
  }

  useEffect(() => {
    if (formReset) {
      reset({ email: "" })
      setFormReset(false)
    }
  }, [formReset, reset])

  const handleEmailPass = () => {}

  return (
    <>
      <Grid container justifyContent="center">
        <Grid
          item
          lg={6}
          className="d-none d-lg-block"
          display="flex"
          alignItems="center"
        >
          <Box className="box-wrapper">
            <Typography
              sx={{
                textAlign: "center",
                color: " #858585",
                fontFamily: "Montserrat",
                fontSize: "30px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              Welcome to
            </Typography>
            <Typography sx={{
                textAlign: "center",
                fontFamily: "Montserrat",
                fontSize: "40px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
              }}
            >
              Send A Therapist
              {/* <img src={mommylogo} width="300" height="auto" /> */}
            </Typography>
            <Box className="Imgbox" display="flex" justifyContent="center" flexWrap="wrap" gap={2}>
              <Box>
                <img src={physioTherapy1} className="login-left-img1"/>
              </Box>
              <Box mt={5}>
                <img src={physioTherapy2} className="login-left-img2"/>
              </Box>

              <Box>
                <img src={physioTherapy3} className="login-left-img3"/>
              </Box>

              <Box mt={5}>
                <img src={physioTherapy4} className="login-left-img4"/>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={6}>
          <Box className="Loginform">
            <Typography
              variant="h4"
              mt={5}
              fontFamily="Montserrat"
              fontSize={22}
              fontWeight="600"
              color="pink.main"
              textAlign="center"
              sx={{ cursor: "pointer" }}
              title="back"
              onClick={() => navigate("/")}
            >
              <IoIosArrowBack /> Forgot Password?
            </Typography>
            <Typography my={2} className="text-login">
              Enter the email connected with your account and we will send an
              email to you.
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box>
                <FormControl sx={{ mt: 0 }} variant="outlined" fullWidth>
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    className="bg-white rounded"
                  >
                    Email
                  </InputLabel>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    render={({ field }: any) => (
                      <OutlinedInput
                        {...field}
                        type="email"
                        placeholder="Email"
                        id="outlined-adornment-password"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton edge="end">
                              <AiOutlineMail />
                            </IconButton>
                          </InputAdornment>
                        }
                        fullWidth
                      />
                    )}
                  />
                  <p className="text-danger">{errors.email?.message}</p>
                </FormControl>
              </Box>
              <Box>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  className="auth-btn mx-auto"
                  sx={{ mt: 1, display: "block" }}
                  disabled={loading}
                  fullWidth
                >
                  {loading ? (
                    <Typography fontSize={1}>
                      <CircularProgress
                        style={{ color: "white", fontSize: 20 }}
                        size={20}
                      />
                    </Typography>
                  ) : (
                    "Send"
                  )}
                </Button>
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
