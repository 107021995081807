import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Avatar,
  AvatarGroup,
  Box,
  Divider,
  Grid,
  Grow,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import serviceProviderIcon from "../../images/service-provider.svg"
import totalBookingIcon from "../../images/upcoming.svg"
import activeBookingIcon from "../../images/active.svg"
import completeBookingIcon from "../../images/completed.svg"
import pendingBookingIcon from "../../images/pending.svg"
import cancelledBookingIcon from "../../images/cancelled.svg"
import cookingImg from "../../images/bookedimg1.png"
import babysittingImg from "../../images/bookedimg2.png"
import householdingImg from "../../images/bookedimg3.png"
import approvedImg from "../../images/babysitting.png"
import { IoChevronForwardOutline } from "react-icons/io5"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import offerImg from "../../images/ads.png"
import dummyImage from "../../images/dummy.png"
import expire from "../../images/expire.svg"
import declined from "../../images/declined.svg"
import * as Style from "./style"
import tick from "../../images/tick.svg"
import user from "../../images/user.jpeg"

import {
  useAcceptGenrateBookingQuery,
  useActivePostQuery,
  useGetGenratePostQuery,
  useGetOffersQuery,
  useGetServiceSuggestionQuery,
  useGetUpcomingBookingsQuery,
} from "../../features/OverViewAuth/overViewApi"
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom"
import { Button, Spinner, Table } from "react-bootstrap"
import whiteLogo from "../../images/logo-white.svg"
import { FaStar } from "react-icons/fa6"
import tableCellImg from "../../images/table-img.png"
import moment from "moment"

import { offersSelect } from "../../features/OverViewAuth/overViewSlice"
import { useAppDispatch } from "../../app/store"
import noData from "../../images/nobooking.png"
import noDataUpcoming from "../../images/no-results.svg"

import survice from "../../images/survice.png"

import {
  FormatChangeTime,
  FormatDate,
  FormatTime,
} from "../common/utctolocalDate"
import { logout } from "../../features/Auth/authSlice"
import CenteredSpinner from "../common/CenteredSpinner"
import { MdOutlineLocationOn } from "react-icons/md"
import RatingStars from "../common/rating"
import "./animation.css"
import CountdownTimer, { TimeCounter } from "./TimeCount"
import { useAppSelector } from "../../app/hooks"
import { useStore } from "react-redux"
import ReviewmPopup from "../Booking/ReviewPopup/reviewpop"
import StorylaneEmbed from "../StorylaneEmbed"

const CustomerLayout = () => {
  const dispatch = useAppDispatch()
  const urlSearchParams = new URLSearchParams(window.location.search)
  const _reviewtype = urlSearchParams.get("type")
  const [datareview, setDatareview] = useState<string>("")
  const [ReviewPop, setReviewPop] = React.useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const pathLocation = location?.pathname

  const avtiveTimeCount = useAppSelector((state) => state.overViewAuth)
  const newUser = useAppSelector((state) => state.auth.newUserDemo)

  const {
    data: countBooing,
    isLoading,
    error: countingbookingError,
  } = useGetGenratePostQuery()
  const { data: upcomingBooking, isLoading: isloadingupcoming } =
    useAcceptGenrateBookingQuery({})

  const {
    data: activePost,
    refetch,
    error: activeBookingError,
    isLoading: activeIsloading,
    isFetching: activeIsFetching,
  } = useActivePostQuery({
    // dataTime
  })

  // this is css
  const TimerStyles2 = {
    width: "108px", // Adjust as needed
    height: "37px", // Adjust as needed
    backgroundColor: "#f0f0f0",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px dashed #868686",
    padding: "0px 20px 0px 20px",
    gap: "10px",
    margin: "0 auto",
    fontSize: "10px",
  }
  const TimerStyles = {
    width: "108px", // Adjust as needed
    height: "40px", // Adjust as needed
    backgroundColor: "rgb(244 117 164 / 18%)",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px dashed #f475a4",
    padding: "0px 20px 0px 20px",
    gap: "10px",
    margin: "2px auto",
    fontSize: "10px",
  }

  const { data: getOffers } = useGetOffersQuery({ limit: 1 })
  const { data: suggestionService, isLoading: suggestIsloading } =
    useGetServiceSuggestionQuery({})

  const navigate = useNavigate()

  // expire token handle redirect on login

  // hanldeRoute with box

  const handleRouteBox = (item: any) => {
    switch (item?.name) {
      case "Upcoming":
        navigate("/dashboard/my-bookings")
        break
      case "Active":
        navigate("")
        break
      case "Completed":
        navigate(`/dashboard/booking-history/?type=${item?.name}`)
        break
      case "Pending":
        navigate("/dashboard/my-bookings")
        break
      case "Expired":
        navigate(`/dashboard/booking-history/?type=${item?.name}`)
        break
      default:
        console.log("No valid route found for:", item?.name)
    }
  }

  useEffect(() => {
    if (_reviewtype == "review-post") {
      setReviewPop(true)
    }
  }, [_reviewtype])

  useEffect(() => {
    if (datareview == "overview") {
      setSearchParams({ type: "" })
    }
  }, [datareview])

  useEffect(() => {
    refetch()
  }, [pathLocation])

  return (
    <>
      {newUser == true ? (
        <StorylaneEmbed />
      ) : (
        <>
          {isLoading || isloadingupcoming || suggestIsloading ? (
            <CenteredSpinner />
          ) : (
            <Style.Overview>
              <ReviewmPopup
                ReviewPop={ReviewPop}
                setDatareview={setDatareview as any}
              />
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} className="mb-1">
                    <>
                      <Box>
                        <Typography align="left" variant="h2" mb={2} pb={1}>
                          Service in Progress
                        </Typography>
                      </Box>
                      <TableContainer
                        component={Paper}
                        className="dashboard-table-wrapper custom-scrollbar "
                      >
                        <Table
                          style={{
                            minWidth: 650,
                            border: "none",
                            backgroundColor: "#fff",
                            borderRadius: "16px",
                            overflow: "hidden",
                          }}
                          className="dashboard-table"
                          aria-label="simple table"
                        >
                          {activePost?.data?.length && (
                            <TableHead>
                              <TableRow>
                                <TableCell align="center"></TableCell>
                                <TableCell
                                  align="center"
                                  className="service-head"
                                >
                                  Service Provider
                                </TableCell>
                                <TableCell align="center">Email</TableCell>
                                <TableCell align="center">
                                  Service Required
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="d-flex align-items-center justify-content-center"
                                >
                                  Location <MdOutlineLocationOn />
                                </TableCell>

                                <TableCell align="center">
                                  Time since service <br></br>
                                  started
                                </TableCell>

                                <TableCell align="center">
                                  Service Started <br />
                                  on
                                </TableCell>
                                <TableCell align="center">Action</TableCell>
                              </TableRow>
                            </TableHead>
                          )}
                          <TableBody>
                            {/* {activeIsFetching  ? (
                          <Spinner
                            animation="grow"
                            style={{ color: "pink", width: "32px" }}
                          />
                        ) : ( */}
                            {activePost?.data?.length > 0 ? (
                              activePost?.data?.map(
                                (postActive: any, index: number) => (
                                  <React.Fragment key={index}>
                                    {postActive?.assignedTo
                                      ?.slice(0, 1)
                                      ?.map((item: any) => (
                                        <React.Fragment key={index}>
                                          <TableRow
                                            key={item.providerassign}
                                            onClick={() =>
                                              navigate(
                                                `/dashboard/active-view/${postActive?._id}`,
                                              ) as any
                                            }
                                            sx={{
                                              "&:last-child td, &:last-child th":
                                                {
                                                  border: 0,
                                                },
                                              cursor: "pointer",
                                            }}
                                          >
                                            <TableCell>
                                              <div className="img-wrapper ms-1">
                                                <img
                                                  src={
                                                    item?.profilePic ||
                                                    dummyImage
                                                  }
                                                  style={{
                                                    width: "74px",
                                                    height: "74px",
                                                    objectFit: "cover",
                                                  }}
                                                />
                                              </div>
                                            </TableCell>
                                            <TableCell
                                              component="td"
                                              align="center"
                                              scope="row"
                                            >
                                              <span className="text flex-column gap-2">
                                                {item?.name}
                                                {postActive?.assignedTo
                                                  ?.length > 1 && (
                                                  <span className="minh-1">
                                                    {" "}
                                                    +{" "}
                                                    {
                                                      postActive?.assignedTo?.slice(
                                                        1,
                                                      )?.length
                                                    }{" "}
                                                    Others
                                                  </span>
                                                )}
                                                <span className="minh-1">
                                                  <RatingStars
                                                    rating={
                                                      item?.rating
                                                        ?.$numberDecimal
                                                    }
                                                  />
                                                </span>
                                              </span>
                                            </TableCell>
                                            <TableCell align="center">
                                              <span className="email">
                                                <span>{item?.email}</span>
                                              </span>
                                            </TableCell>

                                            <TableCell align="center">
                                              <span>
                                                {postActive?.service?.name}
                                              </span>
                                            </TableCell>
                                            <TableCell align="center">
                                              <span className="location">
                                                <span>
                                                  {postActive?.location}
                                                </span>
                                              </span>
                                            </TableCell>

                                            <TableCell>
                                              {postActive?.isJobStartOtpVerified !==
                                              true ? (
                                                <>
                                                  <Button style={TimerStyles2}>
                                                    <CountdownTimer
                                                      from={postActive.from}
                                                      till={postActive.till}
                                                      postActiveTimeStop={
                                                        postActive?.activeJobflow
                                                      }
                                                    />
                                                  </Button>
                                                  <span
                                                    className="minh-1 mt-2 pb-1"
                                                    style={{
                                                      color: "red",
                                                      fontSize: "12px",
                                                    }}
                                                  >
                                                    {" "}
                                                    Service in waiting zone
                                                  </span>
                                                </>
                                              ) : (
                                                <Button style={TimerStyles}>
                                                  <TimeCounter
                                                    from={
                                                      postActive?.currentTime
                                                    }
                                                    till={postActive?.till}
                                                    postActiveTimeStop={
                                                      postActive?.activeJobflow
                                                    }
                                                  />
                                                </Button>
                                              )}
                                            </TableCell>
                                            <TableCell align="center">
                                              <span className="datetime">
                                                <span className="d-flex flex-column">
                                                  <span className="weekday">
                                                    {/* {activePost?.data.availabeJobs} */}
                                                    <FormatDate
                                                      dateString={
                                                        postActive?.from
                                                      }
                                                    />
                                                  </span>
                                                  {/* <span> */}
                                                  <span>
                                                    <FormatChangeTime
                                                      dateString={
                                                        postActive?.from
                                                      }
                                                      // timezone={"india"}
                                                    />
                                                  </span>
                                                  <span>
                                                    <FormatTime
                                                      dateString={
                                                        postActive?.from
                                                      }
                                                    />
                                                  </span>
                                                </span>
                                                {/* </span> */}
                                              </span>
                                            </TableCell>
                                            <TableCell align="center">
                                              <span className="minh-1">
                                                <Link
                                                  to={`/dashboard/active-view/${postActive?._id}`}
                                                  className="view-profile"
                                                >
                                                  {postActive?.activeJobflow ==
                                                  "END_OTP_VERIFIED"
                                                    ? "Pay"
                                                    : postActive?.activeJobflow ==
                                                      "START_OTP_VERIFIED"
                                                    ? "Stop Service"
                                                    : postActive?.activeJobflow ==
                                                      "PROVIDER_STOP_OTP"
                                                    ? "Enter Otp"
                                                    : "Generate Otp"}
                                                </Link>
                                              </span>
                                            </TableCell>
                                          </TableRow>
                                        </React.Fragment>
                                      ))}
                                  </React.Fragment>
                                ),
                              )
                            ) : (
                              <TableRow>
                                <TableCell
                                  align="center"
                                  colSpan={8}
                                  sx={{
                                    width: "100%",
                                    pointerEvents: "none",
                                    border: "none",
                                  }}
                                >
                                  <img
                                    src={noData}
                                    style={{
                                      width: "100%",
                                      maxWidth: "700px",
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            )}

                            {/* )}  */}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>

                    <Box>
                      <Typography align="left" variant="h2" pb={1} mt={2}>
                        Booking Details
                      </Typography>
                    </Box>
                    <div className="custom-scrollbar w-100 overflow-auto ">
                      <Style.Flexbox className="flex-nowrap ">
                        {countBooing?.data.map((item: any, index: number) => (
                          <React.Fragment key={index}>
                            <Box
                              className="Status overview-card text-center"
                              onClick={() => handleRouteBox(item)}
                            >
                              <Link to="my-bookings">
                                {item?.name == "Upcoming" && (
                                  <img
                                    src={totalBookingIcon}
                                    className="mt-2"
                                  />
                                )}
                              </Link>

                              {item?.name == "Active" && (
                                <img src={activeBookingIcon} className="mt-2" />
                              )}

                              {/* <Link to="booking-history/Completed">
                          {item?.name == "Completed" && (
                            <img src={completeBookingIcon} className="mt-2" />
                          )}
                        </Link> */}

                              {item?.name == "Completed" && (
                                <Link
                                  to={`booking-history/?type=${item?.name}`}
                                >
                                  <img
                                    src={completeBookingIcon}
                                    className="mt-2"
                                  />
                                </Link>
                              )}

                              <Link to="/dashboard/my-bookings">
                                {item?.name == "Pending" && (
                                  <img
                                    src={pendingBookingIcon}
                                    className="mt-2"
                                  />
                                )}
                              </Link>

                              {item?.name == "Expired" && (
                                <Link
                                  to={`booking-history/?type=${item?.name}`}
                                >
                                  <img src={expire} className="mt-2" />
                                </Link>
                              )}
                              <Link to={`booking-history/?type=${item?.name}`}>
                                {item?.name == "Declined" && (
                                  <img src={declined} className="mt-2" />
                                )}
                              </Link>

                              <Typography variant="body1">
                                {item?.name}
                              </Typography>
                              <Typography
                                variant="body1"
                                className="darkpink-text font-24 mt-2 d-block"
                              >
                                {item?.value}
                              </Typography>
                            </Box>
                          </React.Fragment>
                        ))}
                      </Style.Flexbox>
                    </div>
                  </Grid>

                  <Grid item xs={12} className="mb-5">
                    <Box className="">
                      <Typography align="left" variant="h2" pb={1} mt={1}>
                        Your Upcoming Bookings
                      </Typography>

                      <TableContainer
                        component={Paper}
                        className="dashboard-table-wrapper custom-scrollbar "
                        style={{ minHeight: 345 }}
                      >
                        <Table
                          style={{
                            minWidth: 650,
                            border: "none",
                            backgroundColor: "#fff",
                            borderRadius: "16px",
                            overflow: "hidden",
                          }}
                          className="dashboard-table"
                          aria-label="simple table"
                        >
                          {upcomingBooking?.data?.length > 0 && (
                            <TableHead>
                              <TableRow>
                                <TableCell align="center"></TableCell>
                                <TableCell
                                  align="center"
                                  className="d-flex align-items-center justify-content-center"
                                >
                                  Assigned providers
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="service-head"
                                >
                                  Service
                                </TableCell>

                                <TableCell align="center">Start Time</TableCell>
                                <TableCell align="center">End Time</TableCell>
                                <TableCell align="center">Action</TableCell>
                              </TableRow>
                            </TableHead>
                          )}
                          <TableBody>
                            {isloadingupcoming ? (
                              <CenteredSpinner />
                            ) : (
                              <>
                                {upcomingBooking?.data?.length > 0 ? (
                                  upcomingBooking?.data
                                    ?.slice(0, 4)
                                    ?.map((item: any, index: number) => (
                                      <Fragment key={item._id}>
                                        <TableRow
                                          key={item._id}
                                          sx={{
                                            "&:last-child td, &:last-child th":
                                              {
                                                border: 0,
                                              },
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            navigate(
                                              `/dashboard/bookings/details/${item?._id}`,
                                            )
                                          }
                                        >
                                          {item?.assignedTo?.length > 0 ? (
                                            item?.assignedTo
                                              ?.slice(0, 1)
                                              ?.map((providerAssign: any) => (
                                                <>
                                                  <TableCell>
                                                    <span className="img-wrapper ms-1">
                                                      <img
                                                        src={
                                                          providerAssign?.profilePic ||
                                                          dummyImage
                                                        }
                                                        style={{
                                                          width: "74px",
                                                          height: "74px",
                                                          objectFit: "cover",
                                                        }}
                                                      />
                                                    </span>
                                                  </TableCell>

                                                  <TableCell>
                                                    <span className="img-wrapper">
                                                      {providerAssign?.name &&
                                                        providerAssign.name
                                                          .charAt(0)
                                                          .toUpperCase() +
                                                          providerAssign.name.slice(
                                                            1,
                                                          )}{" "}
                                                      {/* - */}
                                                      <span
                                                        style={{
                                                          color: "green",
                                                        }}
                                                      >
                                                        {/* Assigned */}
                                                        <br />
                                                      </span>
                                                      {item?.assignedTo
                                                        ?.length > 1 && (
                                                        <>
                                                          +{" "}
                                                          {item?.assignedTo?.slice(
                                                            1,
                                                          )?.length +
                                                            " " +
                                                            "Others"}
                                                        </>
                                                      )}
                                                    </span>
                                                  </TableCell>
                                                </>
                                              ))
                                          ) : (
                                            <>
                                              <TableCell>
                                                <span className="img-wrapper ms-1">
                                                  <img
                                                    src={dummyImage}
                                                    style={{
                                                      width: "74px",
                                                      height: "74px",
                                                      objectFit: "cover",
                                                    }}
                                                  />
                                                </span>
                                              </TableCell>
                                              <TableCell>
                                                <span
                                                  className="language mx-auto text-center"
                                                  style={{ color: "red" }}
                                                >
                                                  Waiting for the admin to
                                                  assign{" "}
                                                </span>
                                              </TableCell>
                                            </>
                                          )}

                                          <TableCell
                                            component="td"
                                            align="center"
                                            scope="row"
                                          >
                                            <span>{item?.service?.name}</span>
                                          </TableCell>

                                          <TableCell align="center">
                                            <span className="datetime">
                                              <span className="d-flex flex-column">
                                                <span className="weekday">
                                                  <FormatDate
                                                    dateString={item?.from}
                                                  />
                                                </span>

                                                <span>
                                                  <FormatTime
                                                    dateString={item?.from}
                                                  />
                                                </span>

                                                <span>
                                                  <FormatChangeTime
                                                    dateString={item?.from}
                                                  />
                                                </span>
                                              </span>
                                            </span>
                                          </TableCell>
                                          <TableCell align="center">
                                            <span className="datetime">
                                              <span className="d-flex flex-column">
                                                <span className="weekday">
                                                  {/* <CountUpTimer till={item?.till as any}/> */}
                                                  <FormatDate
                                                    dateString={item?.till}
                                                  />
                                                </span>

                                                <span>
                                                  <FormatTime
                                                    dateString={item?.till}
                                                  />
                                                </span>

                                                <span>
                                                  <FormatChangeTime
                                                    dateString={item?.till}
                                                  />
                                                </span>
                                              </span>
                                            </span>
                                          </TableCell>

                                          <TableCell align="center">
                                            <span className="minh-1">
                                              <Link
                                                to={`${
                                                  item?.status === "Accepted"
                                                    ? `/dashboard/bookings/details/${item?._id}`
                                                    : ""
                                                }`}
                                                className={`view-profile ${
                                                  item?.status !== "Accepted"
                                                    ? "no-track-color"
                                                    : ""
                                                }`}
                                              >
                                                {item?.status === "Accepted"
                                                  ? "Track Service"
                                                  : "Waiting"}
                                              </Link>
                                            </span>
                                          </TableCell>
                                        </TableRow>
                                      </Fragment>
                                    ))
                                ) : (
                                  <TableRow>
                                    <TableCell
                                      align="center"
                                      colSpan={7}
                                      sx={{
                                        width: "100%",
                                        pointerEvents: "none",
                                        border: "none",
                                      }}
                                    >
                                      <img
                                        src={noDataUpcoming}
                                        style={{
                                          width: "100%",
                                          maxWidth: "208px",
                                        }}
                                      />
                                      <span className="no-data-text">
                                        No Upcoming bookings for you
                                      </span>
                                    </TableCell>
                                  </TableRow>
                                )}
                              </>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                    <Box display="flex" justifyContent="end" mt={2}>
                      {upcomingBooking?.data?.length > 4 && (
                        <Button
                          style={{
                            padding: "5px",
                            fontSize: "12px",
                            fontWeight: "500",
                            color: "white",
                            backgroundColor: "rgb(244, 117, 164)",
                          }}
                          className="view-profile"
                          onClick={() => navigate("/dashboard/my-bookings")}
                        >
                          MORE
                        </Button>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Style.Overview>
          )}
        </>
      )}
    </>
  )
}

export default CustomerLayout
