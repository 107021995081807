import React, { useState } from "react"
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Pagination,
  Paper,
  Rating,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"
import { LiaAngleRightSolid } from "react-icons/lia"
import * as Style from "./style"
import moment from "moment"

import { Table } from "react-bootstrap"
import tableCellImg from "../../../images/table-img.png"
import { Upcoming } from "@mui/icons-material"
import { FaStar } from "react-icons/fa6"
import { Link, useNavigate } from "react-router-dom"
import sendIcon from "../../../images/send-icon.svg"
import editIcon from "../../../images/edit-icon.svg"
import deleteIcon from "../../../images/delete-icon.svg"
import { CiSearch } from "react-icons/ci"
import { IoFilter } from "react-icons/io5"
import { TbArrowsSort } from "react-icons/tb"
import { useGetProviderListQuery } from "../../../features/OverViewAuth/overViewApi"

const SeekerProvider = () => {
  const { data: requestProvider } = useGetProviderListQuery({})
  const [selectedPage, setSelectedPage] = useState(1)

  const handlePageChange = (event: any, value: any) => {
    setSelectedPage(value)
  }

  const navigate = useNavigate()

  const handleViewDetails = (id: any) => {
    navigate(`details/${id}`)
  }

  return (
    <>
      {" "}
      <Style.Upcoming
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          columnGap: "50px",
          paddingTop: "20px",
          paddingBottom: "20px",
          margin: "0px 0px 27px",
        }}
      >
        <Box className="d-flex flex-none w-100 max-w-550">
          <TextField
            id="outlined-basic"
            className="search-field"
            label="Search"
            variant="outlined"
          />
          <Button variant="contained" className="search-btn">
            <CiSearch />
          </Button>
        </Box>
        <Typography
          variant="h2"
          align="left"
          className="text-black mb-0"
          mb={2}
        >
          <IoFilter /> Filter
        </Typography>
        <Typography
          variant="h2"
          align="left"
          className="text-black mb-0"
          mb={2}
        >
          {/* <TbArrowsSort />  */}
        </Typography>
      </Style.Upcoming>
      <Style.Overview>
        <TableContainer component={Paper} className="dashboard-table-wrapper">
          <Table
            style={{
              minWidth: 650,
              border: "none",
              backgroundColor: "#fff",
              borderRadius: "16px",
              overflow: "hidden",
            }}
            className="dashboard-table"
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="left" className="service-head">
                  Name <TbArrowsSort />
                </TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">Phone</TableCell>
                <TableCell align="center">Age</TableCell>
                <TableCell align="center">Language</TableCell>
                <TableCell align="center">Profession</TableCell>
                <TableCell align="center">Delete</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {requestProvider?.data?.providersList?.map(
                (row: any, index: number) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      <Box>
                        <img src={row.userimage} />
                      </Box>
                    </TableCell>
                    <TableCell component="th" align="center" scope="row">
                      <span>
                        {row.name}
                        <span>
                          {row?.rating?.$numberDecimal}
                          <FaStar />
                        </span>
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="email">
                        <span>{row.email}</span>
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span>{row.contactNo}</span>
                    </TableCell>
                    <TableCell align="center">
                      <span>{row.age}</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="language">
                        <span>{row.language}</span>
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span>{row.profession}</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="action-btn">
                        {/* <Link>
                          <img src={sendIcon} />
                        </Link>
                        <Link>
                          <img src={editIcon} />
                        </Link> */}
                        <Link to="">
                          <img src={deleteIcon} />
                        </Link>
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span>
                        <Link
                          to=""
                          className="view-profile"
                          onClick={() => handleViewDetails(row?._id)}
                        >
                          View Profile
                        </Link>
                      </span>
                    </TableCell>
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <span>
          Showing Results {requestProvider?.data?.pagination?.currentPage} /
          {requestProvider?.data?.pagination?.totalItems}
        </span>
        <Grid container justifyContent="flex-end">
          <Grid md={3}>
            <Box display="flex" flexDirection="column">
              <Stack spacing={2} className="mb-3">
                <Pagination
                  count={10}
                  color="secondary"
                  className="custom-pagination"
                  page={selectedPage}
                  onChange={handlePageChange}
                  sx={{
                    "& .MuiPaginationItem-page.Mui-selected": {
                      backgroundColor: "#F475A4",
                      boxSizing: "border-box",
                    },
                    "& .css-wjh20t-MuiPagination-ul": {
                      flexWrap: "nowrap",
                      justifyContent: "end",
                    },
                  }}
                />
              </Stack>
              <Typography className="page-text">
                Showing 1 -25 of list
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Style.Overview>
    </>
  )
}
export default SeekerProvider
