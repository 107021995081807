import React from "react"
import { Box, Grid } from "@mui/material"
import { Outlet } from "react-router-dom"
import * as Style from "./style"

type Props = {}

const AuthWrapper = (props: Props) => {
  return (
    <>
      <Style.AuthPage>
        <Box className=" d-flex justify-content-center align-items-center">
          <Grid container>
            <Grid item xs={5} display="flex" alignItems="center">
              <Box></Box>
            </Grid>
            <Grid item xs={12}>
              <Outlet />
            </Grid>
          </Grid>
        </Box>
      </Style.AuthPage>
    </>
  )
}

export default AuthWrapper
