import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { RootState } from "../../app/store"
import { AnyIfEmpty } from "react-redux"

const BASE_URL = import.meta.env.VITE_REACT_APP_API_URL

export const chatViewApi = createApi({
  reducerPath: "chatAuth",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/api/seeker`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token
      if (token) {
        headers.set("authorization", `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: [
    "chatMessage",
    "chatrefetch",
    "createMessage",
    "createTickeActive",
    "notifications",
    "chatApiTicket",
    "ticketApiCal",
  ],
  endpoints: (builder) => ({
    getChatsMessage: builder.query<any, any>({
      query: (data) => ({
        url: `get-chats?userType=${data?.userType || ""}&pageNo=${
          data?.pageNo || ""
        }&pageLimit=${data?.pageLimit || ""}&searchName=${
          data?.searchName || ""
        }`,
        method: "GET",
      }),
      providesTags: ["createTickeActive", "chatApiTicket"],
    }),

    createTicket: builder.mutation<any, any>({
      query: (data) => ({
        url: `/create-ticket`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["createTickeActive"],
    }),

    getChatsMessageUpdate: builder.query<any, any>({
      query: (data: any) => ({
        url: `get-messenger-chat?ticketId=${data?.ticketId || ""}&pageNo=${
          data?.pageNo || ""
        }&pageLimit=${data?.pageLimit || ""}`,
        method: "GET",
      }),
      providesTags: ["ticketApiCal"],
    }),

    createChatMessage: builder.mutation<any, any>({
      query: (data) => ({
        url: `create-chat`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["createMessage"],
    }),

    getChatsMessageCount: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `/remove-unseen-count`,
        method: "POST",
        body: data,
      }),
    }),

    removeSeenCount: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `/remove-seen-status`,
        method: "POST",
        body: data,
      }),
    }),

    protected: builder.mutation<{ message: string }, void>({
      query: () => "protected",
    }),
  }),
})

export const {
  useGetChatsMessageQuery,
  useCreateTicketMutation,
  useGetChatsMessageUpdateQuery,
  useCreateChatMessageMutation,
  useGetChatsMessageCountMutation,
  useRemoveSeenCountMutation,
} = chatViewApi
