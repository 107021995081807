import React from "react"
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Rating,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"
import { LiaAngleRightSolid } from "react-icons/lia"
import * as Style from "./style"
import moment from "moment"
import { Table } from "react-bootstrap"
import cookingImg from "../../images/cooking.png"
import { FaChevronLeft, FaCirclePlus } from "react-icons/fa6"
import { Upcoming } from "@mui/icons-material"
import { FaStar } from "react-icons/fa6"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"
import sendIcon from "../../images/send-icon.svg"
import editIcon from "../../images/edit-icon.svg"
import deleteIcon from "../../images/delete-icon.svg"
import { CiSearch } from "react-icons/ci"
import { IoFilter } from "react-icons/io5"
import { TbArrowsSort } from "react-icons/tb"
import { useGetJobServiceQuery } from "../../features/OverViewAuth/overViewApi"
import { useAppDispatch } from "../../app/store"
import { offersSelect } from "../../features/OverViewAuth/overViewSlice"
import CircularIndeterminate from "../Loading/Loading"
import CenteredSpinner from "../common/CenteredSpinner"

const SingleFeature = () => {
  const { id } = useParams()
  
  const [searchParams, setSearchParams] = useSearchParams()


  const {
    data: getService,
    isFetching,
    isLoading,
  } = useGetJobServiceQuery({ id })
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  return (
    <>
      {" "}
      {isFetching || isLoading ? (
        <CenteredSpinner/>
      ) : (
        <Box className="">
          <Style.Overview>
            <Button
              className="back-btn mb-0 profile-back"
              onClick={() => navigate(`/dashboard/features`)}
            >
              <FaChevronLeft />
            </Button>
            <Grid container justifyContent="center">
              <Grid xs={12} lg={10} xl={9} className="mb-4">
                <Box className="pe-md-4">
                  {getService?.data?.map((item: any) => (
                    <Box className="feature-card single-feature-card px-1 px-md-3 d-flex flex-column flex-sm-row align-items-center justify-content-center">
                      <Box>
                        <img
                          src={item?.image}
                          className="single-feature-img mb-3"
                        />
                        <Typography
                          variant="body1"
                          className="font-20 fw-medium text-black mb-4"
                        >
                        <strong>  {item?.name}</strong>
                        </Typography>
                        <Button
                          className="view-profile mb-2"
                          onClick={() => {
                            dispatch(offersSelect(item?.name)),
                              navigate(`/dashboard/create-booking`)
                              setSearchParams({ type: id as any })
                          }}
                        >
                          Book Now
                        </Button>
                      </Box>
                      <Box className="feature-card-content py-0 w-100 px-0 ps-sm-4">
                        <Box className="desc-card mb-4">
                          <Typography
                            variant="body1"
                            className="font-16 text-black mb-3 lh-initial"
                          >
                          <strong>  Description </strong>
                          </Typography>
                          <Typography variant="body1" className="lh-initial">
                            {item?.description}
                          </Typography>
                        </Box>
                        <Box className="desc-card">
                          <Typography
                            variant="body1"
                            className="font-16 text-black mb-3 lh-initial"
                          >
                          <strong> Charges </strong> 
                          </Typography>
                          {/* <Typography variant="body1" className="lh-initial mb-2">
                          Lorem ipsum dolor sit amet consectetur. Gravida id
                          nibh eleifend amet viverra eget quis. Quam non eu nibh
                          gravida in neque nisl in eget.
                        </Typography> */}
                          <Typography className="person-text pink-text mb-0">
                            {item?.price}$/h Per person
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Style.Overview>
        </Box>
      )}
    </>
  )
}
export default SingleFeature
