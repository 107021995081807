import React, { useEffect, useState } from "react"
import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  LinearProgress,
  Paper,
  Radio,
  Rating,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"

import * as Style from "./style"
import moment from "moment"
import badReview from "../../images/bad.png"
import okayReview from "../../images/okay.png"
import greatReview from "../../images/great.png"
import awesomeReview from "../../images/awesome.png"

import { useCreateRatusMutation } from "../../features/OverViewAuth/overViewApi"
import { useNavigate } from "react-router-dom"
import { useAppSelector } from "../../app/hooks"
import { alreadyRated } from "../../features/Auth/authSlice"
import { useAppDispatch } from "../../app/store"

const SeekerProvider = () => {
  const [ratingValue, setRatingValue] = useState(0)
  const [messageRate, setMessageRate] = useState()
  const [errorMessage, setErrorMessage] = useState<string>()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [createPostRate, { data, error }] = useCreateRatusMutation()

  const onSubmit = () => {
    if (ratingValue < 2 || ratingValue > 5) {
      setErrorMessage(
        "Invalid rating value. Please choose a rating between 2 and 5.",
      )
      return
    }
    let messageRate: string = ""
    if (!messageRate || messageRate.trim() === "") {
      setErrorMessage("Message is required.")
      return
    }

    const wordCount = messageRate.split(/\s+/).length // Splits on any whitespace
    if (wordCount > 200) {
      setErrorMessage("Message exceeds the maximum length of 200 words.")
      return
    }

    const payload = {
      userType: "SEEKER",
      rating: ratingValue,
      description: messageRate,
    }

    createPostRate(payload)
  }

  useEffect(() => {
    if (data?.message) {
      dispatch(alreadyRated(data?.message))
      navigate(`/dashboard`)
    } else if (
      (error as any)?.data?.message === "Thank You for you valuable Rating"
    ) {
      dispatch(alreadyRated((error as any)?.data?.message))
    }
  }, [data, error])

  return (
    <>
      {" "}
      <Style.Overview>
        <Grid container>
          <Grid md={8}>
            <Box className="mb-5">
              <Box className="d-flex justify-content-between mb-3">
                <Typography variant="body1" className="pink-text">
                  Rate Us
                </Typography>
              </Box>
              <Box className="review-card p-4 mb-3">
                <Typography variant="body1" className="mb-5">
                  What do you think of our services
                </Typography>
                <Box className="d-flex justify-content-around align-items-start mb-5">
                  <Box>
                    <Box>
                      <input
                        type="radio"
                        name="review-radio"
                        className="reviewradio"
                        id="bad-radio"
                      />
                      <label
                        className="reviewlabel"
                        htmlFor="bad-radio"
                        onClick={() => {
                          setRatingValue(2), setErrorMessage("")
                        }}
                      >
                        <img src={badReview} className="feedback-img" />
                        <Typography className="label-text">Avarage</Typography>
                      </label>
                    </Box>
                  </Box>
                  <Box>
                    <Box>
                      <input
                        type="radio"
                        name="review-radio"
                        className="reviewradio"
                        id="okay-radio"
                      />
                      <label
                        className="reviewlabel"
                        htmlFor="okay-radio"
                        onClick={() => {
                          setRatingValue(3), setErrorMessage("")
                        }}
                      >
                        <img src={okayReview} className="feedback-img" />
                        <Typography className="label-text">Good</Typography>
                      </label>
                    </Box>
                  </Box>
                  <Box>
                    <Box>
                      <input
                        type="radio"
                        name="review-radio"
                        className="reviewradio"
                        id="great-radio"
                      />
                      <label
                        className="reviewlabel"
                        htmlFor="great-radio"
                        onClick={() => {
                          setRatingValue(4), setErrorMessage("")
                        }}
                      >
                        <img src={greatReview} className="feedback-img" />
                        <Typography className="label-text">Amazing</Typography>
                      </label>
                    </Box>
                  </Box>
                  <Box>
                    <input
                      type="radio"
                      name="review-radio"
                      className="reviewradio"
                      id="awesome-radio"
                    />
                    <label
                      className="reviewlabel"
                      htmlFor="awesome-radio"
                      onClick={() => {
                        setRatingValue(5), setErrorMessage("")
                      }}
                    >
                      <img src={awesomeReview} className="feedback-img" />
                      <Typography className="label-text">
                        Outstanding
                      </Typography>
                    </label>
                  </Box>
                </Box>
                <span className="dangerous" style={{ color: "red" }}>
                  {errorMessage}
                </span>

                <Typography variant="body1" className="mb-3">
                  Do you have any thoughts you want to share
                </Typography>
                <TextField
                  id="outlined-textarea"
                  placeholder="Type Here..."
                  multiline
                  rows="5"
                  className="review-field w-100 mb-4"
                  onChange={(e: any) => {
                    setMessageRate(e.target.value), setErrorMessage("")
                  }}
                />

                <Button className="view-profile" onClick={onSubmit}>
                  Submit
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Style.Overview>
    </>
  )
}
export default SeekerProvider
