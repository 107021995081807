import * as React from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import { useAppDispatch } from "../../app/store"
import { logout } from "../../features/Auth/authSlice"
import { CircularProgress, Grid } from "@mui/material"
// import { setResendButton } from "../../features/Auth/WrapperSlice"
import { useAppSelector } from "../../app/hooks"
import { toast } from "react-toastify"
import { api, useLogoutCustomerMutation } from "../../features/Auth/authApi"
import { overViewApi } from "../../features/OverViewAuth/overViewApi"
import { chatMessageApi } from "../../features/ChatSocket/chat"
import { notificationsApi } from "../../features/Socket/Socketio"
import { setBookingDetails } from "../../features/PostCreat/postcreateSlice"
import { chatViewApi } from "../../features/ChatAuth/chatApi"
import {
  _setMessage,
  _setSearchTickets,
  _setTicket,
  _setUnseenCount,
} from "../../features/ChatAuth/chatSlice"
import PaymentModal from "../common/commonModal/paymentmodal"
// import { useLogoutProviderMutation } from "../../features/Auth/authApi"

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid #999",
  borderRadius: "20px",
  boxShadow: 24,
  width: "400px",
  height: "270px",
  p: 4,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}
const viewProfileBtn = {
  display: "inline-block",
  width: "100px",
  padding: "8px 8px",
  border: "1px solid #F475A4",
  borderRadius: "8px",
  color: "#F475A4",
  fontSize: "14px",
  textAlign: "center",
  textDecoration: "none",
}
const viewProfileBtnTwo = {
  display: "inline-block",
  width: "100px",
  padding: "8px 8px",
  border: "1px solid #F475A4",
  borderRadius: "8px",
  color: "#fff",
  fontSize: "14px",
  textAlign: "center",
  textDecoration: "none",
  backgroundColor: "#F475A4",
  "&:hover": {
    color: "#F475A4",
  },
}
interface props {
  logoutModal: boolean
  handleClose: () => void
}
export default function LogoutConfirm({ logoutModal, handleClose }: props) {
  const socketId = useAppSelector((state) => state.auth)
  const dispatch = useAppDispatch()

  const [logoutCustomer, { data, isLoading, error }] =
    useLogoutCustomerMutation()

  const handleNoClick = () => {
    handleClose()
  }

  // React.useEffect(() => {
  //   if ((data as any)?.message) {
  //     toast.success((data as any)?.message, { autoClose: 1000 })
  //     dispatch(api.util.resetApiState()) as any
  //     dispatch(overViewApi.util.resetApiState()) as any
  //     dispatch(setBookingDetails(null as any))
  //     handleClose()
  //     dispatch(chatMessageApi.util.resetApiState())
  //     dispatch(notificationsApi.util.resetApiState())
  //   } else {
  //     toast.error((error as any)?.data?.message, { autoClose: 1000 })
  //   }
  // }, [data, error, dispatch, handleClose])

  const handleLogout = async () => {
    const payload = {
      chatId: socketId?.socketId,
      notificationId: socketId?.notificationId,
    }
    try {
      const response: any = await logoutCustomer(payload as any)

      if (response?.data?.message == "Logout successfully") {
        toast.success("Logged out successfully!", { autoClose: 1000 })
        dispatch(api.util.resetApiState()) as any
        dispatch(overViewApi.util.resetApiState()) as any
        dispatch(setBookingDetails(null as any))
        dispatch(chatMessageApi.util.resetApiState())
        dispatch(notificationsApi.util.resetApiState())
        dispatch(chatViewApi.util.resetApiState())

        // dispatch(_setMessage(null as any))
        // dispatch(_setTicket(null as any))
        // dispatch(_setSearchTickets(null as any))
        // dispatch(_setUnseenCount(null as any))
        handleClose()
      }
    } catch (mutationError) {
      console.error("Logout mutation error:", mutationError)
    }
  }

  return (
    <div>
      <Modal
        open={logoutModal as any}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}></Grid>
          <Box sx={style}>
            {/* <PaymentModal /> */}

            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ mb: 5, textAlign: "center", fontSize: "16px" }}
            >
              Are you sure you want to log out?
            </Typography>

            <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
              <Button
                sx={viewProfileBtnTwo}
                className="me-2"
                onClick={handleLogout}
              >
                {isLoading ? (
                  <Typography fontSize={1}>
                    <CircularProgress
                      style={{ color: "pink", fontSize: 20 }}
                      size={20}
                    />
                  </Typography>
                ) : (
                  "Yes"
                )}
              </Button>
              <Button sx={viewProfileBtn} onClick={handleNoClick}>
                No
              </Button>
            </Box>
          </Box>
        </Grid>
      </Modal>
    </div>
  )
}
